import React, { useMemo } from 'react';
import { InputLegend } from 'views/Wizard/components';
import { FormContextValues } from 'react-hook-form';

import Selector from '../Selector';
import { OptionId } from '../Option';

import { useGetJobTypeInterestSelectionsQuery } from 'generated/graphql';

export interface JobTypeInterestPickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultValue: OptionId | null;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
}

const JobTypeInterestPicker: React.FC<JobTypeInterestPickerProps> = ({
  name,
  defaultValue,
  clearable = false,
  required = false,
  placeholder = undefined,
  control,
  error = false,
}) => {
  const { loading: loadingJobTypeInterests, data: dataJobTypeInterests } = useGetJobTypeInterestSelectionsQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const jobTypeInterestOptions = useMemo(() => {
    if (!dataJobTypeInterests) {
      return [];
    }

    return dataJobTypeInterests.grad_interest_job_type_selection.map((fi) => ({
      label: fi.value,
      value: fi.value,
      id: fi.interest_job_type_selection_id,
    }));
  }, [dataJobTypeInterests]);

  return (
    <>
      <Selector
        options={jobTypeInterestOptions}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        required={required}
        isClearable={clearable}
        disabled={false}
        loading={loadingJobTypeInterests}
        control={control}
        isSearchable={false}
        error={error}
      />
      {error && <InputLegend error>Complete this field to move on</InputLegend>}
    </>
  );
};

export default JobTypeInterestPicker;
