import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../library';

export const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 6.4rem 1fr;
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    'logo date'
    'logo university'
    'logo degree'
    'logo gpa'
    'logo award';
  grid-column-gap: 2.7rem;
  grid-row-gap: 0.3rem;
  min-height: 10rem;
  width: 100%;
`;

export const Logo = styled.img`
  grid-area: logo;
  width: 6.4rem;
  height: 6.4rem;
  align-self: center;
  border-radius: 100px;
`;

export const DegreeName = styled.div`
  grid-area: degree;
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.4rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: fit-content;
`;

export const DegreeYear = styled.div`
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  height: fit-content;

  color: ${palette.gray500};
`;

export const GPAHonor = styled.div`
  grid-area: gpa;
  height: fit-content;
`;

export const Award = styled.div`
  grid-area: award;
  height: fit-content;
`;

export const University = styled.div`
  grid-area: university;
  height: fit-content;
`;

export const UniversityName = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: ${palette.black};
  height: fit-content;
`;

export const CardTitle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const TopEditButton = styled(EditButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
