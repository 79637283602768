import { useEffect } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import { useInsertPageViewMutation } from '../generated/graphql';

const useInsertPageViewOnRouteChange = () => {
  const history = useHistory();
  const { loading, user } = useAuth0();

  const [InsertPageView] = useInsertPageViewMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  useEffect(() => {
    return history.listen((location: { pathname: string }, action: string) => {
      const pathName = location.pathname;
      ReactGA.pageview(pathName);
      if (user && user.email_verified) {
        InsertPageView({ variables: { userId: user.sub, pathName, json: { action } } });
      }
    });
  }, [history, InsertPageView, loading, user]);
};
export default useInsertPageViewOnRouteChange;
