import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'theme';

interface WizardButtonsProps {
  disabledNext?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
}

export const WizardFormButtons: React.FC<WizardButtonsProps> = ({ onNext, onPrevious, disabledNext = false }) => {
  return (
    <FormWizardButtons>
      <FlexContainer>
        <LogoutButton onClick={onPrevious}>Previous</LogoutButton>
        <Button disabled={disabledNext} onClick={onNext}>
          Next
        </Button>
      </FlexContainer>
    </FormWizardButtons>
  );
};

export const FormWizardButtons = styled.div`
  margin: 1.2rem 0 0 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  background: ${palette.white};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  margin: 0.8rem 0;
  padding: 0 0.8rem;

  & > * {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 340px) {
    flex-direction: row;

    & > * {
      margin-bottom: 0rem;
      margin-right: 1rem;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const BtnStyle = css`
  height: 4.5rem;
  width: 16.6rem;
  border-radius: 10rem;
  border: 1px solid ${palette.alpha500Red};

  line-height: 2.5rem;
  text-align: center;
  font-family: 'RobotoBold';
  font-size: 1.8rem;
  text-decoration-line: none;
  margin: 0 4rem;
  cursor: pointer;
`;

const Button = styled.button<{ disabled?: boolean }>`
  ${BtnStyle};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  color: ${palette.white};
  background: ${palette.alpha500Red};
`;

const LogoutButton = styled.button.attrs({
  type: 'button',
})`
  ${BtnStyle};

  color: ${palette.alpha500Red};
  background: ${palette.white};
`;
