import {
  GetTestScoresByUserIdDocument,
  GetTestScoresByUserIdQuery,
  useDeleteTestsScoresMutation,
  useUpsertTestsScoresMutation,
} from 'generated/graphql';

export const useDeleteTestsScoresMutationHook = (userId: string) => {
  return useDeleteTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: deleteResultData }) => {
      const testsCache = cache.readQuery<GetTestScoresByUserIdQuery>({
        query: GetTestScoresByUserIdDocument,
        variables: { userId },
      });

      if (!deleteResultData || !deleteResultData.delete_grad_test_score) {
        return;
      }

      if (!testsCache || !testsCache.grad_test_score) {
        return;
      }

      const updatedTestsIds = deleteResultData.delete_grad_test_score.returning.map(
        ({ test }: { test: string }) => test,
      );
      const newTests = testsCache.grad_test_score.filter(
        ({ test }: { test: string }) => !updatedTestsIds.includes(test),
      );

      cache.writeQuery({
        query: GetTestScoresByUserIdDocument,
        variables: { userId },
        data: {
          grad_test_score: newTests,
        },
      });
    },
  });
};

export const useUpsertTestsScoresMutationHook = (userId: string) => {
  return useUpsertTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const testsCache = cache.readQuery<GetTestScoresByUserIdQuery>({
        query: GetTestScoresByUserIdDocument,
        variables: { userId },
      });

      if (!insertResultData || !insertResultData.insert_grad_test_score) {
        return;
      }

      if (!testsCache || !testsCache.grad_test_score) {
        return;
      }

      const updatedTestsIds = insertResultData.insert_grad_test_score.returning.map(
        ({ test_score_id }: { test_score_id: string }) => test_score_id,
      );
      const notUpdatedTests = testsCache.grad_test_score.filter(
        ({ test_score_id }: { test_score_id: string }) => !updatedTestsIds.includes(test_score_id),
      );

      cache.writeQuery({
        query: GetTestScoresByUserIdDocument,
        variables: { userId },
        data: {
          grad_test_score: [...notUpdatedTests, ...insertResultData.insert_grad_test_score.returning],
        },
      });
    },
  });
};
