import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Requirement = styled.div`
  font-size: 1.6rem;
  margin: 0 auto;
  color: ${palette.gray500};
`;

export const FirmSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 4rem);
  grid-gap: 0.8rem 1.1rem;

  width: 100%;
  margin: 4rem 0 1rem 0;

  @media (max-width: ${MEDIA_SIZE.s}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, 4rem);
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 0 3rem;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2rem;
  height: 100%;
  flex: 1;

  @media (min-width: ${MEDIA_SIZE.md}) {
    padding-top: 0;
  } ;
`;
