import React, { useState } from 'react';

import { usePopper } from 'utils/usePopper';
import { WizardElitegradLogo, HeaderLogoMobile } from 'theme/assets';

import HeaderPopper from 'components/common/Popper';
import { HelpButton, PopperInformation } from 'components/common/WizardHeader/WizardHeader.styled';

import { HelpButtonContainer, ElitegradLogo, HeaderContainer } from './Login.styled';

interface LoginHeaderProps {
  onMobile?: boolean;
}

const LoginHeader: React.FC<LoginHeaderProps> = ({ onMobile }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const { popperOpen, togglePopper, setPopperStatus } = usePopper(`login-help`);

  return (
    <HeaderContainer>
      <ElitegradLogo src={!onMobile ? WizardElitegradLogo : HeaderLogoMobile} />
      <HelpButtonContainer>
        <HelpButton ref={setReferenceElement} onClick={togglePopper}>
          ?
        </HelpButton>
      </HelpButtonContainer>
      <HeaderPopper show={popperOpen} referenceElement={referenceElement}>
        <PopperInformation>
          If you need help email us at:
          <a href="mailto:help@elitegrad.com" type="email" target="_blank" rel="noreferrer">
            help@elitegrad.com
          </a>
        </PopperInformation>
      </HeaderPopper>
    </HeaderContainer>
  );
};

export default LoginHeader;
