import React from 'react';
import styled from 'styled-components';

import { SecondaryButton } from 'components/library';
import { palette } from 'theme';

export const StyledButtonLight = styled(SecondaryButton).attrs({
  type: 'button',
})`
  min-width: 8.8rem;
  min-height: 3.2rem;
  padding: 0 1.3rem;

  border-radius: 10px;

  &:disabled {
    opacity: 0.5;

    background-color: ${palette.clearGray};
  }

  &:focus {
    outline: none;
  }
`;

export interface ButtonLightProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonLight: React.FC<ButtonLightProps> = ({ text, disabled = false, ...props }) => {
  return (
    <StyledButtonLight disabled={disabled} {...props}>
      {text}
    </StyledButtonLight>
  );
};

export default ButtonLight;
