import React from 'react';
import styled from 'styled-components';

import { palette } from 'theme';
import { WizardHeader } from 'components/common/WizardHeader';
import GoBackLink from 'components/common/GoBackLink';

interface WizardContainerInterface {
  readonly step: number;
  readonly totalSteps: number;
}

const WizardContainer: React.FC<WizardContainerInterface> = ({ step, totalSteps = 0, children }) => {
  return (
    <Wizard>
      <WizardHeader step={step} totalSteps={totalSteps} />
      {children}
    </Wizard>
  );
};

const Wizard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  text-align: left;
`;

export default WizardContainer;
