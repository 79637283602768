import styled, { css } from 'styled-components';
import { palette } from 'theme';
import { BackgroundCups } from 'theme/assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const Icon = styled.img`
  display: flex;
  width: 7.5rem;
  height: 7.2rem;
  margin-bottom: 2.5rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${BackgroundCups}) center no-repeat;
  background-size: cover;
  justify-content: center;
  height: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const MainText = styled.span`
  font-size: 1.6rem;
  font-family: 'RobotoBold';
  line-height: 2.2rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: 768px) {
    line-height: 2.4rem;
    font-size: 1.8rem;
    max-width: 35.3rem;
  }
`;

export const Title = styled.span`
  font-size: 2.7rem;
  font-family: 'RobotoBold';
  line-height: 3.5rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 4.2rem;
    max-width: 35.3rem;
  }
`;

export const SmallText = styled.span`
  font-size: 1.5rem;
  font-family: 'Roboto';
  line-height: 2rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 2rem;
    max-width: 35.3rem;
  }
`;

const BtnStyle = css`
  height: 4.5rem;
  width: 85%;
  border-radius: 10rem;
  border: 1px solid ${palette.alpha500Red};

  line-height: 2.5rem;
  text-align: center;
  font-family: 'RobotoBold';
  font-size: 1.7rem;
  text-decoration-line: none;
  margin: 0 4rem;
  cursor: pointer;
  max-width: 26.3rem;

  @media (min-width: 768px) {
    width: 16.6rem;
  }
`;

export const Button = styled.button`
  ${BtnStyle};

  color: ${palette.white};
  background: ${palette.alpha500Red};
`;

export const ToastDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const ToastTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const ToastTitleContainer = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ToastSubTitle = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
`;

export const TickIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;
