import React from 'react';
import { WizardFormInputs, WizardInputShadowed, WizardPage } from '../components';
import { Spacing } from 'components/Spacing';
import { JeannieWelcome } from 'theme/assets';
import { Container, FormContainer, JeannieLogo, MainText, Button } from './EmailStep.styled';
import { InputLegend } from 'views/Wizard/components';
import { EmailPattern } from '../../../utils/validations';
import { useForm } from 'react-hook-form';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useHistory } from 'react-router-dom';

export interface EmailStepFormVariables {
  userId: string;
  email: string;
}

interface EmailStepProps {
  onSubmit: (values: EmailStepFormVariables) => void;
  email: string;
  step: number;
  totalSteps: number;
  backGoTo: string;
  nextGoTo: string;
}

const EmailStep: React.FC<EmailStepProps> = ({ onSubmit, email, step, totalSteps, backGoTo }) => {
  const { register, control, handleSubmit, errors, setValue } = useForm<EmailStepFormVariables>();
  const { width } = useWindowDimensions();
  const history = useHistory();
  return (
    <WizardPage
      onNext={handleSubmit(onSubmit)}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      onPrevious={() => history.push(backGoTo)}
      showButtons={width <= mobileBreakpoint}
      navigable={false}
    >
      <WizardFormInputs>
        <FormContainer>
          <Container>
            <JeannieLogo src={JeannieWelcome} />
            <MainText>{'Last question:'}</MainText>
            <MainText>{'What email do you prefer hiring managers to contact you at?'}</MainText>
            <Spacing margin="1.5rem" />
            <WizardInputShadowed
              type="email"
              name="email"
              placeholder="e.g: jdoe@email.com"
              ref={register({ required: true, pattern: EmailPattern })}
              defaultValue={email}
            />
            {errors.email && <InputLegend error={!!errors.email}>Complete this field to move on</InputLegend>}
            <Spacing margin="1.9rem" />
            {width > mobileBreakpoint && <Button onClick={handleSubmit(onSubmit)}>Next</Button>}
          </Container>
        </FormContainer>
      </WizardFormInputs>
    </WizardPage>
  );
};

export default EmailStep;
