import styled, { css } from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from '../../../utils/useDimensions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media (min-width: ${MEDIA_SIZE.md}) {
    flex-direction: row;
  }
`;

export const JeannieLogo = styled.img`
  margin-bottom: 2.3rem;
  height: 10rem;
  width: 9.6rem;
`;

export const MainText = styled.span`
  font-size: 1.8rem;
  font-family: 'RobotoBold';
  line-height: 2.4rem;
  color: ${palette.beta600gray};
  max-width: 35.3rem;
  text-align: center;
`;

const BtnStyle = css`
  height: 4.5rem;
  width: 16.6rem;
  border-radius: 10rem;
  border: 1px solid ${palette.alpha500Red};

  line-height: 2.5rem;
  text-align: center;
  font-family: 'RobotoBold';
  font-size: 1.8rem;
  text-decoration-line: none;
  margin: 0 4rem;
  cursor: pointer;
`;

export const Button = styled.button`
  ${BtnStyle};

  color: ${palette.white};
  background: ${palette.alpha500Red};
`;
