import {
  useInsertEventRegitrationMutation,
  useDeleteEventRegitrationMutation,
  GetEventsRegistrationsByUserAndEventIdDocument,
  GetEventByIdDocument,
} from 'generated/graphql';

export const useDeleteRegistrationMutationHook = (eventId: string, userId: string) => {
  return useDeleteEventRegitrationMutation({
    variables: {
      userId,
      eventId,
    },
    refetchQueries: [
      {
        query: GetEventByIdDocument,
        variables: {
          eventId,
        },
      },
      {
        query: GetEventsRegistrationsByUserAndEventIdDocument,
        variables: {
          eventId,
          userId,
        },
      },
      'GetRegistrationsEventListActiveByUser',
    ],
    awaitRefetchQueries: true,
  });
};

export const useInsertRegistrationMutationHook = (eventId: string, userId: string) => {
  return useInsertEventRegitrationMutation({
    variables: {
      userId,
      eventId,
    },
    refetchQueries: [
      {
        query: GetEventByIdDocument,
        variables: {
          eventId,
        },
      },
      {
        query: GetEventsRegistrationsByUserAndEventIdDocument,
        variables: {
          eventId,
          userId,
        },
      },
      'GetRegistrationsEventListActiveByUser',
    ],
    awaitRefetchQueries: true,
  });
};
