import styled, { css } from 'styled-components';
import { palette } from 'theme';

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;

  width: 3.8rem;
  height: 1.8rem;

  cursor: pointer;
`;

export const ButtonBackground = styled.div<{ active?: boolean }>`
  position: relative;
  top: 0.1rem;
  left: 0.1rem;

  width: 3.6rem;
  height: 1.6rem;

  border: none;
  border-radius: 1.6rem;

  background-color: ${({ active }) => (active ? palette.alpha500Red : palette.gray400)};
`;

export const ButtonCircle = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  width: 1.8rem;
  height: 100%;

  border: solid 1px ${palette.gray50};
  border-radius: 50%;

  background-color: ${palette.white};

  ${({ active }) =>
    !!active
      ? css`
          transform: translateX(2rem);
          transition-duration: 0.5s;
        `
      : css`
          transform: translateX();
          transition-duration: 0.5s;
        `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleLabel = styled.div`
  margin-right: 1rem;

  font-size: 1.2rem;
  line-height: 1.2rem;
`;
