import React from 'react';

import { StyledCard } from 'components';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Container, Row, Title, Elements } from './InterestRoleCard.styled';

interface InterestRolesType {
  interest_role_id: string;
  role: string;
}

export interface InterestRolesProps {
  interestRoles: InterestRolesType[];
}

const InterestRolesListCard: React.FC<InterestRolesProps> = ({ interestRoles }) => {
  return (
    <StyledCard>
      <Container>
        <Row>
          <Title>Type of Role:</Title>
          {interestRoles?.map((role, index) => (
            <Elements key={role.interest_role_id}>
              {index !== interestRoles.length - 1 ? `${role?.role}, ` : role?.role}
            </Elements>
          ))}
        </Row>
      </Container>
    </StyledCard>
  );
};

export default InterestRolesListCard;
