import React, { SyntheticEvent, useState, useCallback, ReactChild, ReactFragment, ReactPortal } from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'theme';
import WidgetCard from './WidgetCard';
import { ReactComponent as ChevronDown } from 'theme/assets/chevron-down-solid.svg';

export interface CollapsibleWidgetCardProps {
  defaultCollapsed?: boolean;
  fixed?: boolean;
  withTitle?: boolean;
}

const StyledArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;
  fill: ${palette.gray400};
  cursor: pointer;
  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

const ArrowContainer = styled.div<{ collapsed?: boolean; fixed?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: ${({ fixed }) => (fixed ? 'none' : `1px solid ${palette.gray100}`)};
  margin-top: ${({ collapsed }) => (collapsed ? '0' : '0.3rem')};
  justify-content: center;
  height: ${({ fixed }) => (fixed ? '0' : '2rem')};
`;

const AnimationContainer = styled.div<{ transitionName?: string; open?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;

  max-height: ${({ open }) => (open ? '50rem' : '0')};
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
`;

const CollapsibleWidgetCard: React.FC<CollapsibleWidgetCardProps> = ({
  defaultCollapsed = true,
  children,
  fixed = false,
  withTitle = false,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [firstChild, ...restChildren] = React.Children.toArray(children);
  let restChildrenWithoutFirst: ReactChild | ReactFragment | ReactPortal | undefined = [];
  if (withTitle) {
    restChildrenWithoutFirst = [...restChildren].slice(1);
  }

  const handleOnClick = useCallback((e: SyntheticEvent) => {
    setCollapsed((c) => !c);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <WidgetCard collapsible={!fixed}>
      <>
        {firstChild}
        {withTitle && restChildren?.[0]}
        <AnimationContainer open={!collapsed}>
          {withTitle
            ? restChildren?.length - 1 > 0 && restChildrenWithoutFirst
            : restChildren?.length > 0 && restChildren}
        </AnimationContainer>
        <ArrowContainer collapsed={collapsed} fixed={fixed}>
          {(withTitle ? restChildren?.length - 1 > 0 && !fixed : restChildren?.length > 0 && !fixed) && (
            <StyledArrowIcon props={{ up: !collapsed }} onClick={handleOnClick} />
          )}
        </ArrowContainer>
      </>
    </WidgetCard>
  );
};

export default CollapsibleWidgetCard;
