import styled from 'styled-components';
import { palette } from 'theme';
import { IonsIcon } from 'theme/assets';

export const Container = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid ${palette.gray200};
  box-sizing: border-box;
  border-radius: 5px;
`;

export const FilterButton = styled.div`
  width: 5rem;
  height: 5rem;
  background-image: url(${IonsIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${palette.gray100};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
