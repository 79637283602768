import { GetExtraStepInfoQuery } from 'generated/graphql';
import { createOptionId, OptionId } from 'components/Option';

export interface ExtraStepOptions {
  isAthlete: boolean;
  languages: OptionId[];
  computerLanguages: OptionId[];
  achievements: OptionId;
  volunteers: OptionId;
}

export const defaultExtraOptions: ExtraStepOptions = {
  isAthlete: false,
  languages: [],
  computerLanguages: [],
  achievements: { value: '', label: '' },
  volunteers: { value: '', label: '' },
};

export const dataToExtraOptions = (data?: GetExtraStepInfoQuery): ExtraStepOptions => {
  if (!data?.grad_profile[0]) return defaultExtraOptions;

  const dataInfo = data.grad_profile[0];

  const isAthlete = dataInfo.student_athlete;

  const languages = dataInfo.spoken_languages.map((language) =>
    createOptionId(language.language, language.spoken_language_id),
  );
  const computerLanguages = dataInfo.computer_languages.map((programingLanguage) =>
    createOptionId(programingLanguage.language, programingLanguage.computer_language_id),
  );
  const achievements = createOptionId(
    dataInfo.achievements[0]?.description || '',
    dataInfo.achievements[0]?.achievement_id,
  );
  const volunteers = createOptionId(dataInfo.volunteers[0]?.description || '', dataInfo.volunteers[0]?.volunteer_id);
  return { languages, computerLanguages, achievements, volunteers, isAthlete };
};

export const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
  !initial ? [] : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

export const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
  !selected ? [] : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));
