import styled from 'styled-components';
import { palette } from 'theme';

export const DetailList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;

  & > *::after {
    content: '';
    background: ${palette.beta400gray};
    position: absolute;
    bottom: 25%;
    right: 0;
    height: 50%;
    width: 0.8px;
  }

  & > *:last-child::after {
    width: 0px;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0 1.8rem;
`;

export const DetailTitle = styled.div`
  font-family: 'Roboto';
  font-size: 1rem;
  line-height: 1.4rem;
  text-transform: capitalize;
`;
export const DetailScore = styled.div`
  font-family: 'RobotoBold';
  font-size: 1.6rem;
  line-height: 1.9rem;
`;

export const TestScore = styled.div`
  float: left;

  display: flex;
`;

export const TestScoreCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.6rem 0;
  padding: 0.8rem;
`;

export const TestScoreTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-right: 0.3rem;
`;

export const Denominator = styled.span`
  font-family: 'Roboto';
  font-size: 3rem;
  line-height: 3.5rem;
  color: ${palette.beta400gray};
`;

export const Numerator = styled.span`
  font-family: 'RobotoBold';
  font-size: 3rem;
  line-height: 3.5rem;
`;

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
