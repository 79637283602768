import React, { useState, useContext, useCallback } from 'react';

export const initialPopperContext: {
  setCurrentPopperId: () => undefined;
  isPopperOpen: () => boolean;
} = {
  setCurrentPopperId: () => undefined,
  isPopperOpen: () => false,
};

export const UsePopperContext = React.createContext<{
  setCurrentPopperId: (newCurrentId: string | null) => void;
  isPopperOpen: (popperId: string) => boolean;
}>(initialPopperContext);

export const usePopper = (
  currentId: string,
): {
  togglePopper: () => void;
  popperOpen: boolean;
  setPopperStatus: (open: boolean) => void;
} => {
  const { setCurrentPopperId, isPopperOpen } = useContext(UsePopperContext);

  const popperOpen = isPopperOpen(currentId);

  const togglePopper = useCallback(() => {
    setCurrentPopperId(popperOpen ? null : currentId);
  }, [currentId, popperOpen, setCurrentPopperId]);

  const setPopperStatus = useCallback(
    (open: boolean) => {
      setCurrentPopperId(open ? currentId : null);
    },
    [currentId, setCurrentPopperId],
  );

  return { togglePopper, popperOpen, setPopperStatus };
};

export const usePopperCloser = (): { closePoppers: () => void } => {
  const { setCurrentPopperId } = useContext(UsePopperContext);

  const closePoppers = () => setCurrentPopperId(null);

  return { closePoppers };
};

export const PopperContextProvider: React.FC = ({ children }) => {
  const [currentPopperId, setCurrentPopperId] = useState<string | null>(null);

  const isPopperOpen = (popperId: string) => currentPopperId === popperId;

  return <UsePopperContext.Provider value={{ setCurrentPopperId, isPopperOpen }}>{children}</UsePopperContext.Provider>;
};
