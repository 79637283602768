import React from 'react';
import { Title, SubTitle } from '../Wizard.styled';
import { Requirement, FirmSelectionGrid } from './FirmsInterestStep.styled';
import { Container } from '../Wizard.styled';

import { Button } from '../../../components/common/Button';

export interface FirmOfInterestOption {
  id: string;
  label: string;
  value: string;
  selected: boolean;
}

interface FirmsOfInterestStepProps {
  options: FirmOfInterestOption[];
  onSelect: (selectedId: string) => void;
}

const FirmsOfInterestStep: React.FC<FirmsOfInterestStepProps> = ({ options, onSelect }) => (
  <Container>
    <Title>Select firms of interest</Title>
    <Requirement>(Pick up to 3)</Requirement>
    <SubTitle>
      {`EliteGrad connects top college students with their dream jobs in the financial services industry.
          But finance is a broad space, so please let us know your top 3 firm types of interest.`}
    </SubTitle>
    <FirmSelectionGrid>
      {options.map((option) => (
        <Button
          key={option.id}
          size="md"
          color={option.selected ? 'beta' : 'white'}
          hasShadow
          noPadding
          onClick={() => onSelect(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </FirmSelectionGrid>
  </Container>
);

export default FirmsOfInterestStep;
