import React, { useState } from 'react';
import styled from 'styled-components';
import { WizardFormInputs, WizardPage, SUBMIT_GO_TO } from '../components';
import { InputLegend } from 'views/Wizard/components';
import { Title, SubTitle } from '../Wizard.styled';
import { TitleContainer, Divider, FormContainer } from './AcademicStep.styled';
import { WizardRequiredInputLabel } from '../components/library';
import {
  UniversitySelectorCreate,
  DegreePickerOption,
  MajorSelector,
  MonthYearPicker,
  OptionId,
  YesNoPicker,
  StyledCheckboxContainer,
} from 'components';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';

import DegreePicker from '../../../components/DegreePicker';
import CheckboxWithController from '../../../components/CheckboxWithController';
import GPAInput from '../../../components/GPAInput';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export interface DegreeFormVariables {
  gradEndMonth: { value: number; label: string };
  gradEndYear: { value: number; label: string };
  GPA: string;
  gpaOutOf: { value: number; label: string };
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  completed: boolean;
  majors: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  gpaNotApplicable: boolean;
  standarizedTest: string;
}

interface AcademicStepProps {
  onSubmit: (values: DegreeFormVariables, watchedGPANotApplicable: boolean) => void;
  majorOptions: OptionId[];
  step: number;
  isLastStep: boolean;
  cancelSkipGoTo: string;
  gpa: number | null;
  gpa_outof: number;
  complete_month_numeric: number | null;
  complete_year: number;
  gpa_not_applicable: boolean;
  degree_level: string;
  degree: string;
  fillInUniversity: string;
  universityName: string;
  universityId: string;
  totalSteps: number;
  backGoTo: string;
}

const AcademicStep: React.FC<AcademicStepProps> = ({
  onSubmit,
  majorOptions,
  step,
  gpa,
  gpa_outof,
  complete_month_numeric,
  complete_year,
  degree,
  degree_level,
  gpa_not_applicable,
  fillInUniversity,
  universityName,
  universityId,
  totalSteps,
  backGoTo,
}) => {
  const { register, setValue, handleSubmit, watch, errors, control, clearError } = useForm<DegreeFormVariables>();
  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');
  const history = useHistory();
  const { width } = useWindowDimensions();

  return (
    <WizardPage
      onNext={handleSubmit((values) => onSubmit(values, watchedNotApplicable))}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons
      onPrevious={() => history.push(backGoTo || SUBMIT_GO_TO)}
    >
      <WizardFormInputs>
        <TitleContainer>
          <Title>{'Academic information'}</Title>
          <SubTitle>
            <div>Create your account with your current degree information.</div>
            <div>
              You will be able to add additional information to your extended profile after you have set up your
              account.
            </div>
          </SubTitle>
        </TitleContainer>
        <FormContainer>
          <Divider>
            <WizardRequiredInputLabel error={!!errors.universitySelectionIdOption}>
              What college or university are you currently attending?
            </WizardRequiredInputLabel>

            <UniversitySelectorCreate
              required
              fillInValue={fillInUniversity}
              value={{ id: universityId, label: universityName, value: universityName }}
              name={'universitySelectionIdOption'}
              control={control}
              error={!!errors.universitySelectionIdOption}
            />
            {errors.universitySelectionIdOption && <InputLegend error>Complete this field to move on</InputLegend>}

            <WizardRequiredInputLabel error={!!(errors.gradEndMonth || errors.gradEndYear)}>
              When will you graduate?
            </WizardRequiredInputLabel>
            <MonthYearPicker
              required
              monthFieldName="gradEndMonth"
              yearFieldName="gradEndYear"
              errors={errors}
              control={control}
              defaultMonth={complete_month_numeric}
              defaultYear={complete_year}
            />
            <WizardRequiredInputLabel error={!!errors.degreeSelectionOption}>
              What degree will you earn?
            </WizardRequiredInputLabel>
            <DegreePicker
              name={'degreeSelectionOption'}
              control={control}
              defaultDegree={degree}
              defaultLevel={degree_level}
              error={!!errors.degreeSelectionOption}
              clearable
              required
            />
            {errors.degreeSelectionOption && <InputLegend error>Complete this field to move on</InputLegend>}
          </Divider>
          <Divider>
            <WizardRequiredInputLabel error={!!errors.majors}>What is your Major?</WizardRequiredInputLabel>
            <MajorSelector
              required
              majorsValue={majorOptions}
              name={'majors'}
              clearErrors={() => clearError('majors')}
              register={register}
              setValue={setValue}
              error={!!errors.majors}
            />

            <StyledCheckboxContainer>
              <CheckboxWithController name="gpaNotApplicable" control={control} defaultChecked={!!gpa_not_applicable} />
              {'GPA is not applicable'}
            </StyledCheckboxContainer>
            {!watchedNotApplicable && (
              <>
                <WizardRequiredInputLabel error={!!errors.GPA}>What is your GPA?</WizardRequiredInputLabel>
                <GPAInput
                  required
                  defaultOutOf={gpa_outof}
                  defaultGPA={gpa}
                  watchedOutOf={watchedOutOf}
                  gpaFieldName="GPA"
                  outOfFieldName="gpaOutOf"
                  control={control}
                  register={register}
                  error={!!errors.GPA}
                />
              </>
            )}
            {!watchedNotApplicable && errors.GPA && (
              <InputLegend error>{errors.GPA.message || 'Complete this field to move on'}</InputLegend>
            )}

            <YesNoPicker
              name="standarizedTest"
              required
              title="Have you taken any standarized tests?"
              register={register}
              error={!!errors.standarizedTest}
            />
            {errors.standarizedTest && <InputLegend error>Complete this field to move on</InputLegend>}
          </Divider>
        </FormContainer>
      </WizardFormInputs>
    </WizardPage>
  );
};

export default AcademicStep;
