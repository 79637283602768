import { useAuth0 } from '../react-auth0-spa';
import { useGetUploadDocByUserQuery } from 'generated/graphql';

const useUserHasResume = () => {
  const {
    user: { sub: userId },
  } = useAuth0();
  const { data: resume } = useGetUploadDocByUserQuery({
    variables: { userId },
    errorPolicy: 'all',
  });
  return Boolean(resume?.grad_upload_doc.length);
};

export default useUserHasResume;
