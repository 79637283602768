import React, { useEffect } from 'react';
import EventDetail from './EventDetail';
import { useParams } from 'react-router-dom';
import { useDeleteRegistrationMutationHook, useInsertRegistrationMutationHook } from './Event.hooks';
import { useGetEventByIdQuery, useGetEventsRegistrationsByUserAndEventIdLazyQuery } from 'generated/graphql';
import { useGetStudentGPAQueryHook } from '../../../hooks';
import { Spinner } from '../../../components';
import { useUserHasResume } from '../../../hooks';
import moment from 'moment';
import { useAuth0 } from '../../../react-auth0-spa';
import { formatMonthYear } from 'utils/dateUtils';

const EventController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth0();
  const userHasResume = useUserHasResume();

  const { data: dataEvent, loading: loadingEvent, error, refetch: refetchEvent } = useGetEventByIdQuery({
    variables: {
      eventId: id,
    },
    fetchPolicy: 'network-only',
  });

  const event = dataEvent?.recruit_event[0];
  const [subscribe, { loading: loadingSubscribe }] = useInsertRegistrationMutationHook(id, user.sub);
  const [unsubscribe, { loading: loadingUnsubscribe }] = useDeleteRegistrationMutationHook(id, user.sub);
  const [
    checkIfIsubcribed,
    { data: eventsRegistry, loading: loadingCheckingRegistration },
  ] = useGetEventsRegistrationsByUserAndEventIdLazyQuery({
    variables: {
      eventId: id,
      userId: user.sub,
    },
  });
  useEffect(() => {
    checkIfIsubcribed();
  }, [id, checkIfIsubcribed]);

  const getSubscribed = async () => {
    const { data: dataEvent2 } = await refetchEvent({
      eventId: id,
    });

    const event = dataEvent2?.recruit_event[0];
    if (event && (event.registration_count < event.capacity || event.capacity === 0)) {
      return subscribe();
    }
  };

  const isLoading = loadingEvent || loadingUnsubscribe || loadingSubscribe || loadingCheckingRegistration;

  if (isLoading || !event) return <Spinner />;
  if (!id || !dataEvent || error) return <div>Error</div>;
  const {
    title,
    event_type,
    description,
    start_datetime,
    end_datetime,
    capacity,
    address,
    event_url,
    timezone,
    event_id,
    image,
    account: { company },
    registration_count,
  } = event;

  const formattedStartTimestamp = formatMonthYear(start_datetime);
  const formattedEndTimestamp = moment(end_datetime).format('MMMM DD, YYYY - hh:mm A');
  const isFull = capacity === 0 ? false : registration_count >= capacity;
  return (
    <EventDetail
      companyName={company?.name || ''}
      companyAddress={company?.address || ''}
      companyId={company?.account_id || ''}
      eventId={event_id}
      eventType={event_type}
      timezone={timezone}
      eventImage={image || ''}
      isFull={isFull}
      event_url={event_url || ''}
      address={address || ''}
      isRegistrated={!!eventsRegistry?.grad_event_registration?.[0]}
      startDatetime={start_datetime}
      endDatetime={end_datetime}
      capacity={capacity}
      title={title}
      description={description || ''}
    />
  );
};

export default EventController;
