import styled from 'styled-components';
import palette from '../../../../theme/palette';

export const StyledFieldRow = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${palette.darkGray};

  &:nth-child(1n) {
    margin-bottom: 4.4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > :nth-child(1n) {
    margin-bottom: 1rem;
  }

  > :last-child {
    margin-bottom: 0rem;
  }
`;
