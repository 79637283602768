import React from 'react';
import { useHistory } from 'react-router-dom';

import { HeadingText } from 'theme/typography';
import { Container, BackArrow } from './JobDetailHeader.styled';
import { EGLogoBackgroundMobile } from 'theme/assets';

import { useWindowDimensions, inMobile } from 'utils/useDimensions';

export const JobDetailHeader: React.FC = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  return (
    <Container bgImage={inMobile(width) ? EGLogoBackgroundMobile : undefined}>
      <BackArrow onClick={() => history.goBack()} />
      {!inMobile(width) && <HeadingText variant="h4">Opportunities</HeadingText>}
    </Container>
  );
};
