import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserType from 'types/User';

import { SpinnerPage } from 'components';
import Profile from './Profile/Profile';
import ResumeUploader from './Profile/ResumeUploader';
import PhotoUploader from './Profile/PhotoUploader';
import MyCard, { WorkDetail } from './MyCard';
import DegreeDetailController from './DegreeDetail';
import Jobs from './JobsList';
import Job from './Job';
import TestScores from './TestScores';
import Company from './Company';
import Companies from './CompanyList';
import Events from './EventList';
import Event from './Event';

import { StudentViewPaths } from './StudentViewRoutes';
import Login from 'views/Login';

export interface StudentRoutesProps {
  user: UserType;
  match?: { path: string };
}

const StudentView: React.FC<StudentRoutesProps> = (props) => {
  if (!props.match) return <SpinnerPage />;
  const {
    login,
    profile,
    profileCV,
    profilePhoto,
    degreeNew,
    testScoresEdit,
    home,
    event,
    events,
    companies,
    company,
    jobs,
    job,
    degreeEdit,
    worksEdit,
    worksNew,
  } = StudentViewPaths;
  return (
    <Switch>
      <Route exact path={login} component={Login} />

      <Route exact path={profile} component={Profile} />
      <Route exact path={profileCV} component={ResumeUploader} />
      <Route exact path={profilePhoto} component={PhotoUploader} />

      <Route exact path={home} component={MyCard} />

      <Route exact path={degreeEdit} component={DegreeDetailController} />
      <Route exact path={degreeNew} component={DegreeDetailController} />

      <Route exact path={testScoresEdit} component={TestScores} />

      <Route exact path={worksNew} component={WorkDetail} />
      <Route exact path={worksEdit} component={WorkDetail} />

      <Route exact path={jobs} component={Jobs} />
      <Route exact path={job} component={Job} />

      <Route exact path={companies} component={Companies} />
      <Route exact path={company} component={Company} />

      <Route exact path={events} component={Events} />
      <Route exact path={event} component={Event} />

      <Redirect to={home} />
    </Switch>
  );
};

export default StudentView;
