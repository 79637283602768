import styled from 'styled-components';
import palette from 'theme/palette';

export const StyledBackLink = styled.a`
  display: flex;
  align-items: center;

  border: 0;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;

  color: ${palette.white};
  line-height: 1.8rem;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
`;

export const StyledImg = styled.img`
  display: inline-block;
  vertical-align: middle;

  margin-right: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
`;
