import styled from 'styled-components';
import palette from './palette';

type HeadingSizeType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingTextProps {
  underline?: boolean;
  color?: string;
  variant: HeadingSizeType;
}

const HEADING_SIZE = {
  h1: '',
  h2: '3rem',
  h3: '',
  h4: '2.5rem',
  h5: '',
  h6: '',
};

export const HeadingText = styled.span<HeadingTextProps>`
  font-family: 'RobotoBold';
  font-size: ${({ variant }) => HEADING_SIZE[variant]};
  color: ${({ color }) => color || palette.gray900};
  ${({ underline }) => underline && 'text-decoration: underline'};
`;

type TextType = 'xl' | 'l' | 'm' | 's' | 'xs';

interface TextProps {
  bold?: boolean;
  underline?: boolean;
  variant: TextType;
  color?: string;
  opacity?: number;
}

const TEXT_SIZE = {
  xl: '1.6rem',
  l: '1.4rem',
  m: '1.2rem',
  s: '1rem',
  xs: '0.8rem',
};

export const Text = styled.span<TextProps>`
  font-family: ${({ bold }) => (bold ? 'RobotoBold' : 'Roboto')};
  font-size: ${({ variant }) => TEXT_SIZE[variant]};
  color: ${({ color }) => color || palette.gray900};
  ${({ underline }) => underline && 'text-decoration: underline'};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};

  text-overflow: ellipsis;
  overflow: hidden;
`;
