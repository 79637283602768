import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';
import { EGLogo as ProfilePicture } from 'theme/assets';
import PhotoUploader from './PhotoUploader';
import { StudentsProfileLayout } from 'components';

import { useGetProfilePhotoQuery, useUploadProfilePhotoMutation } from 'generated/graphql';

const PhotoUploaderController: React.FC = () => {
  const {
    user: { sub: userId },
  } = useAuth0();
  const history = useHistory();

  const [image, setImage] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [editor, setEditor] = useState<AvatarEditor>();
  const [zoom, setZoom] = useState('1');

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const { loading } = useGetProfilePhotoQuery({
    variables: { userId },
    errorPolicy: 'all',
    onCompleted: (data) => {
      const {
        grad_profile: [{ profile_image }],
      } = data;
      if (profile_image) {
        setImage(profile_image);
        setImageLoaded(true);
      } else {
        setImage(ProfilePicture);
      }
    },
    onError: () => undefined,
  });

  const [uploadPhotoMutation] = useUploadProfilePhotoMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const onDelete = () => {
    setImageLoaded(false);
    setImage(ProfilePicture);
  };

  const onFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          setImage((img as HTMLCanvasElement).toDataURL(file.type));
          setImageLoaded(true);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  const onCancel = () => history.goBack();

  const onSubmit = async () => {
    if (imageLoaded && editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const dataUrl = canvasScaled.toDataURL();
      await uploadPhotoMutation({ variables: { userId, profileImage: dataUrl } });
    } else {
      await uploadPhotoMutation({ variables: { userId, profileImage: null } });
    }
    history.goBack();
  };

  return (
    <StudentsProfileLayout loading={loading}>
      <PhotoUploader
        editorRef={editorRef}
        image={image || ''}
        imageLoaded={imageLoaded}
        onCancel={onCancel}
        onDelete={onDelete}
        onZoom={onZoom}
        zoom={zoom}
        onFile={onFile}
        onSubmit={onSubmit}
      />
    </StudentsProfileLayout>
  );
};

export default PhotoUploaderController;
