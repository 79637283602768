const MAX_PAGE_BUTTONS = 5;

export interface PaginationState {
  count: number;
  offset: number;
  limit: number;
}

const PAGE_SIZE = 12;
const INITIAL_OFFSET = 0;

const getPagesCount = (pagination: PaginationState): number => {
  return Math.ceil(pagination.count / pagination.limit);
};

const getPageNumber = (pagination: PaginationState): number => {
  if (pagination.count === 0) {
    return 0;
  }
  return Math.floor(pagination.offset / pagination.limit) + 1;
};

const getPageNumbersToDisplay = (pagination: PaginationState): number[] => {
  const pagesCount = getPagesCount(pagination);
  const pageNumber = getPageNumber(pagination);

  const firstPage = Math.min(Math.max(1, pageNumber - Math.floor(MAX_PAGE_BUTTONS / 2)), pagesCount);

  const lastPage = Math.min(pagesCount, firstPage + MAX_PAGE_BUTTONS - 1);

  return Array.from(Array(lastPage - firstPage + 1)).map((_, i) => firstPage + i);
};

const getOffset = (page: number, limit: number): number => {
  return page * limit - limit;
};

export { PAGE_SIZE, INITIAL_OFFSET, getPagesCount, getPageNumber, getPageNumbersToDisplay, getOffset };

export default PaginationState;
