import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/useDimensions';
import { palette } from '../../../../../theme';

export const Container = styled.div`
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 4rem;
  overflow: scroll;
`;

export const SubTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.7rem;
`;

export const AboutCompany = styled.div`
  font-size: 1.3rem;
`;

export const AboutCompanyDescription = styled.div`
  padding: 1rem;
`;

export const Info = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
  font-size: 1.2rem;
`;
export const InfoItemLabel = styled.span`
  font-weight: bold;
  color: ${palette.gray};
  &:after {
    content: ': ';
  }
`;

export const JobListTabContainer = styled.div`
  height: 40vh;
  overflow-y: scroll;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: 100%;
    overflow-y: visible;
  }
`;
