import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';
import { ReactComponent as Plus } from 'theme/assets/plus-solid.svg';
import { palette } from 'theme';

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div<{ heightAuto?: boolean; withDelete?: boolean }>`
  position: absolute;
  left: ${({ withDelete }) => (withDelete ? '0' : '50%')};
  bottom: ${({ withDelete }) => (withDelete ? '0' : '')};
  top: ${({ withDelete }) => (withDelete ? '' : '50%')};
  z-index: 1001;
  transform: ${({ withDelete }) => (withDelete ? '' : 'translate(-50%, -50%)')};
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 1rem;
  width: 100vw;
  height: ${({ heightAuto }) => (heightAuto ? 'fit-content' : '100%')};
  background: ${palette.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @media (min-width: ${MEDIA_SIZE.md}) {
    max-height: 85vh;
    height: ${({ withDelete }) => (withDelete ? '' : '100%')};
    width: 43rem;
    min-height: ${({ withDelete }) => (withDelete ? '' : '80vh')};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } ;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 15rem);
  overflow-y: auto;

  @media (min-width: ${MEDIA_SIZE.md}) {
    height: calc(100% - 8rem);
  } ;
`;

export const SubmitButton = styled.button<{ withDelete?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 4.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${palette.beta700gray};
  border-radius: 100px;
  font-family: 'RobotoBold';
  color: ${palette.white};
  font-size: 1.8rem;
  line-height: 2.5rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    width: ${({ withDelete }) => (withDelete ? '33%' : '16.6rem')};
  } ;
`;

export const CancelButton = styled.button<{ withDelete?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 4.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${palette.white};
  border-radius: 100px;
  font-family: 'RobotoBold';
  color: ${palette.beta700gray};
  font-size: 1.8rem;
  line-height: 2.5rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    width: ${({ withDelete }) => (withDelete ? '33%' : '16.6rem')};
  } ;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 4.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${palette.white};
  border-radius: 100px;
  font-family: 'RobotoBold';
  border: 2px solid ${palette.alpha500Red};
  color: ${palette.alpha500Red};
  font-size: 1.8rem;
  line-height: 2.5rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    width: 33%;
  } ;
`;

export const ConfirmDelete = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 4.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${palette.alpha500Red};
  border-radius: 100px;
  font-family: 'RobotoBold';
  border: 2px solid ${palette.alpha500Red};
  color: ${palette.white};
  font-size: 1.8rem;
  line-height: 2.5rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    width: 33%;
  } ;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-evenly;
  width: 100%;
  height: 15rem;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  @media (min-width: ${MEDIA_SIZE.md}) {
    height: 8rem;
    flex-direction: row;

    & > * {
      margin: 0 1rem;
    }
  } ;
`;

export const ExitCross = styled(Plus)`
  position: absolute;
  right: 1rem;
  top: 2rem;

  width: 4rem;
  z-index: 2;
  cursor: pointer;
  transform: rotate(45deg);
`;

export const EditTitle = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${palette.alpha500Red};
  font-family: 'RobotoBold';
`;

export const DeleteContainer = styled.div`
  height: 20rem;
  font-family: 'Roboto';
  color: ${palette.gray500};
  font-size: 1.6rem;
  line-height: 2.1rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    height: 10rem;
  } ;
`;
