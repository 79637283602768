import React, { useState } from 'react';

import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';
import { PhotoLayer } from 'theme/assets';
import UploadButton from './UploadButton/UploadButton';
import DeletePicture from './DeletePicture/DeletePicture';
import { AvatarContainer, ScaleInput, Layer, ButtonContainer } from './PictureEditor.styled';

interface PictureEditor {
  queryImage?: string;
  onChange: (image: string) => void;
  onDelete: () => void;
  editorRef?: (editor: AvatarEditor) => void;
  fromWizard?: boolean;
}

const PictureEditor: React.FC<PictureEditor> = ({
  queryImage,
  onChange,
  onDelete,
  editorRef = null,
  fromWizard = false,
}) => {
  const [zoom, setZoom] = useState('1');

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          const dataUrl = (img as HTMLCanvasElement).toDataURL(file.type);
          onChange(dataUrl);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  return (
    <AvatarContainer>
      <AvatarEditor
        ref={editorRef}
        border={0}
        color={[0, 0, 0, 0]}
        scale={parseFloat(zoom)}
        image={queryImage}
        style={{ width: '24rem', height: '24rem', position: 'relative', zIndex: 2 }}
      />
      <Layer src={PhotoLayer} />
      <ScaleInput onChange={onZoom} value={zoom} disabled={!queryImage}></ScaleInput>
      <ButtonContainer fromWizard={fromWizard}>
        <UploadButton onFile={onUploadFile} />
        <DeletePicture onDelete={onDelete} />
      </ButtonContainer>
    </AvatarContainer>
  );
};

export default PictureEditor;
