import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  ToastDiv,
  ToastTitleContainer,
  TickIcon,
  ToastTitle,
} from 'views/Student/Event/EventDetail/EventDetailHeader/EventDetailHeader.styled';
import { Spinner } from 'components';
import { SuccessIconTick } from 'theme/assets';
import { useAuth0 } from '../../../../react-auth0-spa';

import { useGetCompanyByEventListQuery, GetEventListActivePartsFragment } from 'generated/graphql';
import { useDeleteRegistrationMutationHook, useInsertRegistrationMutationHook } from '../../Event/Event.hooks';
import EventCard from '../EventCard';

interface EventListCardProps {
  account_id: string;
  event: GetEventListActivePartsFragment;
  isRegistrated: boolean;
}

const JobsListCard: React.FC<EventListCardProps> = ({ account_id, event, isRegistrated }) => {
  const { user } = useAuth0();
  const { data, loading } = useGetCompanyByEventListQuery({
    skip: !account_id,
    variables: {
      accountId: account_id,
    },
  });

  const [insertRegistration] = useInsertRegistrationMutationHook(event.event_id, user.sub);
  const [deleteRegistration] = useDeleteRegistrationMutationHook(event.event_id, user.sub);

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} />
        <ToastTitle>You’ve registered to participate on the event successfully</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>This event is full</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  const isFull = event?.capacity === 0 ? false : event?.registration_count >= event?.capacity;

  const onClickRegister = (e: Event) => {
    isRegistrated ? deleteRegistration() : isFull ? notifyError() : insertRegistration().then(() => notifySuccess());
    e.stopPropagation();
  };

  const company = data?.recruit_company[0];

  if (!company || loading) return <Spinner />;

  return (
    <EventCard
      isRegistrated={isRegistrated}
      end_datetime={event.end_datetime}
      start_datetime={event.start_datetime}
      key={event.event_id}
      capacity={event.capacity}
      event_type={event.event_type}
      event_id={event.event_id}
      image={event.image || null}
      title={event.title}
      account_id={account_id}
      timezone={event.timezone}
      address={event.address}
      event_url={event.event_url}
      companyName={company?.name}
      companyAvatar={company?.logo || ''}
      companyLocation={company?.address || ''}
      onClickRegister={onClickRegister}
      registration_count={event?.registration_count}
    />
  );
};

export default JobsListCard;
