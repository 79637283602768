import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components';
import { palette } from 'theme';

const SpinnerPage: React.FC = () => {
  return (
    <StyledSpinnerPage>
      <StyledSpinner />
    </StyledSpinnerPage>
  );
};

const StyledSpinner = styled(Spinner)`
  margin: unset;
  width: unset;

  color: ${palette.alpha500Red};
`;

const StyledSpinnerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: ${palette.white};
`;

export default SpinnerPage;
