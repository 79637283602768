import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  overflow-y: scroll;

  @media (max-width: ${MEDIA_SIZE.md}) {
    position: relative;
  }
`;
