import React from 'react';
import {
  Container,
  DesktopContainer,
  LeftContainer,
  MobileWrapper,
  RightContainer,
  RightContainerWithoutCard,
} from './MainViewLayout.styled';
import NavBar from '../NavBar/NavBar';
import TopBar from '../TopBar/TopBar';
import { useWindowDimensions, inMobile } from 'utils/useDimensions';
import StudentWidget from 'views/Student/StudentWidget';

interface MainViewProps {
  children: React.ReactNode;
  withRightCard?: boolean;
}

const MainViewLayout: React.FC<MainViewProps> = ({ children, withRightCard = true }) => {
  const { width } = useWindowDimensions();

  return (
    <Container>
      {!inMobile(width) ? (
        <>
          <TopBar />
          <DesktopContainer>
            <LeftContainer>
              <StudentWidget />
            </LeftContainer>
            {withRightCard ? (
              <RightContainer>{children}</RightContainer>
            ) : (
              <RightContainerWithoutCard>{children}</RightContainerWithoutCard>
            )}
          </DesktopContainer>
        </>
      ) : (
        <MobileWrapper>
          {children}
          <NavBar />
        </MobileWrapper>
      )}
    </Container>
  );
};

export default MainViewLayout;
