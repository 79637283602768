import React from 'react';
import ProfileUnderReview from './ProfileUnderReview';

import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO } from '../components';

const ProfileStepContainer: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  return <ProfileUnderReview step={step} totalSteps={totalSteps} backGoTo={backGoTo || ''} nextGoTo={nextGoTo || ''} />;
};

export default ProfileStepContainer;
