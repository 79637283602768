import styled from 'styled-components';
import { palette } from 'theme';
import { WizardStepTitle } from '../components';

export const Title = styled(WizardStepTitle)`
  margin-bottom: 4.8rem;
`;

export const Photo = styled.div`
  width: 100%;
  height: 80%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 34rem;
  margin: 1.6rem auto 1.4rem;
`;

export const OptionalText = styled.div`
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 2.1rem;
  margin-top: 0.4rem;
  color: ${palette.gray500};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  overflow-y: scroll;
`;
