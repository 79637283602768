import React from 'react';
import CompanyDetailContent, { CompanyDetailContentProps } from './CompanyDetailContent';
import { DetailViewMainButtonProps } from 'components/common/DetailViewMainButton';

interface EventDetailProps extends DetailViewMainButtonProps, CompanyDetailContentProps {}

const CompanyDetail: React.FC<EventDetailProps> = ({ company }) => {
  return <CompanyDetailContent company={company} />;
};

export default CompanyDetail;
