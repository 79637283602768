import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: calc(100% - 4.6rem);
  }
`;
