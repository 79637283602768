import React from 'react';
import { useHistory } from 'react-router-dom';

import { HeadingText } from 'theme/typography';
import { Container, BackArrow } from '../../../Job/JobDetailHeader.styled';
import { EGLogoBackgroundMobile } from 'theme/assets';

import { useWindowDimensions, inMobile } from 'utils/useDimensions';

const CompanyDetailHeader: React.FC<{ logo?: string | null }> = ({ logo }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  return (
    <Container bgImage={inMobile(width) ? logo || EGLogoBackgroundMobile : undefined}>
      <BackArrow onClick={() => history.goBack()} />
      {!inMobile(width) && <HeadingText variant="h4">Companies</HeadingText>}
    </Container>
  );
};

export default CompanyDetailHeader;
