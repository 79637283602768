/**
 * const query: QueryParamInterface<queryName_bool_exp> = {
 *  enabled: true/false,
 *  operator: '_and',
 *  query: { raise_hands: { user_id: { _eq: user.sub } } },
  };
 */
export interface WhereQueryInterface<T extends object> {
  enabled: boolean;
  operator: '_and' | '_not' | '_or';
  whereQuery: T;
}

/**
 *
 * @param queryParams Array of objects of the same type QueryParamInterface<T extends object>
 * The type will be inferred of the array you pass
 */
export const generateWhereQuery = <T extends object>(queryParams: WhereQueryInterface<T>[]): T | null => {
  const onlyEnabledQuery = queryParams.filter(({ enabled }) => enabled);
  if (onlyEnabledQuery.length) return generateWhereQueryStep(onlyEnabledQuery);
  return null;
};

const generateWhereQueryStep = <T extends object>(queryParams: WhereQueryInterface<T>[]): T => {
  const [firstQuery, ...newRest] = queryParams;
  const { operator, whereQuery } = firstQuery;
  if (newRest.length) {
    return { [operator]: firstQuery.whereQuery, ...generateWhereQueryStep(newRest) };
  } else {
    return whereQuery;
  }
};
