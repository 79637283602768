import React, { useCallback } from 'react';
import { WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { OptionId, JobRoleInterestPicker } from 'components';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { Container } from './InterestRolesEdit.styled';
import { useUpdateInterestRolesEditMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface InterestRoles {
  interest_role_id: string;
  role: string;
}

export interface InterestRoleEditVariables {
  interestRoles: { current: OptionId[] };
}

interface InterestRolesEditProps {
  interestRolesInitial?: InterestRoles[];
  setModalOpen: (state: ModalOpenType) => void;
}

const InterestRolesEditStep: React.FC<InterestRolesEditProps> = ({ interestRolesInitial, setModalOpen }) => {
  const { register, control, handleSubmit, errors, setValue, clearError } = useForm<InterestRoleEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [updateRoles] = useUpdateInterestRolesEditMutation();

  const initialInterestRoles = interestRolesInitial?.map(
    ({ role, interest_role_id }: { role: string; interest_role_id: string }) => ({
      value: role,
      label: role,
      id: interest_role_id,
    }),
  );
  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async (data: InterestRoleEditVariables) => {
    const {
      interestRoles: { current: currentRoles },
    } = data;

    const eraseRoles = getEraseOptions(currentRoles, initialInterestRoles)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addRoles = getAddOptions(currentRoles, initialInterestRoles).map(({ value }) => ({
      role: value,
      user_id: user.sub,
    }));
    const variables = {
      interestRolesForUpsert: addRoles,
      interestRolesIdsForDelete: eraseRoles,
    };

    await updateRoles({ variables, refetchQueries: ['GetProfileByUserId'] });
    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Interested Role'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.interestRoles}>
          What type of role are you interested in?
        </WizardRequiredInputLabel>
        <JobRoleInterestPicker
          name="interestRoles"
          defaultValue={initialInterestRoles}
          control={control}
          register={register}
          setValue={setValue}
          required={true}
          placeholder="Select"
          error={!!errors.interestRoles}
          legend
        />
      </Container>
    </Modal>
  );
};

export default InterestRolesEditStep;
