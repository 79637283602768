import styled from 'styled-components';
import { palette } from 'theme';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0;

  @media (min-width: 768px) {
    padding: 4rem 0;
  }
`;

export const JeannieLogo = styled.img`
  margin-bottom: 2.3rem;
  height: 14vh;
`;

export const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border: none;

  @media (min-width: 768px) {
    border-right: 1px solid ${palette.gray200};
    justify-content: center;
    align-items: center;
  }
`;

export const TitleWelcome = styled.span`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${palette.beta600gray};
`;

export const SubtitleWelcome = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: ${palette.beta600gray};
  text-align: center;
  max-width: 85%;
  margin-top: 2.3rem;

  @media (min-width: 768px) {
    max-width: 75%;
  }
`;

export const LockContainer = styled.div`
  display: flex;
  max-width: 10rem;
  justify-content: center;

  .auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
    margin: 0 auto;
  }

  .auth0-lock-header,
  .auth0-lock.auth0-lock .auth0-lock-header {
    display: none;
    height: 0;
    padding: 0;
  }

  .auth0-lock.auth0-lock .auth0-lock-form {
    @media (max-height: 675px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .auth0-lock .auth0-lock-form div.auth0-lock-pane-separator {
    @media (max-height: 675px) {
      padding: 0;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-form p {
    @media (max-height: 675px) {
      margin: 0;
    }
  }

  .auth0-lock.auth0-lock .auth0-global-message {
    @media (max-height: 675px) {
      padding: 0;
    }
  }

  .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-header-bg .auth0-lock-header-bg-blur {
    opacity: 0 !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    flex-grow: 0;
  }

  .auth0-lock-name {
    display: none;
  }

  .auth0-lock.auth0-lock .auth0-lock-submit {
    display: flex;
    box-align: center;
    position: relative !important;
    align-items: center !important;
    justify-content: center !important;
    height: 4rem;
    width: 16rem;
    margin: 0 auto;
    background-color: ${palette.alpha500Red} !important;
    border-radius: 100px;
    padding: 10px 20px;

    & > span {
      display: flex !important;
      justify-content: center !important;
      align-items: center;
      visibility: hidden;
      width: 0px;
      height: 0px;
      ::after {
        visibility: visible;
        content: 'continue';
        width: 100%;
        height: fit-content;
      }
    }

    & > svg {
      display: none;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-terms {
    background: white;
    height: fit-content;
    padding: 0 2rem;
  }
`;
