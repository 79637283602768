import styled from 'styled-components';
import { palette } from 'theme';
import { ShieldMask } from 'theme/assets';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-bottom: 1rem;
`;

export const Avatar = styled.img`
  max-height: 10rem;
  mask: url(${ShieldMask});
  mask-position: center center;
  mask-size: auto;
  overflow: hidden;
  mask-repeat: no-repeat;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4.5rem 3rem 0;

  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 13px;

  @media (max-width: ${MEDIA_SIZE.md}) {
    box-shadow: none;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 1rem 0;
  height: 4rem;

  span {
    margin-left: 0.5rem;
  }
`;

export const FilterIcon = styled.img`
  height: 2rem;
`;
