import React from 'react';
import styled from 'styled-components';

import { Badge } from 'components/Badge';

import { palette } from 'theme';
import { Apple } from 'theme/assets';
import { Text } from 'theme/typography';

import { useWindowDimensions, inMobile, MEDIA_SIZE } from 'utils/useDimensions';
import TabsSection from 'components/common/TabsSection';

import {
  CardContainer,
  CardHeader,
  Column,
  CompanyAvatar,
  CompanyBasicInfoContainer,
  CompanyContainer,
  CompanyExtraInfoContainer,
  Icon,
  IconsContainer,
  Row,
  TabCardContainer,
} from '../../../Job/JobDetail/JobDetail.styled';

import { GetCompanyDetailByPkQuery } from 'generated/graphql';
import JobList from './CompanyDetailJobs';
import { JobListTabContainer } from './CompanyDetailContent.styled';

export interface CompanyDetailContentProps {
  company: GetCompanyDetailByPkQuery | null;
}

const JobDetailDescription = styled.div`
  padding: 3rem;

  white-space: pre-wrap;

  max-height: 35rem;
  overflow-y: scroll;
  @media (max-width: ${MEDIA_SIZE.md}) {
    height: 100%;
    max-height: 100%;
    overflow-y: visible;
  }
`;

const BadgesContainer = styled.div`
  display: flex;

  span {
    margin: 0.5rem 0.25rem;
  }
`;

const CompanyDetailContent: React.FC<CompanyDetailContentProps> = ({ company }) => {
  const { width } = useWindowDimensions();
  const companyData = company?.recruit_company_by_pk;

  const Tabs = [
    {
      label: 'About the Company',
      content: (
        <TabCardContainer>
          <JobDetailDescription>
            <Text color={palette.gray700} variant="m">
              {companyData?.description || 'No description provided'}
            </Text>
          </JobDetailDescription>
        </TabCardContainer>
      ),
    },
    {
      label: 'Job offers',
      content: (
        <TabCardContainer>
          <JobListTabContainer>
            <JobList accountId={companyData?.account_id || ''} />
          </JobListTabContainer>
        </TabCardContainer>
      ),
    },
  ];

  return (
    <CardContainer>
      <CardHeader>
        <CompanyContainer>
          <Column>
            <Row>
              <CompanyAvatar src={companyData?.logo || Apple} />
              <CompanyBasicInfoContainer>
                <Text variant="l" bold>
                  {companyData?.name}
                </Text>
                <Text variant="m">{companyData?.address || ''}</Text>
                <BadgesContainer>
                  {companyData?.subindustries.map((sub) => (
                    <Badge key={sub.value} variant="solid" color="beta" size="medium">
                      {sub.value}
                    </Badge>
                  ))}
                </BadgesContainer>
              </CompanyBasicInfoContainer>
            </Row>
            <CompanyExtraInfoContainer>
              <Text variant="l" color={palette.gray500}>
                Employees:
              </Text>
              {companyData?.num_employees && (
                <Text variant="l" color={palette.alpha500Red} bold>
                  {companyData?.num_employees} Employees
                </Text>
              )}
            </CompanyExtraInfoContainer>
            <CompanyExtraInfoContainer>
              <Text variant="l" color={palette.gray500}>
                Phone:
              </Text>
              <Text variant="l" color={palette.alpha500Red} bold>
                {companyData?.phone}
              </Text>
            </CompanyExtraInfoContainer>
            <CompanyExtraInfoContainer>
              <Text variant="l" color={palette.gray500}>
                Website:
              </Text>
              <Text variant="l" color={palette.alpha500Red} bold>
                {companyData?.website}
              </Text>
            </CompanyExtraInfoContainer>
          </Column>
        </CompanyContainer>
      </CardHeader>
      <TabsSection tabs={Tabs} tabsStyle={inMobile(width) ? 'rounded' : 'enclosed'} />
    </CardContainer>
  );
};

export default CompanyDetailContent;
