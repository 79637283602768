import React from 'react';
import { useAuth0 } from 'react-auth0-spa';

import { StyledInput } from '../../../../components';
import { generateTests } from 'utils/testUtils';
import {
  useDeleteTestScoreDetailMutation,
  useDeleteTestsScoresMutation,
  useUpsertTestsScoresMutation,
} from 'generated/graphql';
import { WizardRequiredInputLabel } from '../../../Wizard/components/library';
import {
  StyledLabelWrite,
  StyledSatContainer,
  CheckboxContainer,
  TestContainer,
  TestLabel,
  TestLabelContainer,
  NotApplicable,
  Container,
} from './TestEdit.styled';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { TestOutOf, TestSATDetailsOutof } from '../../../../data/tests';
import { generateErrorMessages } from '../../../../components/FormValidationMessage';
import CheckboxWithController from '../../../../components/CheckboxWithController';
import { useForm } from 'react-hook-form';
import { ParsedTests } from '../../../../utils/testUtils';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

export interface TestsScoresFormVariables {
  [name: string]: number;
  satMathScore: number;
  satWritingScore: number;
  satReadingScore: number;
  actScore: number;
}
interface TestStepProps {
  testsData: ParsedTests;
  setModalOpen: (status: ModalOpenType) => void;
}

const TestStep: React.FC<TestStepProps> = ({ testsData, setModalOpen }) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const [upsertTestsScoresMutation] = useUpsertTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteTestsDetailMutation] = useDeleteTestScoreDetailMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteTestsScoresMutation] = useDeleteTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });
  const onSubmit = async ({
    satReadingScore,
    satWritingScore,
    satMathScore,
    actScore,
    greScore,
    lsatScore,
    gmatScore,
  }: Partial<TestsScoresFormVariables>) => {
    const { toDelete, toUpsert, toDeleteDetail } = generateTests({
      testsData,
      user_id: user.sub,
      satMathScore: Number(satMathScore),
      satReadingScore: Number(satReadingScore),
      satWritingScore: Number(satWritingScore),
      gmatScore: Number(gmatScore),
      greScore: Number(greScore),
      lsatScore: Number(lsatScore),
      mcatScore: undefined,
      actScore: Number(actScore),
    });

    if (!!toDeleteDetail.length)
      await deleteTestsDetailMutation({
        variables: { ids: toDeleteDetail },
        refetchQueries: ['GetTestScoresByUserId'],
      });
    if (!!toUpsert.length)
      await upsertTestsScoresMutation({ variables: { tests: toUpsert }, refetchQueries: ['GetTestScoresByUserId'] });
    if (!!toDelete.length)
      await deleteTestsScoresMutation({ variables: { ids: toDelete }, refetchQueries: ['GetTestScoresByUserId'] });

    setModalOpen('');
  };
  const { register, handleSubmit, errors, watch, control } = useForm<TestsScoresFormVariables>();
  const actValue = watch('actScore', '');
  const satMathScore = watch('satMathScore', '');
  const satWritingScore = watch('satWritingScore', '');
  const satReadingScore = watch('satReadingScore', '');
  const watchedSatNotApplicable = !!watch('satNotApplicable');
  const watchedActNotApplicable = !!watch('actNotApplicable');
  const satIncomplete = !satMathScore || !satWritingScore || ((!satMathScore || !satWritingScore) && satReadingScore);
  const satRequired = watchedSatNotApplicable ? false : !actValue || satIncomplete;
  const actRequired = !satRequired;
  return (
    <Modal
      setModalOpen={setModalOpen}
      heightAuto={width > mobileBreakpoint}
      handleSubmit={handleSubmit(onSubmit)}
      title={'Test Scores'}
    >
      <Container>
        {!watchedSatNotApplicable && (
          <>
            <WizardRequiredInputLabel>SAT</WizardRequiredInputLabel>
            <StyledSatContainer>
              <TestContainer>
                <TestLabel error={!!errors.satMathScore}>Math</TestLabel>
                <StyledInput
                  defaultValue={
                    testsData.SAT && testsData.SAT.test_score_details.math
                      ? testsData.SAT.test_score_details.math.score
                      : undefined
                  }
                  type="number"
                  name="satMathScore"
                  placeholder="Math"
                  ref={register({
                    required: satRequired ? 'Math is required if no ACT value' : false,
                    max: {
                      value: TestSATDetailsOutof.MATH,
                      message: `Maximum score is ${TestSATDetailsOutof.MATH}`,
                    },
                  })}
                  error={!!errors.satMathScore}
                  narrow
                />
                {generateErrorMessages(errors.satMathScore)}
              </TestContainer>

              <TestContainer>
                <TestLabel error={!!errors.satReadingScore}>Reading</TestLabel>

                <StyledInput
                  defaultValue={
                    testsData.SAT && testsData.SAT.test_score_details.reading
                      ? testsData.SAT.test_score_details.reading.score
                      : undefined
                  }
                  type="number"
                  name="satReadingScore"
                  placeholder="Reading"
                  ref={register({
                    required: satRequired ? 'Reading is required if no ACT value' : false,
                    max: {
                      value: TestSATDetailsOutof.READING,
                      message: `Maximum score is ${TestSATDetailsOutof.READING}`,
                    },
                  })}
                  error={!!errors.satReadingScore}
                  narrow
                />
                {generateErrorMessages(errors.satReadingScore)}
              </TestContainer>

              <TestContainer>
                <TestLabelContainer>
                  <TestLabel error={!!errors.satWritingScore}>Writing</TestLabel>
                  <StyledLabelWrite>{'(optional)'}</StyledLabelWrite>
                </TestLabelContainer>
                <StyledInput
                  defaultValue={
                    testsData.SAT && testsData.SAT.test_score_details.writing
                      ? testsData.SAT.test_score_details.writing.score
                      : undefined
                  }
                  type="number"
                  name="satWritingScore"
                  placeholder="Writing"
                  ref={register({
                    max: {
                      value: TestSATDetailsOutof.WRITING,
                      message: `Maximum score is ${TestSATDetailsOutof.WRITING}`,
                    },
                  })}
                  error={!!errors.satWritingScore}
                  narrow
                />
                {generateErrorMessages(errors.satWritingScore)}
              </TestContainer>
            </StyledSatContainer>
          </>
        )}
        <CheckboxContainer>
          <CheckboxWithController
            name="satNotApplicable"
            control={control}
            black
            defaultChecked={!!watchedSatNotApplicable}
          />
          <NotApplicable>{'SAT not applicable'}</NotApplicable>
        </CheckboxContainer>
        {!watchedActNotApplicable && (
          <>
            <WizardRequiredInputLabel error={!!errors.actScore}>ACT</WizardRequiredInputLabel>
            <StyledInput
              type="number"
              name="actScore"
              placeholder="Enter your ACT Score score"
              defaultValue={testsData.ACT ? testsData.ACT.score : undefined}
              ref={register({
                required: actRequired ? 'ACT is required if no SAT value' : false,
                max: { value: TestOutOf.ACT, message: `Maximum score is ${TestOutOf.ACT}` },
              })}
              error={!!errors.actScore}
              narrow
            />
            {generateErrorMessages(errors.actScore)}
          </>
        )}
        <CheckboxContainer>
          <CheckboxWithController
            name="actNotApplicable"
            control={control}
            black
            defaultChecked={!!watchedActNotApplicable}
          />
          <NotApplicable>{'ACT not applicable'}</NotApplicable>
        </CheckboxContainer>
        <StyledSatContainer split>
          <TestContainer>
            <TestLabel error={!!errors.greScore}>GRE</TestLabel>
            <StyledInput
              type="number"
              name="greScore"
              placeholder="Enter your GRE score"
              defaultValue={testsData.GRE ? testsData.GRE.score : undefined}
              ref={register({
                max: { value: TestOutOf.GRE, message: `Maximum score is ${TestOutOf.GRE}` },
              })}
              error={!!errors.greScore}
              narrow
            />
            {generateErrorMessages(errors.greScore)}
          </TestContainer>
          <TestContainer>
            <TestLabel error={!!errors.gmatScore}>GMAT</TestLabel>
            <StyledInput
              type="number"
              name="gmatScore"
              placeholder="Enter your GMAT score"
              defaultValue={testsData.GMAT ? testsData.GMAT.score : undefined}
              ref={register({
                max: { value: TestOutOf.GMAT, message: `Maximum score is ${TestOutOf.GMAT}` },
              })}
              error={!!errors.gmatScore}
              narrow
            />
            {generateErrorMessages(errors.gmatScore)}
          </TestContainer>
          <TestContainer>
            <TestLabel error={!!errors.lsatScore}>LSAT</TestLabel>
            <StyledInput
              type="number"
              name="lsatScore"
              placeholder="Enter your LSAT score"
              defaultValue={testsData.LSAT ? testsData.LSAT.score : undefined}
              ref={register({
                max: { value: TestOutOf.LSAT, message: `Maximum score is ${TestOutOf.LSAT}` },
              })}
              error={!!errors.lsatScore}
              narrow
            />
            {generateErrorMessages(errors.lsatScore)}
          </TestContainer>
        </StyledSatContainer>
      </Container>
    </Modal>
  );
};

export default TestStep;
