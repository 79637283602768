import styled from 'styled-components';
import { palette } from 'theme';

export const StyledForm = styled.form.attrs({
  noValidate: true,
})`
  display: flex;
  flex-wrap: nowrap;

  height: 100%;
  margin: 1.6rem 2rem;
`;

export const StyledFormInputs = styled.div`
  flex-grow: 1;
  overflow-y: scroll;

  margin-bottom: 6.4rem;
`;

export const StyledInputLabel = styled.label`
  display: block;

  margin: 0 0 0.8rem;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGray};
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 3.4rem;

  margin-bottom: 1.4rem;
  padding: 1rem;

  border: 1px solid ${palette.lightGray};

  border-radius: 10px;

  font-size: 1.4rem;
  font-weight: 300;

  line-height: 1.9rem;

  ::placeholder {
    color: ${palette.brownGray};
  }

  :focus {
    outline: none;
  }
`;

export const StyledButtons = styled.div`
  flex-shrink: 0;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  height: 6.4rem;
  padding: 0 2rem;
  box-shadow: -3px 0px 18px 0px rgba(0, 0, 0, 0.3);

  background: ${palette.white};
`;
