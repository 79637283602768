import React from 'react';

import InterestRoleCard from './StudentInformationCard';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { StudentViewPaths } from '../../StudentViewRoutes';
import { CardTitle, TopEditButton } from './StudentInformation.styled';

export interface EmployerPreferences {
  employer_preference_id: string;
  preference: string;
}
export interface FunFacts {
  fun_fact_id: string;
  description: string;
}
export interface Languages {
  spoken_language_id: string;
  language: string;
}
export interface Achievements {
  achievement_id: string;
  description: string;
}
export interface Activities {
  volunteer_id: string;
  description: string;
}

export interface StudentInformationProps {
  employersPreferences: EmployerPreferences[];
  phone: string;
  communicationMode: string;
  funFacts: FunFacts[];
  languages: Languages[];
  studentAthlete: boolean;
  achievements: Achievements[];
  extracurricularActivities: Activities[];
  fullProfile?: boolean;
  email: string;
  handleEdit: () => void;
}

const StudentInformation: React.FC<StudentInformationProps> = ({
  employersPreferences,
  phone,
  communicationMode,
  funFacts,
  languages,
  studentAthlete,
  achievements,
  extracurricularActivities,
  fullProfile = false,
  email,
  handleEdit,
}) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Student information {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>

      <InterestRoleCard
        email={email}
        phone={phone}
        employersPreferences={employersPreferences}
        communicationMode={communicationMode}
        funFacts={funFacts}
        languages={languages}
        studentAthlete={studentAthlete}
        achievements={achievements}
        extracurricularActivities={extracurricularActivities}
      />
    </CollapsibleWidgetCard>
  );
};

export default StudentInformation;
