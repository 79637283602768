import moment from 'moment';
import { RecruitEventBoolExp } from 'generated/graphql';
import { WhereQueryInterface } from 'utils/queryUtils';

interface EventsDynamicQueryProps {
  keyword?: string;
  isRegistered?: boolean;
}

const useEventsDynamicQuery = ({ keyword }: EventsDynamicQueryProps): RecruitEventBoolExp | null => {
  const keywordQuery: WhereQueryInterface<RecruitEventBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: {
      _or: [
        { title: { _ilike: `%${keyword}%` } },
        { description: { _ilike: `%${keyword}%` } },
        { event_type: { _ilike: `%${keyword}%` } },
        { address: { _ilike: `%${keyword}%` } },
      ],
    },
  };

  const today = moment().format();
  const activeEvent: WhereQueryInterface<RecruitEventBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { _and: [{ end_timestamp: { _gt: today } }, { show_event: { _eq: true } }] },
  };

  const activeFilters = [];

  Boolean(keyword) && activeFilters.push(keywordQuery.whereQuery);
  activeFilters.push(activeEvent.whereQuery);

  return { _and: activeFilters };
};

export default useEventsDynamicQuery;
