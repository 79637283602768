import styled from 'styled-components';
import { mergeStyles } from 'react-select';
import { palette, selectorStyle } from 'theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${palette.beta300gray};
  padding: 2rem;
`;

export const selectStyles = mergeStyles(selectorStyle, {
  container: (base: any) => ({
    ...base,
    width: '100%',
    margin: 0,
    maxWidth: '20rem',
    maxHeight: '3.4rem',
    borderRadius: `4px`,
    marginRight: '1rem',
  }),
  control: (base: any) => ({
    ...base,
    minHeight: '3.4rem',
    maxHeight: '3.4rem',
    maxWidth: '20rem',
    borderRadius: `4px`,
    marginRight: '1rem',
    overflow: 'scroll',
  }),
});

export const FilterContainer = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
`;

export const SelectContainer = styled.div`
  margin-right: 1rem;
  width: 100%;
  max-width: 20rem;
`;

export const InputContainer = styled.div`
  margin-right: 1rem;
  width: 60%;
  max-width: 20rem;
`;

export const Icon = styled.img`
  max-height: 100%;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;

export const ClearFieldsContainer = styled.div`
  width: 10rem;
  margin-left: 1rem;
  cursor: pointer;
`;
