import React from 'react';
import { StyledButtons, StyledForm, StyledFormInputs } from '../library';
import { StyledFieldRow } from './ProfileLegalTab.styled';
import { InputLegend } from 'views/Wizard/components';
import { CountryPicker, CancelButton, RadioButtonWithLabel, SaveButton } from 'components';
import { useForm } from 'react-hook-form';

export interface ProfileLegalFormVariables {
  authorizedToWork: string;
  needSponsor: string;
  legalResidenceCountry: { label: string; value: string };
}

interface ProfileLegalTabProps {
  onSubmit: (data: ProfileLegalFormVariables) => void;
  onCancel: () => void;
  need_sponsor: boolean;
  authorized_to_work: boolean;
  country: string | null;
  country_selection_id: string | null;
}

const ProfileLegalTab: React.FC<ProfileLegalTabProps> = ({
  onSubmit,
  onCancel,
  need_sponsor,
  authorized_to_work,
  country,
  country_selection_id,
}) => {
  const { handleSubmit, register, control, errors } = useForm<ProfileLegalFormVariables>();
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledFieldRow>
          <div>What is your Country of Legal Residence?</div>
          <CountryPicker
            name="legalResidenceCountry"
            defaultCountryId={country_selection_id}
            defaultCountryName={country}
            required={true}
            control={control}
          />
          {errors.legalResidenceCountry && (
            <InputLegend error={!!errors.legalResidenceCountry}>Complete this field to move on</InputLegend>
          )}
        </StyledFieldRow>

        <StyledFieldRow>
          <div>Are you authorized to work lawfully in the United States?</div>
          <RadioButtonWithLabel
            label="Yes"
            name="authorizedToWork"
            value="yes"
            defaultChecked={authorized_to_work}
            register={register}
          />
          <RadioButtonWithLabel
            label="No"
            name="authorizedToWork"
            value="no"
            defaultChecked={!authorized_to_work}
            register={register}
          />
        </StyledFieldRow>

        <StyledFieldRow>
          <div>
            Will you require sponsorship for a work visa in order to work lawfully in the United States for the
            companies you are applying to?
          </div>
          <RadioButtonWithLabel
            label="Yes"
            name="needSponsor"
            value="yes"
            defaultChecked={need_sponsor}
            register={register}
          />
          <RadioButtonWithLabel
            label="No"
            name="needSponsor"
            value="no"
            defaultChecked={!need_sponsor}
            register={register}
          />
        </StyledFieldRow>
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfileLegalTab;
