import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';
import { PrimaryButton } from '../../../components';

export const LogoutButton = styled(PrimaryButton)`
  display: block;

  width: 70%;
  max-width: 36rem;
  margin: 6rem auto 0;
`;

export const Container = styled.div`
  height: 99.5%;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: calc(100% - 7.6rem);
    margin-top: 2rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -12rem;
  align-items: center;

  & > * {
    margin-bottom: 1.4rem;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
