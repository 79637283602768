import React, { useEffect } from 'react';
import Select from 'react-select';
import { Bookmark, BookmarkSelected, Hand, HandRised } from 'theme/assets';
import useJobsDynamicQuery from '../JobList/useJobsDynamicQuery';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';

import {
  Container,
  FilterContainer,
  SelectContainer,
  Icon,
  InputContainer,
  selectStyles,
  ClearFieldsContainer,
} from './JobListHeader.styled';
import { HeadingText, Text } from 'theme/typography';

import { TextInput } from 'components';
import { palette } from 'theme';
import { useJobFiltersContext, defaultFiltersValue } from '../JobList/JobList.context';
import { useAuth0 } from 'react-auth0-spa';

const JobListHeader: React.FC<{ onFilter: (dynamicQuery: any) => void }> = ({ onFilter }) => {
  const { filters, handleFilterChange, clearFilters, filterActive } = useJobFiltersContext();
  const dynamicQuery = useJobsDynamicQuery(filters);
  const { subIndustry, role, keyword, hasHandraise, hasBookmark } = filters;
  const { user } = useAuth0();
  const { data, loading } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  useEffect(() => {
    onFilter(dynamicQuery);
  }, [role, hasHandraise, hasBookmark]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [keyword]);

  if (loading) return null;

  const subIndustryOptions = data?.grad_interest_firm_selection.map((firm) => {
    return { label: firm.value, value: firm.value };
  });

  const jobTypeOptions = data?.grad_interest_job_type_selection.map((jobType) => {
    return { label: jobType.value, value: jobType.value };
  });
  return (
    <Container>
      <HeadingText variant="h4">Opportunities</HeadingText>
      {/* TODO: integrate this field with the company sub industry of the jobs */}
      <FilterContainer>
        {/* <SelectContainer>
          <Select
            options={subIndustryOptions}
            isClearable
            placeholder={'Subindustry'}
            isSearchable={true}
            defaultValue={subIndustry.map((sub) => {
              return { label: sub, value: sub };
            })}
            onChange={(selectedOption) =>
              selectedOption && handleFilterChange({ subIndustry: selectedOption.map((option) => option.value) })
            }
            styles={selectStyles}
            isMulti
          />
        </SelectContainer> */}
        <SelectContainer>
          <Select
            options={jobTypeOptions}
            defaultValue={(role && { label: role, value: role }) || null}
            placeholder={'Role'}
            isSearchable={false}
            isClearable={false}
            value={(role && [{ label: role, value: role }]) || null}
            onChange={(selectedOption) => {
              selectedOption && handleFilterChange({ role: (Object.values(selectedOption)[0] as unknown) as string });
            }}
            styles={selectStyles}
          />
        </SelectContainer>
        <InputContainer>
          <TextInput
            onChange={(value: string) => handleFilterChange({ keyword: value })}
            placeholder="keyword"
            value={keyword}
          />
        </InputContainer>
        <Icon
          src={hasHandraise ? HandRised : Hand}
          onClick={() => handleFilterChange({ hasHandraise: !hasHandraise })}
        />
        <Icon
          src={hasBookmark ? BookmarkSelected : Bookmark}
          onClick={() => handleFilterChange({ hasBookmark: !hasBookmark })}
        />
        <ClearFieldsContainer onClick={() => clearFilters()}>
          <Text
            color={!filterActive ? palette.beta600gray : palette.beta700gray}
            variant="m"
            bold
            opacity={!filterActive ? 0.4 : 1}
          >
            Clear filter
          </Text>
        </ClearFieldsContainer>
      </FilterContainer>
    </Container>
  );
};

export default JobListHeader;
