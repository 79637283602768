import styled, { css } from 'styled-components';
import { palette } from 'theme';
import ButtonLight from 'components/ButtonLight';
import FileInput from 'components/FileInput';
import HorizontalSeparator from 'components/HorizontalSeparator';
import { SaveButton } from 'components/library';

export const StyledButtonLight = styled(ButtonLight)`
  min-width: unset;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisclaimerContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  color: ${palette.gray};
`;

export const StyledCurrentResume = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 2rem 1.6rem;

  > * {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const StyledHorizontalSeparator = styled(HorizontalSeparator)`
  margin: 1rem 0;
`;

export const StyledSaveButton = styled(SaveButton)`
  margin: 0 auto;

  :focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const StyledTitle = styled.div`
  margin: 1.6rem 2rem;

  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;

  line-height: 1.8rem;
`;

export const StyledFileInput = styled(FileInput)`
  margin: 0 2rem 1.6rem;
`;
