import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import ProfilePhoto from './ProfilePhoto';

import { useGetProfilePhotoQuery } from 'generated/graphql';

import { EGLogoGrayBackground as ProfilePicture } from 'theme/assets';

const ProfilePhotoController: React.FC = () => {
  const { user } = useAuth0();

  const { data } = useGetProfilePhotoQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { grad_profile: [{ profile_image = null } = {}] = [] } = data || {};
  const picture = profile_image || ProfilePicture;
  return <ProfilePhoto picture={picture} />;
};

export default ProfilePhotoController;
