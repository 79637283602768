import React from 'react';

import { StudentViewPaths } from '../../StudentViewRoutes';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { Tag } from '../library';
import { CardTitle, TopEditButton, TagContainer, EmptyContainer, Icon, Text } from './ProfessionalDesignations.styled';

interface ProfessionalDesignation {
  award_id: string;
  award_name: string;
  award_category?: string | undefined;
}

export interface ProfessionalDesignationsProps {
  designations: ProfessionalDesignation[];
  fullProfile?: boolean;
  handleEdit: () => void;
}

const ProfessionalDesignations: React.FC<ProfessionalDesignationsProps> = ({
  designations,
  fullProfile = false,
  handleEdit,
}) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Professional Designations {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
      {designations && designations.length > 0 && (
        <TagContainer>
          {designations?.map((designation) => (
            <Tag key={designation.award_id}>{designation?.award_name}</Tag>
          ))}
        </TagContainer>
      )}
      {(!designations || designations.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No Professional Designation</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default ProfessionalDesignations;
