import React, { useState } from 'react';
import JobList from 'views/Student/JobsList/JobList/JobList';
import { useWindowDimensions, inMobile } from 'utils/useDimensions';
import { useGetJobsByCompanyAccountQuery } from 'generated/graphql';
import { useAuth0 } from 'react-auth0-spa';
import { Spinner } from 'components';

const CompanyDetailJobsController = ({ accountId }: { accountId: string }) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();

  const { data, loading, error } = useGetJobsByCompanyAccountQuery({
    variables: {
      userId: user.sub,
      accountId,
    },
  });

  if (loading) return <Spinner />;

  return <JobList jobs={data || null} inMobile={inMobile(width)} fetchMore={() => 0} />;
};

export default CompanyDetailJobsController;
