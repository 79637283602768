import React from 'react';

import UniversityListCard from './DegreeListCard';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { StudentViewPaths } from '../../StudentViewRoutes';
import { CardTitle, EmptyContainer, AddButton, Text, Icon } from './DegreeList.styled';
import { DegreePartsFragment } from 'generated/graphql';
import { useModelContext } from 'views/Student/ModalContext/ModalContext';
export interface UniversityProps {
  degrees: DegreePartsFragment[];
  fullProfile?: boolean;
}

const UniversityList: React.FC<UniversityProps> = ({ degrees = [], fullProfile = false }) => {
  const { setModalState, setDegreeIndex } = useModelContext();
  return (
    <CollapsibleWidgetCard withTitle fixed={!degrees || degrees?.length <= 1}>
      <CardTitle>
        Previous Degrees{' '}
        {fullProfile && (
          <AddButton
            onClick={() => {
              setDegreeIndex(-1);
              setModalState('degreeEdit');
            }}
          />
        )}
      </CardTitle>
      {degrees?.map((degree, index) => (
        <UniversityListCard
          key={degree.degree_id}
          studentDegree={degree}
          fullProfile={fullProfile}
          degreeIndex={index}
        />
      ))}
      {(!degrees || degrees.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No Previous Degrees</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default UniversityList;
