import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';

import { Spinner } from 'components';
import { createOptionId } from 'components/Option';

import ProfilePersonalTab, { ProfilePersonalFormVariables } from './ProfilePersonalTab';
import { AwardsTypes } from 'data/awards';
import { useGetStudentProfileQuery, useUpdateStudentProfileMutation } from 'generated/graphql';

import { useUpdateRelatedProfileMutationHook } from './ProfilePersonalTab.hooks';

const ProfilePersonalTabController: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: getProfileLoading, data: getProfileData } = useGetStudentProfileQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateGradProfile, { loading: updateLoading }] = useUpdateStudentProfileMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateRelatedProfile, { loading: updateRelatedProfileLoading }] = useUpdateRelatedProfileMutationHook(
    user.sub,
  );

  const myInfo = getProfileData?.grad_profile[0];

  if (getProfileLoading) return <Spinner />;

  const languagesOptions = (myInfo?.spoken_languages || []).map((language) =>
    createOptionId(language.language, language.spoken_language_id),
  );

  const funFactsOptions = (myInfo?.fun_facts || []).map((funFact) =>
    createOptionId(funFact.description, funFact.fun_fact_id),
  );

  const volunteerOptions = (myInfo?.volunteers || []).map((volunteer) =>
    createOptionId(volunteer.description, volunteer.volunteer_id),
  );

  const professionalDesignationsOptions = (myInfo?.awards.length ? myInfo.awards : [])
    .filter((award) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION)
    .map((award) => createOptionId(award.award_name, award.award_id));

  const personalAchievementsOptions = (myInfo?.achievements || []).map((achievement) =>
    createOptionId(achievement.description, achievement.achievement_id),
  );

  const onSubmit = async (data: ProfilePersonalFormVariables) => {
    const {
      firstName,
      lastName,
      email,
      title,
      mobile,
      yearsOfRelevantWork,
      funFacts: { optionsValue: funFactsOptions, deletedOptionsIds: deletedFunFactsIds },
      volunteerActivities: { optionsValue: volunteerOptions, deletedOptionsIds: deletedVolunteerIds },
      professionalDesignations: { newOptions: newDesignationsOptions, deletedOptionsIds: deletedDesignationsIds },
      personalAchievements: { optionsValue: personalAchievementsOptions, deletedOptionsIds: deletedAchievementIds },
      languages: { newOptions: newLanguagesOptions, deletedOptionsIds: deleteLanguagesIds },
      citySelectionOption,
      studentAthlete,
    } = data;

    const fullName = `${firstName} ${lastName}`;

    const languagesForUpsert = newLanguagesOptions.map(({ label }) => ({
      language: label,
      user_id: user.sub,
    }));

    const funFactsForUpsert = funFactsOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { fun_fact_id: id }),
    }));

    const volunteersForUpsert = volunteerOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { volunteer_id: id }),
    }));

    const professionalDesignationForUpsert = newDesignationsOptions.map(({ id, value }) => ({
      award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
      award_name: value,
      user_id: user.sub,
      ...(id && { award_id: id }),
    }));

    const grad_achievementForUpsert = personalAchievementsOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { achievement_id: id }),
    }));

    const profileVariablesForUpdate = {
      userId: user.sub,
      fullName,
      email,
      title,
      firstName,
      lastName,
      mobile,
      hometown: citySelectionOption?.[0]?.value || null,
      yearsOfRelevantWork: (yearsOfRelevantWork || {}).value,
      studentAthlete,
    };

    await updateRelatedProfile({
      variables: {
        languagesForUpsert: languagesForUpsert,
        languageIdsForDelete: deleteLanguagesIds,
        funFactsForUpsert: funFactsForUpsert,
        funFactIdsForDelete: deletedFunFactsIds,
        volunteersForUpsert: volunteersForUpsert,
        volunteerIdsForDelete: deletedVolunteerIds,
        awardsForUpsert: professionalDesignationForUpsert,
        awardIdsForDelete: deletedDesignationsIds,
        achievementsForUpsert: grad_achievementForUpsert,
        achievementIdsForDelete: deletedAchievementIds,
      },
    });

    await updateGradProfile({ variables: profileVariablesForUpdate });

    history.goBack();
  };

  const onCancel = () => history.goBack();

  if (updateLoading || updateRelatedProfileLoading) return <Spinner />;

  return (
    <ProfilePersonalTab
      onSubmit={onSubmit}
      volunteerOptions={volunteerOptions}
      languagesOptions={languagesOptions}
      funFactsOptions={funFactsOptions}
      personalAchievementsOptions={personalAchievementsOptions}
      professionalDesignationsOptions={professionalDesignationsOptions}
      firstname={myInfo?.firstname || ''}
      lastname={myInfo?.lastname || ''}
      email={myInfo?.email || ''}
      title={myInfo?.title || ''}
      mobile={myInfo?.mobile || null}
      years_of_relevant_work={myInfo?.years_of_relevant_work || null}
      student_athlete={myInfo?.student_athlete || false}
      hometown={myInfo?.hometown}
      onCancel={onCancel}
    />
  );
};

export default ProfilePersonalTabController;
