import React, { useState } from 'react';
import ShowMore from 'react-show-more-text';
import { ExpandButton, Elements, Wrapper } from './ExpansiveText.styled';

const ExpansibleText: React.FC = ({ children }) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const expandButton = (
    <ExpandButton isExpanded={isExpanded} onClick={() => setExpanded(!isExpanded)}>
      {!isExpanded ? 'see more' : 'show less'}
    </ExpandButton>
  );
  return (
    <Wrapper>
      <ShowMore lines={1} more={expandButton} less={expandButton} onClick={setExpanded} expanded={false} width={650}>
        <Elements>{children}</Elements>
      </ShowMore>
    </Wrapper>
  );
};

export default ExpansibleText;
