import React from 'react';
import { StyledButtons, StyledForm, StyledFormInputs, StyledInput, StyledInputLabel } from '../library';
import { InputLegend } from 'views/Wizard/components';
import {
  CancelButton,
  CellPhoneInput,
  CityStatePickerOption,
  OptionId,
  SaveButton,
  WantToKnowSelector,
} from '../../../../components';

import { EmailPattern } from '../../../../utils/validations';
import CheckboxWithController from '../../../../components/CheckboxWithController';
import SelectorMultiCreateV2 from '../../../../components/SelectorMultiCreateV2';
import { PROFESSIONAL_DESIGNATIONS_OPTIONS } from '../../../../data/awards';
import LanguageSelector from '../../../../components/LanguageSelector';
import RelevantYearsSelector from '../../../../components/RelevantYearsSelector';
import CityStatePicker from '../../../../components/CityStatePicker';
import { useForm } from 'react-hook-form';
import { StyledInlineCheckBox } from './ProfilePersonalTab.styled';

export interface ProfilePersonalFormVariables {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: string;
  email: string;
  title: string;
  mobile: string;
  hometown: string;
  funFacts: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  yearsOfRelevantWork?: { value: string; label: string };
  volunteerActivities: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  professionalDesignations: { newOptions: OptionId[]; deletedOptionsIds: string[] };
  personalAchievements: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  languages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  citySelectionOption: CityStatePickerOption[];
  studentAthlete: boolean;
}

interface ProfilePersonalTabProps {
  onSubmit: (data: ProfilePersonalFormVariables) => void;
  onCancel: () => void;
  volunteerOptions: OptionId[];
  languagesOptions: OptionId[];
  personalAchievementsOptions: OptionId[];
  funFactsOptions: OptionId[];
  professionalDesignationsOptions: OptionId[];
  firstname: string;
  lastname: string;
  email: string;
  mobile: string | null;
  title: string;
  years_of_relevant_work: string | null;
  hometown: string | null | undefined;
  student_athlete: boolean;
}

const ProfilePersonalTab: React.FC<ProfilePersonalTabProps> = ({
  onSubmit,
  volunteerOptions,
  languagesOptions,
  personalAchievementsOptions,
  funFactsOptions,
  onCancel,
  professionalDesignationsOptions,
  firstname,
  lastname,
  email,
  mobile,
  title,
  years_of_relevant_work,
  student_athlete,
  hometown,
}) => {
  const { control, register, handleSubmit, errors, setValue } = useForm<ProfilePersonalFormVariables>();
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledInputLabel>First Name</StyledInputLabel>
        <StyledInput
          type="text"
          name="firstName"
          placeholder="John"
          ref={register({ required: true })}
          defaultValue={firstname}
        />
        {errors.firstName && <InputLegend error={!!errors.firstName}>{'Complete this field to move on'}</InputLegend>}
        <StyledInputLabel>Last Name</StyledInputLabel>
        <StyledInput
          type="text"
          name="lastName"
          placeholder="Doe"
          ref={register({ required: true })}
          defaultValue={lastname}
        />
        {errors.fullName && <InputLegend error={!!errors.fullName}>{'Complete this field to move on'}</InputLegend>}

        <StyledInputLabel>Email</StyledInputLabel>
        <StyledInput
          type="text"
          name="email"
          placeholder="jdoe@email.com"
          ref={register({ required: true, pattern: EmailPattern })}
          defaultValue={email}
        />
        {errors.email && <InputLegend error={!!errors.email}>{'Complete this field to move on'}</InputLegend>}

        <StyledInputLabel>Cell phone</StyledInputLabel>
        <CellPhoneInput control={control} required={true} defaultValue={mobile} name="mobile" />
        {errors.mobile && <InputLegend error={!!errors.mobile}>{'Complete this field to move on'}</InputLegend>}

        <StyledInlineCheckBox>
          <StyledInputLabel>Student Athlete</StyledInputLabel>
          <CheckboxWithController control={control} name={'studentAthlete'} defaultChecked={student_athlete} />
        </StyledInlineCheckBox>
        <StyledInputLabel>Preferred Professional Designation</StyledInputLabel>
        <StyledInput
          type="text"
          name="title"
          placeholder="CFA"
          ref={register({ required: false })}
          defaultValue={title}
        />

        <StyledInputLabel>Professional Designations</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          dropDownIndicator={true}
          dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
          defaultOptionsValue={professionalDesignationsOptions}
          name="professionalDesignations"
          placeholder="Professional Designations Earned"
          register={register}
          setValue={setValue}
        />
        {errors.professionalDesignations && (
          <InputLegend error={!!errors.professionalDesignations}>{'Complete this field to move on'}</InputLegend>
        )}

        <StyledInputLabel>Other Languages</StyledInputLabel>
        <LanguageSelector
          setValue={setValue}
          control={control}
          defaultValue={languagesOptions}
          name={'languages'}
          register={register}
        />

        <StyledInputLabel>Years of Relevant Work Experience/Internship</StyledInputLabel>
        <RelevantYearsSelector
          name={'yearsOfRelevantWork'}
          defaultValue={years_of_relevant_work || null}
          control={control}
          required={false}
        />

        <StyledInputLabel>Hometown</StyledInputLabel>
        <CityStatePicker
          defaultValue={hometown}
          name="citySelectionOption"
          control={control}
          clearable
          required={false}
        />

        <StyledInputLabel>What I want an employer to know about me</StyledInputLabel>
        <WantToKnowSelector name="funFacts" register={register} setValue={setValue} values={funFactsOptions} />

        <StyledInputLabel>Personal Achievements/Awards/Scholarships</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          defaultOptionsValue={personalAchievementsOptions}
          name="personalAchievements"
          placeholder="John Harvard Scholar, Scholarship Athlete - Tennis"
          register={register}
          setValue={setValue}
          dropDownIndicator={false}
        />
        <StyledInputLabel>Volunteer/Clubs/Extracurricular Activities</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          defaultOptionsValue={volunteerOptions}
          name="volunteerActivities"
          placeholder="Big Brothers Big Sisters, Camillus House, Salvation Army"
          register={register}
          setValue={setValue}
          dropDownIndicator={false}
        />
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfilePersonalTab;
