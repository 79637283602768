import styled from 'styled-components';
import { ReactComponent as BackArrow } from 'theme/assets/BackArrow.svg';
import { palette } from '../../../../../theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 6rem;
  padding: 3.5rem;
  align-items: center;
  border-bottom: 1px solid ${palette.beta300gray};
  background-size: cover !important;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: 20rem;
    justify-content: space-between;
  }
`;

export const Icon = styled(BackArrow)<{ colorFill?: string }>`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 3rem;
  cursor: pointer;
  fill: ${({ colorFill }) => colorFill || palette.black};
`;

export const HeaderIcon = styled.img`
  max-width: 3rem;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;

export const ToastDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const ToastTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const ToastTitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const TickIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;
