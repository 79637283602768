import styled from 'styled-components';
import { palette } from 'theme';
import { ReactComponent as ChevronDown } from 'theme/assets/chevron-down-solid.svg';
import { ShieldMask } from 'theme/assets';
import { EditButtonModal } from '../library';

export const CardInfoContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 1rem;
  height: 33.4rem;
  background: linear-gradient(180deg, #dc352c 0%, #cb2e25 100%);
  color: ${palette.white};
  border-radius: 26px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 0.4rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TopButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
`;

export const TopEditButton = styled(EditButtonModal)<{ fullProfile?: boolean }>`
  position: relative;
  fill: ${palette.white};
  visibility: ${({ fullProfile }) => (fullProfile ? 'visible' : 'hidden')};
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const BottomArrowButton = styled(ChevronDown)`
  position: relative;
  width: 1.4rem;
  margin-left: 1rem;
  height: 2.4rem;
  fill: ${palette.white};
`;

export const NameAndTitle = styled.div`
  font-family: 'RobotoBold';
  line-height: 1.8rem;
  font-size: 1.8rem;
  line-height: 2.2.rem;
  color: ${palette.white};
`;

export const UserImage = styled.img`
  max-height: 10rem;
  margin: 0 auto 0.6rem;
  mask: url(${ShieldMask});
  mask-position: center center;
  overflow: hidden;
  mask-repeat: no-repeat;
`;

export const AuthorizedToWorkTag = styled.div`
  background: ${palette.beta50gray};
  text-transform: uppercase;
  border-radius: 4px;
  padding: 3px 5px;
  font-family: 'RobotoBlack';
  font-size: 0.8rem;
  color: ${palette.alpha500Red};
`;

export const TypeOfJobTag = styled.div`
  text-transform: uppercase;
  background: ${palette.beta900gray};
  border-radius: 4px;
  padding: 0.5rem 0.8rem;
  font-family: 'RobotoBlack';
  font-size: 0.8rem;
  color: ${palette.gray50};
`;

export const TagsContainer = styled.div`
  display: flex;
  margin-top: 0.4rem;

  & > div {
    margin: 0 0.2rem;
  }
`;

export const PopperInformation = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  height: 100%;
  color: ${palette.alpha500Red};
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  text-align: center;
  cursor: pointer;
`;

export const Icon = styled.img`
  margin-right: 0.7rem;
  width: 1.8rem;
  height: 1.8rem;
`;
