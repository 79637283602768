import styled from 'styled-components';
import { palette } from 'theme';

export const Container = styled.div`
  position: relative;
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;
`;

export const StyledForm = styled.form`
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  height: 100%;
  padding-bottom: 11rem;
`;

export const StyledFormButtons = styled.div`
  flex-shrink: 0;
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  height: 6.4rem;
  width: 100%;
  padding: 0 2rem;
  box-shadow: -3px 0px 18px 0px rgba(0, 0, 0, 0.3);
  background: ${palette.white};
`;
