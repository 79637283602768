import React, { useEffect } from 'react';
import { RegisterEventGray, RegisteredEventRed } from 'theme/assets';
import useEventsDynamicQuery from '../EventList/useEventsDynamicQuery';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';

import { Container, FilterContainer, Icon, InputContainer, ClearFieldsContainer } from './EventListHeader.styled';
import { HeadingText, Text } from 'theme/typography';

import { TextInput } from 'components';
import { palette } from 'theme';
import { useEventFiltersContext } from '../EventList/EventList.context';
import { useAuth0 } from 'react-auth0-spa';

const EventListHeader: React.FC<{ onFilter: (dynamicQuery: any, registration?: boolean) => void }> = ({ onFilter }) => {
  const { filters, handleFilterChange, clearFilters, filterActive } = useEventFiltersContext();
  const { keyword, registered } = filters;
  const dynamicQuery = useEventsDynamicQuery(filters);
  const { user } = useAuth0();
  const { data, loading } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  useEffect(() => {
    onFilter(dynamicQuery, registered);
  }, [registered]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [keyword]);

  return (
    <Container>
      <HeadingText variant="h4">Events</HeadingText>
      <FilterContainer>
        <InputContainer>
          <TextInput
            onChange={(value: string) => handleFilterChange({ keyword: value })}
            placeholder="keyword"
            value={keyword}
          />
        </InputContainer>
        <Icon
          src={registered ? RegisteredEventRed : RegisterEventGray}
          onClick={() => handleFilterChange({ registered: !registered })}
        />
        <ClearFieldsContainer onClick={() => clearFilters()}>
          <Text
            color={!filterActive ? palette.beta600gray : palette.beta700gray}
            variant="m"
            bold
            opacity={!filterActive ? 0.4 : 1}
          >
            Clear filter
          </Text>
        </ClearFieldsContainer>
      </FilterContainer>
    </Container>
  );
};

export default EventListHeader;
