import styled from 'styled-components';
import { palette } from 'theme';

export const RemovePhotoButton = styled.div`
  margin-top: 1.5rem;
  width: 14.3rem;
  height: 3.2rem;
  border-radius: 10rem;
  border: 2px solid ${palette.alpha500Red};
  background: ${palette.white};

  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3.2rem;
  color: ${palette.alpha500Red};

  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
`;
