import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import { WizardPage, SUBMIT_GO_TO } from '../components';
import { WizardProps } from '../components/interface';
import Spinner from 'components/Spinner';
import FirmsOfInterest from './FirmsInterestStep';
import { getPreSelectedFirms, newFirmsInterest, firmsOptionObject } from './FirmsInterestStep.utils';
import {
  useUpsertFirmInterestsMutation,
  useGetFirmInterestSelectionsQuery,
  useGetFirmInterestsQuery,
  useCreateProfileStepProfileMutation,
} from 'generated/graphql';

const FirmsInterestStepController: React.FC<WizardProps> = ({ totalSteps, step, nextGoTo, backGoTo }) => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: firmsOptionLoading, data: firmsOptionData } = useGetFirmInterestSelectionsQuery();
  const { loading: firmsDataLoading, data: firmsData } = useGetFirmInterestsQuery({
    variables: { userID: user.sub },
    fetchPolicy: 'network-only',
  });
  const [saveFirmInterests] = useUpsertFirmInterestsMutation();
  const [createProfile] = useCreateProfileStepProfileMutation();
  const incomingSelectedFields = getPreSelectedFirms(firmsData);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOptions(incomingSelectedFields);
  }, [firmsData]);

  const options = useMemo(() => {
    if (!firmsOptionData) {
      return [];
    }

    return firmsOptionObject(firmsOptionData, selectedOptions);
  }, [firmsOptionData, selectedOptions]);

  const onSelectOption = (selectedOption: string) => {
    if (selectedOptions.includes(selectedOption)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== selectedOption));
    } else {
      selectedOptions.length < 3 && setSelectedOptions([...selectedOptions, selectedOption]);
    }
  };

  const goToNextPage = () => history.push(nextGoTo || SUBMIT_GO_TO);

  const goToPreviousPage = () => history.push(backGoTo || '');

  const save = async () => {
    //Creates a profile if doesn't exist, this is necesary to start saving information on the db
    if (!firmsData || !firmsData.grad_profile[0]) {
      await createProfile({
        variables: {
          fullName: '',
          firstName: '',
          lastName: '',
          email: user?.email || '',
          mobile: '',
          title: '',
          userId: user?.sub,
          years_of_relevant_work: '',
          hometown: '',
        },
      });
    }

    const payload = newFirmsInterest(
      incomingSelectedFields,
      selectedOptions,
      firmsOptionData?.grad_interest_firm_selection,
      user.sub,
    );

    if (payload !== null)
      saveFirmInterests({
        variables: { addFirms: payload.addFirms, removeFirms: payload.removeFirms, userID: user.sub },
      });
  };

  const onNext = () => {
    save();
    goToNextPage();
  };

  if (firmsOptionLoading || firmsDataLoading || !Boolean(options.length)) {
    return <Spinner />;
  }

  return (
    <WizardPage
      step={step}
      totalSteps={totalSteps}
      showButtons
      navigable
      onNext={onNext}
      onPrevious={goToPreviousPage}
      showBackground
    >
      <FirmsOfInterest options={options} onSelect={onSelectOption} />
    </WizardPage>
  );
};

export default FirmsInterestStepController;
