import React from 'react';
import { WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import RelevantYearsSelector from '../../../../components/RelevantYearsSelector';
import { Container } from './RelevantYears.styled';
import { useRelevantYearsEditMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface InterestRoles {
  interest_role_id: string;
  role: string;
}

export interface InterestRoleEditVariables {
  years_of_relevant_work: { value: string; label: string };
}

interface InterestRolesEditProps {
  yearsOfRelevantWork: string | null;
  setModalOpen: (state: ModalOpenType) => void;
}

const InterestRolesEditStep: React.FC<InterestRolesEditProps> = ({ yearsOfRelevantWork, setModalOpen }) => {
  const { control, handleSubmit, errors } = useForm<InterestRoleEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [updateYears] = useRelevantYearsEditMutation();

  const onSubmit = async (data: InterestRoleEditVariables) => {
    const { years_of_relevant_work } = data;

    const variables = {
      userId: user.sub,
      years_of_relevant_work: (years_of_relevant_work || {})?.value,
    };

    await updateYears({ variables, refetchQueries: ['GetProfileByUserId'] });
    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Relevant Years of Experience'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.years_of_relevant_work}>
          How many years of relevant work experience/internships do you have?
        </WizardRequiredInputLabel>
        <RelevantYearsSelector
          name={'years_of_relevant_work'}
          defaultValue={yearsOfRelevantWork || ''}
          control={control}
          required={true}
          error={!!errors.years_of_relevant_work}
        />
      </Container>
    </Modal>
  );
};

export default InterestRolesEditStep;
