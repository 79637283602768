import React from 'react';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiCreateV2 from '../SelectorMultiCreateV2';
import { OptionId } from '../Option';

import { useGetFunFactsSelectionsQuery } from 'generated/graphql';

interface WantToKnowSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  values: OptionId[];
  name: string;
}

const WantToKnowSelector: React.FC<WantToKnowSelectorProps> = ({ values, name, register, setValue }) => {
  const { loading, data } = useGetFunFactsSelectionsQuery({
    errorPolicy: 'all',
    onError: console.warn, // eslint-disable-line no-console
  });
  const selectorOptions = data?.grad_fun_fact_selection.map(({ description: d }) => ({ value: d, label: d }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={values}
      name={name}
      placeholder={'Select'}
      required={false}
      register={register}
      setValue={setValue}
      maxSelection={Infinity}
    />
  );
};
export default WantToKnowSelector;
