declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkit: any;
  }
}

export const requiresIOSNativeDownloadResume = () =>
  window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.downloadResumeMessageHandler;

export const iOSNativeDownloadResume = (content: string, filename: string) => {
  window.webkit.messageHandlers.downloadResumeMessageHandler.postMessage({
    dataUri: content,
    filename,
  });
};
