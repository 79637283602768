import React from 'react';
import { WizardRequiredInputLabel, WizardInputLabel } from '../../../Wizard/components/library';
import {
  OptionId,
  WantToKnowSelector,
  EmployerPreferenceSelector,
  CellPhoneInput,
  YesNoPicker,
  createOptionId,
  StyledInput,
} from 'components';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import { Spacing } from 'components/Spacing';
import Modal from 'components/common/EditProfileModal';
import { TextArea } from 'components/TextArea';
import { Container } from './StudentInformationEdit.styled';
import { InputLegend } from '../../../Wizard/components';
import LanguageSelector from '../../../../components/LanguageSelector';
import ModeCommunicationSelector from '../../../../components/ModeCommunicationSelector';
import { useStudentInformationEditMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

export interface EmployerPreferences {
  employer_preference_id: string;
  preference: string;
}
export interface FunFacts {
  fun_fact_id: string;
  description: string;
}
export interface Languages {
  spoken_language_id: string;
  language: string;
}
export interface Achievements {
  achievement_id: string;
  description: string;
}
export interface Activities {
  volunteer_id: string;
  description: string;
}
export interface StudentInformationEditVariables {
  studentAthlete: string;
  personalAchievements: string;
  volunteerActivities: string;
  languages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  funFacts: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  employerPreferences: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  mobile: string;
  email: string;
  commPreference: OptionId;
}

interface StudentInformationEditProps {
  setModalOpen: (state: ModalOpenType) => void;
  commPreference: string;
  employersPreferencesInitial: EmployerPreferences[];
  mobile: string;
  email: string;
  funFacts: FunFacts[];
  spokenLanguages: Languages[];
  studentAthlete: boolean;
  achievementsInitial: Achievements[];
  volunteers: Activities[];
}

const StudentInformationsEditStep: React.FC<StudentInformationEditProps> = ({
  commPreference,
  employersPreferencesInitial,
  mobile,
  email,
  funFacts,
  spokenLanguages,
  studentAthlete,
  achievementsInitial,
  volunteers,
  setModalOpen,
}) => {
  const { register, control, handleSubmit, errors, setValue, clearError } = useForm<StudentInformationEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [updateProfile] = useStudentInformationEditMutation();

  const languagesOptions = (spokenLanguages || []).map((language) =>
    createOptionId(language.language, language.spoken_language_id),
  );

  const funFactsOptions = (funFacts || []).map((funFact) => createOptionId(funFact?.description, funFact.fun_fact_id));

  const employerPreferences = (employersPreferencesInitial?.length
    ? employersPreferencesInitial
    : []
  ).map((preference: any) => createOptionId(preference.preference, preference.employer_preference_id));

  const achievements = createOptionId(
    achievementsInitial[0]?.description || '',
    achievementsInitial[0]?.achievement_id,
  );
  const volunteersInitial = createOptionId(volunteers[0]?.description || '', volunteers[0]?.volunteer_id);

  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async (formData: StudentInformationEditVariables) => {
    const {
      studentAthlete: addStudentAthlete,
      funFacts: { optionsValue: funFactsOptionsNew },
      employerPreferences: { optionsValue: newEmployerPreferences },
      commPreference,
      mobile,
      email,
    } = formData;
    const studentAthlete = addStudentAthlete === 'yes';

    const addSpokenLanguages = getAddOptions(formData.languages?.current, languagesOptions).map(({ value }) => ({
      language: value,
      user_id: user.sub,
    }));
    const deleteSpokenLanguagesIDs = getEraseOptions(formData.languages?.current, languagesOptions)
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const eraseFunFacts = getEraseOptions(funFactsOptionsNew, funFactsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addFunFacts = getAddOptions(funFactsOptionsNew, funFactsOptions).map(({ value }) => ({
      description: value,
      user_id: user.sub,
    }));

    const addEmployerPreferences = getAddOptions(newEmployerPreferences, employerPreferences).map(({ value }) => ({
      preference: value,
      user_id: user.sub,
    }));

    const eraseEmployerPreferences = getEraseOptions(newEmployerPreferences, employerPreferences)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const newAchivements = [createOptionId(formData.personalAchievements || '')];

    const addAchievements = getAddOptions(newAchivements, [achievements]).map(({ value }) => ({
      description: value,
      user_id: user.sub,
    }));
    const deleteAchievements = getEraseOptions(newAchivements, [achievements])
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const newVolunteersActivities = [createOptionId(formData.volunteerActivities || '')];

    const addVolunteers = getAddOptions(newVolunteersActivities, [volunteersInitial]).map(({ value }) => ({
      description: value,
      user_id: user.sub,
    }));

    const deleteVolunteers = getEraseOptions(newVolunteersActivities, [volunteersInitial])
      .map(({ id }) => id)
      .filter(Boolean) as string[];
    const variables = {
      addSpokenLanguages,
      deleteSpokenLanguagesIDs,
      studentAthlete,
      funFactsForUpsert: addFunFacts,
      funFactIdsForDelete: eraseFunFacts,
      employerPreferencesForUpsert: addEmployerPreferences,
      employerPreferencesIdsForDelete: eraseEmployerPreferences,
      commPreference: commPreference?.value,
      mobile,
      email,
      userID: user.sub,
      addVolunteers,
      deleteVolunteers,
      addAchievements,
      deleteAchievements,
    };
    await updateProfile({ variables, refetchQueries: ['GetProfileByUserId'] });
    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Student Information'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.email}>Email</WizardRequiredInputLabel>
        <StyledInput
          type="email"
          name="email"
          placeholder="e.g: jdoe@email.com"
          error={!!errors.email}
          ref={register({ required: true })}
          defaultValue={email || ''}
        />
        {errors.email && <InputLegend error={!!errors.email}>Complete this field to move on</InputLegend>}

        <WizardInputLabel>What I want an employer to know about me</WizardInputLabel>
        <WantToKnowSelector name="funFacts" register={register} setValue={setValue} values={funFactsOptions || []} />
        <WizardInputLabel>What I look for in an employer?</WizardInputLabel>
        <EmployerPreferenceSelector
          name="employerPreferences"
          register={register}
          setValue={setValue}
          values={employerPreferences || []}
        />
        <WizardRequiredInputLabel error={!!errors.mobile}>What is your full phone number?</WizardRequiredInputLabel>
        <CellPhoneInput control={control} required defaultValue={mobile || null} name="mobile" />
        {errors.mobile && <InputLegend error={!!errors.mobile}>{errors.mobile.message}</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.commPreference}>
          What is your preferred mode of communication?
        </WizardRequiredInputLabel>
        <ModeCommunicationSelector
          name="commPreference"
          defaultValue={commPreference}
          control={control}
          required
          placeholder="Select"
          error={!!errors.commPreference}
        />

        <WizardInputLabel>In addition to English, what language(s) do you speak?</WizardInputLabel>
        <LanguageSelector
          name={'languages'}
          defaultValue={languagesOptions}
          control={control}
          setValue={setValue}
          register={register}
          error={!!errors.languages}
          required={false}
        />
        {errors.languages && <InputLegend error={!!errors.languages}>Complete this field to move on</InputLegend>}

        <YesNoPicker
          name="studentAthlete"
          required
          title="Are you a student athlete?"
          register={register}
          defaultValue={studentAthlete ? 'yes' : 'no'}
        />
        {errors.studentAthlete && (
          <InputLegend error={!!errors.studentAthlete}>Complete this field to move on</InputLegend>
        )}

        <WizardInputLabel>
          Have you received any awards or scholarships? Do you have any personal achievements you would like to share?
        </WizardInputLabel>
        <TextArea
          name="personalAchievements"
          placeholder="John Harvard Scholar, Scholarship Athlete - Tennis"
          ref={register}
          defaultValue={achievements?.value}
        />
        <Spacing margin="1rem" />
        <WizardInputLabel>
          Do you volunteer, belong to any student clubs or participate in extracurricular activities? If so, tell us!
        </WizardInputLabel>
        <TextArea
          name="volunteerActivities"
          placeholder="Big Brothers Big Sisters, Camillis House, Salvation"
          ref={register}
          defaultValue={volunteersInitial?.value}
        />
      </Container>
    </Modal>
  );
};

export default StudentInformationsEditStep;
