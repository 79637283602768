import React, { useEffect, useState } from 'react';
import { Text, HeadingText } from 'theme/typography';
import { palette } from 'theme';

import FilterDrawer from 'components/common/FilterDrawer';
import CompanyListHeaderDrawer from './CompanyListHeaderDrawer';

import {
  Row,
  Column,
  Avatar,
  Container,
  FiltersContainer,
} from '../../../JobsList/JobListHeader/JobListHeaderMobile.styled';
import { useCompanyFiltersContext } from '../CompanyList.context';
import { Badge } from 'components/Badge';
import useCompanyDynamicQuery from '../useCompanyDynamicQuery';
import { useAuth0 } from 'react-auth0-spa';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';
import { EGLogo } from 'theme/assets';

const CompanyListHeaderMobile: React.FC<{ onFilter: (dynamicQuery: any) => void }> = ({ onFilter }) => {
  const { filters } = useCompanyFiltersContext();
  const { subIndustries, location } = filters;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const dynamicQuery = useCompanyDynamicQuery(filters);
  const { user } = useAuth0();
  const { data } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <Container>
      <Row>
        <Column>
          <Text variant="l" bold color={palette.gray500}>
            {`Hi ${data?.grad_profile[0]?.firstname || ''}`}
          </Text>
          <HeadingText variant="h2">Find top tier Companies</HeadingText>
        </Column>
        <Avatar src={data?.grad_profile[0]?.profile_image || EGLogo} />
      </Row>
      <FilterDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} placeholder="Search For Jobs">
        <CompanyListHeaderDrawer onApply={() => setIsDrawerOpen(false)} onCancel={() => setIsDrawerOpen(false)} />
      </FilterDrawer>
      <FiltersContainer>
        {location && (
          <Badge variant="solid" color="beta" size="small">
            {location}
          </Badge>
        )}
      </FiltersContainer>
    </Container>
  );
};

export default CompanyListHeaderMobile;
