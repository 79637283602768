import styled, { css } from 'styled-components';
import { palette } from 'theme';
import { MEDIA_HEIGHT, MEDIA_SIZE } from 'utils/useDimensions';
import { PeopleTalkingLowQuality } from 'theme/assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -4rem;

  @media (max-width: ${MEDIA_SIZE.lm}) {
    height: calc(100% + 4rem);
  }
`;

const column = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: ${MEDIA_SIZE.md}) {
    width: 100%;
  }
`;

export const WelcomeSection = styled.div`
  ${column};

  position: relative;
  justify-content: flex-start;
  width: 60%;
  height: 100%;
  border: none;
  background-color: ${palette.beta100gray};
  border-right: 1px solid ${palette.gray200};

  @media (max-width: ${MEDIA_SIZE.lm}) {
    width: 100%;
  }

  @media (max-width: ${MEDIA_SIZE.md}) {
    align-items: center;
  }
`;

export const WelcomeTitleContainer = styled.div`
  font-family: 'RobotoBold';
  padding: 6rem;
  color: black;
  z-index: 1;

  @media (max-width: ${MEDIA_SIZE.md}) {
    padding: 3rem 0;
  }
`;

export const WelcomeTitle = styled.div`
  font-size: 2.8rem;
  color: ${palette.alpha500Red};

  div + div {
    color: black;
    margin: 0.5rem 0;
  }

  @media (max-width: ${MEDIA_SIZE.md}) {
    padding-top: 2rem;
    font-size: 2.4rem;
    text-align: center;
  }
`;

export const WelcomeDescription = styled.div`
  width: 60%;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-top: 2rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    width: 100%;
    margin-top: 3rem;
    padding: 0 5rem;
    text-align: center;
  }
`;

export const WelcomeImage = styled.img.attrs({ src: PeopleTalkingLowQuality })`
  position: absolute;
  bottom: 5rem;
  max-width: 60%;
  z-index: 0;

  @media (max-width: ${MEDIA_SIZE.md}) {
    bottom: 5%;
    max-width: 85%;
  }

  @media (max-width: ${MEDIA_SIZE.xs}) {
    max-width: 75%;
  }

  @media (max-height: ${MEDIA_HEIGHT.s}) {
    bottom: 1rem;
    max-width: 55%;
  }
`;

export const LoginSection = styled.div`
  ${column};

  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MEDIA_SIZE.lm}) {
    height: 100%;
  }

  @media (max-width: calc(${MEDIA_SIZE.md} - 1px)) {
    height: fit-content;
  }
`;

export const LoginContainer = styled.div`
  .auth0-lock-header,
  .auth0-lock.auth0-lock .auth0-lock-header {
    display: none;
    height: 0;
    padding: 0;
  }

  .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    flex-grow: 0;
  }

  .auth0-lock.auth0-lock .auth0-lock-submit {
    font-family: 'RobotoBold';
    font-size: 1.4rem;
    align-items: center !important;
    justify-content: center !important;
    height: 4rem;
    width: 70%;
    margin: 0 auto;
    padding: 0;
    background-color: ${palette.alpha500Red} !important;
    border-radius: 100px;

    & > span > svg {
      display: none;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 1rem;
    height: fit-content;
  }

  .auth0-lock.auth0-lock .auth0-lock-form {
    @media (max-height: ${MEDIA_HEIGHT.s}) {
      padding: 0 2rem;
    }
  }
`;

export const LoginFooterButtonsContainer = styled.div<{ subStep?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 4rem;
  box-shadow: ${({ subStep }) => (subStep === 1 ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none')};
  z-index: 2;

  button {
    font-family: 'RobotoBold';
    font-size: 1.8rem;
  }

  button:last-child {
    margin-bottom: 1rem;
    color: ${palette.alpha500Red};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 2.2rem 1.9rem 1.2rem 1.9rem;
  line-height: 1.6rem;
  background: ${palette.alpha500Red};
  height: calc(22.17vh + 4rem);
  margin-top: 4rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    height: 11rem;
    padding: 2.2rem 5rem 1.2rem 5rem;
    justify-content: space-between;
    margin-top: 4rem;
    height: 16.17vh;
  }
`;

export const HelpButtonContainer = styled.div`
  button {
    margin-top: 4rem;
    top: 10%;

    @media (min-width: ${MEDIA_SIZE.md}) {
      margin-top: 0;
      top: 20%;
    }
  }
`;

export const ElitegradLogo = styled.img`
  max-height: 15rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    max-height: 5rem;
  }
`;
