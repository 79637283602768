import React from 'react';
import { FormContextValues } from 'react-hook-form';

import SelectorMulti from 'components/SelectorMulti';
import { OptionId, createOptionId } from 'components/Option';

import { useGetInterestRegionSelectionQuery } from 'generated/graphql';

export interface RegionsPickerProps extends Pick<FormContextValues, 'control'> {
  defaultValue: OptionId[] | null;
  name: string;
  error?: boolean;
  required?: boolean;
}

const RegionsPicker: React.FC<RegionsPickerProps> = (props) => {
  const { data } = useGetInterestRegionSelectionQuery();
  const regionsOptions = data?.grad_interest_region_selection.map((region) => createOptionId(region.value));

  return <SelectorMulti options={regionsOptions || []} {...props} />;
};

export default RegionsPicker;
