import React from 'react';

import { StudentViewPaths } from '../../StudentViewRoutes';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { Tag } from '../library';
import { CardTitle, EmptyContainer, Icon, Text, TopEditButton, TagContainer } from './InterestFirmTypes.styled';

interface InterestFirmType {
  firm_type: string;
  interest_firm_id: string;
}

export interface InterestFirmTypesProps {
  interestFirmTypes: InterestFirmType[];
  fullProfile?: boolean;
  handleEdit: () => void;
}

const InterestFirmTypes: React.FC<InterestFirmTypesProps> = ({
  interestFirmTypes,
  fullProfile = false,
  handleEdit,
}) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Interested Firm Type {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
      {interestFirmTypes && interestFirmTypes.length > 0 && (
        <TagContainer>
          {interestFirmTypes?.map((firmType) => (
            <Tag key={firmType.interest_firm_id}>{firmType?.firm_type}</Tag>
          ))}
        </TagContainer>
      )}
      {(!interestFirmTypes || interestFirmTypes.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No Interested Firm Type</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default InterestFirmTypes;
