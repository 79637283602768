import styled from 'styled-components';
import { palette } from 'theme';

interface BadgeProps {
  variant: 'subtle' | 'solid' | 'outline';
  color: 'alpha' | 'beta' | 'white' | 'gamma';
  size: 'small' | 'medium' | 'big';
  noUppercase?: boolean;
}

const BADGE_COLOR = {
  subtle: {
    alpha: { background: `${palette.alpha50red}`, text: `${palette.alpha800Red}` },
    beta: { background: `${palette.white}`, text: `${palette.black}` },
    white: { background: `${palette.white}`, text: `${palette.alpha500Red}` },
    gamma: { background: `${palette.beta800gray}`, text: `${palette.white}` },
  },
  solid: {
    alpha: { background: `${palette.alpha500Red}`, text: `${palette.white}` },
    beta: { background: `${palette.gray500}`, text: `${palette.white}` },
    white: { background: `${palette.white}`, text: `${palette.alpha500Red}` },
    gamma: { background: `${palette.beta800gray}`, text: `${palette.white}` },
  },
  outline: {
    alpha: { background: `${palette.white}`, text: `${palette.black}` },
    beta: { background: `${palette.white}`, text: `${palette.black}` },
    white: { background: `${palette.white}`, text: `${palette.alpha500Red}` },
    gamma: { background: `${palette.beta800gray}`, text: `${palette.white}` },
  },
};

const BADGE_SIZE = {
  small: {
    padding: '0 0.5rem',
    fontSize: '0.8rem',
  },
  medium: {
    padding: '0.3rem 0.5rem',
    fontSize: '0.8rem',
  },
  big: {
    padding: '0 0.5rem',
    fontSize: '1rem',
  },
};

export const Badge = styled.span<BadgeProps>`
  display: flex;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ size }) => BADGE_SIZE[size].padding};

  font-family: 'RobotoBlack';
  font-size: ${({ size }) => BADGE_SIZE[size].fontSize};
  white-space: nowrap;
  background-color: ${({ variant, color }) => BADGE_COLOR[variant][color].background};
  border-radius: 4px;
  color: ${({ variant, color }) => BADGE_COLOR[variant][color].text};
  text-transform: ${({ noUppercase }) => (noUppercase ? 'none' : 'uppercase')};
`;
