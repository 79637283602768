import React from 'react';
import styled, { css } from 'styled-components';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { WizardHeader } from 'components/common/WizardHeader';
import { WizardFormButtons, WizardFormButtonsMobile } from './';

import { palette } from 'theme';
import { GraduateBackground } from 'theme/assets';

interface WizardContainerInterface {
  readonly step: number;
  readonly totalSteps: number;
  readonly showBackground?: boolean;
  readonly showButtons?: boolean;
  readonly navigable?: boolean;
  cardWidth?: string;
  cardHeight?: string;
  onNext?: (nav?: any) => void;
  onPrevious?: () => void;
  hideProgress?: boolean;
  hideHeaderButtons?: boolean;
  noPadded?: boolean;
  disabledNext?: boolean;
}

const WizardContainer: React.FC<WizardContainerInterface> = ({
  step,
  totalSteps = 0,
  children,
  showBackground,
  showButtons = false,
  navigable = true,
  hideHeaderButtons = false,
  cardWidth,
  cardHeight,
  hideProgress,
  noPadded = false,
  disabledNext = false,
  onNext,
  onPrevious,
}) => {
  const { width } = useWindowDimensions();
  return (
    <Wizard>
      <WizardHeader
        hideHeaderButtons={hideHeaderButtons}
        hideProgress={hideProgress}
        step={step}
        totalSteps={totalSteps}
      />
      <WizardPage noPadded={noPadded} image={showBackground && width >= mobileBreakpoint ? GraduateBackground : ''}>
        <WizardCard height={cardHeight} width={cardWidth}>
          {children}
        </WizardCard>
      </WizardPage>
      {showButtons && width >= mobileBreakpoint && (
        <WizardFormButtons disabledNext={disabledNext} onNext={onNext} onPrevious={onPrevious} />
      )}
      {showButtons && width < mobileBreakpoint && (
        <WizardFormButtonsMobile
          navigable={navigable}
          onNext={onNext}
          disabledNext={disabledNext}
          onPrevious={onPrevious}
        />
      )}
    </Wizard>
  );
};

interface StyledStepProps {
  active?: boolean;
}

export const WizardCard = styled.div<{ width?: string; height?: string }>`
  display: flex;
  box-shadow: none;
  border-radius: 0;
  background-color: ${palette.white};
  margin: 0;
  width: 100%;
  height: ${({ height }) => height || ''};

  @media (min-width: 768px) {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: ${({ width }) => width || '56vw'};
    height: 70vh;
    margin: auto auto;
  }
`;

export const WizardPage = styled.div<{ image?: string; noPadded?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${({ noPadded }) => (noPadded ? '' : '4rem')};
  background-color: ${palette.white};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${({ image }) =>
    image &&
    css`
      background: url(${image}) no-repeat center;
      background-size: cover;
    `}

  @media (min-width: 768px) {
    justify-content: center;
    padding-top: 0;
  }
`;

const Wizard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  text-align: left;
`;

export default WizardContainer;
