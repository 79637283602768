import React, { useState } from 'react';
import {
  Container,
  TabContainer,
  Tab,
  ContentContainer,
  CompanySection,
  AvatarIcon,
  Info,
  Row,
  CompanyContainer,
  CompanyContactInfoContainer,
  CompanyContactColumn,
  ContactContainer,
  ContactRow,
  CompanyWebsite,
} from './EventDetailsTabs.styled';
import { useGetCompanyDetailTabLazyQuery } from 'generated/graphql';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Text } from 'theme/typography';
import { Badge } from 'components/Badge';
import { palette } from 'theme';
import { inMobile, useWindowDimensions } from 'utils/useDimensions';
import Spinner from 'views/Student/StudentWidget/Resume/ResumeSpinner';

interface EventDetailsTabs {
  eventDescription: string;
  companyId: string;
}
const EventDetailTabs: React.FC<EventDetailsTabs> = ({ eventDescription, companyId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { width } = useWindowDimensions();
  const [getCompanyInfo, { data: dataCompany, loading: loadingCompany }] = useGetCompanyDetailTabLazyQuery({
    variables: {
      accountId: companyId,
    },
    fetchPolicy: 'cache-first',
  });

  const company = dataCompany?.recruit_company[0];
  return (
    <Container>
      <TabContainer>
        <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
          {inMobile(width) ? 'Description' : 'About the Event'}
        </Tab>
        <Tab
          active={activeTab === 1}
          onClick={() => {
            getCompanyInfo();
            setActiveTab(1);
          }}
        >
          {inMobile(width) ? 'Company' : ' About the Company'}
        </Tab>
      </TabContainer>
      <ContentContainer>
        {activeTab === 0 ? (
          <Text variant="l" color={palette.gray900}>
            {eventDescription || 'No description provided'}
          </Text>
        ) : (
          <>
            {loadingCompany ? (
              <Spinner />
            ) : (
              <CompanyContainer>
                <CompanySection>
                  <AvatarIcon src={company?.logo || ''} />
                  <Info>
                    <Text variant="xl" color={palette.gray900} bold>
                      {company?.name}
                    </Text>
                    <Text variant="m" color={palette.gray900}>
                      {company?.address}
                    </Text>
                    <Row>
                      {company?.subindustries.map((subindustry) => (
                        <Badge key={subindustry.value} variant={'solid'} color={'beta'} size={'medium'}>
                          {subindustry.value}
                        </Badge>
                      ))}
                    </Row>
                  </Info>
                </CompanySection>
                <Text variant="m" color={palette.gray700}>
                  {company?.description}
                </Text>
                <CompanyContactInfoContainer>
                  <CompanyContactColumn>
                    <Text variant="m" bold color={palette.black}>
                      Contact Information
                    </Text>
                    <ContactContainer>
                      <ContactRow>
                        <Text variant="m" bold color={palette.black}>
                          Phone:
                        </Text>
                        <Text variant="m" color={palette.gray800}>
                          {company?.phone && formatPhoneNumberIntl(company?.phone)}
                        </Text>
                      </ContactRow>
                      <ContactRow>
                        <Text variant="m" bold color={palette.black}>
                          Website:
                        </Text>
                        <CompanyWebsite href={company?.website || ''} target="_blank">
                          <Text variant="m" color={palette.gray800}>
                            {company?.website}
                          </Text>
                        </CompanyWebsite>
                      </ContactRow>
                    </ContactContainer>
                  </CompanyContactColumn>
                  <CompanyContactColumn>
                    <Text variant="m" bold color={palette.black}>
                      Employees
                    </Text>
                    <Badge noUppercase variant={'solid'} color={'beta'} size={'medium'}>
                      {company?.num_employees && (
                        <Text variant="m" color={palette.white}>
                          {company?.num_employees} Employees
                        </Text>
                      )}
                    </Badge>
                  </CompanyContactColumn>
                </CompanyContactInfoContainer>
              </CompanyContainer>
            )}
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

export default EventDetailTabs;
