import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { toast } from 'react-toastify';
import { SuccessIconTick } from 'theme/assets';
import { useForm } from 'react-hook-form';
import ModalOpenType from 'types/ModalOpen';
import { InputLegend } from '../../../Wizard/components';
import { WizardRequiredInputLabel, WizardInputLabel } from '../../../Wizard/components/library';
import SelectorMultiCreateV2 from '../../../../components/SelectorMultiCreateV2';
import { ACADEMIC_HONORS_OPTIONS, AwardsTypes } from '../../../../data/awards';
import { Container, ToastDiv, ToastTitle, ToastTitleContainer, TickIcon } from './PreviousDegree.styled';
import {
  usePreviousDegreeAwardsMutation,
  usePreviousDegreeEditMutation,
  usePreviousDegreeInsertMutation,
  useInsertFillUniversityMutation,
  useInsertConcentrationsMutation,
  useDeleteConcentrationsMutation,
  GetProfileStepProfileAwardPartsFragment,
  useDeletePreviousDegreeMutation,
} from 'generated/graphql';

import { ConcentrationTypes } from 'data/degree';
import { prepareConcentrationForUpsert } from 'components/MajorMinorSelector/concentrationUtils';
import Modal from 'components/common/EditProfileModal';
import {
  UniversitySelectorCreate,
  DegreePickerOption,
  MajorSelector,
  MonthYearPicker,
  OptionId,
  StyledCheckboxContainer,
  createOptionId,
} from 'components';

import DegreePicker from 'components/DegreePicker';
import CheckboxWithController from 'components/CheckboxWithController';
import GPAInput from 'components/GPAInput';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface PictureStepProps {
  setModalOpen: (status: ModalOpenType) => void;
  majorOptions: OptionId[];
  gpa: number | null;
  gpa_outof: number;
  complete_month_numeric: number | null;
  complete_year: number;
  start_month_numeric: number | null;
  start_year: number;
  gpa_not_applicable: boolean;
  degree_level: string;
  degree: string;
  degreeId: string;
  fillInUniversity: string;
  universityName: string;
  universityId: string;
  academicHonors?: GetProfileStepProfileAwardPartsFragment[];
}

export interface DegreeFormVariables {
  completeMonthNumeric: { value: number; label: string };
  completeYear: { value: number; label: string };
  startMonthNumeric: { value: number; label: string };
  startYear: { value: number; label: string };
  GPA: string;
  gpaOutOf: { value: number; label: string };
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  completed: boolean;
  majors: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  gpaNotApplicable: boolean;
  professionalDesignations: { optionsValue: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

const PictureStepController: React.FC<PictureStepProps> = ({
  setModalOpen,
  majorOptions,
  degreeId,
  gpa,
  gpa_outof,
  complete_month_numeric,
  complete_year,
  start_month_numeric,
  start_year,
  degree,
  degree_level,
  gpa_not_applicable,
  fillInUniversity,
  universityName,
  universityId,
  academicHonors,
}) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();

  const [updateMyDegree] = usePreviousDegreeEditMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertMyDegree] = usePreviousDegreeInsertMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateAwards] = usePreviousDegreeAwardsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertFillUniversity] = useInsertFillUniversityMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertConcentrations] = useInsertConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteConcentrations] = useDeleteConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteDegree] = useDeletePreviousDegreeMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const professionalDesignationsOptions = (academicHonors?.length ? academicHonors : [])
    .filter((award: GetProfileStepProfileAwardPartsFragment) => award.award_category === AwardsTypes.ACADEMIC_HONOR)
    .map((award: GetProfileStepProfileAwardPartsFragment) => createOptionId(award.award_name, award.award_id));

  const onSubmit = async (data: DegreeFormVariables, watchedGPANotApplicable: boolean) => {
    const {
      completeYear,
      completeMonthNumeric,
      startMonthNumeric,
      startYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA = watchedGPANotApplicable ? null : data.GPA,
      gpaOutOf = watchedGPANotApplicable ? null : { value: data.gpaOutOf },
      majors: { optionsValue: optionsMajorsNew, deletedOptionsIds: deletedMajorsIds },
      degreeSelectionOption,
      gpaNotApplicable,
      professionalDesignations: { optionsValue: designationsValue },
    } = data;
    const variables = {
      completeMonthNumeric: completeMonthNumeric?.value,
      completeYear: completeYear?.value,
      startMonthNumeric: startMonthNumeric?.value,
      startYear: startYear?.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degreeSelectionOption?.[0]?.degree || '',
      degreeLevel: degreeSelectionOption?.[0]?.degree_level || '',
      completed: true,
      gpaNotApplicable,
    };

    const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !initial
        ? []
        : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

    const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !selected
        ? []
        : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (deletedMajorsIds) {
      const majorsToDelete = getEraseOptions(optionsMajorsNew, majorOptions)
        .map(({ id }) => id)
        .filter((id) => id !== undefined) as string[];
      await deleteConcentrations({ variables: { ids: majorsToDelete } });
    }
    let insertDegreeResult;
    if (!degreeId) {
      insertDegreeResult = await insertMyDegree({
        variables: { userID: user.sub, ...variables },
        refetchQueries: ['GetProfileDegrees'],
      });
    } else {
      await updateMyDegree({ variables: { degreeId, ...variables }, refetchQueries: ['GetProfileDegrees'] });
    }
    const persistedDegreeId =
      insertDegreeResult && insertDegreeResult.data && insertDegreeResult.data.insert_grad_degree
        ? insertDegreeResult.data.insert_grad_degree.returning[0].degree_id
        : degreeId;

    const eraseProfessionalDesignations = getEraseOptions(designationsValue, professionalDesignationsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addProfessionalDesignations = getAddOptions(designationsValue, professionalDesignationsOptions).map(
      ({ value }) => ({
        award_category: AwardsTypes.ACADEMIC_HONOR,
        award_name: value,
        user_id: user.sub,
        ...(persistedDegreeId !== undefined && { degree_id: persistedDegreeId }),
      }),
    );
    if (persistedDegreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId, userId: user.sub };
        await insertFillUniversity({ variables: fillInUniversity });
      }
      if (optionsMajorsNew) {
        const majorsAdd = getAddOptions(optionsMajorsNew, majorOptions);
        const majorsForUpsert = prepareConcentrationForUpsert(
          majorsAdd,
          persistedDegreeId,
          user.sub,
          persistedDegreeId,
          ConcentrationTypes.MAJOR,
        );
        await insertConcentrations({
          variables: { concentrations: majorsForUpsert },
        });
      }
    }
    await updateAwards({
      variables: { awardsForUpsert: addProfessionalDesignations, awardIdsForDelete: eraseProfessionalDesignations },
      refetchQueries: ['GetProfileDegrees'],
    });
    setModalOpen('');
  };

  const onDelete = () => {
    degreeId &&
      deleteDegree({ variables: { degreeId }, refetchQueries: ['GetProfileDegrees'] }).then(() => {
        const successContent = (
          <ToastDiv>
            <ToastTitleContainer>
              <TickIcon src={SuccessIconTick} /> <ToastTitle>Item deleted successfully</ToastTitle>
            </ToastTitleContainer>
          </ToastDiv>
        );
        toast.success(successContent, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
    setModalOpen('');
  };

  const { register, setValue, handleSubmit, watch, errors, control, clearError } = useForm<DegreeFormVariables>();
  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');

  return (
    <Modal
      setModalOpen={setModalOpen}
      heightAuto={width > mobileBreakpoint}
      handleSubmit={handleSubmit((values) => onSubmit(values, watchedNotApplicable))}
      withDelete={Boolean(degreeId)}
      handleDelete={onDelete}
      title={'Previous Degree'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.universitySelectionIdOption}>University</WizardRequiredInputLabel>

        <UniversitySelectorCreate
          required
          fillInValue={fillInUniversity}
          value={{ id: universityId, label: universityName, value: universityName }}
          name={'universitySelectionIdOption'}
          control={control}
          error={!!errors.universitySelectionIdOption}
        />
        {errors.universitySelectionIdOption && <InputLegend error>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.degreeSelectionOption}>Degree</WizardRequiredInputLabel>
        <DegreePicker
          name={'degreeSelectionOption'}
          control={control}
          defaultDegree={degree}
          defaultLevel={degree_level}
          error={!!errors.degreeSelectionOption}
          clearable
          required
        />
        {errors.degreeSelectionOption && <InputLegend error>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.majors}>What is your Major?</WizardRequiredInputLabel>
        <MajorSelector
          required
          majorsValue={majorOptions}
          name={'majors'}
          clearErrors={() => clearError('majors')}
          register={register}
          setValue={setValue}
          error={!!errors.majors}
        />

        <StyledCheckboxContainer>
          <CheckboxWithController name="gpaNotApplicable" control={control} defaultChecked={!!gpa_not_applicable} />
          {'GPA is not applicable'}
        </StyledCheckboxContainer>
        {!watchedNotApplicable && (
          <>
            <WizardRequiredInputLabel error={!!errors.GPA}>GPA</WizardRequiredInputLabel>
            <GPAInput
              required
              defaultOutOf={gpa_outof}
              defaultGPA={gpa}
              watchedOutOf={watchedOutOf}
              gpaFieldName="GPA"
              outOfFieldName="gpaOutOf"
              control={control}
              register={register}
              error={!!errors.GPA}
            />
          </>
        )}
        {!watchedNotApplicable && errors.GPA && (
          <InputLegend error>{errors.GPA.message || 'Complete this field to move on'}</InputLegend>
        )}

        <WizardRequiredInputLabel error={!!(errors.startMonthNumeric || errors.startYear)}>
          Started on:
        </WizardRequiredInputLabel>
        <MonthYearPicker
          required
          monthFieldName="startMonthNumeric"
          yearFieldName="startYear"
          errors={errors}
          control={control}
          defaultMonth={start_month_numeric}
          defaultYear={start_year}
        />
        <WizardRequiredInputLabel error={!!(errors.completeMonthNumeric || errors.completeYear)}>
          Grauduation Date:
        </WizardRequiredInputLabel>
        <MonthYearPicker
          required
          monthFieldName="completeMonthNumeric"
          yearFieldName="completeYear"
          errors={errors}
          control={control}
          defaultMonth={complete_month_numeric}
          defaultYear={complete_year}
        />
        <WizardInputLabel>What are your academic honors?</WizardInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          dropDownIndicator={true}
          dropDownOptions={ACADEMIC_HONORS_OPTIONS}
          defaultOptionsValue={professionalDesignationsOptions || []}
          name="professionalDesignations"
          placeholder="Select"
          register={register}
          setValue={setValue}
        />
      </Container>
    </Modal>
  );
};

export default PictureStepController;
