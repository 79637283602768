import React from 'react';
import styled, { css } from 'styled-components';

import palette from 'theme/palette';

interface StyledTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled: boolean;
  customFontSize?: string;
}

const StyledTextInput = styled.input<StyledTextInputProps>`
  width: 100%;
  height: 3.4rem;
  padding: 0.8rem 1rem;

  border: 1px solid ${palette.gray300};
  border-radius: 5px;
  background-color: transparent;

  font-size: ${({ customFontSize }) => customFontSize || '1.4rem'};
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${palette.gray900};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${palette.gray600};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: none;

      background-color: ${'#eeeeee'};

      font-size: 1.6rem;
      line-height: 1.9rem;
      color: ${palette.darkGray};
    `}
`;

export interface TextInputProps {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  customFontSize?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  disabled = false,
  placeholder = '',
  value = '',
  onChange = () => undefined,
  ...props
}) => {
  return (
    <StyledTextInput
      {...props}
      type="text"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default TextInput;
