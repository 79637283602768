import styled from 'styled-components';
import { palette } from '../../../../theme';
import { ReactComponent as CapacityIcon } from 'theme/assets/CapacityIcon.svg';
import { MEDIA_SIZE } from 'utils/useDimensions';
import { Text } from 'theme/typography';

export const TimeLeft = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${palette.gray};
`;

export const ItemContainer = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
  margin: 0.1rem;
`;

export const ItemContainerRow = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
  margin: 0.1rem;

  & > * {
    margin-right: 1rem;
  }
`;

export const AvatarIcon = styled.img`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${palette.beta300gray};
  border-radius: 100%;
  margin-right: 1rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  & > span {
    margin-bottom: 0.3rem;
  }
`;

export const CompanySection = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const Expiration = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 24rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-size: cover !important;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  border: 1px solid ${palette.beta300gray};
  border-bottom: none;
  height: 10rem;
  padding: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 0;
  background-blend-mode: multiply;
  background-position: center center;
`;

export const HeaderIconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

export const HeaderIcon = styled.img`
  max-width: 3rem;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.beta300gray};
  border-bottom-left-radius: 13px;
  padding: 1rem;
  border-bottom-right-radius: 13px;
`;

export const CapacityIconStyled = styled(CapacityIcon)`
  height: 1.8rem;
  width: 1.8rem;
`;

export const Ellipsis = styled(Text)`
  max-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 22rem;
`;

export const UrlAddress = styled(Text)`
  max-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14rem;
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 1.2rem;
  color: ${palette.white};
  font-family: 'RobotoBold';
  cursor: pointer;
`;
