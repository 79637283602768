import React, { useEffect, useState } from 'react';
import { Text, HeadingText } from 'theme/typography';
import { palette } from 'theme';

import FilterDrawer from 'components/common/FilterDrawer';
import EventListHeaderDrawer from './EventListHeaderDrawer';

import { Row, Column, Avatar, Container, FilterIcon, FiltersContainer } from './EventListHeaderMobile.styled';
import { useEventFiltersContext } from '../EventList/EventList.context';
import { RegisteredEventRed, BookmarkSelected } from 'theme/assets';
import { Badge } from 'components/Badge';
import useEventsDynamicQuery from '../EventList/useEventsDynamicQuery';
import { useAuth0 } from 'react-auth0-spa';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';
import { EGLogo } from 'theme/assets';

const EventListHeaderMobile: React.FC<{ onFilter: (dynamicQuery: any) => void }> = ({ onFilter }) => {
  const { filters } = useEventFiltersContext();
  const { keyword, registered } = filters;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const dynamicQuery = useEventsDynamicQuery(filters);
  const { user } = useAuth0();
  const { data } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  useEffect(() => {
    onFilter(dynamicQuery);
  }, [registered]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [keyword]);

  return (
    <Container>
      <Row>
        <Column>
          <Text variant="l" bold color={palette.gray500}>
            {`Hi ${data?.grad_profile[0]?.firstname || ''}`}
          </Text>
          <HeadingText variant="h2">Find your Event in Finance</HeadingText>
        </Column>
        <Avatar src={data?.grad_profile[0]?.profile_image || EGLogo} />
      </Row>
      <FilterDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} placeholder="Search for events">
        <EventListHeaderDrawer
          onApply={() => {
            setIsDrawerOpen(false);
          }}
          onCancel={() => setIsDrawerOpen(false)}
        />
      </FilterDrawer>
      <FiltersContainer>
        {registered && <FilterIcon src={RegisteredEventRed} />}
        {keyword && (
          <Badge variant="solid" color="beta" size="small">
            {keyword}
          </Badge>
        )}
      </FiltersContainer>
    </Container>
  );
};

export default EventListHeaderMobile;
