import styled from 'styled-components';
import palette from 'theme/palette';

export const AvatarContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin: 0 auto;
  -webkit-appearance: none;
  background-color: ${palette.gray100};

  &::-webkit-slider-runnable-track {
    width: 25rem;
    height: 0.5rem;
    background: ${palette.gray100};
    border: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${palette.alpha500Red};
    margin-top: -0.2rem;
  }

  margin-top: 1rem;
  width: 24rem;
`;

export const Layer = styled.img`
  position: absolute;
  top: 0;
  z-index: 2;
  width: 24rem;
  pointer-events: none;
  height: 24rem;
`;

export const ButtonContainer = styled.div<{ fromWizard?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ fromWizard }) => (fromWizard ? 'center' : 'space-around')};
  & > * {
    margin-right: 2rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
