import React from 'react';
import GoBackHeader from 'components/common/GoBackHeader';
import ProfileJob from './ProfileJobTab';
import ProfilePersonal from './ProfilePersonalTab';
import ProfileLegalTabController from './ProfileLegalTab';
import ProfileSkillsTabController from './ProfileSkillsTab';
import ProfilePhotoController from './ProfilePhoto';
import { StyledLeftH1 } from 'components/library';
import { Container } from './Profile.styled';
import Tabs from 'components/Tabs';

const Profile: React.FC = () => {
  return (
    <Container>
      <GoBackHeader />
      <StyledLeftH1>Profile</StyledLeftH1>
      <ProfilePhotoController />
      <Tabs
        tabs={[
          { component: ProfilePersonal, tabName: 'Personal Info' },
          { component: ProfileJob, tabName: 'Career Goals' },
          { component: ProfileSkillsTabController, tabName: 'Skills' },
          { component: ProfileLegalTabController, tabName: 'Legal' },
        ]}
      />
    </Container>
  );
};

export default Profile;
