import React, { useEffect, useState } from 'react';
import { Text, HeadingText } from 'theme/typography';
import { palette } from 'theme';

import FilterDrawer from 'components/common/FilterDrawer';
import JobListHeaderDrawer from './JobListHeaderDrawer';

import { Row, Column, Avatar, Container, FilterIcon, FiltersContainer } from './JobListHeaderMobile.styled';
import { useJobFiltersContext } from '../JobList/JobList.context';
import { HandRised, BookmarkSelected } from 'theme/assets';
import { Badge } from 'components/Badge';
import useJobsDynamicQuery from '../JobList/useJobsDynamicQuery';
import { useAuth0 } from 'react-auth0-spa';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';
import { EGLogo } from 'theme/assets';

const JobListHeaderMobile: React.FC<{ onFilter: (dynamicQuery: any) => void }> = ({ onFilter }) => {
  const { filters } = useJobFiltersContext();
  const { subIndustry, role, keyword, hasHandraise, hasBookmark } = filters;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const dynamicQuery = useJobsDynamicQuery(filters);
  const { user } = useAuth0();
  const { data } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  useEffect(() => {
    onFilter(dynamicQuery);
  }, [role, subIndustry, hasBookmark, hasHandraise]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [keyword]);

  return (
    <Container>
      <Row>
        <Column>
          <Text variant="l" bold color={palette.gray500}>
            {`Hi ${data?.grad_profile[0]?.firstname || ''}`}
          </Text>
          <HeadingText variant="h2">Find your Job in Finance</HeadingText>
        </Column>
        <Avatar src={data?.grad_profile[0]?.profile_image || EGLogo} />
      </Row>
      <FilterDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} placeholder="Search For Jobs">
        <JobListHeaderDrawer onApply={() => setIsDrawerOpen(false)} onCancel={() => setIsDrawerOpen(false)} />
      </FilterDrawer>
      <FiltersContainer>
        {hasHandraise && <FilterIcon src={HandRised} />}
        {hasBookmark && <FilterIcon src={BookmarkSelected} />}
        {role && (
          <Badge variant="solid" color="alpha" size="small">
            {role}
          </Badge>
        )}
        {subIndustry.length > 0 &&
          subIndustry.map((sub) => (
            <Badge variant="solid" color="beta" size="small" key={sub}>
              {sub}
            </Badge>
          ))}
        {keyword && (
          <Badge variant="solid" color="beta" size="small">
            {keyword}
          </Badge>
        )}
      </FiltersContainer>
    </Container>
  );
};

export default JobListHeaderMobile;
