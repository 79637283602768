import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0 3rem;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2rem;
  height: 100%;
  flex: 1;
`;
