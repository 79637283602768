import React from 'react';
import CompanyDetail from './CompanyDetail';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components';
import { useGetCompanyDetailByPkQuery } from 'generated/graphql';

import { Container } from './Company.styled';
import Header from './CompanyDetail/CompanyDetailHeader';

const CompanyController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: dataCompany, loading: loadingCompany } = useGetCompanyDetailByPkQuery({
    skip: !id || Number.isNaN(id),
    variables: {
      companyId: +id,
    },
  });
  const rawCompany = dataCompany?.recruit_company_by_pk;

  if (!id || !rawCompany || loadingCompany) return <Spinner />;
  return (
    <Container>
      <Header logo={rawCompany.logo} />
      <CompanyDetail company={dataCompany || null} />
    </Container>
  );
};

export default CompanyController;
