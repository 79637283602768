import React from 'react';
import { UploadContainer, UploadFile } from './UploadButton.styled';

interface UploadButtonProps {
  onFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onFile }) => {
  return (
    <UploadContainer>
      <UploadFile onChange={onFile} />
      upload photo
    </UploadContainer>
  );
};

export default UploadButton;
