import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { palette } from 'theme';

const Container = styled.div`
  height: 0.8vh;
  width: 36vw;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 2rem;
`;

const Progress = styled.div<{ completed: number }>`
  height: 100%;
  width: ${({ completed }) => (completed ? `${completed}%` : '0%')};
  background-color: rgba(255, 255, 255, 1);
  transition: width 1s ease-in-out;
  opacity: 1;
  border-radius: inherit;
`;

interface HeaderPopperProps {
  completed: number;
}

const ProgressBar: React.FC<HeaderPopperProps> = ({ completed }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setInterval(() => setProgress(completed), 500);
  }, []);

  return (
    <Container>
      <Progress completed={progress} />
    </Container>
  );
};

export default ProgressBar;
