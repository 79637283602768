import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CardsContainer } from './EventList.styled';
import EventListCard from '../EventListCard';
import { GetEventListActiveQuery } from 'generated/graphql';
import Spinner from 'views/Student/StudentWidget/Resume/ResumeSpinner';
import EventListPlaceholder from '../EventListPlaceholder';
import { useEventFiltersContext } from './EventList.context';
interface EventListProps {
  events: GetEventListActiveQuery;
  registratedEvents: String[];
  inMobile?: boolean;
  fetchMore: () => void;
}

const EventList: React.FC<EventListProps> = ({ events, registratedEvents, inMobile, fetchMore }) => {
  const { filters } = useEventFiltersContext();
  const filteredEvents = filters?.registered
    ? events?.recruit_event?.filter((event: any) => registratedEvents.includes(event.event_id))
    : events?.recruit_event;

  const hasEvents = Boolean(filteredEvents?.length);
  const hasMoreEvents = filters?.registered
    ? Number(registratedEvents?.length) > Number(filteredEvents?.length)
    : Number(events?.recruit_event_aggregate.aggregate?.count) > Number(filteredEvents?.length);
  return hasEvents ? (
    <CardsContainer id="eventsCardsContainer">
      {inMobile ? (
        <InfiniteScroll
          dataLength={filteredEvents?.length || 15}
          next={() => fetchMore()}
          hasMore={hasMoreEvents}
          loader={<Spinner />}
          scrollableTarget="eventsCardsContainer"
        >
          {filteredEvents?.map((event) => (
            <EventListCard
              key={event.event_id}
              isRegistrated={registratedEvents.includes(event.event_id)}
              event={event}
              account_id={event.account_id}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <>
          {filteredEvents?.map((event) => (
            <EventListCard
              key={event.event_id}
              isRegistrated={registratedEvents.includes(event.event_id)}
              event={event}
              account_id={event.account_id}
            />
          ))}
        </>
      )}
    </CardsContainer>
  ) : (
    <EventListPlaceholder />
  );
};

export default EventList;
