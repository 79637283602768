import styled, { css } from 'styled-components';
import { palette } from 'theme';
import { ReactComponent as ChevronDown } from 'theme/assets/chevron-down-solid.svg';

export const ArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;
  fill: ${palette.gray400};
  opacity: 0.8;
  cursor: pointer;
  margin-top: 1rem;
  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  border-bottom: 1px solid rgba(160, 174, 192, 0.2);
  height: 100%;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 1rem 0 1.5rem 0;
  color: ${palette.gray400};
`;
