import styled from 'styled-components';
import { palette } from '../../../../../theme';
import { ReactComponent as CapacityIcon } from 'theme/assets/CapacityIcon.svg';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  padding: 1rem 0;
  border: 1px solid ${palette.beta300gray};
  box-sizing: border-box;
  border-radius: 13px;
  width: 95%;
  height: 100%;
  overflow-y: auto;
  margin: 1.5rem auto;

  @media (max-width: ${MEDIA_SIZE.md}) {
    border: none;
    width: 100%;
    margin: 0;
    padding-top: 0;
    margin-top: 1.5rem;
  }
`;

export const EventInfoContainer = styled.div`
  margin-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 2rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    border: none;
    width: 100%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 13px 13px;
    margin: 0;
    padding-bottom: 1.5rem;
  }
`;

export const ItemContainer = styled.div`
  margin: 0.3rem 0;

  & > *:last-child {
    margin-left: 0.3rem;
  }
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${palette.gray};
  &:after {
    content: ': ';
  }
`;

export const DescriptionContainer = styled.div`
  overflow: scroll;
`;

export const AvatarIcon = styled.img`
  width: 5.8rem;
  height: 5.8rem;
  border: 1px solid ${palette.beta300gray};
  border-radius: 100%;
  margin-right: 1rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  & > span {
    margin-bottom: 0.3rem;
  }
`;

export const CompanySection = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    margin-top: 0;
  }
`;

export const Expiration = styled.div`
  display: flex;
  align-items: center;
`;

export const CapacityIconStyled = styled(CapacityIcon)`
  height: 1.8rem;
  width: 1.8rem;
  margin-left: 1.5rem;
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 1.4rem;
  color: ${palette.white};
  font-family: 'RobotoBold';
  color: ${palette.alpha500Red};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const EventInfo = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  max-height: 100%;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;
