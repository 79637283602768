import React from 'react';

import { ACADEMIC_HONORS_OPTIONS, AwardsTypes } from 'data/awards';
import { ConcentrationTypes } from 'data/degree';
import { convertMonthToString } from 'utils/dateUtils';
import { findConcentrationFromDegree } from 'utils/degreeUtils';
import { createConcentrationOptions } from 'components';
import AcademicHonorTag from '../../CurrentDegree/AcademicHonorTag';
import { DEFAULT_GPA_OUT_OF } from 'components/GPAOutOfPicker';
import { StyledCard, OptionId } from 'components';

import {
  GpaDenominator,
  GpaNumerator,
  GPA,
  GPAText,
  UniversityDetails,
  TopEditButton,
  GpaAwardColumn,
  MajorText,
  DegreeUniversityText,
  DegreeDate,
} from './UniversityListCard.styled';
import { useModelContext } from 'views/Student/ModalContext/ModalContext';
import { DegreePartsFragment } from 'generated/graphql';

export interface UniversityProps {
  studentDegree: DegreePartsFragment;
  fullProfile?: boolean;
  degreeIndex: number;
}

const UniversityListCard: React.FC<UniversityProps> = ({ studentDegree, fullProfile = false, degreeIndex }) => {
  const { setModalState, setDegreeIndex } = useModelContext();
  const { gpa_not_applicable } = studentDegree || {};
  const { location_name } = studentDegree.university || {};
  const name = location_name
    ? location_name
    : studentDegree && studentDegree.fill_in_university && studentDegree.fill_in_university.institution_name;
  const degree = studentDegree.degree;

  const universityDegreeMajorText = `${findConcentrationFromDegree(studentDegree, ConcentrationTypes.MAJOR)} `;

  const universityDegreeDate = `${convertMonthToString(studentDegree.start_month_numeric)}
  ${studentDegree.start_year} - ${convertMonthToString(studentDegree.complete_month_numeric)} ${
    studentDegree.complete_year
  } `;

  const gpaNumeratorText = studentDegree.gpa ? studentDegree?.gpa.toFixed(1) : '0.0';
  const gpaDenominatorText = `/${studentDegree?.gpa_outof ? studentDegree?.gpa_outof?.toFixed(1) : DEFAULT_GPA_OUT_OF}`;

  const academicHonors = studentDegree.awards.filter(
    (award) =>
      award.award_category === AwardsTypes.ACADEMIC_HONOR &&
      ACADEMIC_HONORS_OPTIONS.some((option) => option.value === award.award_name),
  );
  const awardNameText = academicHonors.length ? academicHonors[0].award_name : '';

  return (
    <StyledCard>
      <UniversityDetails>
        <DegreeDate>
          {universityDegreeDate}
          {fullProfile && (
            <TopEditButton
              onClick={() => {
                setDegreeIndex(degreeIndex);
                setModalState('degreeEdit');
              }}
            ></TopEditButton>
          )}
        </DegreeDate>
        <MajorText>{universityDegreeMajorText}</MajorText>
        <DegreeUniversityText>
          {degree} {name}
        </DegreeUniversityText>
        <GpaAwardColumn>
          <GPA hasHonor={!!awardNameText}>
            {!!gpa_not_applicable ? (
              <GpaNumerator>N/A</GpaNumerator>
            ) : (
              <>
                <GpaNumerator>{gpaNumeratorText}</GpaNumerator>
                <GpaDenominator>{gpaDenominatorText}</GpaDenominator>
              </>
            )}
            <GPAText>GPA</GPAText>
          </GPA>
          {awardNameText && <AcademicHonorTag withSeparator awardName={awardNameText} />}
        </GpaAwardColumn>
      </UniversityDetails>
    </StyledCard>
  );
};

export default UniversityListCard;
