import React from 'react';

import JobList from './JobList';

import { MainViewLayout } from 'components';

const JobListView: React.FC = () => {
  return (
    <MainViewLayout>
      <JobList />
    </MainViewLayout>
  );
};

export default JobListView;
