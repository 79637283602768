import React from 'react';
import styled from 'styled-components';

const Questions: React.FC = () => {
  const emailAddress = 'help@elitegrad.com';
  return (
    <StyledQuestions>
      {'Questions? '}
      <a href={`mailto:${emailAddress}?Subject=Questions`}>{emailAddress}</a>
    </StyledQuestions>
  );
};

const StyledQuestions = styled.div`
  margin: 1rem 0;
  width: 100%;
  text-align: center;
`;

export default Questions;
