import React from 'react';
import { FormContextValues } from 'react-hook-form';
import styled from 'styled-components';
import { palette } from 'theme';
import { GPAPattern } from 'utils/validations';
import GPAOutOfPicker, { DEFAULT_GPA_OUT_OF } from './GPAOutOfPicker';
import { StyledInput } from './library';

const StyledGPAInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  width: 100%;

  & > * {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 32rem;
  width: 100%;
`;

const InputDivider = styled.div`
  width: 11.4rem;
`;

const LegendContainer = styled.div`
  text-align: center;
  margin-top: 0.6rem;
  margin-bottom: 1.6rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  height: 4.1rem;
`;

const Legend = styled.span`
  height: 4.1rem;
  font-family: 'Roboto';
  color: ${palette.gray400};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
`;

interface Props extends Pick<FormContextValues, 'control' | 'register'> {
  defaultGPA: number | null;
  defaultOutOf: number | null;
  watchedOutOf: number | null;
  gpaFieldName: string;
  outOfFieldName: string;
  required: boolean;
  error?: boolean;
}

const GPAInput: React.FC<Props> = ({
  defaultGPA,
  defaultOutOf,
  watchedOutOf,
  outOfFieldName,
  gpaFieldName,
  control,
  register,
  required,
  error = false,
}) => {
  const maxGPA = watchedOutOf === null ? DEFAULT_GPA_OUT_OF : watchedOutOf;

  return (
    <StyledGPAInput>
      <InputContainer>
        <InputDivider>
          <StyledInput
            type="text"
            name={gpaFieldName}
            placeholder="e.g: 3.91"
            error={error}
            ref={register({
              pattern: {
                value: GPAPattern,
                message: 'GPA must be a numeric value greater than 0',
              },
              required,
              validate: {
                min: (value) => !value || parseFloat(value) > 0 || 'GPA must be greater than 0',
                max: (value) => {
                  return !value || parseFloat(value) <= maxGPA || `GPA must be less or equal than ${maxGPA}`;
                },
              },
            })}
            defaultValue={defaultGPA ? defaultGPA.toFixed(2) : ''}
          />
        </InputDivider>
        <LegendContainer>
          <Legend>out of</Legend>
        </LegendContainer>
        <InputDivider>
          <GPAOutOfPicker
            name={outOfFieldName}
            required={required}
            defaultValue={defaultOutOf || DEFAULT_GPA_OUT_OF}
            control={control}
            error={error}
          />
        </InputDivider>
      </InputContainer>
    </StyledGPAInput>
  );
};

export default GPAInput;
