/* eslint-disable react/jsx-key */
import React, { FunctionComponent, useState } from 'react';
import { TabOption, TabSelector, TabsMenu } from './Tabs.styled';

export type TabType = { component: FunctionComponent; tabName: string };
export type Tabs = TabType[];

const Tabs: React.FC<{ tabs: Tabs }> = ({ tabs }) => {
  const [selected, setSelected] = useState<number>(0);
  const SelectedComponent = tabs[selected].component;

  const onClickOption = (option: number) => {
    return () => {
      setSelected(option);
    };
  };

  return (
    <>
      <TabsMenu>
        {tabs.map(({ tabName }, index) => {
          return (
            <TabOption key={index + '-' + tabName} active={selected === index} onClick={onClickOption(index)}>
              {tabName}
            </TabOption>
          );
        })}
      </TabsMenu>
      <TabSelector>
        <SelectedComponent />
      </TabSelector>
    </>
  );
};

export default Tabs;
