import React, { useEffect, useState } from 'react';
import { useAuth0 } from 'react-auth0-spa';

import JobList from './JobList';
import JobListHeader from '../JobListHeader';
import JobListHeaderMobile from '../JobListHeader/JobListHeaderMobile';
import JobListFooter from '../JobListFooter';
import { JobFiltersProvider } from './JobList.context';

import { useGetJobPostingsListQuery } from 'generated/graphql';

import { PaginationState } from 'components/common/Pagination';

import { Container, MobileCardContainer } from './JobList.styled';
import { SpinnerPage } from '../../../../components';

import { useWindowDimensions, inMobile } from 'utils/useDimensions';

export type JobType = 'internship' | 'full time' | null;

const JobListController: React.FC = () => {
  const { user } = useAuth0();
  const [pagination, setPagination] = useState<PaginationState>({ count: 9, limit: 9, offset: 0 });
  const { width } = useWindowDimensions();
  const [mobileInfiniteLimit, setMobileInfiniteLimit] = useState<number>(18);
  const [currentFilter, setCurrentFilter] = useState<any>();

  const { loading, data, refetch } = useGetJobPostingsListQuery({
    variables: {
      userId: user.sub,
      limit: 9,
      offset: 0,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const onFilter = (dynamicQuery: any) => {
    refetch({ dynamicQuery, ...pagination, userId: user.sub, offset: 0 });
    setCurrentFilter(dynamicQuery);
  };

  const onPageChange = (offset: number) => {
    refetch({
      dynamicQuery: currentFilter,
      ...pagination,
      offset,
      userId: user.sub,
    });

    setPagination({
      ...pagination,
      offset,
      count: (data?.recruit_job_posting_aggregate.aggregate?.count as number) || 9,
    });
  };

  const fetchMoreData = () => {
    const hasMoreJobs =
      Number(data?.recruit_job_posting_aggregate.aggregate?.count) > Number(data?.recruit_job_posting?.length);
    if (hasMoreJobs) {
      refetch({ limit: mobileInfiniteLimit, offset: 0, userId: user.sub });
      setMobileInfiniteLimit(mobileInfiniteLimit + 9);
    }
  };

  useEffect(() => {
    setPagination({ ...pagination, count: (data?.recruit_job_posting_aggregate.aggregate?.count as number) || 9 });
  }, [data, data?.recruit_job_posting_aggregate.aggregate?.count]);

  if (loading) return <SpinnerPage />;

  return (
    <JobFiltersProvider>
      <Container>
        <MobileCardContainer>
          {!inMobile(width) ? <JobListHeader onFilter={onFilter} /> : <JobListHeaderMobile onFilter={onFilter} />}
          <JobList jobs={data || null} inMobile={inMobile(width)} fetchMore={fetchMoreData} hasFilters />
          {!inMobile(width) && (
            <JobListFooter
              pagination={pagination}
              onPageChange={onPageChange}
              studentsCount={data?.recruit_job_posting.length || 0}
            />
          )}
        </MobileCardContainer>
      </Container>
    </JobFiltersProvider>
  );
};
export default JobListController;
