import React from 'react';
import { WizardInputLabel, WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { OptionId, RegionsPicker, YesNoPicker, OptionIdStrict, CityStatePickerOption } from 'components';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { Container } from './LocationEdit.styled';
import { InputLegend } from '../../../Wizard/components';
import { useUpdateLocationEditMutation, useUpdateLocationEditRelatedMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';
import CityStatePicker from '../../../../components/CityStatePicker';

interface InterestRegions {
  interest_region_id: string;
  region: string;
}

export interface LocationEditVariables {
  citySelectionOption: CityStatePickerOption[];
  interestRegions: OptionIdStrict[];
  needSponsor: string;
  willRelocate: string;
  commPreference: OptionId;
}

interface LocationEditProps {
  initialInterestRegions?: InterestRegions[];
  setModalOpen: (state: ModalOpenType) => void;
  willRelocate: boolean;
  hometown: string;
}

const LocationEditStep: React.FC<LocationEditProps> = ({
  initialInterestRegions,
  setModalOpen,
  willRelocate,
  hometown,
}) => {
  const { register, control, handleSubmit, errors } = useForm<LocationEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();

  const [updateProfile] = useUpdateLocationEditMutation();
  const [updateRegions] = useUpdateLocationEditRelatedMutation();

  const currentRegions =
    initialInterestRegions?.map(({ region, interest_region_id }: { region: string; interest_region_id: string }) => ({
      value: region,
      label: region,
      id: interest_region_id,
    })) || [];

  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async (data: LocationEditVariables) => {
    const { citySelectionOption, interestRegions, willRelocate, needSponsor } = data;

    const isWillingToRelocate = willRelocate === 'yes' ? true : false;

    const eraseRegions = getEraseOptions(interestRegions, currentRegions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addRegions = getAddOptions(interestRegions, currentRegions).map<{ region: string; user_id: string }>(
      ({ value }) => ({
        region: value,
        user_id: user.sub,
      }),
    );
    const variables = {
      hometown: citySelectionOption?.[0]?.value || '',
      willRelocate: isWillingToRelocate,
      userId: user.sub,
    };
    await updateProfile({ variables });
    await updateRegions({
      variables: {
        regionsToUpsert: addRegions,
        regionsToDelete: eraseRegions,
      },
      refetchQueries: ['GetProfileByUserId'],
    });

    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Location'}
    >
      <Container>
        <WizardInputLabel>What is your hometown?</WizardInputLabel>
        <CityStatePicker
          defaultValue={hometown}
          name="citySelectionOption"
          control={control}
          clearable
          required={false}
        />

        <YesNoPicker
          name="willRelocate"
          required
          title="Are you willing to relocate for the right role?"
          register={register}
          defaultValue={willRelocate ? 'yes' : 'no'}
          error={!!errors.willRelocate}
        />
        {errors.willRelocate && <InputLegend error>Complete this field to move on</InputLegend>}
        <WizardRequiredInputLabel error={!!errors.interestRegions}>
          What is your desired location
        </WizardRequiredInputLabel>
        <RegionsPicker
          error={!!errors.interestRegions}
          required
          name="interestRegions"
          control={control}
          defaultValue={currentRegions}
        />
        {errors.interestRegions && (
          <InputLegend error={!!errors.interestRegions}>Complete this field to move on</InputLegend>
        )}
      </Container>
    </Modal>
  );
};

export default LocationEditStep;
