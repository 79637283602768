import React, { useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import { GetCountrySelectionPartsFragment, useGetCountrySelectionsQuery } from 'generated/graphql';
import Selector from '../Selector';
import { OptionId } from '../Option';

const FIRST_COUNTRY_IN_OPTIONS_LIST = '.us';

const sortCountries = (countries: GetCountrySelectionPartsFragment[]) => {
  const first_option = countries.filter((c) => c.country_selection_id === FIRST_COUNTRY_IN_OPTIONS_LIST);
  const other_options = countries
    .filter((c) => c.country_selection_id !== FIRST_COUNTRY_IN_OPTIONS_LIST)
    .sort((a, b) => (a.country < b.country && -1) || (a.country > b.country && 1) || 0);
  return [...first_option, ...other_options];
};

interface CountryPickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultCountryName: string | null;
  defaultCountryId: string | null;
  required?: boolean;
  placeholder?: string;
  errors?: boolean;
}

const CountryPicker: React.FC<CountryPickerProps> = ({
  control,
  name,
  defaultCountryName,
  defaultCountryId,
  required = false,
  placeholder,
  errors = false,
}) => {
  const { loading: loadingCountries, data: dataCountries } = useGetCountrySelectionsQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const options = useMemo(() => {
    if (!dataCountries || !dataCountries.grad_country_selection) {
      return [];
    }

    const countries = sortCountries(dataCountries.grad_country_selection);

    return countries.map((dc) => ({ label: dc.country.trim(), value: dc.country_selection_id }));
  }, [dataCountries]);

  const defaultOption: OptionId | null =
    defaultCountryName || defaultCountryId ? { label: defaultCountryName || '', value: defaultCountryId || '' } : null;

  return (
    <Selector
      options={options}
      defaultValue={defaultOption}
      name={name}
      control={control}
      placeholder={placeholder}
      required={required}
      loading={loadingCountries}
      isClearable={true}
      error={errors}
    />
  );
};

export default CountryPicker;
