import React from 'react';

import { DefaultUniversity } from 'theme/assets';
import AcademicHonorTag from './AcademicHonorTag';
import CandidateGPADisplay from './CandidateGPADisplay';
import ModalOpenType from 'types/ModalOpen';
import { StudentViewPaths } from '../../StudentViewRoutes';
import WidgetCard from '../WidgetCard';

import {
  Container,
  DegreeName,
  DegreeYear,
  GPAHonor,
  Logo,
  University,
  UniversityName,
  Award,
  CardTitle,
  TopEditButton,
} from './CurrentDegree.styled';

export interface CandidateCurrentDegreeProps {
  showDate?: boolean;
  degreeName?: string | null;
  majorName?: string | null;
  universityName?: string | null;
  universityLogo?: string | null;
  awardName?: string | null;
  completeDate?: Date | null;
  gpa?: number | null;
  gpaOutOf?: number | null;
  gpaNotApplicable?: boolean | null;
  completeMonth?: string | null;
  completeYear?: string | null;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const CandidateCurrentDegree: React.FC<CandidateCurrentDegreeProps> = ({
  showDate = true,
  degreeName,
  majorName,
  universityName,
  universityLogo,
  completeMonth,
  completeYear,
  awardName,
  gpa,
  gpaOutOf,
  gpaNotApplicable,
  fullProfile = false,
  handleEdit,
}) => {
  return (
    <WidgetCard>
      <Container>
        <CardTitle>{fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
        <Logo src={universityLogo || DefaultUniversity} />
        {showDate && <DegreeYear>{completeMonth && completeYear && `${completeMonth} ${completeYear}`}</DegreeYear>}
        <University>
          <UniversityName>{universityName}</UniversityName>
        </University>
        <DegreeName>
          <strong>{degreeName}</strong>
          {', '}
          {majorName}
        </DegreeName>
        <GPAHonor>
          <CandidateGPADisplay gpa={gpa} gpaOutOf={gpaOutOf} gpaNotApplicable={gpaNotApplicable} />
        </GPAHonor>
        <Award>{awardName && <AcademicHonorTag awardName={awardName} />}</Award>
      </Container>
    </WidgetCard>
  );
};

export default CandidateCurrentDegree;
