import React, { useState } from 'react';

export interface CompanyFiltersProps {
  subIndustries: string[];
  location: string;
}

interface CompanyFiltersContextProps {
  filters: CompanyFiltersProps;
  filterActive: boolean;
  handleFilterChange: (value: Partial<CompanyFiltersProps>) => void;
  clearFilters: () => void;
}
export const defaultFiltersValue = {
  subIndustries: [],
  location: '',
};

const defaultContextValue = {
  filters: defaultFiltersValue,
  filterActive: false,
  handleFilterChange: (value: Partial<CompanyFiltersProps>) => value,
  clearFilters: () => null,
};

const CompanyFilters = React.createContext<CompanyFiltersContextProps>(defaultContextValue);

export const CompanyFiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<CompanyFiltersProps>(defaultFiltersValue);

  const handleFilterChange = (value: Partial<CompanyFiltersProps>) => setFilters({ ...filters, ...value });

  const clearFilters = () => setFilters(defaultFiltersValue);

  const filterActive = filters?.subIndustries?.length > 0 || Boolean(filters?.location);

  const value = { filterActive, filters, handleFilterChange, clearFilters };

  return <CompanyFilters.Provider value={value}>{children}</CompanyFilters.Provider>;
};

export const useCompanyFiltersContext = () => React.useContext(CompanyFilters);
