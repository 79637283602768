import React, { useMemo } from 'react';
import { InputLegend } from 'views/Wizard/components';
import { FormContextValues } from 'react-hook-form';

import Selector from '../Selector';
import { OptionId } from '../Option';

import { useGetCommunicationSelectorQuery } from 'generated/graphql';

export interface ModeCommunicationSelectorProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultValue: string | null;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
}

const ModeCommunicationSelector: React.FC<ModeCommunicationSelectorProps> = ({
  name,
  defaultValue,
  clearable = false,
  required = false,
  placeholder = undefined,
  control,
  error = false,
}) => {
  const { loading: loadingModes, data: modesOfCommunication } = useGetCommunicationSelectorQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const defaultCommOption = useMemo(() => {
    return {
      label: defaultValue || '',
      value: defaultValue || '',
    };
  }, [defaultValue]);

  const communicationsOptions = useMemo(() => {
    if (!modesOfCommunication) {
      return [];
    }

    return modesOfCommunication.grad_comm_preference_selection.map((fi: any) => ({
      label: fi.value,
      value: fi.value,
      id: fi.comm_preference_selection_id,
    }));
  }, [modesOfCommunication]);

  return (
    <>
      <Selector
        options={communicationsOptions}
        defaultValue={defaultCommOption}
        name={name}
        placeholder={placeholder}
        required={required}
        isClearable={clearable}
        disabled={false}
        loading={loadingModes}
        control={control}
        isSearchable={false}
        error={error}
      />
      {error && <InputLegend error>Complete this field to move on</InputLegend>}
    </>
  );
};

export default ModeCommunicationSelector;
