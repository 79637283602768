import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import { UploadIcon, UploadSuccess } from 'theme/assets';
import { palette } from 'theme';

const getColor = (props: any) => {
  if (props.isDragActive) {
    return '#2196f3';
  }
  return palette.black;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: ${(props) => `1px dashed ${getColor(props)}`};
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: 26rem;
  width: 26rem;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 7.5rem;
  height: 7.5rem;
`;

const UploadText = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
  color: ${palette.black};
  margin: 1rem 0;
  width: 23rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadingText = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${palette.gray500};
  text-align: center;
`;

const BrowseText = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  text-decoration-line: underline;
  color: ${palette.alpha500Red};
`;

const StyledSpinner = styled(Spinner)`
  margin: unset;
  width: unset;
  margin-bottom: 1.5rem;

  color: ${palette.alpha500Red};
`;

interface DropZoneProps {
  file: string | null;
  setFile: (value: File | null) => void;
  setNewFileName: (value: string) => void;
  loading: boolean;
}

const StyledDropzone: React.FC<DropZoneProps> = ({ file, setFile, setNewFileName, loading }) => {
  const onDropAccepted = (files: File[]) => {
    setFile(files?.[0]);
    setNewFileName(files?.[0]?.name);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept:
      '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxFiles: 1,
    onDropAccepted,
  });
  return (
    <Wrapper>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {loading ? (
          <>
            <StyledSpinner />
            <LoadingText>Uploading your file.</LoadingText>
            <LoadingText>You’re closer to becoming</LoadingText>
            <LoadingText>an EliteGrad</LoadingText>
          </>
        ) : (
          <>
            <Icon src={file ? UploadSuccess : UploadIcon} />
            <UploadText>{file ? file : 'Upload your file here'}</UploadText>
            <BrowseText>{file ? 'Replace' : 'Browse'}</BrowseText>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default StyledDropzone;
