import React, { useState } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';

import { useHistory } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor';

import { notify } from 'utils/notify';
import { SUBMIT_GO_TO, CANCEL_GO_TO, WizardPage } from '../components';
import { WizardProps } from '../components/interface';
import { Spinner } from 'components';
import PictureStep from './PictureStep';

import { UserDefault as DefaultAvatar } from 'theme/assets';

import { useGetProfilePhotoQuery, useUploadProfilePhotoMutation } from 'generated/graphql';

const PictureStepController: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
  backGoTo,
}) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [image, setImage] = useState<string>();
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [editor, setEditor] = useState<AvatarEditor>();

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const { loading, error } = useGetProfilePhotoQuery({
    fetchPolicy: 'no-cache',
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onCompleted: (req) => {
      if (req.grad_profile[0].profile_image) {
        setImage(req?.grad_profile[0].profile_image);
      } else {
        setImage(DefaultAvatar);
      }
    },
  });

  const [uploadPhotoMutation] = useUploadProfilePhotoMutation();

  if (error) {
    const queryToastId = 'WizardGetMyPictureToast';
    notify(queryToastId, 'There was an issue uploading your profile picture, please try again later.');
  }

  const updateImage = async (imageFile: string) => {
    setImage(imageFile);
  };

  const onDelete = () => {
    setImage(DefaultAvatar);
  };

  const onNext = async () => {
    const profileImage =
      !image || image === DefaultAvatar ? null : editor ? editor.getImageScaledToCanvas().toDataURL() : image;
    setUploadingImage(true);
    await uploadPhotoMutation({ variables: { userId: user.sub, profileImage } });
    setUploadingImage(false);
    history.push(nextGoTo);
  };

  if (loading || uploadingImage) return <Spinner />;

  return (
    <WizardPage
      onNext={onNext}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons
      onPrevious={() => history.push(backGoTo || '')}
    >
      <PictureStep
        onDelete={onDelete}
        updateImage={updateImage}
        isLastStep={isLastStep}
        cancelSkipGoTo={cancelSkipGoTo}
        step={step}
        editorRef={editorRef}
        image={image || null}
      />
    </WizardPage>
  );
};

export default PictureStepController;
