/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useInsertQueueMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import { WizardPage } from 'views/Wizard/components';
import { Spacing } from 'components/Spacing';
import {
  Container,
  FormContainer,
  MainText,
  Title,
  SmallText,
  TopContainer,
  DoneContainer,
  Icon,
  Link,
  ToastDiv,
  ToastSubTitle,
  ToastTitle,
  TickIcon,
  ToastTitleContainer,
  Button,
} from './VerifyEmail.styled';
import { WizardProps } from 'views/Wizard/components/interface';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useHistory } from 'react-router-dom';
import { DoneCheckMark, SuccessIconTick } from 'theme/assets';
import { StudentViewPaths } from '../StudentViewRoutes';

const DoneScreen: React.FC<WizardProps> = ({ step, totalSteps, backGoTo = '' }) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const history = useHistory();

  const [verifyEmail] = useInsertQueueMutation();

  const params = new URLSearchParams(history.location.search);
  const token = params.get('token');

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} /> <ToastTitle>Email verified successfully</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>Error verifying the email</ToastTitle>
      </ToastTitleContainer>
      <ToastSubTitle>Please contact our support team</ToastSubTitle>
    </ToastDiv>
  );
  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  useEffect(() => {
    if (token) {
      try {
        verifyEmail({
          variables: {
            queue_name: 'verify-email',
            content: {
              encrypted_token: token,
            },
          },
        }).then(() => {
          notifySuccess();
        });
      } catch (error) {
        notifyError();
      }
    }
  }, []);

  return (
    <WizardPage
      onNext={() => {}}
      showBackground={false}
      step={step}
      totalSteps={totalSteps}
      onPrevious={() => history.push(backGoTo)}
      showButtons={false}
      hideProgress
      hideHeaderButtons
      noPadded={width < mobileBreakpoint}
      cardHeight={width < mobileBreakpoint ? '100%' : ''}
    >
      <DoneContainer>
        <TopContainer>
          <Icon src={DoneCheckMark} />
        </TopContainer>
        <FormContainer>
          <Container>
            <Title>{'Done! Your email has been verified'}</Title>
            <Spacing margin="0.8rem" />
            <SmallText>{'If you need help, email us at: help@elitegrad.com'}</SmallText>
            <Spacing margin="2rem" />
            <Button onClick={() => history.push(StudentViewPaths.home)}>Go to profile</Button>
          </Container>
        </FormContainer>
      </DoneContainer>
    </WizardPage>
  );
};

export default DoneScreen;
