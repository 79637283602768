import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import SpinnerPage from 'components/SpinnerPage';
import EmailStep, { EmailStepFormVariables } from './EmailStep';
import { notify } from 'utils/notify';

import {
  useGetProfileStepProfileQuery,
  useUpdateEmailStepProfileMutation,
  useInsertQueueMutation,
} from 'generated/graphql';
import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO } from '../components';

const DEFAULT_VALUES = {
  email: '',
};

const ProfileStepContainer: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: getProfileLoading, data: getProfileData } = useGetProfileStepProfileQuery({
    fetchPolicy: 'network-only',
    variables: { myUserId: user.sub },
  });

  const [setWizardComplete] = useInsertQueueMutation();
  const [updateProfile] = useUpdateEmailStepProfileMutation();

  if (getProfileLoading) return <SpinnerPage />;

  const myInfo = getProfileData?.grad_profile?.[0] || DEFAULT_VALUES;

  const onSubmit = async (data: EmailStepFormVariables) => {
    const { email } = data;

    const variables = {
      userId: user.sub,
      email: email,
    };

    if (getProfileData && getProfileData.grad_profile[0]) {
      try {
        await updateProfile({ variables });
        setWizardComplete({
          variables: {
            queue_name: 'profile-wizard-complete',
            content: {
              user_id: user.sub,
            },
          },
        });
        history.push(nextGoTo);
      } catch (error) {
        notify('Something went wrong. Please try again or contact support team for assistance', 'top-center');
      }
    }
  };

  return (
    <EmailStep
      onSubmit={onSubmit}
      email={myInfo.email || ''}
      step={step}
      totalSteps={totalSteps}
      backGoTo={backGoTo || ''}
      nextGoTo={nextGoTo || ''}
    />
  );
};

export default ProfileStepContainer;
