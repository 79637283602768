import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import HeaderPopper from 'components/common/Popper';
import ProgressBar from 'components/common/ProgressBar';
import { useAuth0 } from 'react-auth0-spa';
import { WizardElitegradLogo, HeaderLogoMobile } from 'theme/assets';
import { usePopper } from '../../../utils/usePopper';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import {
  Container,
  Logo,
  StepBarContainer,
  StepBarLabel,
  Step,
  StepBar,
  ButtonContainer,
  CancelButton,
  HelpButton,
  PopperInformation,
  TopContainer,
} from './WizardHeader.styled';

interface WizardContainerInterface {
  readonly step: number;
  readonly totalSteps: number;
  readonly hideProgress?: boolean;
  readonly hideHeaderButtons?: boolean;
}
const WizardHeader: React.FC<WizardContainerInterface> = ({
  step,
  totalSteps,
  hideProgress = false,
  hideHeaderButtons = false,
}) => {
  const { logout } = useAuth0();
  const { width } = useWindowDimensions();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const { popperOpen, togglePopper, setPopperStatus } = usePopper(`step-${step}-help`);
  return (
    <>
      <Container>
        <TopContainer hideHeaderButtons={hideHeaderButtons}>
          <Link to="/app/wizard/step-2">
            <Logo src={width >= mobileBreakpoint ? WizardElitegradLogo : HeaderLogoMobile} alt="Elitegrad logo" />
          </Link>
          {step !== 1 && !hideProgress && width >= mobileBreakpoint && (
            <StepBarContainer>
              <StepBarLabel>EliteGrad Application</StepBarLabel>
              <StepBar>
                {[...Array(totalSteps)].map((_val, index) => (
                  <Step key={index} active={index < step}></Step>
                ))}
              </StepBar>
            </StepBarContainer>
          )}
          {!hideHeaderButtons && (
            <ButtonContainer>
              {width >= mobileBreakpoint && <CancelButton onClick={logout}>Cancel</CancelButton>}
              <HelpButton ref={setReferenceElement} onClick={togglePopper}>
                ?
              </HelpButton>
            </ButtonContainer>
          )}
        </TopContainer>
        {width < mobileBreakpoint && step !== 1 && !hideProgress && (
          <ProgressBar completed={(step * 100) / totalSteps} />
        )}
      </Container>
      <HeaderPopper show={popperOpen} referenceElement={referenceElement}>
        <PopperInformation>
          If you need help email us at:
          <a href="mailto:help@elitegrad.com" type="email" target="_blank" rel="noreferrer">
            help@elitegrad.com
          </a>
        </PopperInformation>
      </HeaderPopper>
    </>
  );
};

export default WizardHeader;
