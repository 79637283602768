import {
  GetStudentProfileDocument,
  GetStudentProfileQuery,
  useUpdateRelatedSkillsProfileMutation,
} from 'generated/graphql';

export const useUpdateRelatedSkillsProfileMutationHook = (userId: string) => {
  return useUpdateRelatedSkillsProfileMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: updateRelatedData }) => {
      const profileCache = cache.readQuery<GetStudentProfileQuery>({
        query: GetStudentProfileDocument,
        variables: { userId },
      });

      const [oldGradProfile] = profileCache?.grad_profile || [];

      if (!oldGradProfile) return;

      if (!updateRelatedData?.insert_grad_computer_language || !updateRelatedData?.delete_grad_computer_language) {
        return;
      }

      const deletedLanguageIds = updateRelatedData.delete_grad_computer_language.returning.map(
        ({ computer_language_id }: { computer_language_id: string }) => computer_language_id,
      );
      const newLanguages = [
        ...oldGradProfile.computer_languages,
        ...updateRelatedData.insert_grad_computer_language.returning,
      ].filter(({ computer_language_id }) => !deletedLanguageIds.includes(computer_language_id));

      const newGradProfile = {
        ...oldGradProfile,
        computer_languages: newLanguages,
      };

      cache.writeQuery({
        query: GetStudentProfileDocument,
        variables: { userId },
        data: {
          grad_profile: [newGradProfile],
        },
      });
    },
  });
};
