import React from 'react';
import { FormContextValues } from 'react-hook-form';

import Selector from './Selector';
import { OptionGPAOutOf } from './Option';

const VALUES = [4, 5];
const DEFAULT_GPA_OUT_OF = 4;

interface Props extends Pick<FormContextValues, 'control'> {
  required: boolean;
  defaultValue: number | null;
  name: string;
  error?: boolean;
}

const createOption = (value: number): OptionGPAOutOf => ({
  value: value,
  label: String(value),
});

const GPAOutOfPicker: React.FC<Props> = ({ defaultValue, control, name, required, error = false }) => {
  const options = VALUES.map(createOption);

  return (
    <Selector
      options={options}
      defaultValue={defaultValue ? createOption(defaultValue) : null}
      name={name}
      control={control}
      placeholder="Out of"
      required={required}
      error={error}
    />
  );
};

export { DEFAULT_GPA_OUT_OF };

export default GPAOutOfPicker;
