import React, { useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiControlled from '../SelectorMultiControlled';
import { OptionId } from '../Option';

import { Spinner } from 'components/index';

import { useGetProgrammingLanguageSelectionsQuery } from 'generated/graphql';

export interface TechnicalLanguageSelectorProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue?: OptionId[];
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
}

const TechnicalLanguageSelector: React.FC<TechnicalLanguageSelectorProps> = ({
  name,
  clearable = false,
  required = false,
  defaultValue = [],
  placeholder = undefined,
  error = false,
  control,
  setValue,
  register,
}) => {
  const { loading, data } = useGetProgrammingLanguageSelectionsQuery();

  const roleInterestOptions = useMemo(() => {
    return (data?.grad_computer_language_selection || []).map((fi) => ({
      label: fi.computer_language,
      value: fi.computer_language,
      id: fi.computer_language,
    }));
  }, [data]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <SelectorMultiControlled
          name={name}
          options={roleInterestOptions}
          control={control}
          defaultValue={defaultValue}
          isLoading={loading}
          placeholder={placeholder}
          isClearable={clearable}
          required={required}
          setValue={setValue}
          register={register}
          error={error}
        />
      )}
    </>
  );
};

export default TechnicalLanguageSelector;
