import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import SpinnerPage from 'components/SpinnerPage';
import ProfessionalYou, { PersonalYouFormVariables } from './ProfessionalYouStep';
import { createOptionId, OptionId } from 'components/Option';
import { notify } from 'utils/notify';

import {
  useGetPersonalYouStepProfileQuery,
  GetProfileStepProfileAwardPartsFragment,
  useUpdateRelatedPersonalYouStepProfileMutation,
  useUpdatePersonalYouStepProfileMutation,
} from 'generated/graphql';

import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO } from '../components';
import { AwardsTypes } from 'data/awards';

const DEFAULT_VALUES = {
  awards: [],
  years_of_relevant_work: null,
  fun_facts: [],
  employer_preferences: [],
  interest_roles: [],
  interest_job_types: [],
};

const ProfessionalYouContainer: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: getProfileLoading, data: getProfileData } = useGetPersonalYouStepProfileQuery({
    fetchPolicy: 'network-only',
    variables: { myUserId: user.sub },
  });

  const [updateGradProfile] = useUpdatePersonalYouStepProfileMutation();
  const [
    updateRelatedProfile,
    { loading: updateRelatedProfileLoading },
  ] = useUpdateRelatedPersonalYouStepProfileMutation();

  if (getProfileLoading || updateRelatedProfileLoading) return <SpinnerPage />;

  const myInfo = getProfileData?.grad_profile?.[0] || DEFAULT_VALUES;

  const professionalDesignationsOptions = (myInfo.awards.length ? myInfo.awards : [])
    .filter(
      (award: GetProfileStepProfileAwardPartsFragment) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION,
    )
    .map((award: GetProfileStepProfileAwardPartsFragment) => createOptionId(award.award_name, award.award_id));

  const funFactsOptions = (myInfo?.fun_facts.length ? myInfo.fun_facts : []).map((funFact) =>
    createOptionId(funFact.description, funFact.fun_fact_id),
  );

  const employerPreferences = (myInfo?.employer_preferences.length
    ? myInfo.employer_preferences
    : []
  ).map((preference: any) => createOptionId(preference.preference, preference.employer_preference_id));

  const initialInterestRoles = myInfo.interest_roles.map(
    ({ role, interest_role_id }: { role: string; interest_role_id: string }) => ({
      value: role,
      label: role,
      id: interest_role_id,
    }),
  );

  const currentJobType =
    myInfo?.interest_job_types.map(
      ({ job_type, interest_job_type_id }: { job_type: string; interest_job_type_id: string }) => ({
        value: job_type,
        label: job_type,
        id: interest_job_type_id,
      }),
    )[0] || null;

  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async (data: PersonalYouFormVariables) => {
    const {
      years_of_relevant_work,
      professionalDesignations: { optionsValue: designationsValue },
      funFacts: { optionsValue: funFactsOptionsNew },
      employerPreferences: { optionsValue: newEmployerPreferences },
      interestRoles: { current: currentRoles },
      interestJobType,
      careerInFinance,
    } = data;

    const eraseRoles = getEraseOptions(currentRoles, initialInterestRoles)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addRoles = getAddOptions(currentRoles, initialInterestRoles).map(({ value }) => ({
      role: value,
      user_id: user.sub,
    }));

    const eraseFunFacts = getEraseOptions(funFactsOptionsNew, funFactsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addFunFacts = getAddOptions(funFactsOptionsNew, funFactsOptions).map(({ value }) => ({
      description: value,
      user_id: user.sub,
    }));

    const eraseProfessionalDesignations = getEraseOptions(designationsValue, professionalDesignationsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addProfessionalDesignations = getAddOptions(designationsValue, professionalDesignationsOptions).map(
      ({ value }) => ({
        award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
        award_name: value,
        user_id: user.sub,
      }),
    );

    const addEmployerPreferences = getAddOptions(newEmployerPreferences, employerPreferences).map(({ value }) => ({
      preference: value,
      user_id: user.sub,
    }));

    const eraseEmployerPreferences = getEraseOptions(newEmployerPreferences, employerPreferences)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const eraseJobTypes = getEraseOptions(
      interestJobType ? [interestJobType] : [],
      currentJobType ? [currentJobType] : [],
    )
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addJobTypes = getAddOptions(
      interestJobType ? [interestJobType] : [],
      currentJobType ? [currentJobType] : [],
    ).map<{ job_type: string; user_id: string }>(({ value }) => ({
      job_type: value,
      user_id: user.sub,
    }));

    const variables = {
      userId: user.sub,
      years_of_relevant_work: (years_of_relevant_work || {})?.value,
    };

    const updateProfile = async () => {
      await updateRelatedProfile({
        variables: {
          awardsForUpsert: addProfessionalDesignations,
          awardIdsForDelete: eraseProfessionalDesignations,
          funFactsForUpsert: addFunFacts,
          funFactIdsForDelete: eraseFunFacts,
          employerPreferencesForUpsert: addEmployerPreferences,
          employerPreferencesIdsForDelete: eraseEmployerPreferences,
          jobType: addJobTypes,
          jobTypeForDelete: eraseJobTypes,
          interestRolesForUpsert: addRoles,
          interestRolesIdsForDelete: eraseRoles,
        },
      });
      history.push(nextGoTo);
    };
    if (getProfileData) {
      try {
        await updateGradProfile({ variables });
        await updateProfile();
      } catch (error) {
        const queryToastId = 'updateMyProfileErrorEmailRecruiter';
        notify(
          queryToastId,
          'The email address used to sign in is already taken by a recruiter. Please use another to sing in as Student.',
          'top-center',
        );
      }
    }
  };

  return (
    <ProfessionalYou
      onSubmit={onSubmit}
      years_of_relevant_work={myInfo.years_of_relevant_work || null}
      funFactsOptions={funFactsOptions}
      professionalDesignationsOptions={professionalDesignationsOptions}
      employerPreferences={employerPreferences}
      step={step}
      totalSteps={totalSteps}
      interestRoles={initialInterestRoles}
      interestJobType={currentJobType}
      backGoTo={backGoTo || ''}
    />
  );
};

export default ProfessionalYouContainer;
