import styled from 'styled-components';
import { palette } from 'theme';
import { ReactComponent as DefaultResume } from 'theme/assets/DefaultResume.svg';
import { ReactComponent as UploadedResume } from 'theme/assets/ResumeUploaded.svg';

import { EditButtonModal, NewButtonModal } from '../library';

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 0.3rem;
`;

export const Container = styled.div<{ hasFile?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-bottom: ${({ hasFile }) => (hasFile ? '-1.5rem' : '')};
  align-items: ${({ hasFile }) => (hasFile ? 'flex-start' : 'center')};
`;

export const DefaultResumeIcon = styled(DefaultResume)`
  width: 5.6rem;
  height: 5.6rem;
`;

export const UploadedResumeIcon = styled(UploadedResume)`
  width: 7rem;
  height: 7rem;
`;

export const Text = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.beta400gray};
`;

export const WarningText = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.alpha400Red};
`;

export const ResumeName = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  cursor: pointer;
  color: ${palette.gray500};
`;

export const TopEditButton = styled(EditButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const TopAddButton = styled(NewButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  & > * {
    margin-right: 0.5rem;
    margin-bottom: 0.8rem;
  }
`;

export const InfoContainer = styled.div<{ hasFile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 7rem);
  margin-top: ${({ hasFile }) => (hasFile ? '1rem' : '')};
  margin-left: ${({ hasFile }) => (hasFile ? '' : '1rem')};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
