import styled from 'styled-components';
import { palette } from 'theme';
import { NewButtonModal } from '../library';
import { ReactComponent as NoPreviousDegree } from 'theme/assets/NoPreviousDegree.svg';
export const StyledUniversityDetailsList = styled.div`
  margin: 1.6rem 1rem;
`;

export const CardTitle = styled.div`
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  line-height: 1.8rem;
  margin-bottom: 1.2rem;
`;

export const StyledUniversityTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const Icon = styled(NoPreviousDegree)`
  width: 5.6rem;
  height: 5.6rem;
`;

export const Text = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.beta400gray};
  margin-left: 2rem;
`;

export const AddButton = styled(NewButtonModal)`
  position: relative;
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
