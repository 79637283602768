import styled from 'styled-components';
import { PrimaryButton } from 'components/library';
import { palette } from 'theme';

const Logo = styled.img`
  height: 10vh;
  width: 24.2vw;

  @media (min-width: 768px) {
    height: 2.6rem;
    width: 11rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 22.17vh;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 2.2rem 1.9rem 1.2rem 1.9rem;
  line-height: 1.6rem;
  background: ${palette.alpha500Red};

  @media (min-width: 768px) {
    height: 11rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2.2rem 5rem 1.2rem 5rem;
  }
`;

const TopContainer = styled.div<{ hideHeaderButtons?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 768px) {
    justify-content: ${(hideHeaderButtons) => (hideHeaderButtons ? 'center' : 'space-between')};
    align-items: center;
  }
`;

const StepBarContainer = styled.div`
  margin-top: 1rem;
  min-width: 45rem;
  margin-left: 13rem;

  @media (max-width: 984px) {
    min-width: 32rem;
    margin-left: 5rem;
  }
`;

const StepBarLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.1rem;
  color: ${palette.white};
  text-align: left;
  margin-bottom: 0.4rem;
`;

interface StyledStepProps {
  active?: boolean;
}

const Step = styled.div<StyledStepProps>`
  border-radius: 10px;
  width: 100%;
  height: 0.6rem;
  background: ${palette.white};
  opacity: ${(props) => (props.active ? '1' : '0.2')};
`;

const StepBar = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 2.1rem;
  margin: auto;
  > * {
    margin-right: 0.8rem;
  }
  > :last-child {
    margin-right: 0rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex: 0;
  > * {
    margin-right: 3rem;
  }
  > :last-child {
    margin-right: 0rem;
  }

  @media (min-width: 768px) {
    flex: 1;
  }
`;

const CancelButton = styled(PrimaryButton)`
  width: 166px;
  height: 45px;
  border-radius: 100px;
  cursor: pointer;

  @media (max-width: 1366px) {
    width: 100px;
    height: 40px;
  }
`;

const HelpButton = styled(PrimaryButton)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1.2vh;
  position: absolute;
  right: 2rem;
  top: 2.2rem;
  margin-top: 0;

  @media (min-width: 768px) {
    position: unset;
    width: 40px;
    height: 40px;
    margin-top: 0;
  }
`;

const PopperInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${palette.beta600gray};
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 2.1rem;
  text-align: center;
  cursor: pointer;

  > a {
    color: ${palette.alpha500Red};
    text-decoration: none;
  }
`;

export {
  Logo,
  Container,
  StepBarContainer,
  Step,
  StepBarLabel,
  StepBar,
  ButtonContainer,
  CancelButton,
  HelpButton,
  PopperInformation,
  TopContainer,
};
