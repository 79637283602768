import styled from 'styled-components';
import { PrimaryButton } from '../../../components';

export const CardContainer = styled.div`
  flex-grow: 1;

  overflow-y: scroll;
`;
export const LogoutButton = styled(PrimaryButton)`
  display: block;

  width: 70%;
  max-width: 36rem;
  margin: 6rem auto 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
`;

export const LeftRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  height: 100%;

  & > * {
    margin-bottom: 1rem;
  }
`;

export const RightRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  height: 100%;
  padding-bottom: 1rem;

  & > * {
    margin-bottom: 1rem;
  }
`;
