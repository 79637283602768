import React from 'react';
import { useForm } from 'react-hook-form';

import { CancelButton, SaveButton } from 'components';
import {
  StyledCheckboxContainer,
  StyledFormInputs,
  StyledInput,
  StyledInputLabel,
  StyledInputSmallLabel,
  StyledLeftH1,
} from 'components/library';

import {
  StyledFormButtons,
  StyledForm,
} from '../../../components/common/StudentsProfileLayout/StudentsProfileLayout.styled';

import { StyledLabelWrite, StyledSatContainer } from './TestScores.styled';

import { ParsedTests } from 'utils/testUtils';
import { TestOutOf, TestSATDetailsOutof } from 'data/tests';
import { generateErrorMessages } from 'components/FormValidationMessage';

import CheckboxWithController from 'components/CheckboxWithController';

interface TestsScoresFormVariables {
  [name: string]: number;
}

interface TestScoresProps {
  onSubmit: (values: {
    gmatScore?: number;
    greScore?: number;
    lsatScore?: number;
    mcatScore?: number;
    satReadingScore?: number;
    satWritingScore?: number;
    satMathScore?: number;
    actScore?: number;
  }) => void;
  testsData: ParsedTests;
  onCancel: () => void;
}

const TestScores: React.FC<TestScoresProps> = ({ onSubmit, testsData, onCancel }) => {
  const { register, control, handleSubmit, errors, watch } = useForm<Partial<TestsScoresFormVariables>>();

  const actValue = watch('actScore', '');
  const satMathScore = watch('satMathScore', '');
  const satWritingScore = watch('satWritingScore', '');
  const satReadingScore = watch('satReadingScore', '');
  const watchedSatNotApplicable = !!watch('satNotApplicable');
  const watchedActNotApplicable = !!watch('actNotApplicable');
  const satIncomplete = !satMathScore && !satWritingScore && satReadingScore;
  const satNotApplicableDefault = !testsData || !testsData.SAT;
  const actNotApplicableDefault = !testsData || !testsData.ACT;
  const satRequired = watchedSatNotApplicable ? false : !actValue || satIncomplete;
  const actRequired = !satRequired;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledLeftH1>Tests Results</StyledLeftH1>
      <>
        <StyledFormInputs>
          <StyledCheckboxContainer>
            <CheckboxWithController
              name="satNotApplicable"
              control={control}
              defaultChecked={satNotApplicableDefault}
            />
            SAT not applicable
          </StyledCheckboxContainer>
          {!watchedSatNotApplicable && (
            <>
              <StyledInputLabel>SAT</StyledInputLabel>
              <StyledSatContainer>
                <StyledInputSmallLabel>
                  Math
                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.math
                        ? testsData.SAT.test_score_details.math.score
                        : undefined
                    }
                    type="number"
                    name="satMathScore"
                    placeholder="Math"
                    ref={register({
                      required: satRequired ? 'Math is required if no ACT value' : false,
                      max: {
                        value: TestSATDetailsOutof.MATH,
                        message: `The maximum value for this score is ${TestSATDetailsOutof.MATH}`,
                      },
                    })}
                  />
                </StyledInputSmallLabel>

                <StyledInputSmallLabel>
                  Reading
                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.reading
                        ? testsData.SAT.test_score_details.reading.score
                        : undefined
                    }
                    type="number"
                    name="satReadingScore"
                    placeholder="Reading"
                    ref={register({
                      required: satRequired ? 'Reading is required if no ACT value' : false,
                      max: {
                        value: TestSATDetailsOutof.READING,
                        message: `The maximum value for this score is ${TestSATDetailsOutof.READING}`,
                      },
                    })}
                  />
                </StyledInputSmallLabel>

                <StyledInputSmallLabel>
                  Writing
                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.writing
                        ? testsData.SAT.test_score_details.writing.score
                        : undefined
                    }
                    type="number"
                    name="satWritingScore"
                    placeholder="Writing"
                    ref={register({
                      max: {
                        value: TestSATDetailsOutof.WRITING,
                        message: `The maximum value for this score is ${TestSATDetailsOutof.WRITING}`,
                      },
                    })}
                  />
                </StyledInputSmallLabel>
                <StyledLabelWrite>Optional test score</StyledLabelWrite>
              </StyledSatContainer>
              {generateErrorMessages(errors.satWritingScore)}
              {generateErrorMessages(errors.satMathScore)}
              {generateErrorMessages(errors.satReadingScore)}
            </>
          )}
          <StyledCheckboxContainer>
            <CheckboxWithController
              name="actNotApplicable"
              control={control}
              defaultChecked={actNotApplicableDefault}
            />
            ACT not applicable
          </StyledCheckboxContainer>
          {!watchedActNotApplicable && (
            <>
              <StyledInputLabel>ACT</StyledInputLabel>
              <StyledInput
                type="number"
                name="actScore"
                placeholder="Enter your ACT Score score"
                defaultValue={testsData.ACT ? testsData.ACT.score : undefined}
                ref={register({
                  required: actRequired ? 'ACT is required if no SAT value' : false,
                  max: { value: TestOutOf.ACT, message: `The maximum value for this score is ${TestOutOf.ACT}` },
                })}
              />
            </>
          )}
          {generateErrorMessages(errors.actScore)}
          <StyledInputLabel>GMAT</StyledInputLabel>
          <StyledInput
            type="number"
            name="gmatScore"
            placeholder="Enter your GMAT score"
            defaultValue={testsData.GMAT ? testsData.GMAT.score : undefined}
            ref={register({
              max: { value: TestOutOf.GMAT, message: `The maximum value for this score is ${TestOutOf.GMAT}` },
            })}
          />
          {generateErrorMessages(errors.gmatScore)}

          <StyledInputLabel>GRE</StyledInputLabel>
          <StyledInput
            type="number"
            name="greScore"
            placeholder="Enter your GRE score"
            defaultValue={testsData.GRE ? testsData.GRE.score : undefined}
            ref={register({
              max: { value: TestOutOf.GRE, message: `The maximum value for this score is ${TestOutOf.GRE}` },
            })}
          />
          {generateErrorMessages(errors.greScore)}

          <StyledInputLabel>LSAT</StyledInputLabel>
          <StyledInput
            type="number"
            name="lsatScore"
            placeholder="Enter your LSAT score"
            defaultValue={testsData.LSAT ? testsData.LSAT.score : undefined}
            ref={register({
              max: { value: TestOutOf.LSAT, message: `The maximum value for this score is ${TestOutOf.LSAT}` },
            })}
          />
          {generateErrorMessages(errors.lsatScore)}

          <StyledInputLabel>MCAT</StyledInputLabel>
          <StyledInput
            type="number"
            name="mcatScore"
            placeholder="Enter your MCAT score"
            defaultValue={testsData.MCAT ? testsData.MCAT.score : undefined}
            ref={register({
              max: { value: TestOutOf.MCAT, message: `The maximum value for this score is ${TestOutOf.MCAT}` },
            })}
          />
          {generateErrorMessages(errors.mcatScore)}
        </StyledFormInputs>
        <StyledFormButtons>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <SaveButton>Save</SaveButton>
        </StyledFormButtons>
      </>
    </StyledForm>
  );
};

export default TestScores;
