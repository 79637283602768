import React from 'react';

import RelevantWorkExperienceCard from './RelevantWorkExperienceCard';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { useModelContext } from 'views/Student/ModalContext/ModalContext';
import { sortWorkExperiences } from 'utils/dateUtils';
import { CardTitle, EmptyContainer, AddButton, Text, Icon } from './RelevantWorkExperience.styled';
import { WorkExperiencePartsFragment } from 'generated/graphql';

export interface UniversityProps {
  workExperiences: WorkExperiencePartsFragment[];
  fullProfile?: boolean;
}

const UniversityList: React.FC<UniversityProps> = ({ workExperiences, fullProfile = false }) => {
  const { setModalState, setWorkExperienceIndex } = useModelContext();
  return (
    <CollapsibleWidgetCard withTitle fixed={!workExperiences || workExperiences?.length <= 1}>
      <CardTitle>
        Relevant Work Experience/Internship
        {fullProfile && (
          <AddButton
            onClick={() => {
              setWorkExperienceIndex(-1);
              setModalState('workExperienceEdit');
            }}
          />
        )}
      </CardTitle>
      {workExperiences.map((workExperience: WorkExperiencePartsFragment, index) => (
        <RelevantWorkExperienceCard
          workExperience={workExperience}
          key={workExperience.work_experience_id}
          fullProfile={fullProfile}
          handleEdit={() => {
            setWorkExperienceIndex(index);
            setModalState('workExperienceEdit');
          }}
        />
      ))}
      {(!workExperiences || workExperiences.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No previous Work Experience</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default UniversityList;
