import React from 'react';

import { StudentViewPaths } from '../../StudentViewRoutes';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { Tag } from '../library';
import { CardTitle, TopEditButton, TagContainer } from './YearsOfExperience.styled';

export interface YearsOfExperienceProps {
  years: string;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const YearsOfExperience: React.FC<YearsOfExperienceProps> = ({ years, fullProfile = false, handleEdit }) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Relevant Years of Experience {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
      <TagContainer>{years && <Tag>{years}</Tag>}</TagContainer>
    </CollapsibleWidgetCard>
  );
};

export default YearsOfExperience;
