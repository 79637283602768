import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import SpinnerPage from 'components/SpinnerPage';
import AcademicStep, { DegreeFormVariables } from './AcademicStep';

import {
  useGetMyLastDegreeAcademicStepQuery,
  useInsertMyDegreeAcademicStepMutation,
  useInsertFillUniversityMutation,
  useInsertConcentrationsMutation,
  useDeleteConcentrationsMutation,
  useUpdateMyDegreeAcademicStepMutation,
} from 'generated/graphql';

import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO, CANCEL_GO_TO } from '../components';
import { OptionId } from 'components/Option';
import { ConcentrationTypes } from 'data/degree';
import {
  prepareConcentrationForUpsert,
  createConcentrationOptions,
} from 'components/MajorMinorSelector/concentrationUtils';

const AcademicStepController: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  backGoTo,
  cancelSkipGoTo = CANCEL_GO_TO,
  totalSteps,
  step,
  setStandarizedTest,
}) => {
  const nextGoToNoTest = `/app/wizard/step-${step + 2}`;
  const history = useHistory();
  const {
    user: { sub: myUserId },
  } = useAuth0();

  const [insertMyDegree] = useInsertMyDegreeAcademicStepMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateMyDegree] = useUpdateMyDegreeAcademicStepMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertFillUniversity] = useInsertFillUniversityMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { loading: loadingMyDegree, error: errorMyDegree, data: dataMyDegree } = useGetMyLastDegreeAcademicStepQuery({
    fetchPolicy: 'network-only',
    variables: { userId: myUserId },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertConcentrations] = useInsertConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteConcentrations] = useDeleteConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  if (loadingMyDegree) return <SpinnerPage />;
  if (errorMyDegree) return <div>Error</div>;

  const degreeId = dataMyDegree && dataMyDegree.grad_degree[0] && dataMyDegree.grad_degree[0].degree_id;
  const defaultDegreeValues = {
    university: { university_selection_id: '', location_name: '' },
    complete_year: null,
    complete_month_numeric: null,
    degree_level: '',
    gpa: null,
    gpa_outof: null,
    degree: '',
    completed: false,
    concentrations: [],
    gpa_not_applicable: false,
    fill_in_university: { institution_name: '' },
  };
  const degreeDetails = (dataMyDegree && dataMyDegree.grad_degree[0]) || defaultDegreeValues;
  const {
    university: myUniversity = defaultDegreeValues.university,
    complete_year,
    complete_month_numeric,
    gpa,
    gpa_outof,
    completed,
    concentrations,
    gpa_not_applicable = defaultDegreeValues.gpa_not_applicable,
    degree = defaultDegreeValues.degree,
    degree_level = defaultDegreeValues.degree_level,
    fill_in_university = defaultDegreeValues.fill_in_university,
  } = degreeDetails;
  const universityName = myUniversity ? myUniversity.location_name : '';
  const universityId = myUniversity ? myUniversity.university_selection_id : '';
  const fillInUniversity = fill_in_university ? fill_in_university.institution_name : '';

  const majorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MAJOR);

  const onSubmit = async (data: DegreeFormVariables, watchedGPANotApplicable: boolean) => {
    const {
      gradEndMonth,
      gradEndYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA = watchedGPANotApplicable ? null : data.GPA,
      gpaOutOf = watchedGPANotApplicable ? null : { value: data.gpaOutOf },
      majors: { optionsValue: optionsMajorsNew, deletedOptionsIds: deletedMajorsIds },
      degreeSelectionOption,
      gpaNotApplicable,
      standarizedTest,
    } = data;
    const variables = {
      gradEndMonth: gradEndMonth.value,
      gradEndYear: gradEndYear.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degreeSelectionOption?.[0]?.degree || '',
      degreeLevel: degreeSelectionOption?.[0]?.degree_level || '',
      completed: false,
      gpaNotApplicable,
    };

    const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !initial
        ? []
        : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

    const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !selected
        ? []
        : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));
    let insertDegreeResult;
    if (!degreeId) {
      insertDegreeResult = await insertMyDegree({ variables: { myUserId, ...variables } });
    } else {
      await updateMyDegree({ variables: { degreeId, ...variables } });
    }

    const persistedDegreeId =
      insertDegreeResult && insertDegreeResult.data && insertDegreeResult.data.insert_grad_degree
        ? insertDegreeResult.data.insert_grad_degree.returning[0].degree_id
        : degreeId;

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (persistedDegreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId: persistedDegreeId, userId: myUserId };
        await insertFillUniversity({ variables: fillInUniversity });
      }
      if (optionsMajorsNew) {
        const majorsAdd = getAddOptions(optionsMajorsNew, majorOptions);
        const majorsForUpsert = prepareConcentrationForUpsert(
          majorsAdd,
          persistedDegreeId,
          myUserId,
          persistedDegreeId,
          ConcentrationTypes.MAJOR,
        );
        await insertConcentrations({ variables: { concentrations: majorsForUpsert } });
      }
    }
    if (deletedMajorsIds) {
      const majorsToDelete = getEraseOptions(optionsMajorsNew, majorOptions)
        .map(({ id }) => id)
        .filter((id) => id !== undefined) as string[];
      await deleteConcentrations({ variables: { ids: majorsToDelete } });
    }
    setStandarizedTest(standarizedTest === 'yes');
    history.push(standarizedTest === 'yes' ? nextGoTo : nextGoToNoTest);
  };
  return (
    <AcademicStep
      onSubmit={onSubmit}
      majorOptions={majorOptions}
      isLastStep={isLastStep}
      cancelSkipGoTo={cancelSkipGoTo}
      step={step}
      gpa={gpa || null}
      gpa_outof={gpa_outof}
      complete_month_numeric={complete_month_numeric || null}
      complete_year={complete_year}
      degree={degree}
      degree_level={degree_level}
      fillInUniversity={fillInUniversity}
      gpa_not_applicable={gpa_not_applicable}
      universityId={universityId}
      universityName={universityName}
      totalSteps={totalSteps}
      backGoTo={backGoTo || ''}
    />
  );
};

export default AcademicStepController;
