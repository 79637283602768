import React from 'react';
import { StyledFooterContainer, StyledIcon, StyledFooter, StyledNavLink, Text } from './NavBar.styled';
import { StudentViewPaths } from '../../../views/Student';

import { ReactComponent as Jobs } from 'theme/assets/Jobs.svg';
import { ReactComponent as Profile } from 'theme/assets/Profile.svg';
import { ReactComponent as Company } from 'theme/assets/Company.svg';
import { ReactComponent as Events } from 'theme/assets/Events.svg';

const NavBar: React.FC = () => (
  <StyledFooterContainer>
    <StyledFooter>
      <StyledNavLink to={StudentViewPaths.home}>
        <Profile />
        <Text>Profile</Text>
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.jobs}>
        <Jobs />
        <Text>Opportunities</Text>
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.companies}>
        <Company />
        <Text>Company</Text>
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.events}>
        <Events />
        <Text>Events</Text>
      </StyledNavLink>
    </StyledFooter>
  </StyledFooterContainer>
);

export default NavBar;
