import React, { useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import { useGetUSAStatesSelectionsQuery } from 'generated/graphql';
import Selector from '../Selector';
import { OptionId } from '../Option';

interface USAStatePickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultCountryName: string | null;
  defaultCountryId: string | null;
  required?: boolean;
  placeholder?: string;
}

const USAStatePicker = ({
  control,
  name,
  defaultCountryName,
  defaultCountryId,
  required = false,
  placeholder,
}: USAStatePickerProps) => {
  const { loading, data } = useGetUSAStatesSelectionsQuery({
    errorPolicy: 'all',
    // eslint-disable-next-line no-console
    onError: (error) => console.warn(error),
  });

  const options = useMemo(() => {
    if (!data || !data.grad_state_selection) {
      return [];
    }

    const countries = data.grad_state_selection;

    return countries.map((dc) => ({ label: dc.state.trim(), value: dc.state.trim() }));
  }, [data]);

  const defaultOption: OptionId | null =
    defaultCountryName || defaultCountryId ? { label: defaultCountryName || '', value: defaultCountryId || '' } : null;

  return (
    <Selector
      options={options}
      defaultValue={defaultOption}
      name={name}
      control={control}
      placeholder={placeholder}
      required={required}
      loading={loading}
      isClearable={true}
    />
  );
};

export default USAStatePicker;
