import React from 'react';
import styled, { css } from 'styled-components';
import { useAuth0 } from 'react-auth0-spa';
import { palette } from 'theme';

interface WizardButtonsProps {
  onNext?: () => void;
  onPrevious?: () => void;
  navigable?: boolean;
  disabledNext?: boolean;
}

export const WizardFormButtonsMobile: React.FC<WizardButtonsProps> = ({
  onNext,
  onPrevious,
  navigable = true,
  disabledNext = false,
}) => {
  const { logout } = useAuth0();
  return (
    <FormWizardButtons>
      <FlexContainer>
        {navigable ? (
          <>
            <Button disabled={disabledNext} onClick={onNext}>
              Next
            </Button>
            <LogoutButton onClick={onPrevious}>Previous</LogoutButton>
            <CancelButton onClick={logout}>Cancel</CancelButton>
          </>
        ) : (
          <>
            <Button disabled={disabledNext} onClick={onNext}>
              Continue
            </Button>
            <CancelButton onClick={logout}>Cancel</CancelButton>
          </>
        )}
      </FlexContainer>
    </FormWizardButtons>
  );
};

export const FormWizardButtons = styled.div`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 20rem;
  z-index: 2;
  display: flex;
  align-items: center;
  background: ${palette.white};

  @media (min-width: 375px) {
    height: 18rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  margin: 0.8rem 0;
  padding: 1rem 0.8rem;

  @media (min-width: 375px) {
    padding: 1rem 0.8rem;
  }
`;

const BtnStyle = css`
  width: 20rem;
  height: 3rem;
  border-radius: 10rem;
  border: 1px solid ${palette.alpha500Red};

  text-align: center;
  font-family: 'RobotoBold';
  font-size: 1.6rem;
  text-decoration-line: none;
  margin: 0.3rem 0;

  @media (min-width: 375px) {
    height: 3.5rem;
    width: 25rem;
    font-size: 1.6rem;
    margin: 0.4rem 0;
  }
`;

const Button = styled.button<{ disabled?: boolean }>`
  ${BtnStyle};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  color: ${palette.white};
  background: ${palette.alpha500Red};
`;

const LogoutButton = styled.button.attrs({
  type: 'button',
})`
  ${BtnStyle};

  color: ${palette.alpha500Red};
  background: ${palette.white};
`;

const CancelButton = styled.button`
  ${BtnStyle};
  border: none;
  color: ${palette.alpha500Red};
  background: ${palette.white};
`;
