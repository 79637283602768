import styled from 'styled-components';
import { palette } from 'theme';

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${palette.white};
  height: 75%;
  padding: 3rem 3rem 6rem;
  overflow-y: scroll;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

export const FiledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;
