import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import TestStep, { TestsScoresFormVariables } from './TestStep';

import { WizardProps } from '../components/interface';
import { CANCEL_GO_TO, SUBMIT_GO_TO } from '../components';
import { generateTests, parseTests } from 'utils/testUtils';
import {
  useDeleteTestScoreDetailMutation,
  useDeleteTestsScoresMutation,
  useGetTestScoresByUserIdQuery,
  useUpsertTestsScoresMutation,
} from 'generated/graphql';

import { Spinner } from '../../../components';

const TestStepController: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  backGoTo,
  totalSteps,
}) => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading, data: rawTestsData = { grad_test_score: [] } } = useGetTestScoresByUserIdQuery({
    fetchPolicy: 'network-only',
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const testsData = parseTests(rawTestsData);

  const [upsertTestsScoresMutation] = useUpsertTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteTestsDetailMutation] = useDeleteTestScoreDetailMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteTestsScoresMutation] = useDeleteTestsScoresMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });
  const onSubmit = async ({
    satReadingScore,
    satWritingScore,
    satMathScore,
    actScore,
    greScore,
    lsatScore,
    gmatScore,
  }: Partial<TestsScoresFormVariables>) => {
    const { toDelete, toUpsert, toDeleteDetail } = generateTests({
      testsData,
      user_id: user.sub,
      satMathScore: Number(satMathScore),
      satReadingScore: Number(satReadingScore),
      satWritingScore: Number(satWritingScore),
      gmatScore: Number(gmatScore),
      greScore: Number(greScore),
      lsatScore: Number(lsatScore),
      mcatScore: undefined,
      actScore: Number(actScore),
    });

    if (!!toDeleteDetail.length) await deleteTestsDetailMutation({ variables: { ids: toDeleteDetail } });
    if (!!toUpsert.length) await upsertTestsScoresMutation({ variables: { tests: toUpsert } });
    if (!!toDelete.length) await deleteTestsScoresMutation({ variables: { ids: toDelete } });

    history.push(nextGoTo);
  };

  if (loading) return <Spinner />;

  return (
    <TestStep
      onSubmit={onSubmit}
      step={step}
      totalSteps={totalSteps}
      cancelSkipGoTo={cancelSkipGoTo}
      isLastStep={isLastStep}
      testsData={testsData}
      backGoTo={backGoTo || ''}
    />
  );
};

export default TestStepController;
