import React from 'react';
import { WizardInputLabel } from '../../../Wizard/components/library';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import { AwardsTypes } from 'data/awards';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { OptionId, createOptionId } from 'components';
import SelectorMultiCreateV2 from '../../../../components/SelectorMultiCreateV2';
import { PROFESSIONAL_DESIGNATIONS_OPTIONS } from '../../../../data/awards';
import { Container, Requirement } from './ProfessionalDesignationsEdit.styled';
import { useProfessionalDesignationsEditMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface ProfessionalDesignation {
  award_id: string;
  award_name: string;
  award_category?: string | undefined;
}

export interface InterestRoleEditVariables {
  professionalDesignations: { optionsValue: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

interface InterestRolesEditProps {
  professionalDesignations: ProfessionalDesignation[];
  setModalOpen: (state: ModalOpenType) => void;
}

const InterestRolesEditStep: React.FC<InterestRolesEditProps> = ({ professionalDesignations, setModalOpen }) => {
  const { handleSubmit, errors, register, setValue, clearError } = useForm<InterestRoleEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [updateProfessionalDesignations] = useProfessionalDesignationsEditMutation();

  const professionalDesignationsOptions = (professionalDesignations?.length ? professionalDesignations : [])
    .filter((award: ProfessionalDesignation) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION)
    .map((award: ProfessionalDesignation) => createOptionId(award.award_name, award.award_id));

  const onSubmit = async (data: InterestRoleEditVariables) => {
    const { professionalDesignations: { optionsValue: designationsValue = [] } = {} } = data;

    const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !initial
        ? []
        : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

    const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !selected
        ? []
        : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));
    const eraseProfessionalDesignations = getEraseOptions(designationsValue, professionalDesignationsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addProfessionalDesignations = getAddOptions(designationsValue, professionalDesignationsOptions).map(
      ({ value }) => ({
        award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
        award_name: value,
        user_id: user.sub,
      }),
    );

    const variables = {
      userId: user.sub,
      awardsForUpsert: addProfessionalDesignations,
      awardIdsForDelete: eraseProfessionalDesignations,
    };

    await updateProfessionalDesignations({ variables, refetchQueries: ['GetProfileByUserId'] });
    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Professional Designations'}
    >
      <Container>
        <Requirement>The first item you pick will be display next to your name on your profile card.</Requirement>
        <WizardInputLabel>Do you have any professional designations?</WizardInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          dropDownIndicator={true}
          dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
          defaultOptionsValue={professionalDesignationsOptions || []}
          name="professionalDesignations"
          placeholder="Select"
          register={register}
          setValue={setValue}
          clearErrors={() => clearError('professionalDesignations')}
          error={!!errors.professionalDesignations}
          required={false}
        />
      </Container>
    </Modal>
  );
};

export default InterestRolesEditStep;
