import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../library';
import { ReactComponent as DefaultLocation } from 'theme/assets/DefaultLocation.svg';

export const CardTitle = styled.div`
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  line-height: 1.8rem;
  margin-bottom: 1.2rem;
`;

export const TopEditButton = styled(EditButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const Icon = styled(DefaultLocation)`
  width: 5.6rem;
  height: 5.6rem;
`;

export const Text = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.beta400gray};
  margin-left: 2rem;
`;
