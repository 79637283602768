import styled, { css } from 'styled-components';
import { palette } from 'theme';
import { DoneGraduations } from 'theme/assets';
import { SaveButton } from '../../../components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';

export const FinishButton = styled(SaveButton)`
  position: fixed;
  bottom: 1.6rem;
`;

export const StyledDiv = styled.div`
  position: fixed;
  top: 25%;
`;

export const DoneForm = styled.form`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledDone = styled.h1`
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;
  color: ${palette.red};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  @media (min-width: ${MEDIA_SIZE.md}) {
    justify-content: flex-start;
  }
`;

export const Icon = styled.img`
  display: flex;
  width: 10rem;
  height: 10rem;
  margin-top: 1rem;
  position: relative;

  @media (min-width: ${MEDIA_SIZE.md}) {
    position: absolute;
    top: -4rem;
    right: -3rem;
    width: 7.3rem;
    height: 7.3rem;
  }
`;

export const TopContainer = styled.div`
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${MEDIA_SIZE.md}) {
    height: 42%;
    background: url(${DoneGraduations}) center bottom no-repeat;
    background-size: cover;
    position: relative;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;

  @media (min-width: ${MEDIA_SIZE.md}) {
    flex-direction: row;
    height: 58%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
  }
`;

export const DoneContainer = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const MainText = styled.span`
  font-size: 1.4rem;
  font-family: 'RobotoBold';
  line-height: 1.8rem;
  color: ${palette.beta600gray};
  max-width: 28.3rem;
  text-align: center;
  margin-top: 2rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    line-height: 2rem;
    font-size: 1.5rem;
    max-width: 41.3rem;
    margin-top: 0rem;
  }
`;

export const Title = styled.span`
  font-size: 2.3rem;
  font-family: 'RobotoBold';
  line-height: 3rem;
  color: ${palette.beta600gray};
  max-width: 28.3rem;
  margin-top: 1rem;
  text-align: center;

  @media (min-width: ${MEDIA_SIZE.md}) {
    font-size: 2.5rem;
    line-height: 3.6rem;
    max-width: 35.3rem;
    margin-top: 0;
  }
`;

export const SmallText = styled.span`
  font-size: 1.5rem;
  font-family: 'Roboto';
  line-height: 2rem;
  color: ${palette.beta600gray};
  max-width: 28.3rem;
  text-align: center;
  margin: 1rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    font-size: 1.4rem;
    line-height: 2rem;
    max-width: 35.3rem;
    margin: 0;
  }
`;

export const Link = styled.a`
  appearance: none;
  color: ${palette.alpha500Red};
  cursor: pointer;
  text-decoration: underline;
`;

export const ToastDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const ToastTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const ToastTitleContainer = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ToastSubTitle = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
`;

export const TickIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;
