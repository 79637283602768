import React, { useState, useEffect } from 'react';
import EventList from './EventList';
import { SpinnerPage } from '../../../../components';
import { useAuth0 } from '../../../../react-auth0-spa';
import {
  RecruitEventBoolExp,
  useGetEventListActiveQuery,
  useGetRegistrationsEventListActiveByUserQuery,
} from 'generated/graphql';
import EventsListFooter from '../EventsListFooter';
import EventListHeader from '../EventListHeader/EventListHeader';
import EventListHeaderMobile from '../EventListHeader/EventListHeaderMobile';
import { EventFiltersProvider } from './EventList.context';
import { useWindowDimensions, inMobile } from 'utils/useDimensions';
import { PaginationState } from 'components/common/Pagination';
import { Container, MobileCardContainer } from './EventList.styled';
import { generateWhereQuery, WhereQueryInterface } from 'utils/queryUtils';
import { register } from 'serviceWorker';

const EventListController: React.FC = () => {
  const [today] = useState(new Date());
  const [filteringRegistrations, setFilteringRegistrations] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    count: 9,
    limit: !filteringRegistrations ? 9 : 99,
    offset: 0,
  });
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [mobileInfiniteLimit, setMobileInfiniteLimit] = useState<number>(18);
  const [currentFilter, setCurrentFilter] = useState<any>();

  const activeEvent: WhereQueryInterface<RecruitEventBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { end_timestamp: { _gt: today } },
  };
  const activeFilters: WhereQueryInterface<object>[] = [];
  activeFilters.push(activeEvent);
  const dynamicQuery = generateWhereQuery(activeFilters);

  const { loading: loadingEventList, error: errorEventList, data: dataEventLis, refetch } = useGetEventListActiveQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    variables: {
      limit: 9,
      offset: 0,
      dynamicQuery,
    },
    onError: () => undefined,
    onCompleted: (_data) => {
      setPagination({
        ...pagination,
        count: _data?.recruit_event_aggregate?.aggregate?.count || 0,
      });
    },
  });
  const {
    loading: loadingRegistrations,
    error: errorRegistrations,
    data: dataRegistrations,
  } = useGetRegistrationsEventListActiveByUserQuery({
    variables: {
      today,
      userId: user.sub,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    onError: () => undefined,
  });

  const onFilter = (dynamicQuery: any, registrated?: boolean) => {
    refetch({
      dynamicQuery,
      ...pagination,
      limit: !registrated ? 9 : 99,
      offset: !registrated ? pagination.offset : 0,
    });
    setPagination({ ...pagination, offset: 0, limit: !registrated ? 9 : 99 });
    setCurrentFilter(dynamicQuery);
    setFilteringRegistrations(registrated || false);
  };

  const onPageChange = (offset: number) => {
    refetch({ dynamicQuery: currentFilter, ...pagination, offset });
    setPagination({
      ...pagination,
      offset: !filteringRegistrations ? offset : 0,
      limit: !filteringRegistrations ? pagination.limit : 99,
    });
  };

  const fetchMoreData = () => {
    refetch({ limit: mobileInfiniteLimit, offset: 0 });
    setMobileInfiniteLimit(mobileInfiniteLimit + 9);
  };

  const registratedEventsIds = dataRegistrations?.grad_event_registration?.map((regis) => regis.event.event_id) || [];

  useEffect(() => {
    setPagination({
      ...pagination,
      count: !filteringRegistrations
        ? dataEventLis?.recruit_event_aggregate?.aggregate?.count || 0
        : registratedEventsIds.length,
      limit: !filteringRegistrations ? pagination.limit : 99,
    });
  }, [dataEventLis, filteringRegistrations]);

  if (loadingEventList || loadingRegistrations || !dataEventLis) return <SpinnerPage />;
  if (errorEventList || errorRegistrations) return <div>Error</div>;

  return (
    <EventFiltersProvider>
      <Container>
        <MobileCardContainer>
          {!inMobile(width) ? <EventListHeader onFilter={onFilter} /> : <EventListHeaderMobile onFilter={onFilter} />}
          <EventList
            inMobile={inMobile(width)}
            events={dataEventLis || null}
            registratedEvents={registratedEventsIds}
            fetchMore={fetchMoreData}
          />
          {!inMobile(width) && (
            <EventsListFooter
              pagination={pagination}
              onPageChange={onPageChange}
              studentsCount={
                !filteringRegistrations
                  ? dataEventLis?.recruit_event.length || 0
                  : dataRegistrations?.grad_event_registration.length || 0
              }
            />
          )}
        </MobileCardContainer>
      </Container>
    </EventFiltersProvider>
  );
};

export default EventListController;
