import {
  GetProfileByUserIdDocument,
  GetProfileByUserIdQuery,
  useDeleteConcentrationsMutation,
  useDeleteDegreeMutation,
  useInsertConcentrationsMutation,
  useInsertDegreeMutation,
  useUpdateDegreeMutation,
  useInsertAwardsMutation,
  useDeleteAwardsMutation,
  GetDegreeQuery,
  GetDegreeDocument,
} from 'generated/graphql';

export const useInsertDegreeMutationHook = (userId: string) => {
  return useInsertDegreeMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetProfileByUserIdQuery>({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
      });
      if (!myProfileData || !insertResultData || !insertResultData.insert_grad_degree) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: [...oldGradProfile.degrees, ...insertResultData.insert_grad_degree.returning],
      };

      cache.writeQuery({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
  });
};

export const useUpdateDegreeMutationHook = (userId: string) => {
  return useUpdateDegreeMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetProfileByUserIdQuery>({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
      });

      if (!myProfileData || !insertResultData || !insertResultData.update_grad_degree) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const returningDegreesIds = insertResultData.update_grad_degree.returning.map(({ degree_id }) => degree_id);
      const oldDegreesWithoutReturning = oldGradProfile.degrees.filter(
        ({ degree_id }) => !returningDegreesIds.includes(degree_id),
      );
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: [...oldDegreesWithoutReturning, ...insertResultData.update_grad_degree.returning],
      };

      cache.writeQuery({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
  });
};

export const useDeleteDegreeMutationHook = (userId: string, degreeId: string) => {
  return useDeleteDegreeMutation({
    errorPolicy: 'all',
    variables: { degreeId },
    update: (cache, { data: deleteResultData }) => {
      const myProfileData = cache.readQuery<GetProfileByUserIdQuery>({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
      });

      if (!myProfileData || !deleteResultData || !deleteResultData.delete_grad_degree) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const deleteId = deleteResultData.delete_grad_degree.returning[0].degree_id;
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: oldGradProfile.degrees.filter(({ degree_id }) => degree_id !== deleteId),
      };

      cache.writeQuery({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
    onError: () => undefined,
  });
};

export const useInsertConcentrationsMutationHook = (myDegreeId: string) => {
  return useInsertConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      if (!myDegreeId) return;
      const myDegreeCache = cache.readQuery<GetDegreeQuery>({
        query: GetDegreeDocument,
        variables: { myDegreeId },
      });

      if (
        !insertResultData ||
        !insertResultData.insert_grad_concentration ||
        !myDegreeCache ||
        !myDegreeCache.grad_degree[0]
      )
        return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const insertedConcentrations = insertResultData.insert_grad_concentration.returning;
      const newGradDegree = {
        ...oldGradDegree,
        concentrations: [...oldGradDegree.concentrations, ...insertedConcentrations],
      };

      cache.writeQuery({
        query: GetDegreeDocument,
        variables: { myDegreeId },
        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });
};

export const useDeleteConcentrationsMutationHook = (myDegreeId: string) => {
  return useDeleteConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: deleteReturning }) => {
      if (!myDegreeId) return;
      const myDegreeCache = cache.readQuery<GetDegreeQuery>({
        query: GetDegreeDocument,
        variables: { myDegreeId },
      });

      if (!deleteReturning || !deleteReturning.delete_grad_concentration) return;
      if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const deletedIds = deleteReturning.delete_grad_concentration.returning.map(
        ({ concentration_id }) => concentration_id,
      );
      const newConcentrations = oldGradDegree.concentrations.filter(
        ({ concentration_id }) => !deletedIds.includes(concentration_id),
      );
      const newGradDegree = { ...oldGradDegree, concentrations: newConcentrations };

      cache.writeQuery({
        query: GetDegreeDocument,
        variables: { myDegreeId },

        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });
};

export const useInsertAwardsMutationHook = (myDegreeId: string) => {
  return useInsertAwardsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      if (!myDegreeId) return;

      const myDegreeCache = cache.readQuery<GetDegreeQuery>({
        query: GetDegreeDocument,
        variables: { myDegreeId },
      });

      if (!insertResultData || !insertResultData.insert_grad_award) return;
      if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const insertedAwards = insertResultData.insert_grad_award.returning;
      const newGradDegree = { ...oldGradDegree, awards: insertedAwards };

      cache.writeQuery({
        query: GetDegreeDocument,
        variables: { myDegreeId },
        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });
};
export const useDeleteAwardsMutationHook = (myDegreeId: string) => {
  return useDeleteAwardsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: deleteReturning }) => {
      if (!myDegreeId) return;
      const myDegreeCache = cache.readQuery<GetDegreeQuery>({
        query: GetDegreeDocument,
        variables: { myDegreeId },
      });

      if (!deleteReturning || !deleteReturning.delete_grad_award) return;
      if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const deletedIds = deleteReturning?.delete_grad_award.returning?.map(({ award_id }) => award_id);
      const newAwards = oldGradDegree.awards.filter(({ award_id }) => !deletedIds.includes(award_id));
      const newGradDegree = { ...oldGradDegree, awards: newAwards };

      cache.writeQuery({
        query: GetDegreeDocument,
        variables: { myDegreeId },

        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });
};
