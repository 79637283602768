import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from '../../../utils/useDimensions';

export const CheckboxLabel = styled.div`
  box-sizing: border-box;
  margin: 0 0.8rem 0 0;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGray};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;

  padding-top: 3.5rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    flex-direction: column;
    overflow-y: scroll;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 1rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const FloatingJeannie = styled.img`
  position: absolute;
  padding-bottom: 3rem;
  right: 15%;
  bottom: 12%;

  @media (max-width: ${MEDIA_SIZE.lm}) {
    position: absolute;
    bottom: 20%;
  }

  @media (max-width: ${MEDIA_SIZE.md}) {
    width: 100%;
    position: initial;
  }
`;
