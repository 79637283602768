import styled from 'styled-components';
import palette from 'theme/palette';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${MEDIA_SIZE.md}) {
    background-color: ${palette.gray50};
    overflow-y: scroll;
    margin-bottom: 8rem;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${palette.beta300gray};

  padding-left: 3rem;

  & > div {
    margin-right: 1rem;
  }

  @media (max-width: ${MEDIA_SIZE.md}) {
    border-bottom: none;
    justify-content: center;
    padding: 0;
  }
`;
