import styled, { css } from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ReactComponent as Logout } from 'theme/assets/LogoutIcon.svg';
import palette from 'theme/palette';

export const StyledNavLink = styled(RouterNavLink)`
  color: ${palette.beta500gray};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }

  svg {
    color: ${palette.beta500gray};
    fill: ${palette.beta500gray};
    height: 2.5rem;
    width: 2.5rem;
  }

  &.active {
    fill: ${palette.alpha500Red};
    color: ${palette.alpha500Red};
    svg {
      fill: ${palette.alpha500Red};
    }
  }
`;

export const StyledFooterContainer = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${palette.gray300};
  padding: 0 3%;
  background-color: ${palette.white};
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 50rem;
  height: 6rem;

  background-color: ${palette.white};

  text-align: center;
`;

export const LogoutStyled = styled(Logout)`
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  margin-left: 1rem;
`;

export const EliteGradLogo = styled.img`
  cursor: pointer;
`;
