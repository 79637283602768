import React from 'react';
import { Text } from 'theme/typography';
import { ToggleContainer, ButtonBackground, ButtonCircle, ButtonContainer } from './Toggle.styled';

interface ToggleProps {
  active: boolean;
  onChange: (status: boolean) => void;
  label: string;
}

const Toggle: React.FC<ToggleProps> = ({ active, onChange, label }) => {
  return (
    <ToggleContainer>
      <Text variant="m" bold>
        {label}
      </Text>
      <ButtonContainer onClick={() => onChange(active)}>
        <ButtonBackground active={active} />
        <ButtonCircle active={active} />
      </ButtonContainer>
    </ToggleContainer>
  );
};

export default Toggle;
