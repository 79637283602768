import React from 'react';
import TestScores from './TestScores';
import { useDeleteTestScoreDetailMutation, useGetTestScoresByUserIdQuery } from 'generated/graphql';
import { useDeleteTestsScoresMutationHook, useUpsertTestsScoresMutationHook } from './TestScores.hook';
import { generateTests, parseTests } from '../../../utils/testUtils';
import { useAuth0 } from '../../../react-auth0-spa';
import { useHistory } from 'react-router-dom';
import { StudentsProfileLayout } from 'components';

interface TestsScoresFormVariables {
  [name: string]: number;
}

const TestScoresController: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { loading, data: rawTestsData = { grad_test_score: [] } } = useGetTestScoresByUserIdQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });
  const [upsertTestsScoresMutation] = useUpsertTestsScoresMutationHook(user.sub);
  const [deleteTestsScoresMutation] = useDeleteTestsScoresMutationHook(user.sub);
  const [deleteTestsDetailMutation] = useDeleteTestScoreDetailMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });
  const testsData = parseTests(rawTestsData);
  const onCancel = () => history.goBack();
  const onSubmit = async ({
    gmatScore,
    greScore,
    lsatScore,
    mcatScore,
    satReadingScore,
    satWritingScore,
    satMathScore,
    actScore,
  }: Partial<TestsScoresFormVariables>) => {
    const { toDelete, toUpsert, toDeleteDetail } = generateTests({
      testsData,
      user_id: user.sub,
      satMathScore: Number(satMathScore),
      satReadingScore: Number(satReadingScore),
      satWritingScore: Number(satWritingScore),
      gmatScore: Number(gmatScore),
      greScore: Number(greScore),
      lsatScore: Number(lsatScore),
      mcatScore: Number(mcatScore),
      actScore: Number(actScore),
    });

    if (!!toDeleteDetail.length) await deleteTestsDetailMutation({ variables: { ids: toDeleteDetail } });
    if (!!toUpsert.length) await upsertTestsScoresMutation({ variables: { tests: toUpsert } });
    if (!!toDelete.length) await deleteTestsScoresMutation({ variables: { ids: toDelete } });

    history.goBack();
  };

  return (
    <StudentsProfileLayout loading={loading}>
      <TestScores onSubmit={onSubmit} testsData={testsData} onCancel={onCancel} />
    </StudentsProfileLayout>
  );
};

export default TestScoresController;
