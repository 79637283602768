import React, { useEffect } from 'react';
import Select from 'react-select';
import useCompanyDynamicQuery from '../useCompanyDynamicQuery';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';

import {
  Container,
  FilterContainer,
  SelectContainer,
  Icon,
  InputContainer,
  selectStyles,
  ClearFieldsContainer,
} from '../../../JobsList/JobListHeader/JobListHeader.styled';
import { HeadingText, Text } from 'theme/typography';

import { TextInput } from 'components';
import { palette } from 'theme';
import { useCompanyFiltersContext } from '../CompanyList.context';
import { useAuth0 } from 'react-auth0-spa';

const CompanyListHeader: React.FC<{ onFilter: (dynamicQuery: any) => void }> = ({ onFilter }) => {
  const { filters, handleFilterChange, clearFilters, filterActive } = useCompanyFiltersContext();
  const dynamicQuery = useCompanyDynamicQuery(filters);
  const { subIndustries, location } = filters;

  useEffect(() => {
    const timeoutId = setTimeout(() => onFilter(dynamicQuery), 700);
    return () => clearTimeout(timeoutId);
  }, [location]);

  // const subIndustryOptions = data?.grad_interest_firm_selection.map((firm) => {
  //   return { label: firm.value, value: firm.value };
  // });

  return (
    <Container>
      <HeadingText variant="h4">Companies</HeadingText>
      {/* TODO: integrate this field with the company sub industry of the jobs */}
      <FilterContainer>
        {/* <SelectContainer>
          <Select
            options={subIndustryOptions}
            isClearable
            placeholder={'Subindustry'}
            isSearchable={true}
            defaultValue={subIndustry.map((sub) => {
              return { label: sub, value: sub };
            })}
            onChange={(selectedOption) =>
              selectedOption && handleFilterChange({ subIndustry: selectedOption.map((option) => option.value) })
            }
            styles={selectStyles}
            isMulti
          />
        </SelectContainer> */}
        <InputContainer>
          <TextInput
            onChange={(value: string) => handleFilterChange({ location: value })}
            placeholder="Location"
            value={location}
          />
        </InputContainer>
        <ClearFieldsContainer onClick={() => clearFilters()}>
          <Text
            color={!filterActive ? palette.beta600gray : palette.beta700gray}
            variant="m"
            bold
            opacity={!filterActive ? 0.4 : 1}
          >
            Clear filter
          </Text>
        </ClearFieldsContainer>
      </FilterContainer>
    </Container>
  );
};

export default CompanyListHeader;
