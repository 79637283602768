import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory, useParams } from 'react-router-dom';
import { notify } from 'utils/notify';

import { useGetMyWorkQuery, useUpdateMyWorkMutation } from 'generated/graphql';
import { useDeleteMyWorkMutationHook, useInsertMyWorkMutationHook } from './WorkDetail.hooks';

import WorkDetail, { WorkFormVariables } from './WorkDetail';
import { StudentsProfileLayout } from 'components';

const WorkDetailController: React.FC = () => {
  const history = useHistory();
  const { id: myWorkId } = useParams<{ id: string }>();
  const {
    user: { sub: userId },
  } = useAuth0();

  const { loading: loadingGetMyWork, error: errorGetWork, data: dataGetWork } = useGetMyWorkQuery({
    skip: !myWorkId,
    variables: { myWorkId },
  });

  const [insertWorkExperience, { loading: loadingInsert, error: insertError }] = useInsertMyWorkMutationHook(userId);
  const [updateWorkExperience, { loading: loadingUpdate, error: updateError }] = useUpdateMyWorkMutation();
  const [deleteMyWork, { loading: loadingDelete }] = useDeleteMyWorkMutationHook(userId);

  if (updateError || insertError || errorGetWork) notify('noDataToastId', 'Error');

  if (!loadingGetMyWork && myWorkId && (!dataGetWork || !dataGetWork.grad_work_experience.length))
    notify('noDataToastId', 'No data returned');

  const workDetailsData = dataGetWork?.grad_work_experience[0];
  const persistedWorkId = dataGetWork?.grad_work_experience?.[0]?.work_experience_id;

  const onDelete = async () => {
    if (persistedWorkId) await deleteMyWork({ variables: { workId: persistedWorkId } });
    history.goBack();
  };

  const onSubmit = async (data: WorkFormVariables) => {
    const {
      position,
      division,
      startMonth,
      startYear,
      endMonth,
      endYear,
      internship,
      current,
      cityStateOption: [cityStateOption] = [null],
      companyOption: [companyOption] = [null],
    } = data;

    const variables = {
      position,
      division,
      startMonth: startMonth.value,
      startYear: startYear.value,
      endMonth: current ? null : endMonth.value,
      endYear: current ? null : endYear.value,
      jobType: internship ? 'internship' : 'full time',
      current,
      city: cityStateOption ? cityStateOption.city : null,
      state: cityStateOption ? cityStateOption.state : null,
      company: companyOption ? companyOption.value : null,
    };
    if (!persistedWorkId) await insertWorkExperience({ variables: { myUserId: userId, ...variables } });
    else await updateWorkExperience({ variables: { workId: persistedWorkId, ...variables } });
    history.goBack();
  };

  const isLoading = loadingGetMyWork || loadingInsert || loadingUpdate || loadingDelete;

  const onCancel = () => history.goBack();

  return (
    <StudentsProfileLayout loading={isLoading}>
      <WorkDetail
        loading={loadingGetMyWork || loadingInsert || loadingUpdate || loadingDelete}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onCancel={onCancel}
        isNew={!persistedWorkId}
        end_year={workDetailsData?.end_year || null}
        end_month_numeric={workDetailsData?.end_month_numeric || null}
        start_year={workDetailsData?.start_year || null}
        start_month_numeric={workDetailsData?.start_month_numeric || null}
        position={workDetailsData?.position || ''}
        division={workDetailsData?.division || ''}
        current={workDetailsData?.current || false}
        state={workDetailsData?.state || null}
        city={workDetailsData?.city || null}
        job_type={workDetailsData?.job_type || null}
        company={workDetailsData?.company || ''}
      />
    </StudentsProfileLayout>
  );
};

export default WorkDetailController;
