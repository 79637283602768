import styled from 'styled-components';

export const StyledInlineCheckBox = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 1rem;
    margin-bottom: 0;
    line-height: 2rem;
  }

  margin-bottom: 1rem;
`;
