import React from 'react';
import { WizardFormInputs, WizardInput, WizardInputLabel, WizardPage } from '../components';
import { Title } from '../Wizard.styled';
import { Divider, FormContainer, TitleContainer } from './ProfileStep.styled';
import { WizardRequiredInputLabel } from '../components/library';
import { InputLegend } from 'views/Wizard/components';
import {
  CellPhoneInput,
  CityStatePickerOption,
  OptionId,
  RegionsPicker,
  OptionIdStrict,
  YesNoPicker,
} from 'components';
import CityStatePicker from '../../../components/CityStatePicker';
import ModeCommunicationSelector from '../../../components/ModeCommunicationSelector';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';

export interface ProfilePersonalFormVariables {
  firstName: string;
  lastName: string;
  userId: string;
  mobile: string;
  citySelectionOption: CityStatePickerOption[];
  interestRegions: OptionIdStrict[];
  needSponsor: string;
  willRelocate: string;
  commPreference: OptionId;
}

interface ProfileStepProps {
  onSubmit: (values: ProfilePersonalFormVariables) => void;
  firstName: string;
  lastName: string;
  mobile: string;
  hometown: string;
  step: number;
  totalSteps: number;
  isLastStep: boolean;
  backGoTo: string;
  currentRegions: OptionId[];
  commPreference: string;
  willRelocate: boolean;
  needSponsor: boolean;
}

const ProfileStep: React.FC<ProfileStepProps> = ({
  onSubmit,
  firstName,
  lastName,
  mobile,
  hometown,
  totalSteps,
  step,
  backGoTo,
  currentRegions,
  commPreference,
  willRelocate,
  needSponsor,
}) => {
  const { register, control, handleSubmit, errors } = useForm<ProfilePersonalFormVariables>();
  const { width } = useWindowDimensions();
  const history = useHistory();
  return (
    <WizardPage
      onNext={handleSubmit(onSubmit)}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons
      onPrevious={() => history.push(backGoTo)}
    >
      <WizardFormInputs>
        <TitleContainer>
          <Title>Just You</Title>
        </TitleContainer>
        <FormContainer>
          <Divider>
            <WizardRequiredInputLabel error={!!errors.firstName}>What is your first name?</WizardRequiredInputLabel>
            <WizardInput
              type="text"
              name="firstName"
              placeholder="e.g: John"
              ref={register({ required: true })}
              defaultValue={firstName}
              error={!!errors.firstName}
            />
            {errors.firstName && <InputLegend error={!!errors.firstName}>Complete this field to move on</InputLegend>}

            <WizardRequiredInputLabel error={!!errors.lastName}>What is your last name?</WizardRequiredInputLabel>
            <WizardInput
              type="text"
              name="lastName"
              placeholder="e.g: Doe"
              ref={register({ required: true })}
              defaultValue={lastName}
              error={!!errors.lastName}
            />
            {errors.lastName && <InputLegend error={!!errors.lastName}>Complete this field to move on</InputLegend>}

            <WizardRequiredInputLabel error={!!errors.mobile}>What is your full phone number?</WizardRequiredInputLabel>
            <CellPhoneInput control={control} required defaultValue={mobile || null} name="mobile" />
            {errors.mobile && <InputLegend error={!!errors.mobile}>{errors.mobile.message}</InputLegend>}

            <WizardRequiredInputLabel error={!!errors.commPreference}>
              What is your preferred mode of communication?
            </WizardRequiredInputLabel>
            <ModeCommunicationSelector
              name="commPreference"
              defaultValue={commPreference}
              control={control}
              required
              placeholder="Select"
              error={!!errors.commPreference}
            />
          </Divider>
          <Divider>
            <YesNoPicker
              name="needSponsor"
              required
              title="Will you require sponsorship for a work visa
              to work lawfully in the United States either
              now or once your student visa has expired?"
              register={register}
              defaultValue={needSponsor ? 'yes' : 'no'}
              error={!!errors.needSponsor}
            />
            {errors.needSponsor && <InputLegend error>Complete this field to move on</InputLegend>}

            <WizardInputLabel>What is your hometown?</WizardInputLabel>
            <CityStatePicker
              defaultValue={hometown}
              name="citySelectionOption"
              control={control}
              clearable
              required={false}
            />

            <WizardRequiredInputLabel error={!!errors.interestRegions}>
              What is your desired location
            </WizardRequiredInputLabel>
            <RegionsPicker
              error={!!errors.interestRegions}
              required
              name="interestRegions"
              control={control}
              defaultValue={currentRegions}
            />
            {errors.interestRegions && (
              <InputLegend error={!!errors.interestRegions}>Complete this field to move on</InputLegend>
            )}

            <YesNoPicker
              name="willRelocate"
              required
              title="Are you willing to relocate for the right role?"
              register={register}
              defaultValue={willRelocate ? 'yes' : 'no'}
              error={!!errors.willRelocate}
            />
            {errors.willRelocate && <InputLegend error>Complete this field to move on</InputLegend>}
          </Divider>
        </FormContainer>
      </WizardFormInputs>
    </WizardPage>
  );
};

export default ProfileStep;
