import React from 'react';
import {
  DisclaimerContainer,
  StyledButtonLight,
  StyledContent,
  StyledCurrentResume,
  StyledFileInput,
  StyledHorizontalSeparator,
  StyledSaveButton,
  StyledTitle,
} from './ResumeUploader.styled';
import { ButtonLight, TextInput } from '../../../../components';
import { ButtonsProps } from '../../../Wizard/ResumeStep/ResumeStepController';

interface ResumeUploaderProps {
  isSubComponent: boolean;
  disabled: boolean;
  filename: string;
  nameNewFile: string | null;
  handleOnClickDownload: () => void;
  onSkip: () => void;
  onSubmit: () => void;
  setNewFile: (value: File | null) => void;
  handleOnClickDelete: () => void;
  WizardButtons: React.FC<ButtonsProps>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  disabledNewFile: boolean;
}

const ResumeUploader: React.FC<ResumeUploaderProps> = ({
  isSubComponent,
  filename,
  handleOnClickDownload,
  handleOnClickDelete,
  WizardButtons,
  onSkip,
  disabled,
  onSubmit,
  setNewFile,
  fileInputRef,
  disabledNewFile,
  nameNewFile,
}) => {
  return (
    <StyledContent>
      <StyledTitle>Current Resume{isSubComponent && `: ${filename}`}</StyledTitle>
      {!isSubComponent && (
        <>
          <StyledCurrentResume>
            <TextInput value={filename} disabled />
            <StyledButtonLight text="Download" disabled={disabled} onClick={handleOnClickDownload} />
            <ButtonLight text="Delete" disabled={disabled} onClick={handleOnClickDelete} />
          </StyledCurrentResume>
          <StyledHorizontalSeparator />
        </>
      )}
      <StyledTitle>Upload Resume</StyledTitle>
      <StyledFileInput inputRef={fileInputRef} fileName={nameNewFile} onChange={setNewFile} />
      <DisclaimerContainer>
        We recommend uploading a CV. Candidates without a CV are not shown to recruiters.
      </DisclaimerContainer>
      {!isSubComponent ? (
        <StyledSaveButton onClick={onSubmit} disabled={disabledNewFile}>
          Upload
        </StyledSaveButton>
      ) : (
        <WizardButtons onNext={onSubmit} onSkip={onSkip} />
      )}
    </StyledContent>
  );
};

export default ResumeUploader;
