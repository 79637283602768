import React, { useState } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from 'components';
import SpinnerPage from 'components/SpinnerPage';
import {
  SignUpStep,
  ProfessionalYouStep,
  ProfileStep,
  AcademicStep,
  PictureStep,
  TestStep,
  DoneStep,
  ExtraStep,
  ResumeStep,
  FirmsOfInterestStep,
  EmailStep,
} from '.';

export interface StudentRoutesProps {
  match?: { path: string };
}

const WizardRoutes: React.FC<StudentRoutesProps> = ({ match }) => {
  const [standarizedTest, setStandarizedTest] = useState(true);

  if (!match) return <SpinnerPage />;

  const wizardSequence = [
    SignUpStep,
    FirmsOfInterestStep,
    ProfessionalYouStep,
    AcademicStep,
    TestStep,
    ProfileStep,
    ExtraStep,
    PictureStep,
    ResumeStep,
    EmailStep,
    DoneStep,
  ];
  const wizardRoute = `${match.path}/wizard/step-`;
  const profilePath = `${match.path}/home`;

  const wizardPath = wizardSequence.map((Component, index, array) => {
    const isFirstStep = index === 0;
    const totalSteps = array.length - 1; // Do not count DoneStep as a separate step
    const step = index + 1;
    const isLastStep = index === array.length - 1;
    const doneWizard = array.length;

    const previousStep =
      index === 5
        ? !standarizedTest
          ? `${wizardRoute}${index - 1}`
          : `${wizardRoute}${index}`
        : `${wizardRoute}${index}`;
    const currentStep = `${wizardRoute}${index + 1}`;
    const nextStep = `${wizardRoute}${index + 2}`;
    const goToDone = `${wizardRoute}${doneWizard}`;

    const cancelSkipGoTo = isLastStep ? profilePath : nextStep;
    const nextGoTo = isLastStep ? profilePath : nextStep;
    const backGoTo = isFirstStep ? profilePath : previousStep;

    return {
      Component,
      path: currentStep,
      customProps: {
        wizardModeOn: true,
        cancelSkipGoTo,
        nextGoTo,
        isLastStep,
        backGoTo,
        step,
        totalSteps,
        goToDone,
        standarizedTest,
        setStandarizedTest,
      },
    };
  });

  return (
    <Switch>
      {wizardPath.map(({ Component, customProps, path }, index) => {
        return index == 0 ? (
          <Route exact key={Component.name} path={path} render={(props) => <Component {...props} {...customProps} />} />
        ) : (
          <PrivateRoute
            key={Component.name}
            path={path}
            component={(props) => <Component {...props} {...customProps} />}
          />
        );
      })}
      <Redirect to={`${wizardRoute}2`} />
    </Switch>
  );
};

export default WizardRoutes;
