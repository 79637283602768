import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/useDimensions';
import { palette } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: calc(100% - 2.6rem);
  }
`;

export const MobileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: ${MEDIA_SIZE.md}) {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
    background-color: ${palette.white};
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 19rem);
  grid-gap: 1rem;
  padding: 2.5rem 3rem;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 1rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    display: flex;
    flex-direction: column;
  }
`;
