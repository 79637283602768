import React, { useState } from 'react';
import UserType from 'types/User';
import { UserDefault as DefaultAvatar, ExitDoor } from 'theme/assets';
import { useAuth0 } from 'react-auth0-spa';
import { usePopper } from 'utils/usePopper';
import { HeaderPopper } from './Popper';
import { ProfilePartFragment } from 'generated/graphql';
import { PopperInformation } from './Header.styled';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

import {
  CardInfoContainer,
  TopEditButton,
  Info,
  NameAndTitle,
  UserImage,
  AuthorizedToWorkTag,
  TypeOfJobTag,
  TagsContainer,
  TopButtonsContainer,
  BottomArrowButton,
  Icon,
} from './Header.styled';
import { Badge } from 'components/Badge';

export interface HeaderProps {
  studentProfile: ProfilePartFragment;
  user: UserType;
  awardToShow?: string;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const Header: React.FC<HeaderProps> = ({ studentProfile, awardToShow, fullProfile = false, handleEdit }) => {
  const picture = studentProfile?.profile_image || DefaultAvatar;
  const needSponsor = studentProfile?.need_sponsor || null;
  const typeOfJob = studentProfile?.interest_job_types?.[0]?.job_type || '';
  const nameAndTitle = `${studentProfile?.fullname}${awardToShow && `, ${awardToShow}`}`;
  const { width } = useWindowDimensions();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const { popperOpen, togglePopper } = usePopper('logout-profile-header');
  const { logout } = useAuth0();
  return (
    <>
      <CardInfoContainer>
        <TopButtonsContainer>
          {width <= mobileBreakpoint ? (
            <div ref={setReferenceElement} onClick={togglePopper}>
              <BottomArrowButton />
            </div>
          ) : (
            <div />
          )}
          <TopEditButton fullProfile={fullProfile} onClick={handleEdit} />
        </TopButtonsContainer>

        <UserImage src={picture} />

        <Info>
          <NameAndTitle>{nameAndTitle}</NameAndTitle>
          <TagsContainer>
            {typeOfJob && <TypeOfJobTag>{typeOfJob}</TypeOfJobTag>}
            {needSponsor && (
              <Badge variant="solid" size="medium" color="white">
                Needs Sponsorship
              </Badge>
            )}
          </TagsContainer>
        </Info>
      </CardInfoContainer>
      <HeaderPopper show={popperOpen} referenceElement={referenceElement}>
        <PopperInformation onClick={logout}>
          <Icon src={ExitDoor} />
          Log Out
        </PopperInformation>
      </HeaderPopper>
    </>
  );
};

export default Header;
