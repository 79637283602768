/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext } from 'react';
import ModalOpenType from 'types/ModalOpen';

const ModelContext = createContext({
  modalState: '',
  degreeIndex: -1,
  workExperienceIndex: -1,
  setModalState: (arg: ModalOpenType) => {},
  setDegreeIndex: (arg: Number) => {},
  setWorkExperienceIndex: (arg: Number) => {},
});

function ModelContextProvider(props: any) {
  const [modalState, setModalState] = useState<ModalOpenType>('');
  const [degreeIndex, setDegreeIndex] = useState<Number>();
  const [workExperienceIndex, setWorkExperienceIndex] = useState<Number>();

  return (
    <ModelContext.Provider
      value={{
        modalState,
        degreeIndex,
        workExperienceIndex,
        setModalState,
        setDegreeIndex,
        setWorkExperienceIndex,
      }}
      {...props}
    />
  );
}

const useModelContext = () => useContext(ModelContext);

export { ModelContextProvider, useModelContext };
