import React from 'react';
import { palette } from 'theme';
import Select, { mergeStyles } from 'react-select';
import { Controller, FormContextValues } from 'react-hook-form';
import { selectorStyle } from 'theme';
import { Option } from './Option';

export interface Selector extends Pick<FormContextValues, 'control'> {
  options: Option[];
  defaultValue: Option | null;
  name: string;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isSearchable?: boolean;
  error?: boolean;
  narrow?: boolean;
}

const Selector: React.FC<Selector> = ({
  options,
  defaultValue,
  name,
  control,
  required = true,
  isClearable = false,
  disabled = false,
  placeholder = 'Select...',
  loading = false,
  isSearchable = true,
  error = false,
  narrow = false,
}) => {
  const overrideStyles = {
    control: (base: object) => ({
      ...base,
      height: '4.1rem',
      minHeight: '4.1rem',
      border: ` 1px solid ${error ? palette.alpha500Red : palette.gray200}`,
    }),
    container: (base: any) => ({
      ...base,
      marginBottom: error ? '0.8rem' : '1.6rem',
      maxWidth: narrow ? '15.4rem' : '32rem',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray900,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray400,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : 'hsl(0,0%,20%)',
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

  const select = (
    <Select
      options={options}
      isClearable={isClearable}
      isMulti={false}
      maxMenuHeight={180}
      styles={selectorOwnStyles}
      placeholder={placeholder}
      isDisabled={disabled}
      isLoading={loading}
      isSearchable={isSearchable}
      error={error}
    />
  );

  return (
    <Controller
      as={select}
      defaultValue={defaultValue}
      name={name}
      control={control}
      onChange={([selected]) => {
        return { value: selected };
      }}
      rules={{
        validate: (data) => {
          if (required) {
            return !!data?.value;
          }
          return true;
        },
      }}
    />
  );
};

export default Selector;
