import React from 'react';
import GoBackHeader from '../GoBackHeader';
import { Spinner } from '../../index';
import { Container } from './StudentsProfileLayout.styled';

interface StudentsProfileLayoutProps {
  loading: boolean;
  hasError?: boolean;
}

const StudentsProfileLayout: React.FC<StudentsProfileLayoutProps> = ({ loading, children, hasError }) => {
  if (loading) return <Spinner />;
  if (hasError) return <div>Error</div>;
  return (
    <Container>
      <GoBackHeader />
      {children}
    </Container>
  );
};

export default StudentsProfileLayout;
