import React, { useState } from 'react';
import styled from 'styled-components';
import { Controller, FormContextValues } from 'react-hook-form';
import PhoneInput, { isPossiblePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { InputLegend } from 'views/Wizard/components';
import { StyledInput } from 'components/library';
import { palette } from '../theme';

const DEFAULT_COUNTRY = 'US';

const StyledCellPhoneInput = styled.div`
  margin-bottom: 1.4rem;
  width: 100%;
  max-width: 32rem;
`;

const StyledStyledInput = styled(StyledInput)`
  margin-bottom: unset;
`;

const PhoneCountry = styled.div<{ countryCode: string }>`
  div > .PhoneInputCountry {
    padding-right: 0.5rem;
    color: ${palette.gray};
    &:after {
      padding-left: 0.5rem;
      content: '${({ countryCode }) => countryCode}';
    }
  }
`;

export const DisclaimerContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  color: ${palette.red};
`;

interface Props extends Pick<FormContextValues, 'control'> {
  required?: boolean;
  defaultValue: string | null;
  name: string;
}

const CellPhoneInput: React.FC<Props> = ({ required = false, defaultValue, name, control }) => {
  const [countrySelected, setCountrySelected] = useState('US');
  return (
    <StyledCellPhoneInput>
      <PhoneCountry countryCode={`+${getCountryCallingCode(countrySelected)}`}>
        <Controller
          as={PhoneInput}
          name={name}
          control={control}
          defaultValue={defaultValue || ''}
          rules={{
            required,
            validate: {
              phoneNumber: (value) => !value || isPossiblePhoneNumber(value) || 'Phone number must be valid.',
              required: (value) => (required ? !!value : true),
            },
          }}
          onCountryChange={setCountrySelected}
          international={false}
          initialValueFormat="national"
          defaultCountry={DEFAULT_COUNTRY}
          placeholder="Enter phone number"
          inputComponent={StyledStyledInput}
        />
        {countrySelected !== DEFAULT_COUNTRY && (
          <InputLegend error>Are you sure you meant to select an international number?</InputLegend>
        )}
      </PhoneCountry>
    </StyledCellPhoneInput>
  );
};

export default CellPhoneInput;
