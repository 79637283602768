/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { WizardPage } from '../components';
import { Spacing } from 'components/Spacing';
import { Container, FormContainer, MainText, Title, SmallText, Icon } from './ProfileUnderReview.styled';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useHistory } from 'react-router-dom';
import { UnderReview } from 'theme/assets';

interface ProfileUnderReviewProps {
  step: number;
  totalSteps: number;
  backGoTo: string;
  nextGoTo: string;
}

const ProfileUnderReview: React.FC<ProfileUnderReviewProps> = ({ step, totalSteps, backGoTo }) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  return (
    <WizardPage
      onNext={() => {}}
      showBackground={false}
      step={step}
      totalSteps={totalSteps}
      onPrevious={() => history.push(backGoTo)}
      showButtons={false}
      hideProgress
      cardHeight={width <= mobileBreakpoint ? '100%' : ''}
    >
      <FormContainer>
        <Container>
          {width <= mobileBreakpoint && <Icon src={UnderReview} />}
          <Title>{'Hi there!'}</Title>
          <Spacing margin="1.5rem" />
          <MainText>{"Your profile is still being reviewed, we’ll send you an email when it's ready"}</MainText>
          <Spacing margin="1.5rem" />
          <SmallText>{'If you need help in the meantime, email us at: help@elitegrad.com'}</SmallText>
          <Spacing margin="1rem" />
        </Container>
      </FormContainer>
    </WizardPage>
  );
};

export default ProfileUnderReview;
