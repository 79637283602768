import React from 'react';

import { StyledCard } from 'components';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Container, Row, Title, Elements } from './StudentInformation.styled';
import ExpandibleText from '../../expansibleText';
import { EmployerPreferences, FunFacts, Languages, Achievements, Activities } from '../StudentInformation';

export interface StudentInformationProps {
  employersPreferences: EmployerPreferences[];
  email: string;
  phone: string;
  communicationMode: string;
  funFacts: FunFacts[];
  languages: Languages[];
  studentAthlete: boolean;
  achievements: Achievements[];
  extracurricularActivities: Activities[];
}

const StudentInformationListCard: React.FC<StudentInformationProps> = ({
  employersPreferences,
  phone,
  communicationMode,
  funFacts,
  languages,
  studentAthlete,
  achievements,
  email,
  extracurricularActivities,
}) => {
  return (
    <StyledCard>
      <Container>
        {email && (
          <Row>
            <Title>Email:</Title>
            <Elements>{email}</Elements>
          </Row>
        )}
        {employersPreferences && (
          <Row>
            <Title>In an employer I look for:</Title>
            {employersPreferences?.map((preference, index) => (
              <Elements key={preference.employer_preference_id}>
                {index !== employersPreferences.length - 1 ? `${preference?.preference}, ` : preference?.preference}
              </Elements>
            ))}
          </Row>
        )}
        {communicationMode && (
          <Row>
            <Title>Preferred mode of communication:</Title>
            <Elements>{communicationMode}</Elements>
          </Row>
        )}

        {phone && (
          <Row>
            <Title>Phone:</Title>
            <Elements> {formatPhoneNumberIntl(phone)}</Elements>
          </Row>
        )}
        {funFacts && (
          <Row>
            <Title>What I want an employer to know about me:</Title>
            {funFacts?.map((fact, index) => (
              <Elements key={fact.fun_fact_id}>
                {index !== funFacts.length - 1 ? `${fact?.description}, ` : fact?.description}
              </Elements>
            ))}
          </Row>
        )}
        {languages && (
          <Row>
            <Title>Other Languages:</Title>
            {languages?.map((language, index) => (
              <Elements key={language.spoken_language_id}>
                {index !== languages.length - 1 ? `${language?.language}, ` : language?.language}
              </Elements>
            ))}
          </Row>
        )}
        {studentAthlete && (
          <Row>
            <Title>Student Athlete:</Title>
            <Elements>{studentAthlete ? 'Yes' : 'No'}</Elements>
          </Row>
        )}
        {achievements && (
          <Row>
            <Title>Personal achievements:</Title>
            <ExpandibleText>
              {achievements?.map((achievement, index) => (
                <Elements key={achievement.achievement_id}>
                  {index !== achievements.length - 1 ? `${achievement?.description}, ` : achievement?.description}
                </Elements>
              ))}
            </ExpandibleText>
          </Row>
        )}
        {extracurricularActivities && (
          <Row>
            <Title>Extracurricular activities:</Title>
            <ExpandibleText>
              {extracurricularActivities?.map((activity, index) => (
                <Elements key={activity.volunteer_id}>
                  {index !== extracurricularActivities.length - 1
                    ? `${activity?.description}, `
                    : activity?.description}
                </Elements>
              ))}
            </ExpandibleText>
          </Row>
        )}
      </Container>
    </StyledCard>
  );
};

export default StudentInformationListCard;
