import React from 'react';

import { DisclaimerContainer } from './Disclaimer.styled';

interface DisclaimerProps {
  content: string;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ content }) => {
  return <DisclaimerContainer>{content}</DisclaimerContainer>;
};

export default Disclaimer;
