import styled from 'styled-components';
import { palette } from 'theme';

export const Header = styled.div`
  flex-shrink: 0;

  height: 3rem;
  padding: 1rem 2rem;

  background: ${palette.red};

  line-height: 1.6rem;
`;

export const HeaderTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${palette.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;
  background-color: ${palette.beta50gray};
  padding-bottom: 2rem;
`;

export const DesktopContainer = styled.div`
  display: flex;
  background-color: ${palette.beta50gray};
  width: 100%;
  height: calc(100% - 6.1rem);
  padding-top: 1.5rem;
`;

export const LeftContainer = styled.div`
  display: flex;
  margin: 0 4rem;
  height: 100%;
  width: 30%;
  margin-bottom: 1rem;
  justify-content: center;
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 70%;
  margin-right: 4rem;

  border: 1px solid ${palette.beta300gray};
  box-sizing: border-box;
  background-color: ${palette.white};

  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
`;

export const RightContainerWithoutCard = styled.div`
  display: flex;
  height: 100%;
  width: 70%;
`;

export const MobileWrapper = styled.div`
  height: 100%;
  background: ${palette.beta100gray};
`;
