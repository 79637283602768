import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import SpinnerPage from 'components/SpinnerPage';
import { toast } from 'react-toastify';
import ValidateStep from './ValidateStep';
import { notify } from 'utils/notify';
import { ToastDiv, ToastTitleContainer, TickIcon, ToastTitle, ToastSubTitle } from './ValidateStep.styled';
import { useInsertQueueMutation } from 'generated/graphql';
import { SuccessIconTick } from 'theme/assets';
import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO } from '../components';

const ValidateStepContainer: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  const history = useHistory();
  const { user } = useAuth0();

  const [sendEmail] = useInsertQueueMutation();

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} /> <ToastTitle>Email sent successfully</ToastTitle>
      </ToastTitleContainer>
      <ToastSubTitle>Please check your inbox</ToastSubTitle>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>Error sending the email</ToastTitle>
      </ToastTitleContainer>
      <ToastSubTitle>Please contact our support team</ToastSubTitle>
    </ToastDiv>
  );
  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  const onSubmit = async () => {
    try {
      await sendEmail({
        variables: {
          queue_name: 'send-email-verification',
          content: {
            user_id: user.sub,
          },
        },
      })
        .then(() => {
          notifySuccess();
        })
        .catch(() => {
          notifySuccess();
        });
    } catch (error) {
      notify('Something went wrong. Please try again or contact support team for assistance', 'top-center');
    }
  };

  return (
    <ValidateStep
      onSubmit={onSubmit}
      step={step}
      totalSteps={totalSteps}
      backGoTo={backGoTo || ''}
      nextGoTo={nextGoTo || ''}
    />
  );
};

export default ValidateStepContainer;
