import React, { useState, useEffect } from 'react';
import { palette } from 'theme';
import { FormContextValues } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { mergeStyles, OptionTypeBase, StylesConfig } from 'react-select';
import { InputLegend } from 'views/Wizard/components';
import { OptionId, createOptionId } from './Option';
import { selectorStyle } from 'theme';

export interface SelectorMultiCreateProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  defaultOptionsValue: OptionId[];
  dropDownOptions?: OptionId[];
  name: string;
  loading?: boolean;
  placeholder?: string;
  required?: boolean;
  showMenu?: boolean;
  dropDownIndicator?: boolean;
  requiredMessage?: string;
  maxSelection?: number;
  customStyle?: StylesConfig<OptionTypeBase, true>;
  error?: boolean;
  label?: boolean;
  clearErrors?: () => void;
}

const SelectorMultiCreateV2: React.FC<SelectorMultiCreateProps> = ({
  defaultOptionsValue,
  dropDownOptions,
  name,
  loading = false,
  placeholder = 'Select...',
  register,
  setValue,
  showMenu = true,
  dropDownIndicator = true,
  required = false,
  requiredMessage = 'Select at least one option',
  maxSelection = Infinity,
  customStyle = {},
  error = false,
  label = false,
  clearErrors = null,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [optionsValue, setOptionsValue] = useState(defaultOptionsValue);
  const [deletedOptions, setDeletedOptions] = useState<OptionId[]>([]);

  useEffect(() => {
    clearErrors && clearErrors();
    register(name, {
      ...(required && {
        validate: {
          required: () => optionsValue?.length > 0,
        },
      }),
    });
  }, [name, register, required, optionsValue]);

  useEffect(() => {
    const newOptions = optionsValue?.filter(({ id }: OptionId): boolean => id === undefined);
    const deletedOptionsIds = deletedOptions?.map(({ id }) => id);

    setValue(name, { optionsValue, newOptions, deletedOptions, deletedOptionsIds });
  }, [optionsValue, deletedOptions, name, setValue, requiredMessage, required]);

  const newOptionFlow = () => {
    if (optionsValue.length <= maxSelection && inputValue && inputValue.length) {
      setInputValue('');
      const exists = optionsValue.some(({ label }) => label === inputValue);
      const newOptionsValue = [...optionsValue, ...(exists ? [] : [createOptionId(inputValue)])];
      setOptionsValue(newOptionsValue);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any, { action, removedValue }: any) => {
    if (action === 'remove-value' && removedValue && removedValue.id) {
      setDeletedOptions([...deletedOptions, removedValue]);
    }
    const newOptionsValue = value ? value : [];
    setOptionsValue(newOptionsValue);
  };

  const handleInputChange = (typedValue: string) => setInputValue(typedValue);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        newOptionFlow();
        event.preventDefault();
    }
  };

  const selectorConfig = {
    isClearable: false,
    isMulti: true,
    isSearchable: true,
    ...(!showMenu && { menuIsOpen: false }),
    ...(dropDownOptions && { options: dropDownOptions }),
    ...((!dropDownIndicator || optionsValue?.length >= maxSelection) && { components: { DropdownIndicator: null } }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, customStyle);
  const selectorLimitStyle = mergeStyles(selectorOwnStyles, {
    input: (base: object) => ({
      ...base,
      display: optionsValue && optionsValue?.length < maxSelection ? 'block' : 'none',
    }),
    control: (base: any) => ({
      ...base,
      border: ` 1px solid ${error ? palette.alpha500Red : palette.gray200}`,
    }),
    container: (base: any) => ({
      ...base,
      marginBottom: error || label ? '0.8rem' : '1.6rem',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray900,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray400,
    }),
  });
  return (
    <>
      <CreatableSelect
        value={optionsValue}
        inputValue={inputValue}
        loading={loading}
        placeholder={placeholder}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        {...selectorConfig}
        styles={selectorLimitStyle}
      />
      {(label || error) && (
        <InputLegend error={error}>{error ? 'Complete this field to move on' : 'Enter your top 3 options'}</InputLegend>
      )}
    </>
  );
};

export default SelectorMultiCreateV2;
