import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0 3rem;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2rem;
  height: 100%;
  flex: 1;
`;

export const ToastDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const ToastTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const ToastTitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const TickIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;
