import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import { useForm, FormContext } from 'react-hook-form';
import { OptionId, createOptionId } from 'components/Option';
import { notify } from 'utils/notify';
import { WizardPage, SUBMIT_GO_TO } from '../components';
import ExtraStep, { ExtraStepFormProps } from './ExtraStep';
import { WizardProps } from '../components/interface';
import Spinner from 'components/Spinner';

import { useGetExtraStepInfoQuery, useUpsertExtraStepInfoMutation } from 'generated/graphql';

import { defaultExtraOptions, dataToExtraOptions, getAddOptions, getEraseOptions } from './ExtraStep.utils';

const ExtraStepController: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  const history = useHistory();
  const {
    user: { sub: userID },
  } = useAuth0();

  const { loading, error, data } = useGetExtraStepInfoQuery({ variables: { userID }, fetchPolicy: 'network-only' });
  const [updateAditionalInfo] = useUpsertExtraStepInfoMutation();
  const [options, setOptions] = useState(defaultExtraOptions);

  const formMethods = useForm<ExtraStepFormProps>();

  useEffect(() => {
    const newOptions = dataToExtraOptions(data);
    setOptions(newOptions);
  }, [data]);

  const goToNextPage = () => history.push(nextGoTo);

  const goToPreviousPage = () => (backGoTo ? history.push(backGoTo) : history.goBack());

  const onSubmit = (formData: any) => {
    const { studentAthlete: addStudentAthlete } = formData;
    const studentAthlete = addStudentAthlete === 'yes';

    const addSpokenLanguages = getAddOptions(formData.languages?.current, options.languages).map(({ value }) => ({
      language: value,
      user_id: userID,
    }));
    const deleteSpokenLanguagesIDs = getEraseOptions(formData.languages?.current, options.languages)
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const addComputerLanguages = getAddOptions(formData.computerLanguages?.current, options.computerLanguages).map(
      ({ value }) => ({
        language: value,
        user_id: userID,
      }),
    );
    const deleteComputerLanguagesIDs = getEraseOptions(formData.computerLanguages?.current, options.computerLanguages)
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const newAchivements = [createOptionId(formData.personalAchievements || '')];
    const addAchievements = getAddOptions(newAchivements, [options.achievements]).map(({ value }) => ({
      description: value,
      user_id: userID,
    }));
    const deleteAchievements = getEraseOptions(newAchivements, [options.achievements])
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const newVolunteersActivities = [createOptionId(formData.volunteerActivities || '')];
    const addVolunteers = getAddOptions(newVolunteersActivities, [options.volunteers]).map(({ value }) => ({
      description: value,
      user_id: userID,
    }));

    const deleteVolunteers = getEraseOptions(newVolunteersActivities, [options.volunteers])
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    updateAditionalInfo({
      variables: {
        userID,
        studentAthlete,
        addSpokenLanguages,
        deleteSpokenLanguagesIDs,
        addComputerLanguages,
        deleteComputerLanguagesIDs,
        addVolunteers,
        deleteVolunteers,
        addAchievements,
        deleteAchievements,
      },
    });

    goToNextPage();
  };

  if (error) {
    notify('ExtraStepError', 'Something went wrong. Please try again or contact support team for assistance');
  }

  if (loading || !Boolean(data?.grad_profile[0].computer_languages)) return <Spinner />;

  return (
    <FormContext {...formMethods}>
      <WizardPage
        step={step}
        totalSteps={totalSteps}
        showButtons
        navigable
        onNext={formMethods.handleSubmit(onSubmit)}
        onPrevious={() => goToPreviousPage()}
        showBackground
      >
        <ExtraStep
          isStudentAthlete={options.isAthlete}
          achievementsOptions={options.achievements?.value}
          computerLanguagesOptions={options.computerLanguages}
          languagesOptions={options.languages}
          volunteerOptions={options.volunteers?.value}
        />
      </WizardPage>
    </FormContext>
  );
};

export default ExtraStepController;
