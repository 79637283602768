import React from 'react';
import { InputLegend } from 'views/Wizard/components';
import { FormContextValues } from 'react-hook-form';

import Selector from './Selector';

const YEARS_OF_RELEVANT_WORK_OPTIONS = [
  { value: 'None', label: 'None' },
  { value: '1-3', label: '1-3' },
  { value: '3-5', label: '3-5' },
  { value: '5+', label: '5+' },
];

export interface RelevantYearsSelectorProps extends Pick<FormContextValues, 'control'> {
  defaultValue: string | null;
  required?: boolean;
  name: string;
  error?: boolean;
}

const RelevantYearsSelector: React.FC<RelevantYearsSelectorProps> = ({
  defaultValue = null,
  required,
  control,
  name,
  error = false,
}) => {
  return (
    <>
      <Selector
        options={YEARS_OF_RELEVANT_WORK_OPTIONS}
        defaultValue={defaultValue ? { value: defaultValue, label: defaultValue } : null}
        name={name}
        control={control}
        required={required}
        placeholder="Select"
        error={error}
      />
      {error && <InputLegend error>Complete this field to move on</InputLegend>}
    </>
  );
};

export default RelevantYearsSelector;
