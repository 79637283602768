import React, { useState } from 'react';
import Tab, { TabStyles } from 'components/common/Tab';

import { Container, TabsContainer } from './TabsSection.styled';

interface TabProps {
  label: string;
  content: React.ReactNode;
  number?: number;
}

interface TabsSectionProps {
  tabs: TabProps[];
  tabsStyle?: TabStyles;
}

const TabsSection: React.FC<TabsSectionProps> = ({ tabs, tabsStyle }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            variant={tabsStyle || 'enclosed'}
            active={selectedTabIndex === index}
            onClick={() => setSelectedTabIndex(index)}
          />
        ))}
      </TabsContainer>
      {tabs.find((tab, index) => selectedTabIndex === index)?.content}
    </Container>
  );
};

export default TabsSection;
