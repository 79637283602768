import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid ${palette.beta300gray};
  border-radius: 13px;
  box-sizing: border-box;
  padding: 1.6rem 1.2rem 1rem 1.7rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderIconsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const HeaderIcon = styled.img`
  max-width: 3rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  @media (min-width: ${MEDIA_SIZE.md}) {
    :hover {
      transform: scale(1.2);
    }
  }
`;

export const AvatarIcon = styled.img`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${palette.beta300gray};
  border-radius: 100%;
  margin-right: 1rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;

  white-space: nowrap;
  text-overflow: ellipsis;

  & > span {
    margin-bottom: 0.3rem;
  }
`;

export const CompanySection = styled.div`
  display: flex;
  margin: 2rem 0;
`;

export const Expiration = styled.div`
  border-top: 1px solid ${palette.beta300gray};
  padding: 0 1rem;
  width: fit-content;
`;
