import React, { useCallback, useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import QuerySelectorCreate from 'components/QuerySelectorCreate';
import { useGetDegreeUniversitiesSelectionsLazyQuery } from 'generated/graphql';
import { OptionId } from '../Option';

export interface UniversitySelectorCreateProps extends Pick<FormContextValues, 'control'> {
  name: string;
  value: OptionId | null;
  fillInValue?: string;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
}

const UniversitySelectorCreate: React.FC<UniversitySelectorCreateProps> = ({
  name,
  control,
  clearable = false,
  required = false,
  value = null,
  fillInValue = null,
  placeholder = undefined,
  error = false,
}) => {
  const [queryUniversities, { loading: loadingUni, data: dataUni }] = useGetDegreeUniversitiesSelectionsLazyQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const handleOnSearch = useCallback(
    (searchTerm) => {
      queryUniversities({
        variables: {
          searchTerm: `%${searchTerm}%`,
        },
      });
    },
    [queryUniversities],
  );

  const companyOptions = useMemo(
    () =>
      ((dataUni && dataUni.grad_university_selection) || []).map(({ location_name, university_selection_id }) => ({
        value: location_name,
        label: location_name,
        id: university_selection_id,
      })),
    [dataUni],
  );

  return (
    <QuerySelectorCreate
      defaultOptions={companyOptions}
      defaultOptionValue={value && value.id ? value : fillInValue ? { value: fillInValue, label: fillInValue } : null}
      name={name}
      placeholder={placeholder}
      control={control}
      loading={loadingUni}
      isClearable={clearable}
      required={required}
      onSearch={handleOnSearch}
      error={error}
    />
  );
};

export default UniversitySelectorCreate;
