import React, { useCallback, useMemo } from 'react';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { useAuth0 } from '../react-auth0-spa';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { notify } from './notify';

const JWT_EXPIRED_ERROR_PATTERN = /^.*: JWTExpired$/;

const dataIdFromObject = ({ __typename, id, ...rest }: { __typename?: string; id?: string; [key: string]: any }) => {
  switch (__typename) {
    case 'recruit_company_subindustry':
      return rest.company_subindustry_id;
    case 'recruit_company':
      return rest.company_id;
    case 'grad_profile':
      return rest.user_id;
    case 'grad_degree':
      return rest.degree_id;
    case 'grad_test_score':
      return rest.test_score_id;
    case 'grad_test_score_detail':
      return rest.test_score_detail_id;
    case 'grad_work_experience':
      return rest.work_experience_id;
    case 'grad_raise_hand':
      return rest.raise__hand_id;
    case 'grad_upload_doc':
      return rest.upload_doc_id;
    case 'recruit_job_posting':
      return rest.job_posting_id;
    case 'grad_fun_fact':
      return rest.fun_fact_id;
    case 'grad_volunteer':
      return rest.volunteer_id;
    case 'grad_award':
      return rest.award_id;
    case 'grad_achievement':
      return rest.achievement_id;
    case 'grad_major_selection':
      return rest.major_selection_id;
    case 'grad_concentration':
      return rest.concentration_id;
    case 'grad_country_selection':
      return rest.country_selection_id;
    case 'grad_university_selection':
      return rest.university_selection_id;
    case 'grad_interest_job_type':
      return rest.interest_job_type_id;
    case 'grad_interest_firm':
      return rest.interest_firm_id;
    case 'grad_spoken_language':
      return rest.spoken_language_id;
    case 'grad_interest_region':
      return rest.interest_region_id;
    case 'grad_interest_firm_selection':
      return rest.interest_firm_selection_id;
    case 'grad_interest_job_type_selection':
      return rest.interest_job_type_selection_id;
    case 'grad_city_state_selection':
      return rest.city_state_selection_id;
    case 'grad_company_selection':
      return rest.company_selection_id;
    case 'grad_degree_selection':
      return rest.degree_selection_id;
    case 'grad_degree_fill_in_university':
      return rest.degree_id + rest.user_id;
    case 'grad_page_view':
      return rest.page_view_id;
    case 'grad_interest_role':
      return rest.interest_role_id;
    case 'grad_interest_role_selection':
      return rest.interest_role_selection_id;
    case 'grad_computer_language_selection':
      return rest.computer_language;
    case 'grad_computer_language':
      return rest.computer_language_id;
    case 'grad_fun_fact_selection':
      return rest.fun_fact_selection_id;
    case 'grad_employer_preference':
      return rest.employer_preference_id;
    case 'grad_employer_preference_selection':
      return rest.employer_preference_selection_id;
    case 'grad_employer_preference_mutation_response':
    case 'grad_volunteer_mutation_response':
    case 'grad_achievement_mutation_response':
    case 'grad_spoken_language_mutation_response':
    case 'grad_fun_fact_mutation_response':
    case 'grad_award_mutation_response':
    case 'grad_page_view_mutation_response':
    case 'grad_profile_mutation_response':
    case 'grad_interest_job_type_mutation_response':
    case 'grad_interest_firm_mutation_response':
    case 'grad_interest_region_mutation_response':
    case 'grad_degree_mutation_response':
    case 'grad_concentration_mutation_response':
    case 'grad_test_score_detail_mutation_response':
    case 'grad_test_score_mutation_response':
    case 'grad_interest_role_mutation_response':
    case 'grad_computer_language_mutation_response':
      // Don't handle mutation responses
      return undefined;
    default:
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`Unhandled __typename = ${__typename} for caching`);
        // eslint-disable-next-line no-console
        console.warn(rest);
      }
      return id;
  }
};

interface ApolloWrapperNewProps {
  children: React.ReactNode;
}

const createApolloClient = (getToken: any, onAuthError: () => void) => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const setAuthorizationLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (message.match(JWT_EXPIRED_ERROR_PATTERN)) {
          onAuthError();
        } else {
          notify('APIError', `GraphQL error: ${message}`);
        }
      });
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`);
      notify('NetworkError', networkError.message);
    }
  });

  const link = errorLink.concat(setAuthorizationLink.concat(httpLink));
  return new ApolloClient({
    connectToDevTools: false,
    link,
    cache: new InMemoryCache({ dataIdFromObject }),
  });
};

const ApolloWrapper: React.FC<ApolloWrapperNewProps> = ({ children }) => {
  const { getTokenSilently, logout } = useAuth0();

  const handleAuthError = useCallback(logout, [logout]);

  const apolloClient = useMemo(() => createApolloClient(getTokenSilently, handleAuthError), [
    getTokenSilently,
    handleAuthError,
  ]);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
