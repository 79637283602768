// colors
export default {
  black: '#000000',
  white: '#ffffff',
  blue: '#347eff',
  watermelon: '#fc405b',

  // red + variations
  red: '#ee151b',
  darkRed: '#b62d40',
  alpha100Red: '#FAC3C0',
  alpha300Red: '#E77C76',
  alpha400Red: '#E95F57',
  alpha500Red: '#DC352C',
  alpha600Red: '#C1231B',
  alpha700Red: '#A60C03',
  alpha800Red: '#8D150E',

  // gray + variations
  gray50: '#F7FAFC',
  gray100: '#EDF2F7',
  gray200: '#E2E8F0',
  gray300: '#CBD5E0',
  gray400: '#A0AEC0',
  gray500: '#718096',
  gray600: '#4A5568',
  gray700: '#2D3748',
  gray800: '#1A202C',
  gray900: '#171923',

  // alpha + variations (red)
  alpha50red: '#FFF0EF',

  // beta gray + variations
  beta50gray: '#FBFBFB',
  beta100gray: '#F6F9FC',
  beta200gray: '#E5ECF3',
  beta300gray: '#D7DFED',
  beta400gray: '#B5BECD',
  beta500gray: '#878787',
  beta600gray: '#73777B',
  beta700gray: '#54595C',
  beta800gray: '#373D42',
  beta900gray: '#1C2125',

  // old gray variations
  darkGray: '#4a4a4a',
  gray: '#848484',
  brownGray: '#9b9b9b',
  lightGray: '#dddddd',
  clearGray: '#f6f6f6',

  gama800Success: '#22543D',
};
