import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from '../../../../utils/useDimensions';

export const Container = styled.div`
  display: flex;
  padding: 0 3rem;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2rem;
  height: 100%;
  flex: 1;

  @media (min-width: ${MEDIA_SIZE.md}) {
    padding-top: 0;
  } ;
`;

export const Requirement = styled.div`
  font-family: 'Roboto';
  font-size: 1.5rem;
  margin: 0 auto;
  margin-bottom: 4.6rem;
  color: ${palette.gray500};
`;
