import { useUpdateRelatedProfileMutation, GetStudentProfileDocument, GetStudentProfileQuery } from 'generated/graphql';

export const useUpdateRelatedProfileMutationHook = (myUserId: string) => {
  return useUpdateRelatedProfileMutation({
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: updateRelatedData }) => {
      const profileCache = cache.readQuery<GetStudentProfileQuery>({
        query: GetStudentProfileDocument,
        variables: { myUserId },
      });

      const [oldGradProfile] = profileCache?.grad_profile || [];

      if (!oldGradProfile) return;

      if (
        !updateRelatedData ||
        !updateRelatedData.insert_grad_spoken_language ||
        !updateRelatedData.delete_grad_spoken_language ||
        !updateRelatedData.insert_grad_fun_fact ||
        !updateRelatedData.delete_grad_fun_fact ||
        !updateRelatedData.insert_grad_volunteer ||
        !updateRelatedData.delete_grad_volunteer ||
        !updateRelatedData.insert_grad_award ||
        !updateRelatedData.delete_grad_award ||
        !updateRelatedData.insert_grad_achievement ||
        !updateRelatedData.delete_grad_achievement
      ) {
        return;
      }

      const deletedLanguageIds = updateRelatedData.delete_grad_spoken_language.returning.map(
        ({ spoken_language_id }) => spoken_language_id,
      );
      const newLanguages = [
        ...oldGradProfile.spoken_languages,
        ...updateRelatedData.insert_grad_spoken_language.returning,
      ].filter(({ spoken_language_id }) => !deletedLanguageIds.includes(spoken_language_id));

      const newFunFacts = updateRelatedData.insert_grad_fun_fact.returning;

      const newVolunteers = updateRelatedData.insert_grad_volunteer.returning;

      const deletedAwardIds = updateRelatedData.delete_grad_award.returning.map(({ award_id }) => award_id);
      const newAwards = [...oldGradProfile.awards, ...updateRelatedData.insert_grad_award.returning].filter(
        ({ award_id }) => !deletedAwardIds.includes(award_id),
      );

      const newAchievements = updateRelatedData.insert_grad_achievement.returning;

      const newGradProfile = {
        ...oldGradProfile,
        spoken_languages: newLanguages,
        fun_facts: newFunFacts,
        volunteers: newVolunteers,
        awards: newAwards,
        achievements: newAchievements,
      };

      cache.writeQuery({
        query: GetStudentProfileDocument,
        variables: { myUserId },
        data: {
          grad_profile: [newGradProfile],
        },
      });
    },
  });
};
