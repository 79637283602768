/* eslint-disable @typescript-eslint/no-empty-function */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import ModalOpenType from 'types/ModalOpen';
import {
  ModalContainer,
  ModalContent,
  Container,
  ExitCross,
  ButtonContainer,
  SubmitButton,
  CancelButton,
  DeleteButton,
  EditTitle,
  DeleteContainer,
  ConfirmDelete,
} from './Modal.styled';
export interface ModalProps {
  setModalOpen: (status: ModalOpenType) => void;
  handleSubmit?: (nav?: any) => void;
  handleDelete?: () => void;
  heightAuto?: boolean;
  withDelete?: boolean;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  setModalOpen,
  heightAuto = false,
  children,
  handleSubmit,
  withDelete = false,
  title,
  handleDelete = () => {},
}) => {
  const handleModalOnClick = useCallback(
    (e: SyntheticEvent) => {
      setModalOpen('');
    },
    [setModalOpen],
  );

  const [deleteStep, setDeleteStep] = useState(false);
  return (
    <ModalContainer>
      <ModalContent heightAuto={heightAuto || deleteStep} withDelete={withDelete && deleteStep}>
        <Container>
          <ExitCross onClick={handleModalOnClick} />
          {title && <EditTitle>{title}</EditTitle>}
          {deleteStep ? <DeleteContainer>Are you sure you want to delete this item?</DeleteContainer> : children}
        </Container>
        <ButtonContainer>
          <>
            {deleteStep ? (
              <>
                <CancelButton onClick={handleModalOnClick} withDelete={withDelete}>
                  Cancel
                </CancelButton>
                <ConfirmDelete onClick={handleDelete}>Yes, delete</ConfirmDelete>
              </>
            ) : (
              <>
                <CancelButton onClick={handleModalOnClick} withDelete={withDelete}>
                  Cancel
                </CancelButton>
                {withDelete && <DeleteButton onClick={() => setDeleteStep(true)}>Delete</DeleteButton>}
                <SubmitButton onClick={handleSubmit} withDelete={withDelete}>
                  Save
                </SubmitButton>
              </>
            )}
          </>
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
