import React, { useEffect, useState } from 'react';
import { WizardPage } from '../components';
import {
  Container,
  WelcomeSection,
  TitleWelcome,
  SubtitleWelcome,
  JeannieLogo,
  LockContainer,
} from './SignUpStep.styled';
import { WizardProps } from '../components/interface';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { JeannieWelcome } from 'theme/assets';
import { useAuth0 } from 'react-auth0-spa';
import { getAuthParams, generateAuth0Lock, AUTH_PARAMS, hasAllAuthParams } from './SignUpStep.utils';

const onSafariOrIphone = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  return !(ua.indexOf('chrome') != -1) && (ua.indexOf('safari') > -1 || ua.indexOf('iphone') || ua.indexOf('ipad'));
};

const SignUpStep: React.FC<WizardProps> = ({ totalSteps, step, nextGoTo = '/' }) => {
  const { width } = useWindowDimensions();
  const [subStep, setSubStep] = useState(1);
  const [authParams, setAuthParams] = useState(AUTH_PARAMS);
  const { buildAuthorizeUrl, loginWithRedirect } = useAuth0();

  const changeStep = () => setSubStep(2);

  const onMobileResolution = (): boolean => width < mobileBreakpoint;

  useEffect(() => {
    async function getState() {
      const urlString = await buildAuthorizeUrl({});
      const generatedAuthParams = getAuthParams(urlString);
      setAuthParams(generatedAuthParams);
    }

    if (onSafariOrIphone()) {
      loginWithRedirect();
    } else {
      getState();
    }
  }, []);

  useEffect(() => {
    if (!hasAllAuthParams(authParams)) return;
    const lock = generateAuth0Lock(authParams, nextGoTo);
    ((onMobileResolution() && subStep === 2) || !onMobileResolution()) && lock.show();
  }, [subStep, authParams]);

  return (
    <WizardPage
      step={step}
      totalSteps={totalSteps}
      showBackground
      showButtons={onMobileResolution() && subStep === 1}
      navigable={false}
      onNext={changeStep}
      cardWidth={'69vw'}
    >
      <Container>
        {(subStep === 1 || width >= mobileBreakpoint) && (
          <WelcomeSection>
            <JeannieLogo src={JeannieWelcome} />
            <TitleWelcome>Hi, I&apos;m Jeannie!</TitleWelcome>
            <TitleWelcome>Welcome to EliteGrad!</TitleWelcome>
            <SubtitleWelcome>
              Simply enter your email, answer a few questions and submit a resume to apply! Please sign up to continue
              your application
            </SubtitleWelcome>
          </WelcomeSection>
        )}
        {(!onMobileResolution() || subStep === 2) && (
          <WelcomeSection>
            <LockContainer id="lock-container" />
          </WelcomeSection>
        )}
      </Container>
    </WizardPage>
  );
};

export default SignUpStep;
