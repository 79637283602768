import React from 'react';
import styled from 'styled-components';
import { InputLegend } from 'views/Wizard/components';
import { FieldError } from 'react-hook-form/dist/types';

export const DEFAULT_MESSAGE = 'This field requires input';

export const generateErrorMessages = (error: FieldError | undefined) => {
  if (!error || !error.message) return;
  return <InputLegend error>{error.message}</InputLegend>;
};
