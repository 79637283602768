import { Badge } from 'components/Badge';
import React from 'react';
import { palette } from 'theme';
import { Text } from 'theme/typography';
import { AboutCompanyContainer } from './JobDetail.styled';
import {
  CompanySection,
  AvatarIcon,
  Info,
  Row,
  CompanyContainer,
  CompanyContactInfoContainer,
  CompanyContactColumn,
  ContactContainer,
  ContactRow,
  CompanyWebsite,
} from '../../Event/EventDetail/EventDetailTabs/EventDetailsTabs.styled';

interface CompanyData {
  name: string;
  location: string;
  subIndustries: { value: string }[];
  avatar: string;
  description: string;
  phone: string;
  website: string;
  employees: string;
}

const JobDetailCompany: React.FC<{ company: CompanyData }> = ({ company }) => (
  <AboutCompanyContainer>
    <CompanyContainer>
      <CompanySection>
        <AvatarIcon src={company?.avatar || ''} />
        <Info>
          <Text variant="xl" color={palette.gray900} bold>
            {company?.name}
          </Text>
          <Text variant="m" color={palette.gray900}>
            {company?.location}
          </Text>
          <Row>
            {company?.subIndustries.map((subindustry) => (
              <Badge key={subindustry.value} variant={'solid'} color={'beta'} size={'medium'}>
                {subindustry.value}
              </Badge>
            ))}
          </Row>
        </Info>
      </CompanySection>
      <Text variant="m" color={palette.gray700}>
        {company?.description}
      </Text>
      <CompanyContactInfoContainer>
        <CompanyContactColumn>
          <Text variant="m" bold color={palette.black}>
            Contact Information
          </Text>
          <ContactContainer>
            <ContactRow>
              <Text variant="m" bold color={palette.black}>
                Phone:
              </Text>
              <Text variant="m" color={palette.gray800}>
                {company?.phone}
              </Text>
            </ContactRow>
            <ContactRow>
              <Text variant="m" bold color={palette.black}>
                Website:
              </Text>
              <CompanyWebsite href={company?.website || ''} target="_blank">
                <Text variant="m" color={palette.gray800}>
                  {company?.website}
                </Text>
              </CompanyWebsite>
            </ContactRow>
          </ContactContainer>
        </CompanyContactColumn>
        <CompanyContactColumn>
          <Text variant="m" bold color={palette.black}>
            Employees
          </Text>
          <Badge noUppercase variant={'solid'} color={'beta'} size={'medium'}>
            <Text variant="m" color={palette.white}>
              {company?.employees} Employees
            </Text>
          </Badge>
        </CompanyContactColumn>
      </CompanyContactInfoContainer>
    </CompanyContainer>
  </AboutCompanyContainer>
);

export default JobDetailCompany;
