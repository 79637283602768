import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';

import ProfileJobTab, { FormInterface } from './ProfileJobTab';
import { OptionIdStrict } from 'components/Option';
import { Spinner } from '../../../../components';

import {
  useDeleteInterestsMutation,
  useGetStudentJobInterestsQuery,
  useInsertEmployerPreferenceMutation,
  useInsertFirmInterestsMutation,
  useInsertJobInterestsMutation,
  useInsertRegionInterestsMutation,
  useInsertRoleInterestsMutation,
} from 'generated/graphql';

const DEFAULT_INTERESTS = [
  { interest_job_types: [], interest_firms: [], interest_regions: [], interest_roles: [], employer_preferences: [] },
];

const ProfileJobTabController: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  const {
    loading: loadingInterests,
    error: getProfileError,
    data: interestsData = { grad_profile: [] },
  } = useGetStudentJobInterestsQuery({ variables: { myUserId: user.sub } });

  const [deleteInterests] = useDeleteInterestsMutation();
  const [insertFirmInterests] = useInsertFirmInterestsMutation();
  const [insertJobInterests] = useInsertJobInterestsMutation();
  const [insertRegionInterests] = useInsertRegionInterestsMutation();
  const [insertEmployerPreference] = useInsertEmployerPreferenceMutation();
  const [insertRoleInterests] = useInsertRoleInterestsMutation();

  const [
    {
      interest_job_types = [],
      interest_firms = [],
      interest_regions = [],
      interest_roles = [],
      employer_preferences = [],
    },
  ] = interestsData?.grad_profile?.length ? interestsData.grad_profile : DEFAULT_INTERESTS;

  const currentJobType =
    interest_job_types.map(
      ({ job_type, interest_job_type_id }: { job_type: string; interest_job_type_id: string }) => ({
        value: job_type,
        label: job_type,
        id: interest_job_type_id,
      }),
    )[0] || null;

  const currentFirmInterests = interest_firms.map(
    ({ firm_type, interest_firm_id }: { firm_type: string; interest_firm_id: string }) => ({
      value: firm_type,
      label: firm_type,
      id: interest_firm_id,
    }),
  );

  const currentRegions = interest_regions.map(
    ({ region, interest_region_id }: { region: string; interest_region_id: string }) => ({
      value: region,
      label: region,
      id: interest_region_id,
    }),
  );

  const currentRoles = interest_roles.map(({ role, interest_role_id }: { role: string; interest_role_id: string }) => ({
    value: role,
    label: role,
    id: interest_role_id,
  }));

  const currentEmployerPreferences = employer_preferences.map(({ preference, employer_preference_id }) => ({
    value: preference,
    label: preference,
    id: employer_preference_id,
  }));

  const getEraseOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));
  const getAddOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async ({
    desiredFirms: { current: desiredFirms },
    desiredJobType,
    interestRegions,
    interestRoles: { current: interestRoles },
    employerPreferences,
  }: FormInterface) => {
    const { deletedOptionsIds: erasePreferences, newOptions: newEmployerPreferences } = employerPreferences;

    const addEmployerPreferences = newEmployerPreferences.map(({ value }) => ({
      preference: value,
      user_id: user.sub,
    }));

    const eraseFirms = getEraseOptions(desiredFirms || [], currentFirmInterests || [])
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addFirms = getAddOptions(desiredFirms, currentFirmInterests).map(({ value }) => ({
      firm_type: value,
      user_id: user.sub,
    }));

    const eraseJobTypes = getEraseOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    )
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addJobTypes = getAddOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    ).map(({ value }) => ({
      job_type: value,
      user_id: user.sub,
    }));

    const eraseRegions = getEraseOptions(interestRegions, currentRegions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRegions = getAddOptions(interestRegions, currentRegions).map(({ value }) => ({
      region: value,
      user_id: user.sub,
    }));

    const eraseRoles = getEraseOptions(interestRoles, currentRoles)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRoles = getAddOptions(interestRoles, currentRoles).map(({ value }) => ({
      role: value,
      user_id: user.sub,
    }));

    if (
      eraseJobTypes.length ||
      eraseFirms.length ||
      eraseRegions.length ||
      eraseRoles.length ||
      erasePreferences.length
    ) {
      await deleteInterests({
        variables: {
          jobs: eraseJobTypes,
          firms: eraseFirms,
          regions: eraseRegions,
          roles: eraseRoles,
          employerPreferences: erasePreferences,
        },
      });
    }

    if (addFirms.length) await insertFirmInterests({ variables: { firms: addFirms } });

    if (addJobTypes.length) await insertJobInterests({ variables: { jobs: addJobTypes } });

    if (addRegions.length) await insertRegionInterests({ variables: { regions: addRegions } });

    if (addRoles.length) await insertRoleInterests({ variables: { roles: addRoles } });

    if (addEmployerPreferences.length)
      await insertEmployerPreference({ variables: { preferences: addEmployerPreferences } });

    history.goBack();
  };
  const onCancel = () => history.goBack();
  if (loadingInterests) return <Spinner />;
  if (getProfileError) return <div>Error</div>;
  return (
    <ProfileJobTab
      onSubmit={onSubmit}
      onCancel={onCancel}
      currentRoles={currentRoles}
      currentEmployerPreferences={currentEmployerPreferences}
      currentFirmInterests={currentFirmInterests}
      currentJobType={currentJobType}
      currentRegions={currentRegions}
    />
  );
};

export default ProfileJobTabController;
