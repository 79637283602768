import styled from 'styled-components';
import SelectorCreateV2 from '../../../components/SelectorCreateV2';

export const StyledAcademicHonors = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledAcademicHonorsRow = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;

  & > * {
    width: 50%;
  }

  & > *:last-child {
    margin-left: 0.8rem;
  }
`;

export const StyledAcademicHonorsSelector = styled(SelectorCreateV2)`
  height: 3.4rem;
`;
