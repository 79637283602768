import moment from 'moment';
import { RecruitJobPostingBoolExp } from 'generated/graphql';
import { generateWhereQuery, WhereQueryInterface } from 'utils/queryUtils';
import { useAuth0 } from 'react-auth0-spa';

interface JobsDynamicQueryProps {
  subIndustry?: string[];
  role?: string;
  keyword?: string;
  hasHandraise?: boolean;
  hasBookmark?: boolean;
}

const useJobsDynamicQuery = ({
  subIndustry,
  role,
  keyword,
  hasHandraise = false,
  hasBookmark = false,
}: JobsDynamicQueryProps): RecruitJobPostingBoolExp | null => {
  const { user } = useAuth0();

  const typeOfSubIndustry: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { job_type: { _in: subIndustry } },
  };

  const roleQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { job_type: { _ilike: `%${role}%` } },
  };

  const keywordQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: {
      _or: [
        { job_type: { _ilike: `%${keyword}%` } },
        { position_title: { _ilike: `%${keyword}%` } },
        { region: { _ilike: `%${keyword}%` } },
      ],
    },
  };

  const raisedHandQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: hasHandraise,
    operator: '_and',
    whereQuery: { raise_hands: { user_id: { _eq: user.sub } } },
  };

  const bookmarkedQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: hasBookmark,
    operator: '_and',
    whereQuery: { bookmark_jobs: { user_id: { _eq: user.sub } } },
  };

  const today = moment().format('YYYY-MM-DD');

  const deadlineQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { deadline: { _gt: today } },
  };

  const activeQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { active: { _eq: true } },
  };
  const publicQuery: WhereQueryInterface<RecruitJobPostingBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { public: { _eq: true } },
  };

  const dynamicQueryCustom = [publicQuery.whereQuery, activeQuery.whereQuery, deadlineQuery.whereQuery];
  // Boolean(subIndustry?.length) && activeFilters.push(typeOfSubIndustry);
  Boolean(role) && dynamicQueryCustom.push(roleQuery.whereQuery);
  Boolean(keyword) && dynamicQueryCustom.push(keywordQuery.whereQuery);
  Boolean(hasHandraise) && dynamicQueryCustom.push(raisedHandQuery.whereQuery);
  Boolean(hasBookmark) && dynamicQueryCustom.push(bookmarkedQuery.whereQuery);

  return { _and: dynamicQueryCustom };
};

export default useJobsDynamicQuery;
