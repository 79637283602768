import React from 'react';
import { Photo, TitleContainer, OptionalText, Container } from './PictureStep.styled';
import { Title, SubTitle } from '../Wizard.styled';
import PictureEditor from '../../../components/PictureEditor/PictureEditor';
import AvatarEditor from 'react-avatar-editor';

interface PictureStepProps {
  onDelete: () => void;
  updateImage: (imageFile: string) => void;
  step: number;
  isLastStep: boolean;
  cancelSkipGoTo: string;
  image: string | null;
  editorRef?: (editor: AvatarEditor) => void;
}

const PictureStep: React.FC<PictureStepProps> = ({ onDelete, updateImage, image, editorRef }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>Profile picture</Title>
        <OptionalText>(optional)</OptionalText>
        <SubTitle>
          Hint: Do it. Employers like it. In fact, complete profiles are 67% more likely to be clicked on by Hiring
          Managers!
        </SubTitle>
      </TitleContainer>
      <Photo>
        {image && (
          <PictureEditor
            fromWizard
            queryImage={image}
            onChange={updateImage}
            onDelete={onDelete}
            editorRef={editorRef}
          />
        )}
      </Photo>
    </Container>
  );
};

export default PictureStep;
