import React from 'react';
import EventDetailHeader from './EventDetailHeader';
import EventDetailContent, { EventDetailContentProps } from './EventDetailContent';
import { Container } from './EventDetail.styled';

const EventDetail: React.FC<EventDetailContentProps> = ({
  title,
  description,
  capacity,
  address,
  event_url,
  companyName,
  companyAddress,
  companyId,
  eventType,
  timezone,
  startDatetime,
  endDatetime,
  eventId,
  isRegistrated,
  eventImage,
  isFull,
}) => {
  return (
    <Container>
      <EventDetailHeader isFull={isFull} isRegistrated={isRegistrated} eventImage={eventImage} eventId={eventId} />
      <EventDetailContent
        companyName={companyName}
        companyAddress={companyAddress}
        companyId={companyId}
        address={address}
        event_url={event_url}
        startDatetime={startDatetime}
        endDatetime={endDatetime}
        capacity={capacity}
        title={title}
        eventType={eventType}
        description={description}
        timezone={timezone}
        eventId={eventId}
        isRegistrated={isRegistrated}
        eventImage={eventImage}
        isFull={isFull}
      />
    </Container>
  );
};

export default EventDetail;
