import styled from 'styled-components';
import { palette } from 'theme';
import { StyledFormInputs } from 'components/library';

export const StyledCustomUpload = styled.label`
  margin-top: 1.5rem;
  height: 3.2rem;
  width: 12.8rem;

  border-radius: 10px;
  border: 1px solid ${palette.red};
  background: ${palette.white};

  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3.2rem;
  color: ${palette.red};

  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
`;
export const StyledUploadFile = styled.input.attrs({
  type: 'file',
  accept: '.png, .jpg, .jpeg',
})`
  display: none;
`;

export const StyledPhotoEditor = styled.div`
  position: relative;

  width: 100%;
`;
export const StyledScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin-top: 1rem;
  width: 100%;
`;

export const StyledFormInputsFlex = styled(StyledFormInputs)`
  flex-direction: column;
  align-items: center;

  display: flex;
  padding-bottom: 11rem;
`;

export const TrashIcon = styled.img`
  display: inline-block;

  height: inherit;
`;

export const StyledRemovePhotoButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: inline-block;

  padding: 0.5rem 0.5rem 0 0;
  height: 3rem;
`;
