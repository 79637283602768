import React from 'react';
import CompanyListCard from '../CompanyListCard';
import CardList from '../../../../components/common/CardList';
import { GetCompaniesListQuery } from 'generated/graphql';

import { CardsContainer } from '../../JobsList/JobList/JobList.styled';
import JobListPlaceholder from '../../JobsList/JobListPlaceholder';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'components';

interface CompanyListProps {
  companies: GetCompaniesListQuery | null;
  inMobile: boolean;
  fetchMore: () => void;
}

const CompanyList: React.FC<CompanyListProps> = ({ companies, inMobile, fetchMore }) => {
  const hasJobs = Boolean(companies?.recruit_company?.length);
  const hasMoreJobs =
    Number(companies?.recruit_company_aggregate.aggregate?.count) > Number(companies?.recruit_company?.length);

  return hasJobs ? (
    <CardsContainer id="cardsContainer">
      {inMobile ? (
        <InfiniteScroll
          dataLength={companies?.recruit_company?.length || 15}
          next={() => fetchMore()}
          hasMore={hasMoreJobs}
          loader={<Spinner />}
          scrollableTarget="cardsContainer"
        >
          {companies?.recruit_company?.map((job) => (
            <CompanyListCard
              key={job.company_id}
              name={job.name}
              address={job.address}
              description={job.description}
              company_id={job.company_id}
              logo={job.logo}
              subindustries={job.subindustries}
            />
          ))}
        </InfiniteScroll>
      ) : (
        companies?.recruit_company?.map((job) => (
          <CompanyListCard
            key={job.company_id}
            name={job.name}
            address={job.address}
            description={job.description}
            company_id={job.company_id}
            logo={job.logo}
            subindustries={job.subindustries}
          />
        ))
      )}
    </CardsContainer>
  ) : (
    <JobListPlaceholder />
  );
};

export default CompanyList;
