import styled from 'styled-components';
import { palette } from 'theme';

export const Wrapper = styled.div`
  span {
    font-family: 'Roboto';
    color: ${palette.beta700gray};
    font-size: 1.4rem;
    line-height: 1.8rem;
    align-items: center;
  }
`;

export const ExpandButton = styled.span<{ isExpanded: boolean }>`
  text-decoration: underline;
  font-family: 'Roboto';
  font-size: 1.4rem;
  color: ${palette.gray500};
`;

export const Elements = styled.span`
  font-family: 'Roboto';
  color: ${palette.beta700gray};
  font-size: 1.4rem;
  line-height: 1.8rem;
  align-items: center;
`;
