import React from 'react';
import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../library';
import { ReactComponent as TestScoresIcon } from 'theme/assets/DefaultTestScores.svg';
import Test from '../../../../types/Test';

import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import TestScoreCardWithDetails from './TestScoreCardWithDetails';
import TestScoreCardSingle from './TestScoreCardSingle';

export interface TestScoresProps {
  satTest: Test;
  actTest: Test;
  otherTests: Test[];
  fullProfile?: boolean;
  handleEdit: () => void;
}

const TestScores: React.FC<TestScoresProps> = ({ satTest, actTest, otherTests, fullProfile = false, handleEdit }) => {
  return (
    <CollapsibleWidgetCard fixed={(!satTest && !actTest) || (satTest && !actTest && !otherTests)} withTitle>
      <CardTitle>Test Score {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
      {satTest && (
        <TestScoreCardWithDetails
          details={satTest?.test_score_details}
          test_score_id={satTest?.test_score_id}
          outof={satTest?.outof}
          score={satTest?.score}
          test={satTest?.test}
        />
      )}
      {actTest && (
        <TestScoreCardSingle
          key={actTest?.test_score_id}
          test={actTest?.test}
          score={actTest?.score}
          outof={actTest?.outof}
        />
      )}
      {otherTests?.map((otherTest) => (
        <TestScoreCardSingle
          key={otherTest?.test_score_id}
          test={otherTest?.test}
          score={otherTest?.score}
          outof={otherTest?.outof}
        />
      ))}
      {!satTest && !actTest && (!otherTests || otherTests.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No applicable Test scores</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default TestScores;

export const CardTitle = styled.div`
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  line-height: 1.8rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

const Icon = styled(TestScoresIcon)`
  width: 5.6rem;
  height: 5.6rem;
`;

const Text = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.beta400gray};
  margin-left: 2rem;
`;

const TopEditButton = styled(EditButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
