import { palette } from 'theme';
import { ButtonStyleType } from './Button';

const WHITE_COLOR = { background: palette.white, text: palette.beta700gray, border: 'transparent' };

const COLOR = {
  //TODO
  white: {
    default: WHITE_COLOR,
    hover: WHITE_COLOR,
    press: WHITE_COLOR,
    disable: WHITE_COLOR,
  },
  alpha: {
    default: { background: palette.alpha500Red, text: palette.white, border: 'transparent' },
    hover: { background: palette.alpha600Red, text: palette.white, border: 'transparent' },
    press: { background: palette.alpha700Red, text: palette.white, border: 'transparent' },
    disable: { background: palette.alpha500Red, text: palette.white, border: 'transparent' },
  },
  beta: {
    default: { background: palette.beta700gray, text: palette.white, border: 'transparent' },
    hover: { background: palette.beta800gray, text: palette.white, border: 'transparent' },
    press: { background: palette.beta900gray, text: palette.white, border: 'transparent' },
    disable: { background: palette.beta600gray, text: palette.white, border: 'transparent' },
  },
};

const OUTLINE_COLOR = {
  //TODO
  white: {
    default: WHITE_COLOR,
    hover: WHITE_COLOR,
    press: WHITE_COLOR,
    disable: WHITE_COLOR,
  },
  //TODO
  alpha: {
    default: { background: palette.alpha500Red, text: palette.white, border: palette.white },
    hover: { background: palette.alpha600Red, text: palette.white, border: palette.white },
    press: { background: palette.alpha700Red, text: palette.white, border: palette.white },
    disable: { background: palette.alpha500Red, text: palette.white, border: palette.white },
  },
  beta: {
    default: { background: palette.white, text: palette.beta700gray, border: palette.beta700gray },
    hover: { background: palette.white, text: palette.beta800gray, border: palette.beta800gray },
    press: { background: palette.white, text: palette.beta900gray, border: palette.beta900gray },
    disable: { background: palette.white, text: palette.beta600gray, border: palette.beta600gray },
  },
};

const GHOST_COLOR = {
  //TODO
  white: {
    default: WHITE_COLOR,
    hover: WHITE_COLOR,
    press: WHITE_COLOR,
    disable: WHITE_COLOR,
  },
  //TODO
  alpha: {
    default: { background: palette.alpha500Red, text: palette.white, border: 'transparent' },
    hover: { background: palette.alpha600Red, text: palette.white, border: 'transparent' },
    press: { background: palette.alpha700Red, text: palette.white, border: 'transparent' },
    disable: { background: palette.alpha500Red, text: palette.white, border: 'transparent' },
  },
  beta: {
    default: { background: palette.white, text: palette.beta700gray, border: 'transparent' },
    hover: { background: palette.white, text: palette.beta800gray, border: 'transparent' },
    press: { background: palette.white, text: palette.beta900gray, border: 'transparent' },
    disable: { background: palette.white, text: palette.beta600gray, border: 'transparent' },
  },
};

export const colorPicker = (style?: ButtonStyleType) => {
  switch (style) {
    case 'outline':
      return OUTLINE_COLOR;
    case 'ghost':
      return GHOST_COLOR;
    default:
      return COLOR;
  }
};

export const SIZE = {
  md: { padding: '1rem 2.5rem' },
  lg: { padding: '1.3rem 2.5rem' },
};
