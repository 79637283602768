import React from 'react';
import { FormContextValues } from 'react-hook-form';
import SelectorMultiCreateV2 from '../SelectorMultiCreateV2';
import { OptionId } from '../Option';

import { useGetSelectorMajorQuery } from 'generated/graphql';

interface MinorSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  required: boolean;
  minorsValue: OptionId[];
  name: string;
}

export const MinorSelector: React.FC<MinorSelectorProps> = ({ minorsValue, name, required, register, setValue }) => {
  const { loading, error, data } = useGetSelectorMajorQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const selectorOptions = ((!error && data && data.grad_major_selection) || []).map(({ major: minor }) => ({
    value: minor,
    label: minor,
  }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={minorsValue}
      name={name}
      placeholder={'e.g: Computer Technician'}
      required={required}
      register={register}
      setValue={setValue}
      maxSelection={Infinity}
    />
  );
};
