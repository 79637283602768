import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const UploaderTitle = styled.div`
  margin: 1.6rem 2rem;

  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;

  line-height: 1.8rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2.8rem auto 2.5rem;
`;

export const UploaderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;
