import React, { Ref } from 'react';
import styled from 'styled-components';
import { WizardInputLabel } from '../views/Wizard/components';
import { WizardRequiredInputLabel } from '../views/Wizard/components/library';
import { RadioButtonWithLabel } from './';
interface YesNoPicker {
  title: string;
  defaultValue?: YesNo;
  name: string;
  required?: boolean;
  error?: boolean;
  register: () => Ref<HTMLInputElement>;
}
type YesNo = 'yes' | 'no';

export const YesNoPicker: React.FC<YesNoPicker> = ({
  defaultValue,
  title,
  name,
  error = false,
  required = false,
  register,
}) => {
  return (
    <InputContainer>
      {required ? (
        <WizardRequiredInputLabel error={error}>{title}</WizardRequiredInputLabel>
      ) : (
        <WizardInputLabel>{title}</WizardInputLabel>
      )}
      <RadioButtonsContainer>
        <RadioButtonWithLabel
          label="Yes"
          name={name}
          value="yes"
          defaultChecked={defaultValue === 'yes'}
          register={register}
          required={required}
        />
        <RadioButtonWithLabel
          defaultChecked={defaultValue === 'no'}
          label="No"
          name={name}
          value="no"
          register={register}
          required={required}
        />
      </RadioButtonsContainer>
    </InputContainer>
  );
};

const RadioButtonsContainer = styled.div`
  display: flex;
  margin-top: 1.4rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1.6rem;
`;
