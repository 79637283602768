import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import palette from 'theme/palette';

export const StyledNavLink = styled(RouterNavLink)`
  color: ${palette.gray300};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }

  svg {
    color: ${palette.gray300};
    fill: ${palette.gray300};
    height: 3rem;
    width: 3rem;
  }

  &.active {
    color: ${palette.alpha500Red};

    svg {
      fill: ${palette.alpha500Red};
    }
  }
`;

export const StyledFooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  height: 7.6rem;

  background-color: ${palette.white};

  text-align: center;
`;

export const StyledIcon = styled.img`
  height: 3rem;
  width: 3rem;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
`;
