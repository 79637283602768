import React from 'react';

import { ErrorToastMessage } from 'components';
import { toast, PositionOptions } from 'react-toastify';

export const DEFAULT_MESSAGE = 'Error :( Please try again';

export const notify = (toastId: string, message: string, position?: PositionOptions | null) => {
  toast(<ErrorToastMessage message={message} />, {
    className: 'error-toast-black',
    progressClassName: 'error-toast-progress',
    position: position || toast.POSITION.BOTTOM_RIGHT,
    toastId: toastId,
  });
};
