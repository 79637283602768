import React from 'react';

import { StudentViewPaths } from '../../StudentViewRoutes';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { Tag } from '../library';
import { CardTitle, TopEditButton, TagContainer, EmptyContainer, Icon, Text } from './TechnicalLanguages.styled';

interface TechnicalLanguage {
  computer_language_id: string;
  language: string;
}

export interface ProfessionalDesignationsProps {
  languages: TechnicalLanguage[];
  fullProfile?: boolean;
  handleEdit: () => void;
}

const ProfessionalDesignations: React.FC<ProfessionalDesignationsProps> = ({
  languages,
  fullProfile = false,
  handleEdit,
}) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Technical Languages {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>
      {languages && languages?.length > 0 && (
        <TagContainer>
          {languages?.map((language) => (
            <Tag key={language.computer_language_id}>{language?.language}</Tag>
          ))}
        </TagContainer>
      )}
      {(!languages || languages?.length === 0) && (
        <EmptyContainer>
          <Icon />
          <Text>No Technical Languages</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default ProfessionalDesignations;
