import React from 'react';
import { TagsContainers, Row, DescriptionContainer, Container } from './CompanyListCard.styled';
import { companyPath } from '../../StudentViewRoutes';
import { GetCompaniesListPartsFragment } from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import { Text } from 'theme/typography';

import { Info, AvatarIcon } from '../../JobsList/JobCard/JobCard.styled';
import { Badge } from 'components/Badge';
import { Apple } from 'theme/assets';
import { palette } from 'theme';

const CompanyListCard: React.FC<GetCompaniesListPartsFragment> = ({
  name,
  address,
  description,
  company_id,
  logo,
  subindustries,
}) => {
  const tags = subindustries.map(({ value }) => ({ text: value }));
  const history = useHistory();
  return (
    <Container onClick={() => history.push(companyPath(company_id))}>
      <Row>
        <AvatarIcon src={logo || Apple} />
        <Info>
          <Text variant="l" bold>
            {name}
          </Text>
          <Text variant="m" color={palette.gray900}>
            {address}
          </Text>
        </Info>
      </Row>
      <TagsContainers>
        {tags.map((tag) => (
          <Badge key={tag.text} variant="solid" size="medium" color="beta">
            {tag.text}
          </Badge>
        ))}
      </TagsContainers>
      <DescriptionContainer>
        <Text variant="s" color={palette.gray400}>
          {description}
        </Text>
      </DescriptionContainer>
    </Container>
  );
};

export default CompanyListCard;
