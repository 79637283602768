import styled from 'styled-components';
import { palette } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > * {
    margin-bottom: 0.8rem;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: pre-wrap;
`;

export const Title = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  align-items: center;
  margin-right: 0.2rem;
`;

export const Elements = styled.span`
  font-family: 'Roboto';
  color: ${palette.beta700gray};
  font-size: 1.4rem;
  line-height: 1.8rem;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.8rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
