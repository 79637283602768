import React from 'react';
import { Content, UploaderTitle, TitleContainer, UploaderContainer } from './ResumeUploader.styled';
import { Title, SubTitle } from '../Wizard.styled';
import ResumeDnd from './ResumeDnd';
interface ResumeUploaderProps {
  file: string | null;
  setFile: (value: File | null) => void;
  setNewFileName: (value: string) => void;
  loading: boolean;
  withoutTitle?: boolean;
}

const ResumeUploader: React.FC<ResumeUploaderProps> = ({
  setFile,
  file,
  setNewFileName,
  loading,
  withoutTitle = false,
}) => {
  return (
    <Content>
      {!withoutTitle && (
        <TitleContainer>
          <Title required>Final Step: Upload Your Resume!</Title>
          <SubTitle>You will always be able to edit or change your CV in your profile.</SubTitle>
        </TitleContainer>
      )}
      <UploaderContainer>
        <UploaderTitle>{!loading && 'Upload Resume:'}</UploaderTitle>
        <ResumeDnd loading={loading} setFile={setFile} file={file} setNewFileName={setNewFileName} />
      </UploaderContainer>
    </Content>
  );
};

export default ResumeUploader;
