import React, { useState, useEffect, useCallback } from 'react';
import { palette } from 'theme';
import { Controller, FormContextValues } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { mergeStyles } from 'react-select';
import { selectorStyle } from 'theme';
import { OptionId } from './Option';

const SEARCH_TIMEOUT_LENGTH = 400;

export interface QuerySelectorCreateProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultOptionValue: OptionId | null;
  defaultOptions?: OptionId[];
  loading?: boolean;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
  debounceTime?: number;
  error?: boolean;
  onSearch: (searchTerm: string) => void;
}

const QuerySelectorCreate: React.FC<QuerySelectorCreateProps> = ({
  defaultOptionValue = null,
  defaultOptions = [],
  name,
  loading = false,
  placeholder = 'Select',
  control,
  required = false,
  isClearable = false,
  onSearch,
  error = false,
  debounceTime = SEARCH_TIMEOUT_LENGTH,
}) => {
  const overrideStyles = {
    control: (base: object) => ({
      ...base,
      height: '4.1rem',
      minHeight: '4.1rem',
      border: ` 1px solid ${error ? palette.alpha500Red : palette.gray200}`,
    }),
    container: (base: any) => ({
      ...base,
      marginBottom: error ? '0.8rem' : '1.6rem',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray900,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray400,
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);
  const [searchTimer, setSearchTimer] = useState<number | null>(null);
  const [localLoading, setLocalLoading] = useState(loading);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading]);

  useEffect(() => {
    return () => {
      if (searchTimer !== null) {
        clearTimeout(searchTimer);
      }
    };
  }, [searchTimer]);

  const onChange = useCallback((newSelectedOption: OptionId, action: object) => {
    if (!newSelectedOption) {
      return [];
    }

    return [newSelectedOption, action];
  }, []);

  const onInputChange = useCallback(
    (displayValue: string, { action }: { action: string }) => {
      if (action === 'set-value' || action === 'input-blur' || action === 'menu-close') {
        setLocalLoading(false);
        if (searchTimer !== null) {
          clearTimeout(searchTimer);
        }
        return;
      }

      setLocalLoading(true);
      const timerId = window.setTimeout(() => {
        setLocalLoading(loading);
        onSearch(displayValue);
      }, debounceTime);
      setSearchTimer(timerId);
    },
    [searchTimer, debounceTime, loading, onSearch],
  );

  const onMenuClose = () => {
    setLocalLoading(false);
  };

  const onMenuOpen = useCallback(() => {
    if (isFirstSearch && searchTimer === null && defaultOptions.length === 0) {
      setIsFirstSearch(false);
      onSearch('');
    }
  }, [isFirstSearch, searchTimer, defaultOptions, onSearch]);

  return (
    <Controller
      as={
        <CreatableSelect
          isSearchable
          isLoading={localLoading}
          options={defaultOptions}
          placeholder={placeholder}
          isClearable={isClearable}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          styles={selectorOwnStyles}
        />
      }
      defaultValue={[defaultOptionValue]}
      name={name}
      control={control}
      rules={{
        validate: ([value]) => {
          return !required || (value !== null && value !== undefined);
        },
      }}
      onChange={([selectedOption, action]: [OptionId, object]) => onChange(selectedOption, action)}
    />
  );
};

export default QuerySelectorCreate;
