import React from 'react';
import EventList from './EventList';
import { MainViewLayout } from 'components';

const EventListView: React.FC = () => {
  return (
    <MainViewLayout>
      <EventList />
    </MainViewLayout>
  );
};

export default EventListView;
