import styled from 'styled-components';
import palette from 'theme/palette';
import { TabStyles } from './Tab';
import { TAB_STYLES } from './Tab.constants';

export const Container = styled.div<{ variant: TabStyles; color?: string; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 6rem;
  min-height: 4rem;
  padding: 0.3rem 1rem;

  border: 1px solid ${palette.beta300gray};
  border-radius: ${({ variant }) => TAB_STYLES[variant].borderRadius};
  background-color: ${({ color, active }) => (color || !active ? palette.beta300gray : palette.white)};

  cursor: pointer;
`;
