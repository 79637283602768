import React from 'react';
import { palette } from 'theme';
import styled from 'styled-components';

const GPALabel = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.black};
`;

const GPAValue = styled.span`
  margin-left: 0.6rem;
  font-family: 'RobotoBold';
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${palette.black};
`;

const GPAOutOf = styled.span`
  font-family: 'RobotoBold';
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${palette.black};
`;

interface CandidateGPADisplayProps {
  gpa?: number | null;
  gpaOutOf?: number | null;
  gpaNotApplicable?: boolean | null;
  big?: boolean;
}

const CandidateGPADisplay: React.FC<CandidateGPADisplayProps> = ({ gpa, gpaOutOf, gpaNotApplicable }) => {
  return (
    <>
      {gpaNotApplicable ? (
        <>
          <GPALabel>GPA</GPALabel>
          <GPAValue> N/A</GPAValue>
        </>
      ) : (
        <>
          <GPALabel>GPA</GPALabel>
          <GPAValue>{gpa}</GPAValue>
          <GPAOutOf>{`/${gpaOutOf}`}</GPAOutOf>
        </>
      )}
    </>
  );
};

export default CandidateGPADisplay;
