import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  margin-top: 1.5rem;
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  padding-left: 3rem;
  border-bottom: 1px solid ${palette.beta300gray};

  @media (max-width: ${MEDIA_SIZE.md}) {
    border: none;
    justify-content: center;
    padding-left: 0;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  margin-right: 1.2rem;
  background-color: ${({ active }) => (active ? palette.white : palette.beta300gray)};
  color: ${({ active }) => (active ? palette.alpha500Red : palette.black)};
  font-family: ${({ active }) => (active ? 'RobotoBold' : 'Roboto')};
  color: ${({ active }) => (active ? palette.alpha500Red : palette.black)};
  border: 1px solid ${palette.beta300gray};
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  border-bottom: none;

  @media (max-width: ${MEDIA_SIZE.md}) {
    justify-content: center;
    border-radius: 100px;
    text-align: center;
    border-bottom: 1px solid ${palette.beta300gray};
    margin-right: 1.5rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 1.6rem 4rem;
  height: 100%;
  width: 100%;
  word-break: break-word;

  @media (max-width: ${MEDIA_SIZE.md}) {
    border: 1px solid ${palette.beta300gray};
    box-sizing: border-box;
    border-radius: 13px;
    background: ${palette.white};
    width: 90%;
    margin: 1.5rem auto;
    padding: 1.6rem 1.5rem;
  }
`;

export const AvatarIcon = styled.img`
  width: 5.8rem;
  height: 5.8rem;
  border: 1px solid ${palette.beta300gray};
  border-radius: 100%;
  margin-right: 1rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  & > span {
    margin-bottom: 0.3rem;
  }
`;

export const CompanySection = styled.div`
  display: flex;
  margin: 1.2rem 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > * {
    margin-right: 0.7rem;
  }
  @media (max-width: ${MEDIA_SIZE.md}) {
    flex-wrap: wrap;

    & > * {
      margin-bottom: 0.7rem;
    }
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
`;

export const CompanyContactInfoContainer = styled.div`
  display: flex;
  padding-left: 1.6rem;
  margin-top: 3rem;

  & > * {
    margin-right: 3.4rem;
  }
  @media (max-width: ${MEDIA_SIZE.md}) {
    flex-direction: column;
    padding-left: 0;
  }
`;

export const CompanyContactColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    margin-bottom: 1.2rem;
  }

  @media (max-width: ${MEDIA_SIZE.md}) {
    margin-top: 1.5rem;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.gray50};
  padding: 1rem;
  max-width: 24rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContactRow = styled.div`
  display: flex;
  margin: 0.3rem 0;
  align-items: center;
  & > *:first-child {
    margin-right: 0.5rem;
  }
`;

export const CompanyWebsite = styled.a`
  line-height: 1.6rem;
`;
