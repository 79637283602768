import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import { Spinner } from 'components';

import JobDetail from './JobDetail';
import { Container } from './JobDetail/JobDetail.styled';
import { useUserHasResume } from '../../../hooks';
import {
  useGetJobPostingsDetailQuery,
  useGetCompanyByJobPostingDetailQuery,
  useInsertRisehandMutation,
  useDeleteRisehandMutation,
} from 'generated/graphql';

import { MainViewLayout } from 'components';
import { JobDetailHeader as Header } from './JobDetailHeader';
import { useDeleteBookmarkJobMutationHook, useInsertBookmarkJobMutationHook } from '../JobsList/JobList/JobList.hooks';
import { toast } from 'react-toastify';
import {
  ToastDiv,
  ToastTitleContainer,
  ToastTitle,
} from '../Event/EventDetail/EventDetailHeader/EventDetailHeader.styled';

const JobController: React.FC = () => {
  const { user } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const getPostingQuery = { userId: user.sub, dynamicQuery: { job_posting_id: { _eq: id } } };

  const { loading, error, data } = useGetJobPostingsDetailQuery({
    skip: !id,
    variables: getPostingQuery,
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { data: dataCompany, loading: loadingCompany } = useGetCompanyByJobPostingDetailQuery({
    skip: !data?.recruit_job_posting[0].account_id,
    variables: {
      accountId: data?.recruit_job_posting[0].account_id || '',
    },
  });

  const {
    job_posting_id = '',
    region = '',
    position_title = '',
    job_type = '',
    description = '',
    raise_hands = [],
    bookmark_jobs = [],
    deadline,
  } = (data && data.recruit_job_posting[0]) || {};

  const userHasResume = useUserHasResume();

  const [insertBookmark] = useInsertBookmarkJobMutationHook(job_posting_id, bookmark_jobs, user.sub);

  const [deleteBookmark] = useDeleteBookmarkJobMutationHook(job_posting_id, bookmark_jobs, user.sub);

  const [raiseHandMutation] = useInsertRisehandMutation({
    variables: { jobId: job_posting_id, userId: user.sub },
    refetchQueries: ['GetJobPostingsList', 'GetJobPostingsDetail'],
  });

  const [lowerHandMutation] = useDeleteRisehandMutation({
    variables: { jobId: job_posting_id, userId: user.sub },
    refetchQueries: ['GetJobPostingsList', 'GetJobPostingsDetail'],
  });

  if (loading || loadingCompany) return <Spinner />;
  if (!id || !data || error) return <div>Error</div>;

  const notifyError = () =>
    toast.error(
      <ToastDiv>
        <ToastTitleContainer>
          <ToastTitle>You need to upload a resume</ToastTitle>
        </ToastTitleContainer>
      </ToastDiv>,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      },
    );

  const onRisehand = () => {
    if (!userHasResume) {
      notifyError();
    } else {
      raise_hands.length > 0 ? lowerHandMutation() : raiseHandMutation();
    }
  };

  const onBookmark = () => (bookmark_jobs.length > 0 ? deleteBookmark() : insertBookmark());

  return (
    <MainViewLayout>
      <Container>
        <Header />
        <JobDetail
          jobName={position_title}
          jobType={job_type}
          deadline={deadline}
          location={region}
          risedHand={raise_hands.length > 0}
          setRisedHand={onRisehand}
          bookmarked={bookmark_jobs.length > 0}
          setBookmarked={onBookmark}
          description={description}
          company={dataCompany}
        />
      </Container>
    </MainViewLayout>
  );
};

export default JobController;
