import {
  GetProfileByUserIdDocument,
  useDeleteMyWorkMutation,
  useInsertMyWorkMutation,
  GetProfileByUserIdQuery,
} from 'generated/graphql';

export const useDeleteMyWorkMutationHook = (userId: string) => {
  return useDeleteMyWorkMutation({
    errorPolicy: 'all',
    update: (cache, { data: deleteResultData }) => {
      const myProfileData = cache.readQuery<GetProfileByUserIdQuery>({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
      });

      if (!myProfileData || !deleteResultData?.delete_grad_work_experience) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const deleteId = deleteResultData.delete_grad_work_experience.returning[0].work_experience_id;
      const newMyGradProfile = {
        ...oldGradProfile,
        work_experiences: (oldGradProfile.work_experiences || []).filter(
          ({ work_experience_id }) => work_experience_id !== deleteId,
        ),
      };

      cache.writeQuery({
        query: GetProfileByUserIdDocument,
        variables: { userId },
        data: { grad_profile: [newMyGradProfile] },
      });
    },
  });
};
export const useInsertMyWorkMutationHook = (userId: string) => {
  return useInsertMyWorkMutation({
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetProfileByUserIdQuery>({
        query: GetProfileByUserIdDocument,
        variables: {
          userId,
        },
      });

      if (!myProfileData || !insertResultData?.insert_grad_work_experience) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const newMyGradProfile = {
        ...oldGradProfile,
        work_experiences: [
          ...oldGradProfile.work_experiences,
          ...insertResultData.insert_grad_work_experience.returning,
        ],
      };

      cache.writeQuery({
        query: GetProfileByUserIdDocument,
        variables: { userId },
        data: { grad_profile: [newMyGradProfile] },
      });
    },
  });
};
