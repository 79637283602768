import { Auth0Lock } from 'auth0-lock';

interface AuthParamsType {
  scope: string;
  state: string;
  audience: string;
  response_type: string;
  response_mode: string;
  nonce: string;
  code_challenge: string;
  code_challenge_method: string;
  auth0Client: string;
}

export const AUTH_PARAMS: AuthParamsType = {
  scope: '',
  state: '',
  audience: '',
  response_type: '',
  response_mode: '',
  nonce: '',
  code_challenge: '',
  code_challenge_method: '',
  auth0Client: '',
};

export const getAuthParams = (urlString: string): AuthParamsType => {
  const url = new URL(urlString);

  const authParams = Object.fromEntries(
    Object.entries(AUTH_PARAMS).map(([key]) => [[key], url.searchParams.get(key) || '']),
  );

  return authParams;
};

export const generateAuth0Lock = (
  authParams: AuthParamsType,
  nextPageUri: string,
  allowLogin?: boolean,
  container?: string,
) => {
  const redirectUrl = window.location.protocol + '//' + window.location.host + nextPageUri;

  return new Auth0Lock(process.env.REACT_APP_CLIENT_ID || '', process.env.REACT_APP_DOMAIN || '', {
    auth: {
      audience: authParams.audience,
      responseType: authParams.response_type,
      responseMode: authParams.response_mode,
      redirectUrl,
      params: {
        audience: authParams.audience,
        scope: authParams.scope,
        state: authParams.state,
        nonce: authParams.nonce,
        protocol: 'oauth2',
        code_challenge: authParams.code_challenge,
        code_challenge_method: authParams.code_challenge_method,
        auth0Client: authParams.auth0Client,
      },
    },
    container: container || 'lock-container',
    languageDictionary: {
      title: 'Elitegrad',
      signUpTerms: `<span>By signing up, you agree to our <a href="https://elitegrad.com/terms-and-conditions/" target="_blank">terms of service and privacy policy</a></span><div></br><a href="${redirectUrl}">Already signed up? Log in here</a></div>.`,
    },
    closable: false,
    defaultADUsernameFromEmailPrefix: false,
    mustAcceptTerms: true,
    socialButtonStyle: 'small',
    allowLogin: Boolean(allowLogin),
    allowSignUp: Boolean(!allowLogin),
    loginAfterSignUp: Boolean(!allowLogin),
  });
};

export const hasAllAuthParams = (authParams: AuthParamsType) => Object.values(authParams).every(Boolean);
