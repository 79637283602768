import React, { useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import { createConcentrationOptions, StudentsProfileLayout } from 'components';
import { createOptionId, OptionId } from 'components/Option';
import DegreeDetail, { DegreeFormVariables } from './DegreeDetail';

import { GradAward, useGetDegreeQuery, useInsertFillUniversityMutation } from 'generated/graphql';
import {
  useDeleteAwardsMutationHook,
  useDeleteConcentrationsMutationHook,
  useDeleteDegreeMutationHook,
  useInsertAwardsMutationHook,
  useInsertConcentrationsMutationHook,
  useInsertDegreeMutationHook,
  useUpdateDegreeMutationHook,
} from './DegreeDetail.hooks';

import { AwardsTypes } from 'data/awards';

import { ConcentrationTypes } from 'data/degree';
import { prepareConcentrationForUpsert } from 'components/MajorMinorSelector/concentrationUtils';

const DegreeDetailController: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    user: { sub: myUserId },
  } = useAuth0();

  const [insertDegree] = useInsertDegreeMutationHook(myUserId);
  const [updateDegree] = useUpdateDegreeMutationHook(myUserId);
  const [deleteMyDegree] = useDeleteDegreeMutationHook(myUserId, id);
  const [insertAwards] = useInsertAwardsMutationHook(id);
  const [deleteAwards] = useDeleteAwardsMutationHook(id);
  const [insertConcentrations] = useInsertConcentrationsMutationHook(id);
  const [deleteConcentrations] = useDeleteConcentrationsMutationHook(id);

  const [insertFillUniversity] = useInsertFillUniversityMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { loading: loadingMyDegree, error: errorMyDegree, data: dataMyDegree } = useGetDegreeQuery({
    skip: !id,
    variables: { userId: id },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const defaultDegreeValues = {
    university: { university_selection_id: '', location_name: '' },
    complete_year: null,
    complete_month_numeric: null,
    start_year: null,
    start_month_numeric: null,
    degree_level: '',
    gpa: 0,
    gpa_outof: null,
    degree: '',
    awards: [],
    concentrations: [],
    gpa_not_applicable: false,
    fill_in_university: { institution_name: '' },
  };

  const degreeDetails = dataMyDegree?.grad_degree[0] || defaultDegreeValues;

  const {
    concentrations,
    university = defaultDegreeValues.university,
    complete_year,
    complete_month_numeric,
    start_year,
    start_month_numeric,
    gpa,
    gpa_outof,
    degree = defaultDegreeValues.degree,
    degree_level = defaultDegreeValues.degree_level,
    gpa_not_applicable = defaultDegreeValues.gpa_not_applicable,
    fill_in_university = defaultDegreeValues.fill_in_university,
  } = degreeDetails;

  const universityName = university?.location_name || '';
  const universityId = university?.university_selection_id || '';
  const fillInUniversity = fill_in_university?.institution_name || '';

  const majorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MAJOR);
  const minorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MINOR);

  const academicHonors: GradAward = (degreeDetails.awards.length ? degreeDetails.awards : []).filter(
    (award: GradAward) => award.award_category === AwardsTypes.ACADEMIC_HONOR,
  )[0];

  const academicHonorsOption = useMemo(
    () => (academicHonors ? createOptionId(academicHonors.award_name, academicHonors.award_id) : null),
    [academicHonors],
  );

  let persistedDegreeId = dataMyDegree?.grad_degree[0].degree_id || null;

  const onDelete = async () => {
    await deleteMyDegree();
    history.goBack();
  };

  const onSubmit = async (data: DegreeFormVariables) => {
    const {
      gradEndMonth,
      gradEndYear,
      gradStartMonth,
      gradStartYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA,
      gpaOutOf,
      academicHonors: { optionValue: academicHonorsOption, deletedOptionId: deletedAcademicHonorsId },
      academicHonorsYearReceived,
      majors: { optionsValue: optionsMajors, deletedOptionsIds: deletedMajorsIds },
      minors: { optionsValue: optionsMinors, deletedOptionsIds: deletedMinorsIds },
      degreeSelectionOption: [{ degree, degree_level }],
      gpaNotApplicable,
    } = data;

    const variables = {
      gradEndMonth: gradEndMonth.value,
      gradEndYear: gradEndYear.value,
      gradStartMonth: gradStartMonth.value,
      gradStartYear: gradStartYear.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degree,
      degreeLevel: degree_level,
      gpaNotApplicable,
    };

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (!persistedDegreeId) {
      const insertDegreeResult = await insertDegree({ variables: { myUserId, ...variables } });
      persistedDegreeId =
        insertDegreeResult && insertDegreeResult.data && insertDegreeResult.data.insert_grad_degree
          ? insertDegreeResult.data.insert_grad_degree.returning[0].degree_id
          : null;
    } else {
      await updateDegree({ variables: { degreeId: id, ...variables } });
    }

    if (persistedDegreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId: persistedDegreeId, userId: myUserId };
        await insertFillUniversity({ variables: fillInUniversity });
      } else {
      }

      if (academicHonorsOption) {
        const academicHonorsForUpsert = {
          award_category: AwardsTypes.ACADEMIC_HONOR,
          award_name: academicHonorsOption.value,
          year_received: academicHonorsYearReceived.value,
          degree_id: persistedDegreeId,
          user_id: myUserId,
          ...(academicHonorsOption.id ? { award_id: academicHonorsOption.id } : {}),
        };

        await insertAwards({ variables: { awards: [academicHonorsForUpsert] } });
      }

      const majorsForUpsert = prepareConcentrationForUpsert(
        optionsMajors,
        persistedDegreeId,
        myUserId,
        id,
        ConcentrationTypes.MAJOR,
      );
      const minorsForUpsert = prepareConcentrationForUpsert(
        optionsMinors,
        persistedDegreeId,
        myUserId,
        id,
        ConcentrationTypes.MINOR,
      );
      const concentrationsForUpsert = [...majorsForUpsert, ...minorsForUpsert];

      if (concentrationsForUpsert)
        await insertConcentrations({ variables: { concentrations: concentrationsForUpsert } });

      if (deletedAcademicHonorsId) {
        await deleteAwards({ variables: { ids: [deletedAcademicHonorsId] } });
      }

      const deleteConcentrationsIds = [...(deletedMajorsIds || []), ...(deletedMinorsIds || [])];
      if (deleteConcentrationsIds) {
        await deleteConcentrations({ variables: { ids: deleteConcentrationsIds } });
      }
    }

    history.goBack();
  };

  const onCancel = () => history.goBack();

  const isLoading = loadingMyDegree || Boolean(id && (!dataMyDegree || !dataMyDegree.grad_degree.length));
  return (
    <StudentsProfileLayout loading={isLoading} hasError={Boolean(errorMyDegree)}>
      <DegreeDetail
        onSubmit={onSubmit}
        onCancel={onCancel}
        fillInUniversity={fillInUniversity}
        onDelete={onDelete}
        persistedDegreeId={persistedDegreeId}
        degree_level={degree_level}
        degree={degree}
        academicHonors={academicHonors}
        universityName={universityName}
        universityId={universityId}
        academicHonorsOption={academicHonorsOption}
        minorOptions={minorOptions}
        gpa={gpa || null}
        majorOptions={majorOptions}
        start_month_numeric={start_month_numeric}
        complete_month_numeric={complete_month_numeric}
        start_year={start_year}
        complete_year={complete_year}
        gpa_outof={gpa_outof}
        gpa_not_applicable={gpa_not_applicable}
      />
    </StudentsProfileLayout>
  );
};

export default DegreeDetailController;
