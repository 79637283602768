import React from 'react';
import EventDetail from './EventController';
import { MainViewLayout } from 'components';

const Event: React.FC = () => {
  return (
    <MainViewLayout>
      <EventDetail />
    </MainViewLayout>
  );
};

export default Event;
