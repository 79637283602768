import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import SpinnerPage from 'components/SpinnerPage';
import ProfileStep, { ProfilePersonalFormVariables } from './ProfileStep';
import { notify } from 'utils/notify';
import { OptionIdStrict } from 'components';
import {
  useGetProfileStepProfileQuery,
  useUpdateProfileStepProfileMutation,
  useUpdateRelatedProfileStepProfileMutation,
} from 'generated/graphql';

import { WizardProps } from '../components/interface';
import { SUBMIT_GO_TO, CANCEL_GO_TO } from '../components';

const DEFAULT_VALUES = {
  fullName: '',
  firstname: '',
  lastname: '',
  mobile: '',
  hometown: '',
  comm_preference: '',
  will_relocate: false,
  need_sponsor: false,
  awards: [],
  fun_facts: [],
  interest_regions: [],
};

const ProfileStepContainer: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  step,
  totalSteps,
  backGoTo,
}) => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: getProfileLoading, data: getProfileData } = useGetProfileStepProfileQuery({
    fetchPolicy: 'network-only',
    variables: { myUserId: user.sub },
  });

  const [updateGradProfile] = useUpdateProfileStepProfileMutation();
  const [updateRelatedProfile, { loading: updateRelatedProfileLoading }] = useUpdateRelatedProfileStepProfileMutation();

  if (getProfileLoading || updateRelatedProfileLoading) return <SpinnerPage />;

  const myInfo = getProfileData?.grad_profile?.[0] || DEFAULT_VALUES;

  const getEraseOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const currentRegions = myInfo?.interest_regions.map(
    ({ region, interest_region_id }: { region: string; interest_region_id: string }) => ({
      value: region,
      label: region,
      id: interest_region_id,
    }),
  );

  const onSubmit = async (data: ProfilePersonalFormVariables) => {
    const {
      firstName,
      lastName,
      mobile,
      citySelectionOption,
      interestRegions,
      willRelocate,
      commPreference,
      needSponsor,
    } = data;
    const fullName = `${firstName} ${lastName}`;
    const isWillingToRelocate = willRelocate === 'yes' ? true : false;
    const userNeedsSponsor = needSponsor === 'yes' ? true : false;

    const eraseRegions = getEraseOptions(interestRegions, currentRegions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addRegions = getAddOptions(interestRegions, currentRegions).map<{ region: string; user_id: string }>(
      ({ value }) => ({
        region: value,
        user_id: user.sub,
      }),
    );

    const variables = {
      userId: user.sub,
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      mobile: mobile,
      hometown: citySelectionOption?.[0]?.value || '',
      willRelocate: isWillingToRelocate,
      commPreference: commPreference?.value,
      needSponsor: userNeedsSponsor,
    };

    const updateProfile = async () => {
      await updateRelatedProfile({
        variables: {
          regionsToUpsert: addRegions,
          regionsToDelete: eraseRegions,
        },
      });
      history.push(nextGoTo);
    };

    if (getProfileData && getProfileData.grad_profile[0]) {
      try {
        await updateGradProfile({ variables });
        await updateProfile();
      } catch (error) {
        const queryToastId = 'ProfileStepError';
        notify(
          queryToastId,
          'Something went wrong. Please try again or contact support team for assistance',
          'top-center',
        );
      }
    }
  };

  return (
    <ProfileStep
      onSubmit={onSubmit}
      mobile={myInfo.mobile || ''}
      firstName={myInfo.firstname || ''}
      hometown={myInfo.hometown || ''}
      lastName={myInfo.lastname || ''}
      step={step}
      backGoTo={backGoTo || ''}
      isLastStep={isLastStep}
      totalSteps={totalSteps}
      currentRegions={currentRegions}
      commPreference={myInfo.comm_preference || ''}
      willRelocate={myInfo.will_relocate || false}
      needSponsor={myInfo.need_sponsor || false}
    />
  );
};

export default ProfileStepContainer;
