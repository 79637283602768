import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { palette } from 'theme';
import { ReactComponent as Edit } from 'theme/assets/edit-solid.svg';
import { ReactComponent as Plus } from 'theme/assets/plus-solid.svg';

const StyledIconNavLink = styled(NavLink)`
  width: 2.4rem;
  height: 2.4rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: ${palette.gray};

  :hover {
    cursor: pointer;
  }
`;

const StyledButtonEdit = styled.button`
  outline: none;
  border: none;
  background: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  padding: 0;
  color: ${palette.gray};

  :hover {
    cursor: pointer;
  }
`;

const StyledEditButton = styled(StyledIconNavLink)`
  position: absolute;
  top: 0rem;
  right: 0rem;
`;

const StyledEdit = styled(Edit)`
  height: 2.4rem;

  vertical-align: middle;
`;

const StyledPlus = styled(Plus)`
  height: 2.4rem;

  vertical-align: middle;
`;

export const EditButton: React.FC<{ to?: string; onClick: () => void }> = ({ to, ...props }) => (
  <StyledEditButton to={to || ''} {...props}>
    <StyledEdit />
  </StyledEditButton>
);

export const EditButtonModal = ({ ...props }) => (
  <StyledButtonEdit {...props}>
    <StyledEdit />
  </StyledButtonEdit>
);

export const NewButton: React.FC<{ to: string }> = ({ to, ...props }) => (
  <StyledEditButton to={to} {...props}>
    <StyledPlus />
  </StyledEditButton>
);

export const NewButtonModal = ({ ...props }) => (
  <StyledButtonEdit {...props}>
    <StyledPlus />
  </StyledButtonEdit>
);

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.gray500};
  border-radius: 4px;
  padding: 0.5rem;
  font-family: 'Roboto';
  font-size: 1.2rem;
  color: ${palette.white};
  height: 2.4rem;
`;
