import React from 'react';
import { RemovePhotoButton } from './DeletePicture.styled';
interface DeletePictureProps {
  onDelete: () => void;
}

const DeletePicture: React.FC<DeletePictureProps> = ({ onDelete }) => {
  return <RemovePhotoButton onClick={onDelete}>Delete photo</RemovePhotoButton>;
};

export default DeletePicture;
