import React from 'react';

import { StyledHeader } from '../../library';
import GoBackLink from '../GoBackLink';

const GoBackHeader: React.FC = () => {
  return (
    <StyledHeader>
      <GoBackLink />
    </StyledHeader>
  );
};

export default GoBackHeader;
