import styled from 'styled-components';
import { palette } from '../../../../theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Industry = styled.h1`
  font-weight: bold;
  color: ${palette.gray};
`;

export const ItemContainer = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${palette.gray};
  &:after {
    content: ': ';
  }
`;

export const TagsContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
  span {
    margin: 0.3rem 0.25rem;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const DescriptionContainer = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid ${palette.beta300gray};
  border-radius: 13px;
  box-sizing: border-box;
  padding: 1.6rem 1.2rem 1rem 1.7rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    min-height: 12rem;
  }
`;
