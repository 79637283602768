import React from 'react';

import { convertMonthToString } from 'utils/dateUtils';
import { StudentViewPaths } from '../../../StudentViewRoutes';
import { StyledCard } from 'components';

import {
  RelevantWorkDetail,
  TopEditButton,
  Company,
  CityState,
  Position,
  WorkDate,
  CurrentTag,
} from './RelevantWorkExperienceCard.styled';
import { WorkExperiencePartsFragment } from 'generated/graphql';

export interface UniversityProps {
  workExperience: WorkExperiencePartsFragment;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const UniversityListCard: React.FC<UniversityProps> = ({ workExperience, fullProfile = false, handleEdit }) => {
  return (
    <StyledCard key={workExperience.work_experience_id}>
      <RelevantWorkDetail>
        <WorkDate>
          {convertMonthToString(workExperience.start_month_numeric)} {workExperience.start_year}
          {(workExperience.end_month_numeric || workExperience.end_year || workExperience.current) && ' - '}
          {workExperience.current ? (
            <CurrentTag>Current</CurrentTag>
          ) : (
            <>
              {workExperience.end_month_numeric && convertMonthToString(workExperience.end_month_numeric)}{' '}
              {workExperience.end_year}
            </>
          )}
        </WorkDate>
        <Company>
          <Position>{`${workExperience.position}`}</Position>
          {workExperience.company}
        </Company>

        <CityState>
          {workExperience.city}
          {workExperience.city && workExperience.state && ', '}
          {workExperience.state}
          {workExperience.state && workExperience.country && ', '}
          {workExperience.country}
        </CityState>
      </RelevantWorkDetail>
      {fullProfile && <TopEditButton onClick={handleEdit}></TopEditButton>}
    </StyledCard>
  );
};

export default UniversityListCard;
