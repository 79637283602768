import React from 'react';
import { WizardInputLabel } from '../../../Wizard/components/library';
import { OptionId, TechnicalLanguageSelector } from 'components';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { Container } from './TechnicalLanguagesEdit.styled';
import { InputLegend } from '../../../Wizard/components';
import { useUpsertLangugesEditMutation } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface TechnicalLanguages {
  computer_language_id: string;
  language: string;
}

export interface TechnicalLanguageEditVariables {
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

interface TechnicalLanguagesEditProps {
  initialTechnicalLanguages?: TechnicalLanguages[];
  setModalOpen: (state: ModalOpenType) => void;
}

const TechnicalLanguagesEditStep: React.FC<TechnicalLanguagesEditProps> = ({
  initialTechnicalLanguages,
  setModalOpen,
}) => {
  const { register, control, handleSubmit, errors, setValue, clearError } = useForm<TechnicalLanguageEditVariables>();
  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [updateTechnicalLanguages] = useUpsertLangugesEditMutation();

  const computerLanguagesOptions = initialTechnicalLanguages?.map(
    ({ language, computer_language_id }: { language: string; computer_language_id: string }) => ({
      value: language,
      label: language,
      id: computer_language_id,
    }),
  );
  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async (formData: TechnicalLanguageEditVariables) => {
    const addComputerLanguages = getAddOptions(formData.computerLanguages?.current, computerLanguagesOptions).map(
      ({ value }) => ({
        language: value,
        user_id: user.sub,
      }),
    );
    const deleteComputerLanguagesIDs = getEraseOptions(formData.computerLanguages?.current, computerLanguagesOptions)
      .map(({ id }) => id)
      .filter(Boolean) as string[];

    const variables = {
      addComputerLanguages,
      deleteComputerLanguagesIDs,
    };

    await updateTechnicalLanguages({ variables, refetchQueries: ['GetProfileByUserId'] });
    setModalOpen('');
  };
  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit(onSubmit)}
      heightAuto={width > mobileBreakpoint}
      title={'Interested Role'}
    >
      <Container>
        <WizardInputLabel>Are you proficient in any technical languages?</WizardInputLabel>
        <TechnicalLanguageSelector
          name="computerLanguages"
          defaultValue={computerLanguagesOptions}
          control={control}
          setValue={setValue}
          register={register}
          error={!!errors.computerLanguages}
          required={false}
        />
        {errors.computerLanguages && (
          <InputLegend error={!!errors.computerLanguages}>Complete this field to move on</InputLegend>
        )}
      </Container>
    </Modal>
  );
};

export default TechnicalLanguagesEditStep;
