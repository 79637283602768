import React, { useState, useEffect } from 'react';
import { FormContextValues } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { mergeStyles } from 'react-select';
import { selectorStyle } from 'theme';
import { OptionId, createOptionId } from './Option';

export interface SelectorCreateProps
  extends Pick<FormContextValues, 'register' | 'setValue' | 'setError' | 'clearError'> {
  defaultOptionValue: OptionId | null;
  dropDownOptions?: OptionId[];
  name: string;
  loading?: boolean;
  placeholder?: string;
  required?: boolean;
  dropDownIndicator?: boolean;
  requiredMessage?: string;
}

const overrideStyles = {
  container: (base: object) => ({
    ...base,
    width: '50%',
  }),
  control: (base: object) => ({
    ...base,
    height: '3.4rem',
    minHeight: '3.4rem',
  }),
};

const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

const SelectorCreateV2: React.FC<SelectorCreateProps> = ({
  defaultOptionValue = null,
  dropDownOptions = [],
  name,
  loading = false,
  placeholder = 'Select...',
  register,
  setError,
  setValue,
  clearError,
  dropDownIndicator = true,
  required = false,
  requiredMessage = 'Select one option',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [optionValue, setOptionValue] = useState<OptionId | null>(defaultOptionValue);

  useEffect(() => {
    register(name);
  }, [name, register]);

  useEffect(() => {
    const sanitizedOption = optionValue
      ? [defaultOptionValue, ...dropDownOptions].filter((o) => o && o.value === optionValue.value)[0] || optionValue
      : null;

    const newOption = sanitizedOption && sanitizedOption.id === undefined ? sanitizedOption : null;

    let deletedOption;

    if (defaultOptionValue && sanitizedOption && defaultOptionValue.id !== sanitizedOption.id) {
      deletedOption = defaultOptionValue;
    } else if (defaultOptionValue && !sanitizedOption) {
      deletedOption = defaultOptionValue;
    }

    const deletedOptionId = deletedOption ? deletedOption.id : null;

    if (required && !optionValue) setError(name, requiredMessage);
    if (required && optionValue) clearError(name);

    setValue(name, { optionValue: sanitizedOption, newOption, deletedOption, deletedOptionId }, true);
  }, [
    defaultOptionValue,
    dropDownOptions,
    optionValue,
    name,
    setValue,
    setError,
    clearError,
    requiredMessage,
    required,
  ]);

  const newOptionFlow = () => {
    if (inputValue !== '') {
      const existentOption = dropDownOptions.filter(({ label }) => label === inputValue)[0];
      const selectedOption = existentOption ? existentOption : createOptionId(inputValue);

      setOptionValue(selectedOption);
      setInputValue('');
    }
  };

  const handleInputChange = (typedValue: string) => setInputValue(typedValue);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        newOptionFlow();
    }
  };

  const selectorConfig = {
    isClearable: true,
    isMulti: false,
    isSearchable: true,
    ...(dropDownOptions && { options: dropDownOptions }),
    ...(!dropDownIndicator && { components: { DropdownIndicator: null } }),
  };

  return (
    <CreatableSelect
      value={optionValue}
      inputValue={inputValue}
      loading={loading}
      placeholder={placeholder}
      onChange={(option) => setOptionValue(option as OptionId)}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      styles={selectorOwnStyles}
      {...selectorConfig}
    />
  );
};

export default SelectorCreateV2;
