import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ModalOpenType from 'types/ModalOpen';
import { InputLegend } from '../../../Wizard/components';
import { WizardInputLabel, WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { SuccessIconTick } from 'theme/assets';
import {
  Container,
  ToastDiv,
  ToastTitle,
  ToastTitleContainer,
  TickIcon,
  CityStateContainer,
  LabelOptionContainer,
} from './WorkExperienceEdit.styled';
import {
  useDeleteWorkExperienceEditMutation,
  useUpdateWorkExperienceEditMutation,
  useInsertWorkExperienceEditMutation,
} from 'generated/graphql';

import Modal from 'components/common/EditProfileModal';
import { MonthYearPicker, StyledInput, StyledCheckboxContainer, CountryPicker, CityPicker } from 'components';

import USAStatePicker from 'components/USAStatesPicker';
import CompanyPicker from 'components/CompanyPicker';
import CheckboxWithController from 'components/CheckboxWithController';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

const USA = { label: 'United States of America', value: '.us' };

interface WorkExperienceProps {
  setModalOpen: (status: ModalOpenType) => void;
  isNew: boolean;
  end_year: number | null;
  end_month_numeric: number | null;
  start_year: number | null;
  start_month_numeric: number | null;
  company: string | null;
  position: string;
  current: boolean;
  country: string | null;
  state: string | null;
  city: string | null;
  work_id: string;
}

export interface WorkExperienceVariables {
  position: string | null;
  startMonth: { value: number; label: string };
  startYear: { value: number; label: string };
  endMonth: { value: number; label: string };
  endYear: { value: number; label: string };
  jobType: string;
  current: boolean;
  country: { value: number; label: string };
  city: { label: string; value: string };
  state: { label: string; value: string };
  notUSACity: string | null;
  notUSAState: string | null;
  companyOption: { value: string; label: string }[];
}

const WorkExperienceController: React.FC<WorkExperienceProps> = ({
  setModalOpen,
  end_year,
  end_month_numeric,
  start_year,
  start_month_numeric,
  company,
  position,
  current,
  country,
  state,
  city,
  work_id,
}) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();

  const [deleteWorkExperience] = useDeleteWorkExperienceEditMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertWorkExperience] = useInsertWorkExperienceEditMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateWorkExperience] = useUpdateWorkExperienceEditMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const onSubmit = async (data: WorkExperienceVariables) => {
    const {
      position,
      startMonth,
      startYear,
      endMonth,
      endYear,
      current,
      country,
      city,
      state,
      notUSACity,
      notUSAState,
      companyOption: [companyOption] = [null],
    } = data;

    const variables = {
      position,
      startMonth: startMonth?.value,
      startYear: startYear?.value,
      endMonth: current ? null : endMonth.value,
      endYear: current ? null : endYear.value,
      current,
      country: country?.label,
      city: city ? city.label : notUSACity,
      state: state ? state.value : notUSAState,
      company: companyOption ? companyOption.value : null,
    };

    if (!work_id) {
      await insertWorkExperience({
        variables: { userID: user.sub, ...variables },
        refetchQueries: ['GetProfileByUserId'],
      });
    } else {
      await updateWorkExperience({
        variables: { workId: work_id, ...variables },
        refetchQueries: ['GetProfileByUserId'],
      });
    }
    setModalOpen('');
  };

  const onDelete = async () => {
    const successContent = (
      <ToastDiv>
        <ToastTitleContainer>
          <TickIcon src={SuccessIconTick} /> <ToastTitle>Item deleted successfully</ToastTitle>
        </ToastTitleContainer>
      </ToastDiv>
    );
    if (work_id) {
      await deleteWorkExperience({ variables: { workId: work_id }, refetchQueries: ['GetProfileByUserId'] }).then(
        () => {
          toast.success(successContent, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        },
      );
    }
    setModalOpen('');
  };

  const { register, control, watch, handleSubmit, errors } = useForm<WorkExperienceVariables>();

  const currentJobPosition = !!watch('current');
  const selectedCountry = watch('country');
  const states = watch('state') as { label: string; value: string } | undefined;

  return (
    <Modal
      setModalOpen={setModalOpen}
      heightAuto={width > mobileBreakpoint}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={onDelete}
      withDelete={Boolean(work_id)}
      title={'Work Experience/Internship'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.position}>Position</WizardRequiredInputLabel>
        <StyledInput
          type="text"
          name="position"
          placeholder="Accountant"
          error={!!errors.position}
          ref={register({ required: true })}
          defaultValue={position || ''}
        />
        {errors.position && <InputLegend error={!!errors.position}>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.companyOption}>Company</WizardRequiredInputLabel>
        <CompanyPicker
          defaultValue={company}
          name="companyOption"
          control={control}
          required
          clearable
          error={!!errors.companyOption}
        />
        {errors.companyOption && (
          <InputLegend error={!!errors.companyOption}>Complete this field to move on</InputLegend>
        )}

        <WizardRequiredInputLabel error={!!errors.startMonth || !!errors.startYear}>
          Started working on:
        </WizardRequiredInputLabel>
        <MonthYearPicker
          required={true}
          control={control}
          monthFieldName="startMonth"
          yearFieldName="startYear"
          errors={errors}
          defaultMonth={start_month_numeric || null}
          defaultYear={start_year || null}
        />

        {!currentJobPosition && (
          <>
            <WizardRequiredInputLabel error={!!errors.endMonth || !!errors.endYear}>
              Ended working on:
            </WizardRequiredInputLabel>
            <MonthYearPicker
              required={!currentJobPosition}
              monthFieldName="endMonth"
              yearFieldName="endYear"
              errors={errors}
              control={control}
              defaultMonth={end_month_numeric || null}
              defaultYear={end_year || null}
            />
          </>
        )}

        <StyledCheckboxContainer>
          <CheckboxWithController name="current" control={control} defaultChecked={!!current} />
          {'Current Position'}
        </StyledCheckboxContainer>

        <WizardRequiredInputLabel error={!!errors.country}>Country</WizardRequiredInputLabel>

        <CountryPicker
          required={true}
          name="country"
          defaultCountryName={country || null}
          defaultCountryId={null}
          control={control}
          errors={!!errors.country}
        />
        {errors.country && <InputLegend error={!!errors.country}>Complete this field to move on</InputLegend>}

        {JSON.stringify(selectedCountry) === JSON.stringify(USA) || country === USA.label ? (
          <CityStateContainer>
            <LabelOptionContainer>
              <WizardInputLabel>City</WizardInputLabel>
              <CityPicker
                name={'city'}
                defaultCityName={city}
                defaultCountryId={null}
                control={control}
                state={states?.value}
                placeholder="City"
                disabled={!states ? true : false}
              />
            </LabelOptionContainer>
            <LabelOptionContainer>
              <WizardInputLabel>State</WizardInputLabel>
              <USAStatePicker
                name={'state'}
                defaultCountryName={state}
                defaultCountryId={null}
                control={control}
                placeholder="State"
              />
            </LabelOptionContainer>
          </CityStateContainer>
        ) : (
          <CityStateContainer>
            <LabelOptionContainer>
              <WizardInputLabel>City</WizardInputLabel>
              <StyledInput
                type="text"
                name="notUSACity"
                placeholder="City"
                ref={register({ required: false })}
                defaultValue={city || ''}
              />
            </LabelOptionContainer>

            <LabelOptionContainer>
              <WizardInputLabel>State/Province</WizardInputLabel>
              <StyledInput
                type="text"
                name="notUSAState"
                placeholder="State"
                ref={register({ required: false })}
                defaultValue={state || ''}
              />
            </LabelOptionContainer>
          </CityStateContainer>
        )}
      </Container>
    </Modal>
  );
};

export default WorkExperienceController;
