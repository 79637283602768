import styled from 'styled-components';
import { palette } from '../../../theme';

export const StyledLabelWrite = styled.div`
  grid-area: labelW;

  color: ${palette.gray};
  font-size: 0.8rem;
`;

export const StyledSatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1rem;
  grid-template-areas:
    'math read write'
    'labelM labelR labelW';

  grid-gap: 0.8rem;

  margin-bottom: 1.4rem;

  & label > input {
    margin-bottom: 0;
  }
`;
