import React from 'react';
import { WizardPage } from '../components';
import { Spacing } from 'components/Spacing';
import { Container, FormContainer, MainText, Button, Title, SmallText, Icon } from './ValidateStep.styled';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useHistory } from 'react-router-dom';
import { PlaneIcon } from 'theme/assets';

interface ValidateStepProps {
  onSubmit: () => void;
  step: number;
  totalSteps: number;
  backGoTo: string;
  nextGoTo: string;
}

const ValidateStep: React.FC<ValidateStepProps> = ({ onSubmit, step, totalSteps, backGoTo }) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  return (
    <WizardPage
      onNext={onSubmit}
      showBackground={false}
      step={step}
      totalSteps={totalSteps}
      onPrevious={() => history.push(backGoTo)}
      showButtons={false}
      hideProgress
      cardHeight={width <= mobileBreakpoint ? '100%' : ''}
    >
      <FormContainer>
        <Container>
          {width <= mobileBreakpoint && <Icon src={PlaneIcon} />}
          <Title>{'Oops!'}</Title>
          <Spacing margin="1.5rem" />
          <MainText>{'Please validate your email.'}</MainText>
          <Spacing margin="1.5rem" />
          <SmallText>{'If you need help in the meantime, email us at: help@elitegrad.com'}</SmallText>
          <Spacing margin="1rem" />
          <Button onClick={onSubmit}>Validate Email</Button>
        </Container>
      </FormContainer>
    </WizardPage>
  );
};

export default ValidateStep;
