import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../library';

export const CardTitle = styled.div`
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  line-height: 1.8rem;
  margin-bottom: 1.2rem;
`;

export const TopEditButton = styled(EditButtonModal)`
  position: relative;
  fill: ${palette.gray500};
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
