import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Title = styled.span<{ required?: boolean }>`
  font-family: 'RobotoBold';
  font-size: 2rem;
  color: ${palette.alpha500Red};
  align-self: center;
  &:after {
    content: '${({ required }) => (required ? ' *' : '')}';
    margin-left: 0.5rem;
  }
`;

export const SubTitle = styled.span`
  font-size: 1.2rem;
  line-height: 130%;
  color: ${palette.gray500};
  white-space: pre-wrap;
  padding-top: 1.3rem;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3.4rem 6rem 0;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: ${MEDIA_SIZE.l}) {
    padding: 2rem 3rem;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 3.4rem 4rem;

  @media (max-width: ${MEDIA_SIZE.l}) {
    padding: 2rem 3rem;
  }
`;
