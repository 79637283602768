import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2.2rem auto 3.1rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIA_SIZE.md}) {
    flex-direction: row;
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    width: 50%;
    align-items: flex-start;
  }
`;
