import React from 'react';
import styled from 'styled-components';

import { palette } from 'theme';
import { ReactComponent as NoPreviousDegree } from 'theme/assets/NoPreviousDegree.svg';

const AcademicHonorTagContainer = styled.div<{ withSeparator?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.3rem 0.4rem 0.2rem 0.3rem;

  border-radius: 4px;
  background-color: ${palette.beta100gray};

  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.gray400};
  max-width: 14rem;
  border-left: ${({ withSeparator }) => (withSeparator ? `1px solid ${palette.gray200}` : 'none')};
  border-radius: ${({ withSeparator }) => (withSeparator ? `0 4px 4px 0` : '4px')};
`;

const IconAcademicHonor = styled(NoPreviousDegree)`
  width: 2rem;
  height: 2rem;

  vertical-align: middle;
`;

const Label = styled.span`
  margin-left: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface AcademicHonorTagProps {
  awardName: string;
  withSeparator?: boolean;
}

const AcademicHonorTag: React.FC<AcademicHonorTagProps> = ({ awardName, withSeparator = false }) => {
  return (
    <AcademicHonorTagContainer withSeparator={withSeparator}>
      <IconAcademicHonor />
      <Label>{awardName}</Label>
    </AcademicHonorTagContainer>
  );
};

export default AcademicHonorTag;
