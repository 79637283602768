import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import StudentWidget from '../StudentWidget';
import { SpinnerPage, MainViewLayout, OptionId } from 'components';
import {
  useGetProfileByUserIdQuery,
  useGetProfileDegreesQuery,
  useGetUploadedDocsForUserQuery,
} from 'generated/graphql';
import { ConcentrationTypes } from 'data/degree';
import { createConcentrationOptions } from 'components/MajorMinorSelector/concentrationUtils';
import { AwardsTypes } from 'data/awards';
import { LeftRow, RightRow, Container } from './MyCard.styled';
import InterestFirmTypes from '../StudentWidget/InterestFirmTypes';
import InterestRoles from '../StudentWidget/InterestRoles';
import Location from '../StudentWidget/Location';
import TechnicalLanguages from '../StudentWidget/TechnicalLanguages';
import StudentInformation from '../StudentWidget/StudentInformation';

import ProfessionalDesignations from '../StudentWidget/ProfessionalDesignations';
import YearsOfExperience from '../StudentWidget/YearsOfExperience';
import RelevantWorkExperience from '../StudentWidget/RelevantWorkExperience';
import PreviousDegrees from '../StudentWidget/PreviousDegrees';
import Resume from '../StudentWidget/Resume';
import { useModelContext } from '../ModalContext/ModalContext';
import InterestRolesEdit from '../Profile/InterestRolesEdit';
import InterestFirmTypesEdit from '../Profile/InterestFirmTypesEdit';
import LocationEdit from '../Profile/LocationEdit';
import TechnicalLanguagesEdit from '../Profile/TechnicalLanguagesEdit';
import StudentInformationEdit from '../Profile/StudentInformationEdit';
import RelevantYearsExperienceEdit from '../Profile/RelevantYearsExperienceEdit';
import ProfessionalDesignationsEdit from '../Profile/ProfessionalDesignationsEdit';
import ResumeEdit from '../Profile/ResumeEdit';
import PreviousDegreeEdit from '../Profile/PreviousDegreeEdit';
import WorkExperienceEdit from '../Profile/WorkExperienceEdit';

const MyCardController: React.FC = () => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const { modalState, setModalState, degreeIndex, workExperienceIndex } = useModelContext();

  const { loading: loadingUniversities, error: errorUniversities, data: dataUniversities } = useGetProfileDegreesQuery({
    skip: !user,
    variables: { userId: user.sub },
  });

  const { loading: loadingUserProfile, error: errorUserProfile, data: userProfile } = useGetProfileByUserIdQuery({
    variables: { userId: user.sub },
  });

  const { loading: loadingResume, data: resumeData } = useGetUploadedDocsForUserQuery({
    errorPolicy: 'all',
    variables: { userId: user.sub },
  });

  const resumeInfo = resumeData?.grad_profile[0]?.upload_docs;

  if (loadingUserProfile || loadingUniversities) return <SpinnerPage />;
  if (errorUserProfile || !userProfile || errorUniversities) return <div>Error</div>;

  const studentProfile = userProfile.grad_profile[0];
  const awards = studentProfile?.awards;
  const degrees = dataUniversities?.grad_degree || [];
  const professionalDesignations = awards
    .filter((award) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION)
    .map(({ award_category, award_id, award_name }) => ({
      award_category: award_category || '',
      award_id,
      award_name,
    }));

  const [currentDegree, ...previousDegrees] = degrees;
  const workExperiences = studentProfile?.work_experiences;
  return (
    <MainViewLayout withRightCard={false}>
      {width > mobileBreakpoint ? (
        <Container>
          <LeftRow>
            <InterestRoles
              interestRoles={studentProfile?.interest_roles}
              fullProfile
              handleEdit={() => setModalState('roles')}
            />
            <InterestFirmTypes
              interestFirmTypes={studentProfile?.interest_firms}
              fullProfile
              handleEdit={() => setModalState('interestFirms')}
            />
            <Location
              interestRegions={studentProfile?.interest_regions}
              willRelocate={studentProfile?.will_relocate || false}
              homeTown={studentProfile?.hometown || ''}
              fullProfile
              handleEdit={() => setModalState('location')}
            />
            <TechnicalLanguages
              languages={studentProfile?.computer_languages}
              fullProfile
              handleEdit={() => setModalState('computerLanguage')}
            />
            <StudentInformation
              email={studentProfile?.email || ''}
              employersPreferences={studentProfile?.employer_preferences}
              communicationMode={studentProfile?.comm_preference || ''}
              phone={studentProfile?.mobile || ''}
              funFacts={studentProfile?.fun_facts}
              languages={studentProfile?.spoken_languages}
              studentAthlete={studentProfile?.student_athlete}
              achievements={studentProfile?.achievements}
              extracurricularActivities={studentProfile?.volunteers}
              handleEdit={() => setModalState('studentInformation')}
              fullProfile
            />
          </LeftRow>
          <RightRow>
            <ProfessionalDesignations
              designations={professionalDesignations}
              fullProfile
              handleEdit={() => setModalState('professionalDesignation')}
            />
            <YearsOfExperience
              years={studentProfile?.years_of_relevant_work || ''}
              fullProfile
              handleEdit={() => setModalState('yearsOfWork')}
            />
            <RelevantWorkExperience workExperiences={studentProfile?.work_experiences} fullProfile />
            <PreviousDegrees degrees={previousDegrees} fullProfile />

            <Resume
              loading={loadingResume}
              fileName={resumeInfo?.[0]?.filename || ''}
              content={resumeInfo?.[0]?.hex_content || ''}
              fullProfile
              handleEdit={() => setModalState('resume')}
            />
          </RightRow>
          {modalState === 'roles' && (
            <InterestRolesEdit setModalOpen={setModalState} interestRolesInitial={studentProfile?.interest_roles} />
          )}
          {modalState === 'interestFirms' && (
            <InterestFirmTypesEdit setModalOpen={setModalState} firmsData={studentProfile?.interest_firms} />
          )}
          {modalState === 'location' && (
            <LocationEdit
              setModalOpen={setModalState}
              initialInterestRegions={studentProfile?.interest_regions}
              willRelocate={studentProfile?.will_relocate || false}
              hometown={studentProfile?.hometown || ''}
            />
          )}
          {modalState === 'computerLanguage' && (
            <TechnicalLanguagesEdit
              setModalOpen={setModalState}
              initialTechnicalLanguages={studentProfile?.computer_languages}
            />
          )}
          {modalState === 'studentInformation' && (
            <StudentInformationEdit
              email={studentProfile?.email || ''}
              setModalOpen={setModalState}
              employersPreferencesInitial={studentProfile?.employer_preferences}
              commPreference={studentProfile?.comm_preference || ''}
              mobile={studentProfile?.mobile || ''}
              funFacts={studentProfile?.fun_facts}
              spokenLanguages={studentProfile?.spoken_languages}
              studentAthlete={studentProfile?.student_athlete}
              achievementsInitial={studentProfile?.achievements}
              volunteers={studentProfile?.volunteers}
            />
          )}
          {modalState === 'yearsOfWork' && (
            <RelevantYearsExperienceEdit
              setModalOpen={setModalState}
              yearsOfRelevantWork={studentProfile?.years_of_relevant_work || null}
            />
          )}
          {modalState === 'professionalDesignation' && (
            <ProfessionalDesignationsEdit
              setModalOpen={setModalState}
              professionalDesignations={professionalDesignations}
            />
          )}
          {modalState === 'resume' && <ResumeEdit setModalOpen={setModalState} data={resumeInfo?.[0]} />}
          {modalState === 'degreeEdit' && (
            <PreviousDegreeEdit
              setModalOpen={setModalState}
              majorOptions={createConcentrationOptions(
                previousDegrees?.[degreeIndex]?.concentrations || [],
                ConcentrationTypes.MAJOR,
              )}
              gpa={previousDegrees?.[degreeIndex]?.gpa}
              gpa_outof={previousDegrees?.[degreeIndex]?.gpa_outof}
              gpa_not_applicable={previousDegrees?.[degreeIndex]?.gpa_not_applicable}
              start_month_numeric={previousDegrees?.[degreeIndex]?.start_month_numeric || null}
              start_year={previousDegrees?.[degreeIndex]?.start_year}
              complete_month_numeric={previousDegrees?.[degreeIndex]?.complete_month_numeric || null}
              complete_year={previousDegrees?.[degreeIndex]?.complete_year}
              degree_level={previousDegrees?.[degreeIndex]?.degree_level}
              degreeId={previousDegrees?.[degreeIndex]?.degree_id}
              degree={previousDegrees?.[degreeIndex]?.degree}
              fillInUniversity={previousDegrees?.[degreeIndex]?.fill_in_university?.institution_name || ''}
              universityId={previousDegrees?.[degreeIndex]?.university?.university_selection_id || ''}
              universityName={previousDegrees?.[degreeIndex]?.university?.location_name || ''}
              academicHonors={previousDegrees?.[degreeIndex]?.awards}
            />
          )}
          {modalState === 'workExperienceEdit' && (
            <WorkExperienceEdit
              setModalOpen={setModalState}
              isNew={!workExperiences?.[workExperienceIndex]?.work_experience_id}
              end_year={workExperiences?.[workExperienceIndex]?.end_year || null}
              end_month_numeric={workExperiences?.[workExperienceIndex]?.end_month_numeric || null}
              start_year={workExperiences?.[workExperienceIndex]?.start_year || null}
              start_month_numeric={workExperiences?.[workExperienceIndex]?.start_month_numeric || null}
              position={workExperiences?.[workExperienceIndex]?.position || ''}
              current={workExperiences?.[workExperienceIndex]?.current || false}
              state={workExperiences?.[workExperienceIndex]?.state || null}
              city={workExperiences?.[workExperienceIndex]?.city || null}
              company={workExperiences?.[workExperienceIndex]?.company || ''}
              work_id={workExperiences?.[workExperienceIndex]?.work_experience_id || ''}
              country={workExperiences?.[workExperienceIndex]?.country || ''}
            />
          )}
        </Container>
      ) : (
        <StudentWidget />
      )}
    </MainViewLayout>
  );
};

export default MyCardController;
