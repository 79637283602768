import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';
import { palette } from 'theme';
import { BackgroundCups } from 'theme/assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  @media (min-width: ${MEDIA_SIZE.md}) {
    justify-content: center;
  }
`;

export const Icon = styled.img`
  display: flex;
  width: 7.5rem;
  height: 7.2rem;
  margin-bottom: 2.5rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${BackgroundCups}) center no-repeat;
  background-size: cover;
  justify-content: center;
  height: 100%;

  @media (min-width: ${MEDIA_SIZE.md}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const MainText = styled.span`
  font-size: 1.6rem;
  font-family: 'RobotoBold';
  line-height: 2.2rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: ${MEDIA_SIZE.md}) {
    line-height: 2.4rem;
    font-size: 1.8rem;
    max-width: 35.3rem;
  }
`;

export const Title = styled.span`
  font-size: 2.7rem;
  font-family: 'RobotoBold';
  line-height: 3.5rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: ${MEDIA_SIZE.md}) {
    font-size: 3rem;
    line-height: 4.2rem;
    max-width: 35.3rem;
  }
`;

export const SmallText = styled.span`
  font-size: 1.5rem;
  font-family: 'Roboto';
  line-height: 2rem;
  color: ${palette.beta600gray};
  max-width: 26.3rem;
  text-align: center;

  @media (min-width: ${MEDIA_SIZE.md}) {
    font-size: 1.6rem;
    line-height: 2rem;
    max-width: 35.3rem;
  }
`;
