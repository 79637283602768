/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useInsertQueueMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import { WizardPage } from '../components';
import { Spacing } from 'components/Spacing';
import {
  Container,
  FormContainer,
  MainText,
  Title,
  SmallText,
  TopContainer,
  DoneContainer,
  Icon,
  Link,
  ToastDiv,
  ToastSubTitle,
  ToastTitle,
  TickIcon,
  ToastTitleContainer,
} from './DoneStep.styled';
import { WizardProps } from '../components/interface';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useHistory } from 'react-router-dom';
import { DoneCheckMark, SuccessIconTick } from 'theme/assets';

const DoneScreen: React.FC<WizardProps> = ({ step, totalSteps, backGoTo = '' }) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const history = useHistory();

  const [resendEmail] = useInsertQueueMutation();

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} /> <ToastTitle>Email sent successfully</ToastTitle>
      </ToastTitleContainer>
      <ToastSubTitle>Please check your inbox</ToastSubTitle>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>Error sending the email</ToastTitle>
      </ToastTitleContainer>
      <ToastSubTitle>Please contact our support team</ToastSubTitle>
    </ToastDiv>
  );
  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  const handleResend = async () => {
    try {
      await resendEmail({
        variables: {
          queue_name: 'resend-profile-wizard-complete-email',
          content: {
            user_id: user.sub,
          },
        },
      }).then(() => {
        notifySuccess();
      });
    } catch (error) {
      notifyError();
    }
  };

  return (
    <WizardPage
      onNext={() => {}}
      showBackground={false}
      step={step}
      totalSteps={totalSteps}
      onPrevious={() => history.push(backGoTo)}
      showButtons={false}
      hideProgress
      hideHeaderButtons
      noPadded={width < mobileBreakpoint}
      cardHeight={width < mobileBreakpoint ? '100%' : ''}
    >
      <DoneContainer>
        <TopContainer>
          <Icon src={DoneCheckMark} />
        </TopContainer>
        <FormContainer>
          <Container>
            <Title>{'Done!'}</Title>
            <Spacing margin="0.8rem" />
            <MainText>
              {
                "Your profile is now being reviewed. Please validate your email. Give us 24 hours and we'll let you know if you've been accepted into EliteGrad."
              }
            </MainText>
            <Spacing margin="0.5rem" />
            <SmallText>{'If you need help in the meantime, email us at: help@elitegrad.com'}</SmallText>
            <Spacing margin="0.5rem" />
            <SmallText>
              {"If you didn't receive this email "}
              <Link onClick={handleResend}>click here</Link>
            </SmallText>
          </Container>
        </FormContainer>
      </DoneContainer>
    </WizardPage>
  );
};

export default DoneScreen;
