import React from 'react';
import Pagination, { PaginationState } from 'components/common/Pagination';
import { Text } from 'theme/typography';
import { PaginationContainer } from './JobListFooter.styled';

interface JobListFooterProps {
  pagination: PaginationState;
  onPageChange: any;
  studentsCount: number;
}

const JobListFooter: React.FC<JobListFooterProps> = ({ pagination, onPageChange, studentsCount }) => {
  return (
    <PaginationContainer>
      <Pagination pagination={pagination} onPageChange={onPageChange} />
      <Text variant="s" bold>
        Showing {studentsCount + pagination.offset} of {pagination.count} jobs
      </Text>
    </PaginationContainer>
  );
};

export default JobListFooter;
