import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'components';

import ProfileLegalTab, { ProfileLegalFormVariables } from './ProfileLegalTab';

import {
  useGetStudentProfileQuery,
  useUpdateStudentLegalMutation,
  GetStudentProfileDocument,
  GetStudentProfileQuery,
} from 'generated/graphql';

const DEFAULT_VALUES = {
  authorized_to_work: false,
  need_sponsor: false,
  legal_residence_country: null,
};

const ProfileLegalTabController: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  const [updateStudentLegal, { loading: updateLoading }] = useUpdateStudentLegalMutation({
    update: (cache, { data: updateResultData }) => {
      const cachedGradProfile = cache.readQuery<GetStudentProfileQuery>({
        query: GetStudentProfileDocument,
        variables: { userId: user.sub },
      });

      if (!updateResultData || !updateResultData.update_grad_profile) return;

      if (!cachedGradProfile || !cachedGradProfile.grad_profile) return;

      const oldGradProfile = cachedGradProfile.grad_profile[0];
      const newGradProfile = {
        ...oldGradProfile,
        ...updateResultData.update_grad_profile.returning[0],
      };

      cache.writeQuery({
        query: GetStudentProfileDocument,
        variables: { userId: user.sub },

        data: {
          grad_profile: [newGradProfile],
        },
      });
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { loading: getProfileLoading, data: getProfileData } = useGetStudentProfileQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  if (getProfileLoading) return <Spinner />;

  const myInfo = getProfileData && getProfileData.grad_profile[0] ? getProfileData.grad_profile[0] : DEFAULT_VALUES;

  const onCancel = () => history.goBack();

  const onSubmit = async (data: ProfileLegalFormVariables) => {
    const { authorizedToWork, needSponsor, legalResidenceCountry } = data;

    const variables = {
      userId: user.sub,
      authorizedToWork: authorizedToWork === 'yes',
      needSponsor: needSponsor === 'yes',
      legalResidenceCountryId: legalResidenceCountry.value,
    };

    await updateStudentLegal({ variables });

    history.goBack();
  };

  if (updateLoading) return <Spinner />;

  return (
    <ProfileLegalTab
      onSubmit={onSubmit}
      onCancel={onCancel}
      need_sponsor={myInfo.need_sponsor || false}
      authorized_to_work={myInfo.authorized_to_work || false}
      country={myInfo.legal_residence_country?.country || null}
      country_selection_id={myInfo.legal_residence_country?.country_selection_id || null}
    />
  );
};

export default ProfileLegalTabController;
