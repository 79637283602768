import { GradBookmarkJob, useDeleteBookmarkMutation, useInsertBookmarkMutation } from 'generated/graphql';

export const useInsertBookmarkJobMutationHook = (jobId: string, bookmark_jobs: GradBookmarkJob[], userId: string) => {
  return useInsertBookmarkMutation({
    variables: { userId, jobId },
    errorPolicy: 'all',
    refetchQueries: ['GetJobPostingsList', 'GetJobPostingsDetail', 'GetJobsByCompanyAccount'],
  });
};

export const useDeleteBookmarkJobMutationHook = (jobId: string, bookmark_jobs: GradBookmarkJob[], userId: string) => {
  return useDeleteBookmarkMutation({
    variables: { userId, jobId },
    errorPolicy: 'all',
    refetchQueries: ['GetJobPostingsList', 'GetJobPostingsDetail', 'GetJobsByCompanyAccount'],
    onError: () => undefined,
  });
};
