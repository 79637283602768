import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  LoginContainer,
  LoginSection,
  WelcomeDescription,
  WelcomeImage,
  WelcomeSection,
  WelcomeTitle,
  WelcomeTitleContainer,
} from './Login.styled';

import { StudentViewPaths as routes } from 'views/Student/StudentViewRoutes';
import { WizardCard, WizardPage } from 'views/Wizard/components/WizardPage';
import { Button } from 'components/common/Button';

interface LoginProps {
  onMobile: boolean;
  subStep: number;
}

const Login: React.FC<LoginProps> = ({ onMobile, subStep }) => {
  const history = useHistory();

  return (
    <WizardPage>
      <WizardCard width="70vw" height="100%">
        {(!onMobile || subStep === 1) && (
          <WelcomeSection>
            <WelcomeTitleContainer>
              <WelcomeTitle>
                <div>Stand out in the crowd </div>
                <div>find your dream job</div>
              </WelcomeTitle>
              <WelcomeDescription>
                EliteGrad offers a virtual space to connect leading firms with students seeking careers in finance
              </WelcomeDescription>
            </WelcomeTitleContainer>
            <WelcomeImage />
          </WelcomeSection>
        )}
        {(!onMobile || subStep === 2) && (
          <LoginSection>
            <LoginContainer id="login-container"></LoginContainer>
            {!onMobile && (
              <Button color="white" size="lg" isRounded onClick={() => history.push(routes.signin)}>
                <strong>Go to sign up</strong>
              </Button>
            )}
          </LoginSection>
        )}
      </WizardCard>
    </WizardPage>
  );
};

export default Login;
