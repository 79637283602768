import React, { Dispatch } from 'react';
import { Button } from 'components/common/Button';
import { LoginFooterButtonsContainer } from './Login.styled';
import { useHistory } from 'react-router-dom';
import { StudentViewPaths as routes } from 'views/Student/StudentViewRoutes';

interface LoginFooterProp {
  subStep: number;
  setSubStep: Dispatch<React.SetStateAction<number>>;
}

const LoginFooter: React.FC<LoginFooterProp> = ({ subStep, setSubStep }) => {
  const history = useHistory();

  return (
    <LoginFooterButtonsContainer subStep={subStep}>
      {subStep === 1 && (
        <Button
          color={subStep === 1 ? 'alpha' : 'white'}
          size="lg"
          isRounded
          onClick={() => setSubStep(subStep === 1 ? 2 : 1)}
        >
          <strong>Log in</strong>
        </Button>
      )}

      <Button color="white" size="lg" isRounded onClick={() => history.push(routes.signin)}>
        <strong>Go to sign up</strong>
      </Button>
    </LoginFooterButtonsContainer>
  );
};

export default LoginFooter;
