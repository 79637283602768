import React from 'react';
import { Container, PlaceholderImg, ClearFilterContainer } from './JobListPlaceholder.styled';
import { NoWorkExperience } from 'theme/assets';
import { HeadingText, Text } from 'theme/typography';
import { palette } from 'theme';
import { useJobFiltersContext, defaultFiltersValue } from '../JobList/JobList.context';

interface JobListPlaceholderProps {
  hasFilters?: boolean;
}

const JobListPlaceholder = ({ hasFilters }: JobListPlaceholderProps) => {
  const { handleFilterChange } = useJobFiltersContext();

  return (
    <Container>
      <PlaceholderImg src={NoWorkExperience} />
      <HeadingText variant="h4" color={palette.gray300}>
        Sorry, no results found
      </HeadingText>
      {hasFilters && (
        <ClearFilterContainer onClick={() => handleFilterChange(defaultFiltersValue)}>
          <Text variant="l" color={palette.alpha400Red} bold>
            Clear all filters
          </Text>
        </ClearFilterContainer>
      )}
    </Container>
  );
};

export default JobListPlaceholder;
