import styled from 'styled-components';
import { palette } from 'theme';

export const TestScoreCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin: 0.3rem 0;
  padding: 0.8rem;

  border-radius: 4px;
  background: ${palette.gray50};
`;

export const TestScoreTitle = styled.div`
  font-family: 'Roboto';
  font-size: 1.5rem;
  line-height: 2.4rem;
`;

export const Numerator = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Denominator = styled.span`
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${palette.beta400gray};
`;

export const NumberContainer = styled.div`
  display: flex;
`;
