import React from 'react';

import {
  Denominator,
  Numerator,
  Detail,
  DetailList,
  DetailScore,
  DetailTitle,
  TestScore,
  TestScoreCard,
  TestScoreTitle,
  TopContainer,
} from './TestScoreCardWithDetails.styled';
import TestDetails from '../../../../../types/TestDetail';

export interface TestProps {
  details: TestDetails[];
  test: string;
  score: number;
  test_score_id: string;
  outof: number | null | undefined;
}

const TestScoreCardWithDetails: React.FC<TestProps> = ({ details, outof, test_score_id, score, test }) => {
  return (
    <TestScoreCard key={test_score_id}>
      <TopContainer>
        <TestScoreTitle>{test}:</TestScoreTitle>
        <TestScore>
          <Numerator>{score}</Numerator>
          <Denominator>{`/${outof}`}</Denominator>
        </TestScore>
      </TopContainer>

      <DetailList>
        {details?.map(({ test_score_detail_id, detail, score }: TestDetails) => (
          <Detail key={test_score_detail_id}>
            <DetailScore>{score}</DetailScore>
            <DetailTitle>{detail}</DetailTitle>
          </Detail>
        ))}
      </DetailList>
    </TestScoreCard>
  );
};
export default TestScoreCardWithDetails;
