import moment from 'moment';
import momentTz from 'moment-timezone';
import { format, parse } from 'date-fns';
import { GradWorkExperience } from 'generated/graphql';

export const convertMonthToString = (numberMonth: number | null | undefined) => {
  return numberMonth ? format(parse(`${numberMonth}`, 'M', new Date()), 'MMMM') : '';
};

export const abbreviateMonth = (fullMonth: number | null | undefined) => {
  return fullMonth ? format(parse(`${fullMonth}`, 'M', new Date()), 'MMM') : '';
};

export const sortWorkExperiences = (
  workExperiences: Omit<GradWorkExperience, 'awards' | 'awards_aggregate'>[] = [],
) => {
  return [...workExperiences].sort((a, b) => {
    if (a.end_year === null && b.end_year === null) {
      const aDateStart = new Date(a.start_year, a.start_month_numeric || 12);
      const bDateStart = new Date(b.start_year, b.start_month_numeric || 12);
      return bDateStart.getTime() - aDateStart.getTime();
    }
    if (a.end_year === null) return -1;
    if (b.end_year === null) return 1;

    const aDateEnd = new Date(a.end_year, a.end_month_numeric || 12);
    const bDateEnd = new Date(b.end_year, b.end_month_numeric || 12);
    return bDateEnd.getTime() - aDateEnd.getTime();
  });
};

export const formatMonthYear = (date: Date): string => {
  return moment(date).format('MMMM YYYY');
};

export const formatMonthYearWithComma = (date: Date): string => {
  return moment(date).format('DD MMMM, YYYY');
};

export const getHourInCentralTime = (date: Date): string => {
  const convertedDate = new Date(date.toLocaleString('en-US'));
  let hours = convertedDate.getHours();
  const AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  let minutes = String(convertedDate.getMinutes());
  if (minutes === '0') {
    minutes = minutes + '0';
  }
  const finalTime = hours + ':' + minutes + ' ' + AmOrPm;

  return finalTime;
};

export const getTimeZoneAbbreviation = (timeZone: string): string => {
  let timeZoneAbb = momentTz.tz(timeZone).zoneName();
  if (timeZoneAbb.startsWith('-') || timeZoneAbb.startsWith('+')) {
    timeZoneAbb = 'GMT' + timeZoneAbb;
  }
  return timeZoneAbb;
};
