import styled from 'styled-components';
import { palette } from 'theme';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 3rem 0.8rem 4rem;
  margin-top: auto;
  background-color: ${palette.beta100gray};
  border-radius: 0 0 13px 13px;
`;
