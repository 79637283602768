import styled from 'styled-components';

export const Container = styled.div`
  overflow: scroll;
  margin: 2rem 1.6rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BodyContainer = styled.div`
  overflow: scroll;
`;

export const MainButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

export const BodyContent = styled.pre`
  flex-shrink: 1;
  margin: auto;
  text-align: justify;

  overflow-y: scroll;
  font: inherit;
  white-space: pre-wrap;
`;
