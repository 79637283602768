import React from 'react';
import { StyledButtons, StyledForm, StyledFormInputs, StyledInputLabel } from '../library';
import { TechnicalLanguageSelector } from 'components';
import { CancelButton, OptionId, SaveButton } from '../../../../components';
import { useForm } from 'react-hook-form';

interface ProfileSkillsTabVariables {
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

interface ProfileSkillsTabProps {
  onSubmit: (values: ProfileSkillsTabVariables) => void;
  onCancel: () => void;
  computerLanguagesOptions: OptionId[];
}

const ProfileSkillsTab: React.FC<ProfileSkillsTabProps> = ({ onSubmit, onCancel, computerLanguagesOptions }) => {
  const { control, register, handleSubmit, setValue } = useForm<ProfileSkillsTabVariables>();
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledInputLabel>Technical Languages</StyledInputLabel>
        <TechnicalLanguageSelector
          name="computerLanguages"
          control={control}
          register={register}
          setValue={setValue}
          defaultValue={computerLanguagesOptions}
        />
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfileSkillsTab;
