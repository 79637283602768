import styled from 'styled-components';
import { palette } from 'theme';
import { BlackArrow } from 'theme/assets';
import { MEDIA_SIZE } from 'utils/useDimensions';
import { EGLogoBackgroundMobile } from 'theme/assets';

export const Container = styled.div<{ bgImage?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${palette.beta300gray};
  padding: 2rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    padding: 0;
    min-height: 20rem;

    background-image: url(${EGLogoBackgroundMobile});
    background-repeat: no-repeat;
    background-color: ${palette.gray100};
  }
`;

export const BackArrow = styled.img.attrs({ src: BlackArrow })`
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  margin-right: 3rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    margin: 2rem;
    height: 3rem;
    width: 3rem;
  }
`;
