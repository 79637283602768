import styled from 'styled-components';
import palette from 'theme/palette';
import { Text } from 'theme/typography';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  overflow-y: hidden;

  @media (max-width: ${MEDIA_SIZE.md}) {
    position: relative;
    overflow-y: scroll;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  border: 1px solid ${palette.beta300gray};
  box-sizing: border-box;
  border-radius: 13px;
  margin: 2rem 3rem;
  overflow: hidden;

  @media (max-width: ${MEDIA_SIZE.md}) {
    border: none;
    margin: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;

  padding: 3rem 4rem;
  position: relative;

  @media (max-width: ${MEDIA_SIZE.md}) {
    background-color: ${palette.white};
    border-radius: 0 0 13px 13px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    position: static;
    margin-bottom: 2rem;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyMainInfoContainer = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
`;

export const CompanyBasicInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  span {
    margin-bottom: 0.3rem;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const CompanyExtraInfoContainer = styled.div`
  display: flex;

  span {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const IconsContainer = styled.div``;

export const Icon = styled.img`
  width: 4rem;
  height: 4rem;

  cursor: pointer;
`;

export const CompanyAvatar = styled.img`
  width: 6rem;
  height: 6rem;

  border-radius: 100%;

  margin-right: 1rem;
  margin-bottom: 2rem;
`;

export const CompanyDescription = styled(Text)`
  margin-bottom: 1rem;
`;

export const AboutCompanyContainer = styled.div`
  padding: 3rem;

  @media (max-width: ${MEDIA_SIZE.md}) {
    padding: 2rem;
  }
`;

export const ExtraCompanySection = styled.div`
  padding: 1rem;
`;

export const ContactInformationContainer = styled.div`
  background: ${palette.gray50};
  border-radius: 4px;
  padding: 1rem;
`;

export const SubIndustryContainer = styled.div`
  display: flex;

  span {
    margin-right: 1rem;
  }
`;

export const TabCardContainer = styled.div`
  height: 100%;
  @media (max-width: ${MEDIA_SIZE.md}) {
    border: 1px solid ${palette.beta300gray};
    box-sizing: border-box;
    border-radius: 13px;
    margin: 2rem;
    background-color: ${palette.white};
    height: fit-content;
  }
`;
