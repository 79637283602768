import React from 'react';
import { FormContextValues } from 'react-hook-form';
import { InputLegend } from 'views/Wizard/components';
import styled from 'styled-components';

import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';

interface MonthYearPickerProps extends Pick<FormContextValues, 'control' | 'errors'> {
  required?: boolean;
  defaultMonth: number | null | undefined;
  defaultYear: number | null;
  monthFieldName: string;
  yearFieldName: string;
}

export const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  required = false,
  defaultMonth = null,
  defaultYear = null,
  monthFieldName,
  yearFieldName,
  control,
  errors,
}) => {
  return (
    <>
      <DoubleDropDown>
        <MonthPicker
          error={!!errors[monthFieldName]}
          defaultMonth={defaultMonth}
          name={monthFieldName}
          required={required}
          control={control}
        />
        <YearPicker
          error={!!errors[yearFieldName]}
          defaultYear={defaultYear}
          name={yearFieldName}
          required={required}
          control={control}
        />
      </DoubleDropDown>
      {(errors[yearFieldName] || errors[monthFieldName]) && (
        <InputLegend error>Complete this fields to move on</InputLegend>
      )}
    </>
  );
};

const DoubleDropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  line-height: 1.9rem;
  font-size: 1.4rem;
  font-weight: 300;
  max-width: 32rem;

  & > * + * {
    margin-left: 0.8rem;
  }
`;
