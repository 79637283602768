export enum TestACTDetailsTypes {
  ENGLISH = 'math',
  MATHEMATICS = 'reading',
  SCIENCE = 'writing',
  REASONING = 'reasoning',
  WRITING = 'writing',
}
export enum TestACTDetailsOutof {
  ENGLISH = 36,
  MATHEMATICS = 36,
  SCIENCE = 36,
  REASONING = 36,
  WRITING = 12,
}

export enum TestACTDetailsMin {
  ENGLISH = 1,
  MATHEMATICS = 1,
  SCIENCE = 1,
  REASONING = 1,
  WRITING = 1,
}

export enum TestSATDetailsTypes {
  MATH = 'math',
  READING = 'reading',
  WRITING = 'writing',
}
export enum TestSATDetailsOutof {
  MATH = 800,
  READING = 800,
  WRITING = 800,
}

export enum TestTypes {
  ACT = 'ACT',
  SAT = 'SAT',
  GMAT = 'GMAT',
  GRE = 'GRE',
  LSAT = 'LSAT',
  MCAT = 'MCAT',
}
export enum TestOutOf {
  ACT = 36,
  SAT = TestSATDetailsOutof.MATH + TestSATDetailsOutof.READING + TestSATDetailsOutof.WRITING,
  GMAT = 800,
  GRE = 340,
  LSAT = 180,
  MCAT = 528,
}
