import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../utils/useDimensions';
import { palette } from 'theme';

export const StyledLabelWrite = styled.span`
  color: ${palette.gray500};
  font-size: 0.8rem;
  margin-left: 0.2rem;
  line-height: 1.6rem;
`;

export const StyledSatContainer = styled.div<{ split?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ split }) => (split ? 'column' : 'row')};

  & > * {
    margin: ${({ split }) => (split ? '0.1rem 0' : '0 0.6rem')};
    width: 100%;
  }

  & > :first-child {
    margin: ${({ split }) => (split ? '0.1rem 0' : '0 0.6rem 0 0')};
  }

  & > :last-child {
    margin: ${({ split }) => (split ? '0.1rem 0' : '0 0 0 0.6rem')};
  }

  @media (min-width: ${MEDIA_SIZE.md}) {
    flex-direction: row;

    & > * {
      margin: 0 0.7rem;
      width: 100%;
    }

    & > :first-child {
      margin: 0 0.7rem 0 0;
    }

    & > :last-child {
      margin: 0 0 0 0.7rem;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2.2rem auto 2.6rem;
`;

export const TestCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 1rem 3.5rem;

  @media (min-width: ${MEDIA_SIZE.md}) {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding: 1.2rem 4rem;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  font-size: 1.3rem;
  font-family: 'Roboto';
  padding: 0.3rem 0;
  align-items: flex-start;

  & > label {
    margin-bottom: 0;
    line-height: 1.5em;
  }
`;

export const TestContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: 'RobotoBold';
`;

export const TestLabel = styled.span<{ error?: boolean }>`
  font-family: 'RobotoBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ error }) => (error ? palette.alpha500Red : palette.black)};
`;

export const TestLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NotApplicable = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
