import React, { Ref } from 'react';
import styled from 'styled-components';

import ButtonLight from './ButtonLight';
import TextInput from './TextInput';

const StyledInput = styled.input.attrs({
  type: 'file',
  accept: '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
})`
  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0;
`;

const StyledFileInput = styled.label`
  position: relative;

  display: flex;

  > * {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export interface FileInputProps {
  fileName?: string | null;
  inputRef?: Ref<HTMLInputElement>;
  onChange?: (file: File) => void;
}

const FileInput: React.FC<FileInputProps> = ({ fileName, inputRef, onChange = () => undefined, ...props }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files) {
      onChange(e.target.files[0] || null);
    }
  };

  return (
    <StyledFileInput {...props}>
      <TextInput disabled value={fileName || ''} />
      <StyledInput type="file" ref={inputRef} onChange={handleOnChange} />
      <ButtonLight text="Choose" />
    </StyledFileInput>
  );
};

export default FileInput;
