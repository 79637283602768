import React, { useState } from 'react';
import { Spacing } from 'components/Spacing';
import { useAuth0 } from 'react-auth0-spa';
import { useForm } from 'react-hook-form';
import { AwardsTypes } from 'data/awards';
import AvatarEditor from 'react-avatar-editor';
import { PROFESSIONAL_DESIGNATIONS_OPTIONS } from '../../../../data/awards';
import { notify } from 'utils/notify';
import ModalOpenType from 'types/ModalOpen';
import { Spinner } from 'components';
import PictureEditor from '../../../../components/PictureEditor/PictureEditor';
import { WizardInput, InputLegend } from '../../../Wizard/components';
import { WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { UserDefault as DefaultAvatar } from 'theme/assets';
import { Container } from './HeaderEdit.styled';
import {
  useGetProfilePhotoQuery,
  useUploadProfilePhotoMutation,
  useUpdateHeaderRelatedProfileMutation,
  useUpdateHeaderProfileItemsMutation,
} from 'generated/graphql';
import Modal from 'components/common/EditProfileModal';
import SelectorMultiCreateV2 from 'components/SelectorMultiCreateV2';
import { OptionId, JobTypeInterestPicker, YesNoPicker, createOptionId } from 'components';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface ProfessionalDesignation {
  award_id: string;
  award_name: string;
  award_category?: string | undefined;
}

interface InterestJobType {
  interest_job_type_id: string;
  job_type: string;
}
interface PictureStepProps {
  setModalOpen: (status: ModalOpenType) => void;
  needSponsor: boolean;
  firstName: string;
  lastName: string;
  designations: ProfessionalDesignation[];
  interestJobType: InterestJobType;
}

export interface PersonalYouFormVariables {
  needSponsor: string;
  firstName: string;
  lastName: string;
  professionalDesignations: { optionsValue: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  interestJobType: OptionId;
}

const PictureStepController: React.FC<PictureStepProps> = ({
  setModalOpen,
  designations,
  firstName,
  lastName,
  needSponsor,
  interestJobType,
}) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const [image, setImage] = useState<string>();
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [editor, setEditor] = useState<AvatarEditor>();

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const { loading, error } = useGetProfilePhotoQuery({
    fetchPolicy: 'no-cache',
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onCompleted: (req) => {
      if (req.grad_profile[0].profile_image) {
        setImage(req?.grad_profile[0].profile_image);
      } else {
        setImage(DefaultAvatar);
      }
    },
  });

  const [uploadPhotoMutation] = useUploadProfilePhotoMutation();
  const [updateProfileRelated] = useUpdateHeaderRelatedProfileMutation();
  const [updateProfileHeader] = useUpdateHeaderProfileItemsMutation();

  if (error) {
    const queryToastId = 'WizardGetMyPictureToast';
    notify(queryToastId, 'There was an issue uploading your profile picture, please try again later.');
  }

  const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

  const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const currentJobType =
    [interestJobType].map(({ job_type, interest_job_type_id }: { job_type: string; interest_job_type_id: string }) => ({
      value: job_type,
      label: job_type,
      id: interest_job_type_id,
    }))[0] || null;

  const updateImage = async (imageFile: string) => {
    setImage(imageFile);
  };

  const onDelete = () => {
    setImage(DefaultAvatar);
  };

  const onNext = async (data: PersonalYouFormVariables) => {
    const {
      needSponsor,
      interestJobType,
      firstName,
      lastName,
      professionalDesignations: { optionsValue: designationsValue },
    } = data;

    const eraseProfessionalDesignations = getEraseOptions(designationsValue, professionalDesignationsOptions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addProfessionalDesignations = getAddOptions(designationsValue, professionalDesignationsOptions).map(
      ({ value }) => ({
        award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
        award_name: value,
        user_id: user.sub,
      }),
    );

    const eraseJobTypes = getEraseOptions(
      interestJobType ? [interestJobType] : [],
      currentJobType ? [currentJobType] : [],
    )
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];

    const addJobTypes = getAddOptions(
      interestJobType ? [interestJobType] : [],
      currentJobType ? [currentJobType] : [],
    ).map<{ job_type: string; user_id: string }>(({ value }) => ({
      job_type: value,
      user_id: user.sub,
    }));
    const profileImage =
      !image || image === DefaultAvatar ? null : editor ? editor.getImageScaledToCanvas().toDataURL() : image;

    const fullName = `${firstName} ${lastName}`;

    setUploadingImage(true);
    const variablesProfile = {
      userId: user.sub,
      needSponsor: needSponsor === 'yes' ? true : false,
      firstName,
      lastName,
      fullName,
    };

    const variablesProfileRelated = {
      userId: user.sub,
      awardsForUpsert: addProfessionalDesignations,
      awardIdsForDelete: eraseProfessionalDesignations,
      jobType: addJobTypes,
      jobTypeForDelete: eraseJobTypes,
    };

    await updateProfileHeader({ variables: variablesProfile });
    await updateProfileRelated({ variables: variablesProfileRelated });
    await uploadPhotoMutation({
      variables: { userId: user.sub, profileImage },
      refetchQueries: ['GetProfileDegrees', 'GetProfileByUserId'],
    });
    setUploadingImage(false);
    setModalOpen('');
  };

  const { register, control, handleSubmit, errors, setValue, clearError } = useForm<PersonalYouFormVariables>();

  if (loading || uploadingImage)
    return (
      <Modal setModalOpen={setModalOpen} heightAuto={width > mobileBreakpoint}>
        <Spinner />
      </Modal>
    );

  const professionalDesignationsOptions = designations.map((award: ProfessionalDesignation) =>
    createOptionId(award.award_name, award.award_id),
  );

  const jobType = createOptionId(interestJobType?.job_type, interestJobType?.interest_job_type_id);

  return (
    <Modal
      setModalOpen={setModalOpen}
      heightAuto={width > mobileBreakpoint}
      handleSubmit={handleSubmit(onNext)}
      title={'Profile Header'}
    >
      <Container>
        <PictureEditor onDelete={onDelete} onChange={updateImage} editorRef={editorRef} queryImage={image || ''} />
        <Spacing margin="1rem" />
        <WizardRequiredInputLabel error={!!errors.firstName}>What is your first name?</WizardRequiredInputLabel>
        <WizardInput
          type="text"
          name="firstName"
          placeholder="e.g: John"
          ref={register({ required: true })}
          defaultValue={firstName}
          error={!!errors.firstName}
        />
        {errors.firstName && <InputLegend error={!!errors.firstName}>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.lastName}>What is your last name?</WizardRequiredInputLabel>
        <WizardInput
          type="text"
          name="lastName"
          placeholder="e.g: Doe"
          ref={register({ required: true })}
          defaultValue={lastName}
          error={!!errors.lastName}
        />
        {errors.lastName && <InputLegend error={!!errors.lastName}>Complete this field to move on</InputLegend>}

        <YesNoPicker
          name="needSponsor"
          required
          title="Will you require sponsorship for a work visa
              to work lawfully in the United States either
              now or once your student visa has expired?"
          register={register}
          defaultValue={needSponsor ? 'yes' : 'no'}
          error={!!errors.needSponsor}
        />
        {errors.needSponsor && <InputLegend error>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.interestJobType}>
          Are you looking for a full-time role or an internship?
        </WizardRequiredInputLabel>
        <JobTypeInterestPicker
          name="interestJobType"
          defaultValue={jobType}
          control={control}
          required={true}
          placeholder="Select"
          error={!!errors.interestJobType}
        />

        <WizardRequiredInputLabel error={!!errors.professionalDesignations}>
          Do you have any professional designations?
        </WizardRequiredInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          dropDownIndicator={true}
          dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
          defaultOptionsValue={professionalDesignationsOptions || []}
          name="professionalDesignations"
          placeholder="Select"
          register={register}
          setValue={setValue}
          clearErrors={() => clearError('professionalDesignations')}
          error={!!errors.professionalDesignations}
          required={false}
        />
        <InputLegend>The first item you pick will be display next to your name on your profile card.</InputLegend>
      </Container>
    </Modal>
  );
};

export default PictureStepController;
