import React from 'react';
import {
  CancelButton,
  DegreePickerOption,
  MajorSelector,
  MinorSelector,
  MonthYearPicker,
  OptionId,
  SaveButton,
  StyledButtons,
  StyledCheckboxContainer,
  StyledDeleteButton,
  StyledForm,
  StyledFormInputs,
  StyledInputLabel,
  UniversitySelectorCreate,
  YearPicker,
} from '../../../components';
import { InputLegend } from 'views/Wizard/components';
import DegreePicker from '../../../components/DegreePicker';
import CheckboxWithController from '../../../components/CheckboxWithController';
import GPAInput from '../../../components/GPAInput';
import { StyledAcademicHonors, StyledAcademicHonorsRow, StyledAcademicHonorsSelector } from './DegreeDetail.styled';
import { ACADEMIC_HONORS_OPTIONS } from '../../../data/awards';
import { useForm } from 'react-hook-form';
import { GradAward } from 'generated/graphql';

export interface DegreeFormVariables {
  gradEndMonth: { value: number; label: string };
  gradEndYear: { value: number; label: string };
  gradStartMonth: { value: number; label: string };
  gradStartYear: { value: number; label: string };
  GPA: string | null;
  gpaOutOf: { value: number; label: string } | null;
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  academicHonors: { optionValue: OptionId | null; deletedOptionId: string | null };
  academicHonorsYearReceived: { value: number; label: string };
  majors: { optionsValue: OptionId[] | null; deletedOptionsIds: string[] | null };
  minors: { optionsValue: OptionId[] | null; deletedOptionsIds: string[] | null };
  gpaNotApplicable: boolean;
}

interface DegreeDetailProps {
  onSubmit: (data: DegreeFormVariables) => void;
  onCancel: () => void;
  onDelete: () => void;
  fillInUniversity: string;
  degree: string;
  degree_level: string;
  gpa: number | null;
  start_year: number | null;
  gpa_outof: number | null;
  complete_year: number | null;
  universityName: string;
  universityId: string;
  gpa_not_applicable: boolean;
  persistedDegreeId: string | null;
  academicHonors: GradAward;
  academicHonorsOption: OptionId | null;
  minorOptions: OptionId[];
  majorOptions: OptionId[];
  start_month_numeric: number | null | undefined;
  complete_month_numeric: number | null | undefined;
}

const DegreeDetail: React.FC<DegreeDetailProps> = ({
  onSubmit,
  onCancel,
  fillInUniversity,
  onDelete,
  persistedDegreeId,
  degree_level,
  degree,
  academicHonors,
  universityId,
  universityName,
  academicHonorsOption,
  minorOptions,
  majorOptions,
  gpa,
  complete_month_numeric,
  start_month_numeric,
  start_year,
  complete_year,
  gpa_outof,
  gpa_not_applicable,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    clearError,
    watch,
  } = useForm<DegreeFormVariables>();
  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');
  const watchAcademicHonorsField = watch('academicHonors');
  const isAcademicHonorsYearReceivedRequired = !!(watchAcademicHonorsField && watchAcademicHonorsField.optionValue);
  return (
    <StyledForm
      onSubmit={handleSubmit((data) =>
        onSubmit({
          ...data,
          GPA: watchedNotApplicable ? null : data.GPA,
          gpaOutOf: watchedNotApplicable ? null : data.gpaOutOf,
        }),
      )}
    >
      <StyledFormInputs>
        <StyledInputLabel>Name of College or University</StyledInputLabel>
        <UniversitySelectorCreate
          required={true}
          fillInValue={fillInUniversity}
          value={{ id: universityId, label: universityName, value: universityName }}
          name={'universitySelectionIdOption'}
          control={control}
        ></UniversitySelectorCreate>
        {errors.universitySelectionIdOption && (
          <InputLegend error={!!errors.universitySelectionIdOption}>Complete this field to move on</InputLegend>
        )}

        <StyledInputLabel>Start Date</StyledInputLabel>
        <MonthYearPicker
          required={true}
          monthFieldName="gradStartMonth"
          yearFieldName="gradStartYear"
          errors={errors}
          control={control}
          defaultMonth={start_month_numeric}
          defaultYear={start_year}
        ></MonthYearPicker>

        <StyledInputLabel>Graduation Date</StyledInputLabel>
        <MonthYearPicker
          required={true}
          monthFieldName="gradEndMonth"
          yearFieldName="gradEndYear"
          errors={errors}
          control={control}
          defaultMonth={complete_month_numeric}
          defaultYear={complete_year}
        ></MonthYearPicker>

        <StyledInputLabel>What is your Degree?</StyledInputLabel>
        <DegreePicker
          name={'degreeSelectionOption'}
          control={control}
          defaultDegree={degree}
          defaultLevel={degree_level}
          clearable
          required={true}
        />

        <StyledCheckboxContainer>
          <CheckboxWithController name="gpaNotApplicable" control={control} defaultChecked={!!gpa_not_applicable} />
          {'GPA is not applicable'}
        </StyledCheckboxContainer>

        {!watchedNotApplicable && (
          <GPAInput
            required={true}
            defaultOutOf={gpa_outof}
            defaultGPA={gpa}
            watchedOutOf={watchedOutOf}
            gpaFieldName="GPA"
            outOfFieldName="gpaOutOf"
            control={control}
            register={register}
          />
        )}

        {!watchedNotApplicable && errors.GPA && <InputLegend error={!!errors.GPA}>{errors.GPA.message}</InputLegend>}

        <StyledInputLabel>Major</StyledInputLabel>
        <MajorSelector
          required={true}
          majorsValue={majorOptions}
          name={'majors'}
          register={register}
          setValue={setValue}
        />
        {errors.majors && <InputLegend error={!!errors.majors}>Complete this field to move on</InputLegend>}

        <StyledInputLabel>Minor</StyledInputLabel>
        <MinorSelector
          required={false}
          minorsValue={minorOptions}
          name={'minors'}
          register={register}
          setValue={setValue}
        />

        <StyledAcademicHonors>
          <StyledAcademicHonorsRow>
            <StyledInputLabel>What are your academic honors?</StyledInputLabel>
            <StyledInputLabel>Year received</StyledInputLabel>
          </StyledAcademicHonorsRow>
        </StyledAcademicHonors>

        <StyledAcademicHonors>
          <StyledAcademicHonorsRow>
            <StyledAcademicHonorsSelector
              dropDownIndicator={true}
              dropDownOptions={ACADEMIC_HONORS_OPTIONS}
              defaultOptionValue={academicHonorsOption}
              name="academicHonors"
              placeholder="Type your academic honors"
              register={register}
              setValue={setValue}
              setError={setError}
              clearError={clearError}
            />
            <div>
              <YearPicker
                defaultYear={academicHonors && academicHonors.year_received}
                name={`academicHonorsYearReceived${isAcademicHonorsYearReceivedRequired ? '' : 'disabled'}`}
                required={isAcademicHonorsYearReceivedRequired}
                control={control}
                disabled={!isAcademicHonorsYearReceivedRequired}
              />
              {errors.academicHonorsYearReceived && (
                <InputLegend error={!!errors.academicHonorsYearReceived}>Complete this field to move on</InputLegend>
              )}
            </div>
          </StyledAcademicHonorsRow>
        </StyledAcademicHonors>

        {persistedDegreeId && <StyledDeleteButton onClick={onDelete}>Remove Education</StyledDeleteButton>}
      </StyledFormInputs>

      <StyledButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default DegreeDetail;
