import React from 'react';

import { useAuth0 } from 'react-auth0-spa';

import styled from 'styled-components';

import { EGLogoWhite, Refresh } from 'theme/assets';
import { LogoutButton } from 'components/library';
import { palette } from 'theme';

const SplashPage: React.FC = () => {
  const { user, logout } = useAuth0();

  const handleOnRefresh = () => window.location.reload();

  return (
    <StyledSplashPage>
      <EliteGradLogoSplash color="white" src={EGLogoWhite} alt="logo" />
      <StyledLoggedInSection>
        <LoggedInMessage>Welcome {user.name}</LoggedInMessage>

        <StyledMessage>Please validate your email and click refresh</StyledMessage>
        <RefreshIcon onClick={handleOnRefresh} src={Refresh} alt="logo"></RefreshIcon>

        <LogoutButton onClick={logout}>Log out</LogoutButton>
      </StyledLoggedInSection>
    </StyledSplashPage>
  );
};

const StyledLoggedInSection = styled.div`
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  display: flex;

  bottom: 8%;
  width: 100%;
`;

const StyledMessage = styled.span`
  max-width: 70%;
  margin-bottom: 2%;
  overflow: scroll;
  color: ${palette.white};
`;

const LoggedInMessage = styled(StyledMessage)`
  margin-bottom: 3.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const EliteGradLogoSplash = styled.img`
  top: 10%;
  position: relative;
`;

export const RefreshIcon = styled.img`
  height: 3rem;
`;

const StyledSplashPage = styled.div`
  background: ${palette.red};
  align-items: center;
  text-align: center;
  height: inherit;
`;

export default SplashPage;
