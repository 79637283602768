import React, { useState, useEffect, useMemo } from 'react';
import { OptionId } from 'components';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { Container, Requirement, FirmSelectionGrid } from './InterestFirmTypesEdit.styled';
import { Button } from '../../../../components/common/Button';
import { newFirmsInterest, firmsOptionObject } from '../../../Wizard/FirmsOfInterestStep/FirmsInterestStep.utils';
import { useUpsertFirmInterestsEditMutation, useGetFirmInterestSelectionsQuery } from 'generated/graphql';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface InterestFirmTypes {
  interest_firm_id: string;
  firm_type: string;
}

export interface FirmOfInterestOption {
  id: string;
  label: string;
  value: string;
  selected: boolean;
}

export interface InterestRoleEditVariables {
  firmsData: { current: OptionId[] };
}

interface InterestRolesEditProps {
  firmsData?: InterestFirmTypes[];
  setModalOpen: (state: ModalOpenType) => void;
}

const InterestRolesEditStep: React.FC<InterestRolesEditProps> = ({ firmsData, setModalOpen }) => {
  const { loading: firmsOptionLoading, data: firmsOptionData } = useGetFirmInterestSelectionsQuery();

  const { width } = useWindowDimensions();
  const { user } = useAuth0();
  const [saveFirmInterests] = useUpsertFirmInterestsEditMutation();

  const getPreSelectedFirms = (data: InterestFirmTypes[] | []): string[] =>
    data ? data.map((firm: InterestFirmTypes) => firm?.firm_type) : [];

  const incomingSelectedFields = getPreSelectedFirms(firmsData || []);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOptions(incomingSelectedFields);
  }, [firmsData]);

  const options = useMemo(() => {
    if (!firmsOptionData) {
      return [];
    }

    return firmsOptionObject(firmsOptionData, selectedOptions);
  }, [firmsOptionData, selectedOptions]);

  const onSelectOption = (selectedOption: string) => {
    if (selectedOptions.includes(selectedOption)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== selectedOption));
    } else {
      selectedOptions.length < 3 && setSelectedOptions([...selectedOptions, selectedOption]);
    }
  };

  const save = async () => {
    const payload = newFirmsInterest(
      incomingSelectedFields,
      selectedOptions,
      firmsOptionData?.grad_interest_firm_selection,
      user.sub,
    );

    if (payload !== null)
      await saveFirmInterests({
        variables: { addFirms: payload.addFirms, removeFirms: payload.removeFirms, userID: user.sub },
        refetchQueries: ['GetProfileByUserId'],
      });
    setModalOpen('');
  };

  return (
    <Modal
      setModalOpen={setModalOpen}
      handleSubmit={save}
      heightAuto={width > mobileBreakpoint}
      title={'Interested Firm Type'}
    >
      <Container>
        <Requirement>(Pick up to 3)</Requirement>
        <FirmSelectionGrid>
          {options.map((option: FirmOfInterestOption) => (
            <Button
              key={option.id}
              size="md"
              color={option.selected ? 'beta' : 'white'}
              hasShadow
              noPadding
              onClick={() => onSelectOption(option.value)}
            >
              {option.label}
            </Button>
          ))}
        </FirmSelectionGrid>
      </Container>
    </Modal>
  );
};

export default InterestRolesEditStep;
