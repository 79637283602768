import {
  GetInterestFirmSelectionPartsFragment,
  GetFirmInterestsQuery,
  GetFirmInterestSelectionsQuery,
} from 'generated/graphql';

export const getPreSelectedFirms = (data: GetFirmInterestsQuery | undefined): string[] =>
  data?.grad_profile[0] ? data.grad_profile[0].interest_firms.map((firm) => firm.firm_type) : [];

export const newFirmsInterest = (
  preSelectedFirms: string[],
  selectedFirms: string[],
  allFirms: GetInterestFirmSelectionPartsFragment[] | undefined,
  targetUserId: string,
) => {
  if (!allFirms) return null;
  const addFirms = allFirms
    .filter((firm) => selectedFirms.includes(firm.value) && !preSelectedFirms.includes(firm.value))
    .map((firm) => {
      return { firm_type: firm.value, user_id: targetUserId };
    });
  const removeFirms = preSelectedFirms.filter((preSelected) => !selectedFirms.includes(preSelected));
  return { addFirms, removeFirms };
};

export const firmsOptionObject = (firmsOptionData: GetFirmInterestSelectionsQuery, selectedOptions: string[]) =>
  firmsOptionData?.grad_interest_firm_selection.map((firm) => ({
    id: firm.interest_firm_selection_id,
    label: firm.value,
    value: firm.value,
    selected: selectedOptions.includes(firm.value),
  }));
