import React, { useEffect, useState } from 'react';
import { ErrorToastMessage, SpinnerPage } from '../../../../components';
import { Redirect } from 'react-router-dom';
import CompanyList from './CompanyList';
import { StudentViewPaths } from '../../StudentViewRoutes';
import { useGetCompaniesListQuery } from 'generated/graphql';
import { CompanyFiltersProvider } from './CompanyList.context';
import { Container, MobileCardContainer } from '../../JobsList/JobList/JobList.styled';
import { useWindowDimensions, inMobile } from 'utils/useDimensions';
import CompanyListHeader from './CompanyListHeader/CompantListHeader';
import CompanyListHeaderMobile from './CompanyListHeader/CompanyListHeaderMobile';
import { useAuth0 } from 'react-auth0-spa';
import { PaginationState } from 'components/common/Pagination';
import CompanyListFooter from './CompanyListFooter';

const CompanyListController: React.FC = () => {
  const { user } = useAuth0();
  const [pagination, setPagination] = useState<PaginationState>({ count: 9, limit: 9, offset: 0 });
  const { width } = useWindowDimensions();
  const [mobileInfiniteLimit, setMobileInfiniteLimit] = useState<number>(18);
  const [currentFilter, setCurrentFilter] = useState<any>();

  const { loading, error, data, refetch } = useGetCompaniesListQuery({
    variables: {
      limit: 9,
      offset: 0,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    onCompleted: (_data) => {
      setPagination({ ...pagination, count: (_data?.recruit_company_aggregate.aggregate?.count as number) || 9 });
    },
  });

  if (error) {
    return (
      <>
        <ErrorToastMessage message={error.message} />
        <Redirect to={StudentViewPaths.home} />
      </>
    );
  }

  const onFilter = (dynamicQuery: any) => {
    refetch({ dynamicQuery, ...pagination, offset: 0 });
    setPagination({
      ...pagination,
      offset: 0,
      count: (data?.recruit_company_aggregate.aggregate?.count as number) || 9,
    });

    setCurrentFilter(dynamicQuery);
  };

  const onPageChange = (offset: number) => {
    refetch({ dynamicQuery: currentFilter, ...pagination, offset });
    setPagination({ ...pagination, offset, count: (data?.recruit_company_aggregate.aggregate?.count as number) || 9 });
  };

  const fetchMoreData = () => {
    const hasMoreJobs =
      Number(data?.recruit_company_aggregate.aggregate?.count) > Number(data?.recruit_company?.length);
    if (hasMoreJobs) {
      refetch({ limit: mobileInfiniteLimit, offset: 0 });
      setMobileInfiniteLimit(mobileInfiniteLimit + 9);
    }
  };

  useEffect(() => {
    setPagination({ ...pagination, count: (data?.recruit_company_aggregate.aggregate?.count as number) || 9 });
  }, [data]);

  if (loading) return <SpinnerPage />;

  return (
    <CompanyFiltersProvider>
      <Container>
        <MobileCardContainer>
          {!inMobile(width) ? (
            <CompanyListHeader onFilter={onFilter} />
          ) : (
            <CompanyListHeaderMobile onFilter={onFilter} />
          )}
          <CompanyList companies={data || null} inMobile={inMobile(width)} fetchMore={fetchMoreData} />
          {!inMobile(width) && (
            <CompanyListFooter
              pagination={pagination}
              onPageChange={onPageChange}
              studentsCount={data?.recruit_company.length || 0}
            />
          )}
        </MobileCardContainer>
      </Container>
    </CompanyFiltersProvider>
  );
};

export default CompanyListController;
