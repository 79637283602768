import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';

import { createOptionId, OptionId } from 'components/Option';
import { useGetStudentProfileQuery } from 'generated/graphql';

import { Spinner } from 'components';
import { useUpdateRelatedSkillsProfileMutationHook } from './ProfileSkillsTab.hooks';
import ProfileSkillsTab from './ProfileSkillsTab';

interface ProfileSkillsTabVariables {
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

const ProfileSkillsTabController: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  const { loading: getProfileLoading, data: getProfileData } = useGetStudentProfileQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [
    updateRelatedProfileSkills,
    { loading: updateRelatedProfileLoading },
  ] = useUpdateRelatedSkillsProfileMutationHook(user.sub);

  const myInfo = getProfileData?.grad_profile[0];

  if (getProfileLoading) return <Spinner />;

  const computerLanguagesOptions = (myInfo?.computer_languages || []).map((language) =>
    createOptionId(language.language, language.computer_language_id),
  );

  const onCancel = () => history.goBack();

  const onSubmit = async (data: ProfileSkillsTabVariables) => {
    const {
      computerLanguages: { newOptions: newComputerLanguagesOptions, deletedOptionsIds: deleteComputerLanguagesIds },
    } = data;

    const technicalLanguagesForUpsert = newComputerLanguagesOptions.map(({ label }) => ({
      user_id: user.sub,
      language: label,
    }));

    await updateRelatedProfileSkills({
      variables: {
        technicalLanguagesForDelete: deleteComputerLanguagesIds,
        technicalLanguagesForUpsert,
      },
    });

    history.goBack();
  };

  if (updateRelatedProfileLoading) return <Spinner />;

  return (
    <ProfileSkillsTab onSubmit={onSubmit} onCancel={onCancel} computerLanguagesOptions={computerLanguagesOptions} />
  );
};

export default ProfileSkillsTabController;
