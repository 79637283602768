import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 1rem;
`;

export const PlaceholderImg = styled.img`
  width: 18rem;
  padding-bottom: 1rem;
`;

export const ClearFilterContainer = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  padding: 1.3rem;
  margin-top: 1rem;
  cursor: pointer;
`;
