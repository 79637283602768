import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;
`;
