import React from 'react';
import {
  CancelButton,
  OptionId,
  OptionIdStrict,
  SaveButton,
  StyledButtons,
  StyledForm,
  StyledFormInputs,
  StyledInputLabel,
} from '../../../../components';
import { InputLegend } from 'views/Wizard/components';
import Disclaimer from '../../../../components/common/Disclaimer';
import { FORM_MESSAGES } from '../../../../utils/constants';
import FirmInterestsPicker from '../../../../components/FirmInterestsPicker/FirmInterestsPicker';
import JobTypeInterestPicker from '../../../../components/JobTypeInterestPicker/JobTypeInterestPicker';
import { EmployerPreferenceSelector, JobRoleInterestPicker, RegionsPicker } from 'components';
import { useForm } from 'react-hook-form';

export interface FormInterface {
  desiredFirms: { current: OptionIdStrict[] };
  desiredJobType: OptionIdStrict;
  interestRegions: OptionIdStrict[];
  interestRoles: { current: OptionIdStrict[] };
  employerPreferences: { newOptions: OptionIdStrict[]; deletedOptionsIds: string[] };
}

interface ProfileJobTabProps {
  onSubmit: (values: FormInterface) => void;
  onCancel: () => void;
  currentRoles: OptionId[];
  currentEmployerPreferences: OptionId[];
  currentRegions: OptionId[];
  currentFirmInterests: OptionId[];
  currentJobType: OptionId;
}

const ProfileJobTab: React.FC<ProfileJobTabProps> = ({
  onSubmit,
  onCancel,
  currentRoles,
  currentEmployerPreferences,
  currentFirmInterests,
  currentJobType,
  currentRegions,
}) => {
  const { handleSubmit, control, setValue, register, errors } = useForm<FormInterface>();
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledInputLabel>Firms of Interest</StyledInputLabel>
        <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
        <FirmInterestsPicker
          name="desiredFirms"
          defaultValue={currentFirmInterests}
          control={control}
          register={register}
          setValue={setValue}
        />
        {errors.desiredFirms && (
          <InputLegend error={!!errors.desiredFirms}>{'Complete this field to move on'}</InputLegend>
        )}
        <StyledInputLabel>Job Type</StyledInputLabel>
        <JobTypeInterestPicker
          name="desiredJobType"
          defaultValue={currentJobType}
          required
          clearable
          control={control}
        />
        {errors.desiredJobType && (
          <InputLegend error={!!errors.desiredJobType}>{'Complete this field to move on'}</InputLegend>
        )}
        <StyledInputLabel>Desired Locations</StyledInputLabel>
        <RegionsPicker name="interestRegions" control={control} defaultValue={currentRegions} />
        {errors.interestRegions && (
          <InputLegend error={!!errors.interestRegions}>{'Complete this field to move on'}</InputLegend>
        )}

        <StyledInputLabel>What I look for in an employer?</StyledInputLabel>
        <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
        <EmployerPreferenceSelector
          name="employerPreferences"
          register={register}
          setValue={setValue}
          values={currentEmployerPreferences}
        />
        {errors.employerPreferences && (
          <InputLegend error={!!errors.employerPreferences}>{'Complete this field to move on'}</InputLegend>
        )}
        <StyledInputLabel>Roles of interest</StyledInputLabel>
        <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
        <JobRoleInterestPicker
          name="interestRoles"
          control={control}
          register={register}
          setValue={setValue}
          defaultValue={currentRoles}
        />
      </StyledFormInputs>
      {errors.interestRoles && (
        <InputLegend error={!!errors.interestRoles}>{'Complete this field to move on'}</InputLegend>
      )}
      <StyledButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfileJobTab;
