import React from 'react';
import {
  StyledCustomUpload,
  StyledFormInputsFlex,
  StyledPhotoEditor,
  StyledRemovePhotoButton,
  StyledScaleInput,
  StyledUploadFile,
  TrashIcon,
} from './PhotoUploader.styled';

import { StyledFormButtons } from '../../../../components/common/StudentsProfileLayout/StudentsProfileLayout.styled';

import { CancelButton, SaveButton, StyledLeftH1 } from '../../../../components';
import AvatarEditor from 'react-avatar-editor';
import { Trash } from '../../../../theme/assets';

interface PhotoUploaderProps {
  editorRef: (editor: AvatarEditor) => void;
  zoom: string;
  image: string;
  imageLoaded: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onZoom: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const PhotoUploader: React.FC<PhotoUploaderProps> = ({
  editorRef,
  image,
  imageLoaded,
  onCancel,
  onDelete,
  onZoom,
  zoom,
  onFile,
  onSubmit,
}) => {
  return (
    <>
      <StyledFormInputsFlex>
        <StyledLeftH1>Manage Profile Photo</StyledLeftH1>
        <StyledPhotoEditor>
          <AvatarEditor
            ref={editorRef}
            borderRadius={1000}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={parseFloat(zoom)}
            image={image}
            style={{ width: '100%', height: 'auto' }}
          />
          {imageLoaded && (
            <StyledRemovePhotoButton onClick={onDelete}>
              <TrashIcon src={Trash} />
            </StyledRemovePhotoButton>
          )}
        </StyledPhotoEditor>

        <StyledScaleInput onChange={onZoom} value={zoom} disabled={!imageLoaded}></StyledScaleInput>

        <StyledCustomUpload>
          <StyledUploadFile onChange={onFile} />
          upload photo
        </StyledCustomUpload>
      </StyledFormInputsFlex>

      <StyledFormButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSubmit}>Save</SaveButton>
      </StyledFormButtons>
    </>
  );
};

export default PhotoUploader;
