import {
  GetUploadDocByUserDocument,
  useDeleteResumeProfileMutation,
  useInsertResumeProfileMutation,
  useUpdateResumeProfileMutation,
} from 'generated/graphql';

export const useDeleteResumeProfileMutationHook = (userId: string) => {
  return useDeleteResumeProfileMutation({
    update: (cache) => {
      cache.writeQuery({
        query: GetUploadDocByUserDocument,
        variables: { userId },
        data: { grad_upload_doc: [] },
      });
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });
};

export const useInsertResumeProfileMutationHook = (userId: string) => {
  return useInsertResumeProfileMutation({
    update: (cache, { data: mutationData }) => {
      if (mutationData && mutationData.insert_grad_upload_doc) {
        cache.writeQuery({
          query: GetUploadDocByUserDocument,
          variables: { userId },
          data: { grad_upload_doc: mutationData.insert_grad_upload_doc.returning },
        });
      }
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });
};

export const useUpdateResumeProfileMutationHook = (userId: string) => {
  return useUpdateResumeProfileMutation({
    update: (cache, { data: mutationData }) => {
      if (mutationData && mutationData.update_grad_upload_doc) {
        cache.writeQuery({
          query: GetUploadDocByUserDocument,
          variables: { userId },
          data: { grad_upload_doc: mutationData.update_grad_upload_doc.returning },
        });
      }
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });
};
