import React from 'react';
import moment from 'moment';
import { useDeleteRegistrationMutationHook, useInsertRegistrationMutationHook } from '../../Event.hooks';
import { toast } from 'react-toastify';
import { useAuth0 } from 'react-auth0-spa';
import { Badge } from 'components/Badge';
import { RegisterEventGray, RegisteredEventRed, SuccessIconTick } from 'theme/assets';
import { inMobile, useWindowDimensions } from 'utils/useDimensions';
import { ToastDiv, ToastTitleContainer, TickIcon, ToastTitle } from '../EventDetailHeader/EventDetailHeader.styled';

import {
  ItemContainer,
  EventInfo,
  EventInfoContainer,
  Link,
  CompanySection,
  AvatarIcon,
  Info,
  Container,
  CapacityIconStyled,
  Expiration,
  Row,
  Icon,
} from './EventDetailContent.styled';
import { Text } from 'theme/typography';
import { palette } from 'theme';
import { getTimeZoneAbbreviation, getHourInCentralTime } from 'utils/dateUtils';
import EventDetailTabs from '../EventDetailTabs';
export interface EventDetailContentProps {
  description: string;
  address: string;
  event_url: string;
  companyName: string;
  companyAddress: string;
  capacity: number;
  title: string;
  eventType: string;
  timezone: string;
  startDatetime: Date;
  endDatetime: Date;
  companyId: string;
  eventId: string;
  isRegistrated: boolean;
  eventImage: string;
  isFull?: boolean;
}

const EventDetailContent: React.FC<EventDetailContentProps> = ({
  description,
  event_url,
  address,
  capacity,
  companyName,
  companyAddress,
  title,
  eventType,
  timezone,
  startDatetime,
  endDatetime,
  companyId,
  eventId,
  isRegistrated,
  eventImage,
  isFull,
}) => {
  const getAbbreviation = getTimeZoneAbbreviation(timezone);
  const timezoneToDisplay = getAbbreviation.startsWith('+') ? `GMT${getAbbreviation}` : getAbbreviation;
  const { user } = useAuth0();
  const { width } = useWindowDimensions();
  const [insertRegistration] = useInsertRegistrationMutationHook(eventId, user.sub);
  const [deleteRegistration] = useDeleteRegistrationMutationHook(eventId, user.sub);

  const formatDate = (date: Date): string => `${moment(date).format('MMMM DD, YYYY - HH:MM')} ${timezoneToDisplay}`;

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} />
        <ToastTitle>You’ve registered to participate on the event successfully</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>This event is full</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  return (
    <Container>
      <EventInfoContainer>
        <CompanySection>
          <Row>
            <EventInfo>
              <AvatarIcon src={eventImage} />
              <Info>
                <Text variant="xl" color={palette.gray900} bold>
                  {title}
                </Text>
                <Text variant="m" color={palette.gray900}>
                  {`${companyName},`} {`${companyAddress}`}
                </Text>

                <Expiration>
                  <Badge variant={'solid'} color={eventType === 'Online' ? 'gamma' : 'alpha'} size={'medium'}>
                    {eventType}
                  </Badge>
                  <CapacityIconStyled />
                  <Text variant="s" color={palette.gray400}>
                    {capacity === 0 ? 'Unlimited' : capacity}
                  </Text>
                </Expiration>
              </Info>
            </EventInfo>
            {!inMobile(width) && (
              <Icon
                src={isRegistrated ? RegisteredEventRed : RegisterEventGray}
                onClick={() =>
                  isRegistrated
                    ? deleteRegistration()
                    : isFull
                    ? notifyError()
                    : insertRegistration().then(() => notifySuccess())
                }
              />
            )}
          </Row>
        </CompanySection>
        <ItemContainer>
          <Text variant="l" color={palette.gray500}>
            Start:
          </Text>
          <Text bold variant="l" color={palette.alpha500Red}>
            {formatDate(startDatetime)}
          </Text>
        </ItemContainer>
        <ItemContainer>
          <Text variant="l" color={palette.gray500}>
            End:
          </Text>
          <Text bold variant="l" color={palette.alpha500Red}>
            {formatDate(endDatetime)}
          </Text>
        </ItemContainer>
        {address && (
          <ItemContainer>
            <Text variant="l" color={palette.gray500}>
              Address:
            </Text>
            <Text bold variant="l" color={palette.alpha500Red}>
              {address}
            </Text>
          </ItemContainer>
        )}
        {event_url && (
          <ItemContainer>
            <Text variant="l" color={palette.gray500}>
              Place:
            </Text>
            <Link href={`http://${event_url}`} target="_blank" rel="noopener noreferrer">
              {event_url}
            </Link>
          </ItemContainer>
        )}
      </EventInfoContainer>
      <EventDetailTabs eventDescription={description} companyId={companyId} />
    </Container>
  );
};

export default EventDetailContent;
