import React from 'react';
import { Text } from 'theme/typography';
import { useAuth0 } from 'react-auth0-spa';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useDeleteRegistrationMutationHook, useInsertRegistrationMutationHook } from '../../Event.hooks';
import { StudentViewPaths } from 'views/Student';
import { useWindowDimensions, inMobile } from 'utils/useDimensions';
import {
  Icon,
  Container,
  HeaderIcon,
  ToastDiv,
  ToastTitle,
  ToastTitleContainer,
  TickIcon,
} from './EventDetailHeader.styled';
import { palette } from 'theme';
import { RegisteredEvent, RegisterEvent, SuccessIconTick } from 'theme/assets';
export interface DetailHeaderProps {
  title?: string;
  eventImage?: string;
  isRegistrated?: boolean;
  eventId: string;
  isFull?: boolean;
}

const EventDetailHeader: React.FC<DetailHeaderProps> = ({ eventImage, isRegistrated, eventId, isFull }) => {
  const { user } = useAuth0();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const [insertRegistration] = useInsertRegistrationMutationHook(eventId, user.sub);
  const [deleteRegistration] = useDeleteRegistrationMutationHook(eventId, user.sub);

  const successContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <TickIcon src={SuccessIconTick} />
        <ToastTitle>You’ve registered to participate on the event successfully</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const errorContent = (
    <ToastDiv>
      <ToastTitleContainer>
        <ToastTitle>This event is full</ToastTitle>
      </ToastTitleContainer>
    </ToastDiv>
  );

  const notifySuccess = () =>
    toast.success(successContent, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(errorContent, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  return (
    <Container
      style={{ background: inMobile(width) ? `url(${eventImage}) center no-repeat rgba(0, 0, 0, 0.447662)` : '' }}
    >
      <Icon
        colorFill={inMobile(width) ? palette.white : palette.black}
        onClick={() => history.push(StudentViewPaths.events)}
      />
      {!inMobile(width) && (
        <Text variant="xl" bold>
          Events
        </Text>
      )}
      <HeaderIcon
        src={isRegistrated ? RegisteredEvent : RegisterEvent}
        onClick={() =>
          isRegistrated
            ? deleteRegistration()
            : isFull
            ? notifyError()
            : insertRegistration().then(() => notifySuccess())
        }
      />
    </Container>
  );
};

export default EventDetailHeader;
