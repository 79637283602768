import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../../library';

export const RelevantWorkDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > * {
    margin-bottom: 0.2rem;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TopEditButton = styled(EditButtonModal)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  fill: ${palette.gray500};
  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const Company = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.black};
  max-width: 23.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CityState = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.gray500};
`;

export const Position = styled.span`
  font-family: 'Roboto';
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-right: 0.3rem;
  color: ${palette.black};
`;

export const CurrentTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.alpha500Red};
  color: ${palette.white};
  border-radius: 2px;
  font-size: 0.8rem;
  font-family: 'RobotoBlack';
  text-transform: uppercase;
  width: 4.7rem;
  height: 1rem;
  margin-left: 0.4rem;
`;

export const WorkDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${palette.gray500};
`;
