import React from 'react';

import InterestRoleCard from './LocationCard';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { CardTitle, TopEditButton, EmptyContainer, Icon, Text } from './Location.styled';

export interface InterestRegions {
  interest_region_id: string;
  region: string;
}

export interface LocationProps {
  willRelocate: boolean;
  interestRegions: InterestRegions[];
  homeTown: string;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const Location: React.FC<LocationProps> = ({
  willRelocate,
  interestRegions,
  homeTown,
  fullProfile = false,
  handleEdit,
}) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Location {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>

      {((interestRegions && interestRegions.length > 0) || Boolean(homeTown)) && (
        <InterestRoleCard willRelocate={willRelocate} interestRegions={interestRegions} homeTown={homeTown} />
      )}
      {(!interestRegions || interestRegions.length === 0) && !homeTown && (
        <EmptyContainer>
          <Icon />
          <Text>No Location Information</Text>
        </EmptyContainer>
      )}
    </CollapsibleWidgetCard>
  );
};

export default Location;
