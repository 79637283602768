import React from 'react';
import { Denominator, Numerator, TestScoreCard, TestScoreTitle, NumberContainer } from './TestScoreCardSingle.styled';

export interface TestProps {
  test: string;
  score: number;
  outof: number | null | undefined;
}

const TestScoreCardSingle: React.FC<TestProps> = ({ test, score, outof }) => {
  return (
    <TestScoreCard>
      <TestScoreTitle>{test}:</TestScoreTitle>
      <NumberContainer>
        <Numerator>{score}</Numerator>
        <Denominator>{`/${outof}`}</Denominator>
      </NumberContainer>
    </TestScoreCard>
  );
};

export default TestScoreCardSingle;
