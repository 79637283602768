import React, { useState } from 'react';
import { StyledFooterContainer, StyledFooter, StyledNavLink, Text, LogoutStyled, EliteGradLogo } from './TopBar.styled';
import { StudentViewPaths } from '../../../views/Student';
import { ReactComponent as Jobs } from 'theme/assets/Jobs.svg';
import { ReactComponent as JobsActive } from 'theme/assets/JobsActive.svg';
import { ReactComponent as Profile } from 'theme/assets/Profile.svg';
import { ReactComponent as ProfileActive } from 'theme/assets/ProfileActive.svg';
import { ReactComponent as Company } from 'theme/assets/Company.svg';
import { ReactComponent as CompanyActive } from 'theme/assets/CompanyActive.svg';
import { ReactComponent as Events } from 'theme/assets/Events.svg';
import { ReactComponent as EventsActive } from 'theme/assets/EventsActive.svg';
import { LogoTopBar } from 'theme/assets';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';

type tabActiveType = 'home' | 'jobs' | 'companies' | 'events';

const TopBar: React.FC = () => {
  const [tabActiveName, setTabActiveName] = useState<tabActiveType>();
  const { logout } = useAuth0();
  const history = useHistory();

  const handleIsTabActive = (path: tabActiveType, match: any) => {
    if (match) {
      setTabActiveName(path);
    }

    return match;
  };

  return (
    <StyledFooterContainer>
      <EliteGradLogo src={LogoTopBar} onClick={() => history.push(StudentViewPaths.root)} />
      <StyledFooter>
        <StyledNavLink to={StudentViewPaths.home} isActive={(match) => handleIsTabActive('home', match)}>
          {tabActiveName === 'home' ? <ProfileActive /> : <Profile />}
          <Text>Profile</Text>
        </StyledNavLink>
        <StyledNavLink to={StudentViewPaths.jobs} isActive={(match) => handleIsTabActive('jobs', match)}>
          {tabActiveName === 'jobs' ? <JobsActive /> : <Jobs />}
          <Text>Opportunities</Text>
        </StyledNavLink>
        <StyledNavLink to={StudentViewPaths.companies} isActive={(match) => handleIsTabActive('companies', match)}>
          {tabActiveName === 'companies' ? <CompanyActive /> : <Company />}
          <Text>Companies</Text>
        </StyledNavLink>
        <StyledNavLink to={StudentViewPaths.events} isActive={(match) => handleIsTabActive('events', match)}>
          {tabActiveName === 'events' ? <EventsActive /> : <Events />}
          <Text>Events</Text>
        </StyledNavLink>
        <LogoutStyled onClick={logout} />
      </StyledFooter>
    </StyledFooterContainer>
  );
};

export default TopBar;
