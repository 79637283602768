import styled from 'styled-components';
import { palette } from '../../../../theme';

export const StyledEditIcon = styled.img`
  position: absolute;

  width: 1.6rem;

  vertical-align: middle;

  fill: ${palette.darkGray};
`;

export const StyledEditUserImage = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledUserImage = styled.img`
  width: 6.4rem;
  height: 6.4rem;

  border-radius: 50%;
  border: 8px solid ${palette.lightGray};

  background: ${palette.watermelon};
  opacity: 0.5;
`;

export const StyledProfilePhotoDetails = styled.div`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 2rem 1.6rem;
`;
