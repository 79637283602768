import styled from 'styled-components';

import { palette } from 'theme';

export const WizardStepTitle = styled.h1`
  margin-top: 2.8rem;
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 2.8rem;
  text-align: left;
  color: ${palette.red};
`;

export const WizardForm = styled.form.attrs({
  noValidate: true, // Prevent browser validations and browser error displaying
})`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

export const WizardContainer = styled.div`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

export const WizardInputLabel = styled.label`
  box-sizing: border-box;
  margin: 0 0 0.2rem;
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${palette.black};
`;

export const WizardRequiredInputLabel = styled.label<{ error?: boolean }>`
  &:after {
    content: ' *';
    color: ${palette.alpha500Red};
  }
  box-sizing: border-box;
  margin: 0 0 0.2rem;
  width: 100%;
  font-family: 'RobotoBold';
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${({ error }) => (error ? palette.alpha500Red : palette.black)};
`;

export const WizardRequiredMessageForm = styled.span`
  &:before {
    content: '* ';
    color: ${palette.alpha500Red};
  }

  color: ${palette.red};
  font-size: 1rem;
`;

export const WizardInput = styled.input<{ error?: boolean }>`
  margin-bottom: 1.4rem;
  box-sizing: border-box;
  border: ${({ error }) => (error ? `1px solid ${palette.alpha500Red}` : `1px solid ${palette.gray200}`)};
  border-radius: 8px;
  width: 100%;
  height: 4.1rem;
  padding: 1rem;
  max-width: 32rem;
  color: ${({ error }) => (error ? palette.alpha500Red : 'hsl(0,0%,20%)')};
  line-height: 1.9rem;
  font-size: 1.4rem;
  margin-top: 0.6rem;
  font-family: 'Roboto';

  ::placeholder {
    color: ${({ error }) => (error ? palette.alpha500Red : palette.brownGray)};
  }

  :focus {
    outline: none;
  }
`;

export const WizardInputShadowed = styled(WizardInput)`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid ${palette.gray200};
  max-width: 30.8rem;
  height: 4.03rem;
  margin-bottom: 0;

  ::placeholder {
    color: ${palette.gray300};
  }
`;

export const WizardFormInputs = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
`;

export const WizardCheckBox = styled.input`
  box-sizing: border-box;
  border: 1px solid ${palette.black};
  border-radius: 8px;
  padding: 0.6rem;

  background-color: ${palette.white};

  :checked ~ span > span {
    color: ${palette.white};
    background-color: ${palette.alpha500Red};
  }
`;

export const WizardCheckBoxRow = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 1.4rem;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: ${palette.black};
`;

export const StepLegend = styled.div`
  margin-bottom: 1.4rem;
  width: 100%;

  font-size: 1.3rem;
  text-align: left;
`;

export const InputLegend = styled.span<{ error?: boolean }>`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ error }) => (error ? palette.alpha500Red : palette.gray500)};
  margin-bottom: 2.3rem;
`;
