import { OptionTypeBase, StylesConfig } from 'react-select';
import palette from './palette';

const font = {
  fontFamily: "'Roboto', sans-serif",
  fontSize: '1.4rem',
  fontWeight: 300,
};

const selectorStyle: StylesConfig<OptionTypeBase, true> = {
  container: (base: any) => ({
    ...base,
    ...font,
    display: 'inline-block',
    width: '100%',
    marginBottom: '1.6rem',
    marginTop: '0.6rem',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: `8px`,
    maxWidth: '32rem',
  }),
  control: (base: any) => ({
    ...base,
    ...font,
    boxShadow: 'none',
    border: `1px solid ${palette.lightGray}`,
    borderRadius: `8px`,
    backgroundColor: 'transparent',
    minHeight: '4.1rem',
    width: '100%',
    '&:hover': {
      borderColor: palette.lightGray,
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    ...font,
    padding: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    ...font,
    padding: 0,
    borderRadius: `8px`,
  }),
  menu: (base: any) => ({
    ...base,
    ...font,
    boxShadow: 'none',
    padding: 0,
    border: `1px solid ${palette.lightGray}`,
    borderRadius: `8px`,
  }),
  input: (base: any) => ({
    ...base,
    ...font,
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    backgroundColor: palette.gray300,
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    backgroundColor: palette.gray300,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.gray300,
      color: palette.black,
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: palette.gray900,
    cursor: 'pointer',
    '&:hover': {
      color: palette.gray900,
    },
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: palette.gray900,
    '& > svg': {
      height: '1.8rem',
      width: '1.8rem',
    },
    '&:hover': {
      color: palette.gray900,
    },
  }),
};

export default selectorStyle;
