import React, { Ref } from 'react';
import styled from 'styled-components';

import RadioButton, { RadioButtonProps } from './RadioButton';

const StyledLabel = styled.label`
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
  width: 6rem;
`;

const StyledLabelText = styled.span`
  padding-left: 1rem;
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export interface RadioButtonWithLabelProps extends RadioButtonProps {
  label: string;
  onClick?: () => void;
  register?: (arg: any) => Ref<HTMLInputElement>;
  required?: boolean;
}

const RadioButtonWithLabel: React.FC<RadioButtonWithLabelProps> = ({
  label,
  onClick,
  register,
  required,
  ...props
}) => {
  return (
    <StyledLabel>
      <RadioButton {...props} register={register} onClick={onClick} required={required} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabel>
  );
};

export default RadioButtonWithLabel;
