import styled from 'styled-components';
import { MEDIA_SIZE } from '../../../../utils/useDimensions';

export const Container = styled.div`
  display: flex;
  padding: 0 3rem;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2rem;
  height: 100%;
  flex: 1;

  @media (min-width: ${MEDIA_SIZE.md}) {
    padding-top: 0;
  } ;
`;
