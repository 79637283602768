import React from 'react';
import styled from 'styled-components';
import { palette } from 'theme';

export const TextArea = styled.textarea`
  border: 1px solid ${palette.lightGray};
  border-radius: 4px;
  background-color: transparent;

  max-width: 100%;
  min-width: 100%;
  max-height: 15rem;
  min-height: 10rem;

  font-size: 1.3rem;
  font-family: 'Roboto';
  line-height: 1.6rem;
  color: ${palette.black};

  padding: 0.8rem;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${palette.lightGray};
  }
`;
