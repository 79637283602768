import React from 'react';

import InterestRoleCard from './InterestRoleCard';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import { CardTitle, TopEditButton } from './InterestRoles.styled';

interface InterestRolesType {
  interest_role_id: string;
  role: string;
}

export interface InterestRolesProps {
  interestRoles: InterestRolesType[];
  fullProfile?: boolean;
  handleEdit: () => void;
}

const InterestRoles: React.FC<InterestRolesProps> = ({ interestRoles, fullProfile = false, handleEdit }) => {
  return (
    <CollapsibleWidgetCard withTitle fixed>
      <CardTitle>Interested Role {fullProfile && <TopEditButton onClick={handleEdit} />}</CardTitle>

      <InterestRoleCard interestRoles={interestRoles} />
    </CollapsibleWidgetCard>
  );
};

export default InterestRoles;
