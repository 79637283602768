import React, { useState } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useLocation } from 'react-router-dom';
import { useGetUploadedDocsForUserLazyQuery } from 'generated/graphql';
import { parseTests } from 'utils/testUtils';
import { StudentViewPaths } from '../StudentViewRoutes';
import { SpinnerPage, OptionId } from 'components';
import Header from './Header';
import TestScores from './TestScores';
import { AwardsTypes } from 'data/awards';
import { ConcentrationTypes } from 'data/degree';
import { createConcentrationOptions } from 'components/MajorMinorSelector/concentrationUtils';
import {
  useGetProfileByUserIdQuery,
  useGetProfileDegreesQuery,
  useGetTestScoresByUserIdQuery,
} from 'generated/graphql';
import { Container, CardsContainer } from './StudentWidget.styled';
import { notify } from '../../../utils/notify';
import { TestTypes } from '../../../data/tests';
import Test from '../../../types/Test';
import { convertMonthToString } from '../../../utils/dateUtils';
import { majorsAsDescription } from '../../../utils/degreeUtils';
import CurrentDegree from './CurrentDegree';
import PreviousDegrees from './PreviousDegrees';
import RelevantWorkExperience from './RelevantWorkExperience';
import InterestFirmTypes from './InterestFirmTypes';
import YearsOfExperience from './YearsOfExperience';
import ProfessionalDesignations from './ProfessionalDesignations';
import TechnicalLanguages from './TechnicalLanguages';
import InterestRoles from './InterestRoles';
import StudentInformation from './StudentInformation';
import Location from './Location';
import Resume from './Resume';
import ExpandComponent from './ExpandComponent';
import { mobileBreakpoint, useWindowDimensions } from 'utils/useDimensions';
import HeaderEdit from '../Profile/HeaderEdit';
import CurrentDegreeEdit from '../Profile/CurrentDegreeEdit';
import TestEdit from '../Profile/TestEdit';
import InterestRolesEdit from '../Profile/InterestRolesEdit';
import InterestFirmTypesEdit from '../Profile/InterestFirmTypesEdit';
import { useModelContext } from '../ModalContext/ModalContext';
import LocationEdit from '../Profile/LocationEdit';
import TechnicalLanguagesEdit from '../Profile/TechnicalLanguagesEdit';
import StudentInformationEdit from '../Profile/StudentInformationEdit';
import RelevantYearsExperienceEdit from '../Profile/RelevantYearsExperienceEdit';
import ProfessionalDesignationsEdit from '../Profile/ProfessionalDesignationsEdit';
import ResumeEdit from '../Profile/ResumeEdit';
import PreviousDegreeEdit from '../Profile/PreviousDegreeEdit';
import WorkExperienceEdit from '../Profile/WorkExperienceEdit';

const MyCardController: React.FC = ({}) => {
  const { user, logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const { modalState, setModalState, degreeIndex, workExperienceIndex } = useModelContext();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const { loading: loadingScores, data: dataScores = { grad_test_score: [] } } = useGetTestScoresByUserIdQuery({
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: (error) => {
      notify('GetMyTestScoresToast', error.message);
    },
  });

  const satTest = dataScores?.grad_test_score?.find(({ test }: { test: string }) => test === TestTypes.SAT) as Test;
  const actTest = dataScores?.grad_test_score?.find(({ test }: { test: string }) => test === TestTypes.ACT) as Test;
  const otherTests = dataScores?.grad_test_score.filter(
    ({ test }: { test: string }) => test !== TestTypes.SAT && test !== TestTypes.ACT,
  ) as Test[];

  const { loading: loadingUniversities, error: errorUniversities, data: dataUniversities } = useGetProfileDegreesQuery({
    skip: !user,
    variables: { userId: user.sub },
  });

  const { loading: loadingUserProfile, error: errorUserProfile, data: userProfile } = useGetProfileByUserIdQuery({
    variables: { userId: user.sub },
  });

  const [queryResume, { loading: loadingResume, data: resumeData }] = useGetUploadedDocsForUserLazyQuery({
    errorPolicy: 'all',
    variables: { userId: user.sub },
  });

  const resumeInfo = resumeData?.grad_profile[0]?.upload_docs;

  if (loadingUserProfile || loadingUniversities || loadingScores) return <SpinnerPage />;
  if (errorUserProfile || !userProfile || errorUniversities) return <div>Error</div>;

  const studentProfile = userProfile.grad_profile?.[0];
  const awards = studentProfile?.awards;
  const degrees = dataUniversities?.grad_degree || [];
  const professionalDesignations = awards
    .filter((award) => award?.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION)
    .map(({ award_category, award_id, award_name }) => ({
      award_category: award_category || '',
      award_id,
      award_name,
    }));

  const [currentDegree, ...previousDegrees] = degrees;

  const handleToggle = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
      queryResume();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const fullProfile = location.pathname === StudentViewPaths.home;
  const interestJobType = studentProfile?.interest_job_types?.[0];
  const majorOptions: OptionId[] = createConcentrationOptions(currentDegree?.concentrations, ConcentrationTypes.MAJOR);
  const workExperiences = studentProfile?.work_experiences;
  return (
    <>
      <Container>
        <Header
          user={user}
          studentProfile={studentProfile}
          awardToShow={professionalDesignations?.[0]?.award_name || ''}
          fullProfile={fullProfile}
          handleEdit={() => setModalState('header')}
        />
        <CardsContainer>
          <CurrentDegree
            awardName={currentDegree?.awards[0]?.award_name}
            completeMonth={convertMonthToString(currentDegree?.complete_month_numeric)}
            completeYear={currentDegree?.complete_year}
            degreeName={currentDegree?.degree}
            gpa={currentDegree?.gpa}
            gpaOutOf={currentDegree?.gpa_outof}
            gpaNotApplicable={currentDegree?.gpa_not_applicable}
            majorName={majorsAsDescription(currentDegree)}
            universityLogo={currentDegree.university?.logo_url}
            universityName={currentDegree.university?.location_name}
            fullProfile={fullProfile}
            handleEdit={() => setModalState('currentDegree')}
          />
          <TestScores
            satTest={satTest}
            actTest={actTest}
            otherTests={otherTests}
            fullProfile={fullProfile}
            handleEdit={() => setModalState('tests')}
          />
          {(!fullProfile || width <= mobileBreakpoint) && (
            <>
              <PreviousDegrees degrees={previousDegrees} fullProfile={fullProfile} />
              <RelevantWorkExperience workExperiences={studentProfile?.work_experiences} fullProfile={fullProfile} />
              <InterestFirmTypes
                interestFirmTypes={studentProfile?.interest_firms}
                fullProfile={fullProfile}
                handleEdit={() => setModalState('interestFirms')}
              />
              <TechnicalLanguages
                languages={studentProfile?.computer_languages}
                fullProfile={fullProfile}
                handleEdit={() => setModalState('computerLanguage')}
              />
              {!isOpen && <ExpandComponent open={isOpen} handleToggle={handleToggle} />}
              {isOpen && (
                <>
                  <YearsOfExperience
                    years={studentProfile?.years_of_relevant_work || ''}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('yearsOfWork')}
                  />
                  <ProfessionalDesignations
                    designations={professionalDesignations}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('professionalDesignation')}
                  />
                  <InterestRoles
                    interestRoles={studentProfile?.interest_roles}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('roles')}
                  />
                  <Location
                    interestRegions={studentProfile?.interest_regions}
                    willRelocate={studentProfile?.will_relocate || false}
                    homeTown={studentProfile?.hometown || ''}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('location')}
                  />
                  <StudentInformation
                    email={studentProfile?.email || ''}
                    employersPreferences={studentProfile?.employer_preferences}
                    communicationMode={studentProfile?.comm_preference || ''}
                    phone={studentProfile?.mobile || ''}
                    funFacts={studentProfile?.fun_facts}
                    languages={studentProfile?.spoken_languages}
                    studentAthlete={studentProfile?.student_athlete}
                    achievements={studentProfile?.achievements}
                    extracurricularActivities={studentProfile?.volunteers}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('studentInformation')}
                  />
                  <Resume
                    loading={loadingResume}
                    fileName={resumeInfo?.[0]?.filename || ''}
                    content={resumeInfo?.[0]?.hex_content || ''}
                    fullProfile={fullProfile}
                    handleEdit={() => setModalState('resume')}
                  />
                  <ExpandComponent open={isOpen} handleToggle={handleToggle} />
                </>
              )}
              {modalState === 'roles' && (
                <InterestRolesEdit setModalOpen={setModalState} interestRolesInitial={studentProfile?.interest_roles} />
              )}
              {modalState === 'interestFirms' && (
                <InterestFirmTypesEdit setModalOpen={setModalState} firmsData={studentProfile?.interest_firms} />
              )}
              {modalState === 'location' && (
                <LocationEdit
                  setModalOpen={setModalState}
                  initialInterestRegions={studentProfile?.interest_regions}
                  willRelocate={studentProfile?.will_relocate || false}
                  hometown={studentProfile?.hometown || ''}
                />
              )}
              {modalState === 'computerLanguage' && (
                <TechnicalLanguagesEdit
                  setModalOpen={setModalState}
                  initialTechnicalLanguages={studentProfile?.computer_languages}
                />
              )}
              {modalState === 'studentInformation' && (
                <StudentInformationEdit
                  email={studentProfile?.email || ''}
                  setModalOpen={setModalState}
                  employersPreferencesInitial={studentProfile?.employer_preferences}
                  commPreference={studentProfile?.comm_preference || ''}
                  mobile={studentProfile?.mobile || ''}
                  funFacts={studentProfile?.fun_facts}
                  spokenLanguages={studentProfile?.spoken_languages}
                  studentAthlete={studentProfile?.student_athlete}
                  achievementsInitial={studentProfile?.achievements}
                  volunteers={studentProfile?.volunteers}
                />
              )}
              {modalState === 'yearsOfWork' && (
                <RelevantYearsExperienceEdit
                  setModalOpen={setModalState}
                  yearsOfRelevantWork={studentProfile?.years_of_relevant_work || null}
                />
              )}
              {modalState === 'professionalDesignation' && (
                <ProfessionalDesignationsEdit
                  setModalOpen={setModalState}
                  professionalDesignations={professionalDesignations}
                />
              )}
              {modalState === 'resume' && <ResumeEdit setModalOpen={setModalState} data={resumeInfo?.[0]} />}
              {modalState === 'degreeEdit' && (
                <PreviousDegreeEdit
                  setModalOpen={setModalState}
                  majorOptions={createConcentrationOptions(
                    previousDegrees?.[degreeIndex]?.concentrations || [],
                    ConcentrationTypes.MAJOR,
                  )}
                  gpa={previousDegrees?.[degreeIndex]?.gpa}
                  gpa_outof={previousDegrees?.[degreeIndex]?.gpa_outof}
                  gpa_not_applicable={previousDegrees?.[degreeIndex]?.gpa_not_applicable}
                  start_month_numeric={previousDegrees?.[degreeIndex]?.start_month_numeric || null}
                  start_year={previousDegrees?.[degreeIndex]?.start_year}
                  complete_month_numeric={previousDegrees?.[degreeIndex]?.complete_month_numeric || null}
                  complete_year={previousDegrees?.[degreeIndex]?.complete_year}
                  degree_level={previousDegrees?.[degreeIndex]?.degree_level}
                  degreeId={previousDegrees?.[degreeIndex]?.degree_id}
                  degree={previousDegrees?.[degreeIndex]?.degree}
                  fillInUniversity={previousDegrees?.[degreeIndex]?.fill_in_university?.institution_name || ''}
                  universityId={previousDegrees?.[degreeIndex]?.university?.university_selection_id || ''}
                  universityName={previousDegrees?.[degreeIndex]?.university?.location_name || ''}
                  academicHonors={previousDegrees?.[degreeIndex]?.awards}
                />
              )}
              {modalState === 'workExperienceEdit' && (
                <WorkExperienceEdit
                  setModalOpen={setModalState}
                  isNew={!workExperiences?.[workExperienceIndex]?.work_experience_id}
                  end_year={workExperiences?.[workExperienceIndex]?.end_year || null}
                  end_month_numeric={workExperiences?.[workExperienceIndex]?.end_month_numeric || null}
                  start_year={workExperiences?.[workExperienceIndex]?.start_year || null}
                  start_month_numeric={workExperiences?.[workExperienceIndex]?.start_month_numeric || null}
                  position={workExperiences?.[workExperienceIndex]?.position || ''}
                  current={workExperiences?.[workExperienceIndex]?.current || false}
                  state={workExperiences?.[workExperienceIndex]?.state || null}
                  city={workExperiences?.[workExperienceIndex]?.city || null}
                  company={workExperiences?.[workExperienceIndex]?.company || ''}
                  work_id={workExperiences?.[workExperienceIndex]?.work_experience_id || ''}
                  country={workExperiences?.[workExperienceIndex]?.country || ''}
                />
              )}
            </>
          )}
        </CardsContainer>
      </Container>
      {modalState === 'header' && (
        <HeaderEdit
          setModalOpen={setModalState}
          firstName={studentProfile?.firstname}
          lastName={studentProfile?.lastname}
          designations={professionalDesignations}
          needSponsor={studentProfile?.need_sponsor || false}
          interestJobType={interestJobType}
        />
      )}
      {modalState === 'currentDegree' && (
        <CurrentDegreeEdit
          setModalOpen={setModalState}
          majorOptions={majorOptions}
          gpa={currentDegree?.gpa}
          gpa_outof={currentDegree?.gpa_outof}
          gpa_not_applicable={currentDegree?.gpa_not_applicable}
          complete_month_numeric={currentDegree?.complete_month_numeric || null}
          complete_year={currentDegree?.complete_year}
          degree_level={currentDegree?.degree_level}
          degreeId={currentDegree?.degree_id}
          degree={currentDegree?.degree}
          fillInUniversity={currentDegree?.fill_in_university?.institution_name || ''}
          universityId={currentDegree?.university?.university_selection_id || ''}
          universityName={currentDegree?.university?.location_name || ''}
        />
      )}
      {modalState === 'tests' && <TestEdit setModalOpen={setModalState} testsData={parseTests(dataScores)} />}
    </>
  );
};

export default MyCardController;
