import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { jobPath } from 'views/Student/StudentViewRoutes';
import { Spinner } from 'components';
import { notify } from 'utils/notify';

import { useDeleteBookmarkJobMutationHook, useInsertBookmarkJobMutationHook } from '../JobList/JobList.hooks';

import { useAuth0 } from '../../../../react-auth0-spa';

import {
  GetJobPostingsListPartsFragment,
  useGetCompanyByJobPostingListQuery,
  useInsertRisehandMutation,
  useDeleteRisehandMutation,
  useStudentHasResumeQuery,
} from 'generated/graphql';

import JobCard from '../JobCard';

const JobsListCard: React.FC<GetJobPostingsListPartsFragment> = ({
  account_id,
  job_posting_id,
  position_title,
  job_type,
  region,
  raise_hands,
  bookmark_jobs,
  deadline,
}) => {
  const { user } = useAuth0();
  const history = useHistory();

  const { data, loading } = useGetCompanyByJobPostingListQuery({
    skip: !account_id,
    variables: {
      accountId: account_id,
    },
  });
  const [insertBookmark] = useInsertBookmarkJobMutationHook(job_posting_id, bookmark_jobs, user.sub);
  const [deleteBookmark] = useDeleteBookmarkJobMutationHook(job_posting_id, bookmark_jobs, user.sub);
  const [insertRisehand] = useInsertRisehandMutation({
    variables: { jobId: job_posting_id, userId: user.sub },
    refetchQueries: ['GetJobPostingsList', 'GetJobsByCompanyAccount'],
  });
  const [deleteRisehand] = useDeleteRisehandMutation({
    variables: { jobId: job_posting_id, userId: user.sub },
    refetchQueries: ['GetJobPostingsList', 'GetJobsByCompanyAccount'],
  });
  const { data: studentResumeData } = useStudentHasResumeQuery({ variables: { userId: user.sub } });

  const company = data?.recruit_company[0];

  const onClickBookmark = () => (bookmark_jobs.length > 0 ? deleteBookmark() : insertBookmark());

  const onClickRisehand = () => {
    const studentResumesCount = studentResumeData?.grad_profile[0].upload_docs_aggregate.aggregate?.count;
    if (!Boolean(studentResumesCount)) {
      notify('hasNoResume', 'To raise your hand firts upload a Resume');
    }

    raise_hands?.length > 0 ? deleteRisehand() : insertRisehand();
  };

  if (!company || loading) return <Spinner />;

  const deadlineDate = moment(deadline, 'YYYY-MM-DD');

  return (
    <JobCard
      title={position_title}
      jobType={job_type}
      hasBookmark={bookmark_jobs.length > 0}
      hasRisehand={raise_hands.length > 0}
      companyAvatar={company?.logo || ''}
      companyName={company.name}
      companyLocation={region}
      expirationDaysLeft={Math.abs(moment().diff(deadlineDate, 'days')).toString()}
      onClickBookmark={onClickBookmark}
      onClickRisehand={onClickRisehand}
      redirectToJobDetail={() => history.push(jobPath(job_posting_id))}
    />
  );
};

export default JobsListCard;
