export interface Option {
  value: number | string;
  label: number | string;
}

export interface OptionGPAOutOf {
  value: number;
  label: string;
}

export interface OptionMonth {
  value: number;
  label: string;
}

export interface OptionYear {
  value: number;
  label: number;
}

export interface OptionId {
  value: string;
  label: string;
  id?: string;
}

export interface OptionIdStrict {
  value: string;
  label: string;
  id: string;
}

export const createOptionId = (value: string, id?: string): OptionId => ({
  label: value,
  value,
  ...(id !== undefined && { id }),
});
