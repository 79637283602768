import React from 'react';
import Select, { mergeStyles } from 'react-select';
import { Controller, FormContextValues } from 'react-hook-form';
import { selectorStyle } from 'theme';
import { OptionId } from './Option';
import { palette } from 'theme';
export interface SelectorMultiProps extends Pick<FormContextValues, 'control'> {
  options: OptionId[];
  defaultValue: OptionId[] | null;
  name: string;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
  error?: boolean;
}

const SelectorMulti: React.FC<SelectorMultiProps> = ({
  options,
  defaultValue,
  name,
  control,
  required = true,
  isClearable = false,
  placeholder = 'Select...',
  error = false,
}) => {
  const overrideStyles = {
    container: (base: object) => ({
      ...base,
      height: 'auto',
      minHeight: '3.4rem',
      marginBottom: error ? '0.8rem' : '1.6rem',
    }),
    control: (base: object) => ({
      ...base,
      height: 'auto',
      minHeight: '3.4rem',
      border: ` 1px solid ${error ? palette.alpha500Red : palette.gray200}`,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray900,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray400,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : 'hsl(0,0%,20%)',
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

  const select = (
    <Select
      options={options}
      isClearable={isClearable}
      isMulti={true}
      maxMenuHeight={180}
      styles={selectorOwnStyles}
      placeholder={placeholder}
      error={error}
    />
  );
  return (
    <Controller
      as={select}
      defaultValue={defaultValue}
      name={name}
      control={control}
      onChange={([selected]) => {
        return { value: selected };
      }}
      rules={{
        validate: (data) => {
          if (required) {
            return data?.length > 0;
          }
          return true;
        },
      }}
    />
  );
};

export default SelectorMulti;
