import React from 'react';
import CompanyDetail from './CompanyController';
import { MainViewLayout } from 'components';

const Event: React.FC = () => {
  return (
    <MainViewLayout>
      <CompanyDetail />
    </MainViewLayout>
  );
};

export default Event;
