import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'theme';

const StyledCheckBox = styled.span`
  position: relative;

  width: 1.9rem;
  height: 1.9rem;
  margin: 0 0.5em 0 0;
`;

interface StyledCustomCheckBoxProps {
  checked?: boolean;
  black?: boolean;
}

const StyledCustomCheckBox = styled.span<StyledCustomCheckBoxProps>`
  position: absolute;

  width: 1.6rem;
  height: 1.6rem;

  border-radius: 2px;
  border: ${({ black }) => (black ? `solid 2px ${palette.black}` : `solid 2px ${palette.alpha500Red}`)};

  ${({ checked, black }) =>
    checked &&
    css`
      background-color: ${black ? palette.black : palette.alpha500Red};
    `}
  :after {
    position: absolute;
    content: '';
    top: 0.01rem;
    left: 0.4rem;
    height: 0;
    width: 0;

    border-radius: 4px;
    border: 0.5px solid ${palette.white};
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1);
        width: 0.4rem;
        height: 0.9rem;
        background-color: transparent;
        border-radius: 0;
      `}
  }
`;

export interface CheckBoxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  black?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange = () => undefined, black, ...props }) => {
  return (
    <StyledCheckBox {...props}>
      <StyledCustomCheckBox black={black} checked={checked} onClick={() => onChange(!checked)} />
    </StyledCheckBox>
  );
};

export default CheckBox;
