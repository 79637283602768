import styled from 'styled-components';
import { palette } from 'theme';
import { MEDIA_SIZE } from 'utils/useDimensions';

export const Requirement = styled.div`
  font-size: 1.6rem;
  padding-top: 0.5rem;
  color: ${palette.gray500};
`;

export const FirmSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 4rem);
  grid-gap: 0.8rem 1.1rem;

  width: 100%;
  margin: 4rem 3rem 1rem;

  @media (max-width: ${MEDIA_SIZE.s}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, 4rem);
  }
`;
