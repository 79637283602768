import React from 'react';

import palette from 'theme/palette';
import { Text } from 'theme/typography';
import { Badge } from 'components/Badge';

import { Container } from './Tab.styled';

export type TabStyles = 'enclosed' | 'rounded';

interface TabProps {
  variant: TabStyles;
  active?: boolean;
  number?: number;
  label?: string;
  darkMode?: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ variant = 'enclosed', active, number, label, onClick }) => {
  return (
    <Container variant={variant} active={active} onClick={() => onClick()}>
      <Text variant="l" bold={active} color={active ? palette.alpha500Red : palette.black}>
        {label}
      </Text>
      {number && (
        <Badge variant="solid" color="alpha" size="small">
          {number}
        </Badge>
      )}
    </Container>
  );
};

export default Tab;
