import React from 'react';

import { StyledCard } from 'components';
import { Tag } from '../../library';
import { Container, Row, Title, Elements, TagContainer } from './LocationCard.styled';
import { InterestRegions } from '../Location';

export interface LocationProps {
  willRelocate: boolean;
  interestRegions: InterestRegions[];
  homeTown: string;
}

const LocationListCard: React.FC<LocationProps> = ({ willRelocate, interestRegions, homeTown }) => {
  return (
    <StyledCard>
      <Container>
        {interestRegions && interestRegions.length > 0 && (
          <>
            <Title>Interested regions:</Title>
            <TagContainer>
              {interestRegions?.map((region) => (
                <Tag key={region.interest_region_id}>{region?.region}</Tag>
              ))}
            </TagContainer>
          </>
        )}
        {homeTown && (
          <Row>
            <Title>Hometown:</Title>
            <Elements> {homeTown}</Elements>
          </Row>
        )}
        {willRelocate !== null && (
          <Row>
            <Title>Willing to relocate:</Title>
            <Elements>{willRelocate ? 'Yes' : 'No'}</Elements>
          </Row>
        )}
      </Container>
    </StyledCard>
  );
};

export default LocationListCard;
