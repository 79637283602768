import { RecruitCompanyBoolExp } from 'generated/graphql';
import { generateWhereQuery, WhereQueryInterface } from 'utils/queryUtils';

interface CompanyDynamicQueryProps {
  subIndustries?: string[];
  location?: string;
}

const useCompanyDynamicQuery = ({
  subIndustries,
  location,
}: CompanyDynamicQueryProps): RecruitCompanyBoolExp | null => {
  const subIndustryQuery: WhereQueryInterface<RecruitCompanyBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { subindustries: { value: { _in: subIndustries } } },
  };

  const locationQuery: WhereQueryInterface<RecruitCompanyBoolExp> = {
    enabled: true,
    operator: '_and',
    whereQuery: { address: { _like: `%${location}%` } },
  };

  const activeFilters: WhereQueryInterface<object>[] = [];

  Boolean(subIndustries?.length) && activeFilters.push(subIndustryQuery);
  Boolean(location) && activeFilters.push(locationQuery);

  const dynamicQuery = generateWhereQuery(activeFilters);

  return dynamicQuery;
};

export default useCompanyDynamicQuery;
