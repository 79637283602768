import React, { useEffect, useState } from 'react';
import { mobileBreakpoint, useWindowDimensions } from 'utils/useDimensions';
import Login from './Login';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import { Container } from './Login.styled';
import {
  AUTH_PARAMS,
  getAuthParams,
  hasAllAuthParams,
  generateAuth0Lock,
} from 'views/Wizard/SignUpStep/SignUpStep.utils';
import { useAuth0 } from 'react-auth0-spa';

const onMobile = (width: number): boolean => width <= mobileBreakpoint;

const onSafariOrIphone = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  return (
    !(ua.indexOf('chrome') != -1) && (ua.indexOf('safari') > -1 || ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)
  );
};

const LoginController: React.FC = () => {
  const { width } = useWindowDimensions();
  const [subStep, setSubStep] = useState(1);
  const [authParams, setAuthParams] = useState(AUTH_PARAMS);
  const { buildAuthorizeUrl, loginWithRedirect } = useAuth0();

  useEffect(() => {
    async function getState() {
      const urlString = await buildAuthorizeUrl({});
      const generatedAuthParams = getAuthParams(urlString);
      setAuthParams(generatedAuthParams);
    }

    if (onSafariOrIphone()) {
      loginWithRedirect();
    } else {
      getState();
    }
  }, []);

  useEffect(() => {
    if (!hasAllAuthParams(authParams)) return;
    const lock = generateAuth0Lock(authParams, '', true, 'login-container');
    ((onMobile(width) && subStep === 2) || !onMobile(width)) && lock.show();
  }, [subStep, authParams]);

  return (
    <Container>
      {(!onMobile(width) || (onMobile(width) && subStep === 2)) && <LoginHeader onMobile={onMobile(width)} />}
      <Login subStep={subStep} onMobile={onMobile(width)} />
      {onMobile(width) && <LoginFooter subStep={subStep} setSubStep={setSubStep} />}
    </Container>
  );
};

export default LoginController;
