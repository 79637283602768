import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { palette } from 'theme';

const WidgetCard: React.FC<{ children: ReactElement; collapsible?: boolean }> = ({ children, collapsible = false }) => {
  return <CardContainer collapsible={collapsible}>{children}</CardContainer>;
};

export const CardContainer = styled.div<{ collapsible?: boolean; transitionName?: string; appearDuration?: number }>`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  background-color: ${palette.white};
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  padding-bottom: ${({ collapsible }) => (collapsible ? '0.6rem' : '1rem')};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  width: 100%;
  z-index: 10;
`;

export default WidgetCard;
