/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Badge } from 'components/Badge';
import { palette } from 'theme';
import { useHistory } from 'react-router-dom';
import { formatMonthYear, getTimeZoneAbbreviation } from 'utils/dateUtils';
import { Text } from 'theme/typography';
import { eventsPath } from 'views/Student/StudentViewRoutes';
import {
  ItemContainer,
  CapacityIconStyled,
  CompanySection,
  AvatarIcon,
  Info,
  Container,
  HeaderIcon,
  HeaderIconsContainer,
  CardHeader,
  Expiration,
  CardBody,
  Ellipsis,
  ItemContainerRow,
  UrlAddress,
  Link,
} from './EventCard.styled';
import { Apple, RegisterEvent, RegisteredEvent } from 'theme/assets';
import { GetEventListActivePartsFragment } from 'generated/graphql';

interface EventProps extends GetEventListActivePartsFragment {
  isRegistrated: boolean;
  companyAvatar: string;
  companyName: string;
  companyLocation: string;
  onClickRegister: (e: any) => void;
}

const EventListCard: React.FC<EventProps> = ({
  title,
  image,
  capacity,
  start_datetime,
  end_datetime,
  event_type,
  event_id,
  event_url,
  address,
  isRegistrated,
  companyAvatar,
  companyName,
  companyLocation,
  timezone,
  onClickRegister,
}) => {
  const getAbbreviation = getTimeZoneAbbreviation(timezone);
  const timezoneToDisplay = getAbbreviation.startsWith('+') ? `GMT${getAbbreviation}` : getAbbreviation;
  const history = useHistory();

  const handleClickLink = (event: any, url: string) => {
    window.open(url, '_blank');
    event.stopPropagation();
  };

  return (
    <Container onClick={() => history.push(eventsPath(event_id))}>
      <CardHeader style={{ background: `url(${image}) rgba(0, 0, 0, 0.447662) no-repeat` }}>
        <Info>
          <ItemContainer>
            <Text variant="xl" bold color={palette.white}>
              {formatMonthYear(start_datetime)}
            </Text>
          </ItemContainer>
          <ItemContainerRow>
            <Badge variant={'subtle'} color={event_type === 'Online' ? 'gamma' : 'alpha'} size={'medium'}>
              {event_type}
            </Badge>
            <UrlAddress variant="m" color={palette.white} bold underline={event_type === 'Online'}>
              {event_type === 'Online' ? (
                <Link onClick={(e) => handleClickLink(e, event_url || '')}>{event_url}</Link>
              ) : (
                address
              )}
            </UrlAddress>
          </ItemContainerRow>
        </Info>
        <HeaderIconsContainer>
          <HeaderIcon src={isRegistrated ? RegisteredEvent : RegisterEvent} onClick={(e) => onClickRegister(e)} />
        </HeaderIconsContainer>
      </CardHeader>
      <CardBody>
        <Ellipsis variant="xl" bold>
          {title}
        </Ellipsis>
        <CompanySection>
          <AvatarIcon src={companyAvatar || Apple} />
          <Info>
            <Text variant="m">{companyName}</Text>
            <Text variant="s" color={palette.gray400}>
              {companyLocation}
            </Text>
          </Info>
          <Expiration>
            <CapacityIconStyled />
            <Text variant="s" color={palette.gray400}>
              {capacity === 0 ? 'Unlimited' : capacity}
            </Text>
          </Expiration>
        </CompanySection>
      </CardBody>
    </Container>
  );
};

export default EventListCard;
