import React, { useEffect, useState } from 'react';
import { toBase64 } from 'utils/fileUtils';
import {
  useInsertResumeProfileMutationHook,
  useUpdateResumeProfileMutationHook,
} from '../../../Wizard/ResumeStep/ResumeUploader.hooks';
import ResumeUploader from 'views/Wizard/ResumeStep/ResumeUploader';
import { OptionId } from 'components';
import { useAuth0 } from 'react-auth0-spa';
import ModalOpenType from 'types/ModalOpen';
import Modal from 'components/common/EditProfileModal';
import { Container } from './ResumeEdit.styled';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface Resume {
  hex_content: string;
  filename: string;
  upload_doc_id: string;
}

export interface InterestRoleEditVariables {
  interestRoles: { current: OptionId[] };
}

interface InterestRolesEditProps {
  data?: Resume | null;
  setModalOpen: (state: ModalOpenType) => void;
}

const InterestRolesEditStep: React.FC<InterestRolesEditProps> = ({ data, setModalOpen }) => {
  const { width } = useWindowDimensions();
  const {
    user: { sub: userId },
  } = useAuth0();

  const [newFileName, setNewFileName] = useState<string>(data?.filename || '');
  const [newFile, setNewFile] = useState<File | null>();
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [insertUploadDocMutation] = useInsertResumeProfileMutationHook(userId);
  const [updateUploadDocMutation] = useUpdateResumeProfileMutationHook(userId);

  useEffect(() => {
    setNewFileName(data?.filename || '');
  }, [data]);

  const onSubmit = async () => {
    if (!newFile) {
      return;
    }

    const hexContent = await toBase64(newFile);
    setUploadingFile(true);
    if (data?.upload_doc_id) {
      await updateUploadDocMutation({
        variables: {
          uploadDocId: data?.upload_doc_id,
          fileName: newFile.name,
          hexContent,
        },
        refetchQueries: ['GetUploadedDocsForUser'],
      });
    } else {
      await insertUploadDocMutation({
        variables: {
          fileName: newFile.name,
          hexContent,
          userId,
        },
        refetchQueries: ['GetUploadedDocsForUser'],
      });
    }
    setUploadingFile(true);
    setNewFile(null);
    setModalOpen('');
  };
  return (
    <Modal setModalOpen={setModalOpen} handleSubmit={onSubmit} heightAuto={width > mobileBreakpoint} title={'Resume'}>
      <Container>
        <ResumeUploader
          setFile={setNewFile}
          file={newFileName}
          setNewFileName={setNewFileName}
          loading={uploadingFile}
          withoutTitle
        />
      </Container>
    </Modal>
  );
};

export default InterestRolesEditStep;
