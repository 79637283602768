import React, { Dispatch, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { mergeStyles } from 'react-select';
import { palette, selectorStyle } from 'theme';

import { Container, FilterButton } from './FilterDrawer.styled';

const overrideStyles = {
  container: (base: object) => ({
    ...base,
    height: '3.4rem',
    margin: 0,
    color: `${palette.gray400}`,
  }),
  control: (base: object) => ({
    ...base,
    minHeight: '3.4rem',
    height: '3.4rem',
    borderRadius: '8px 0px 0px 8px',
    border: `none`,
    color: `${palette.gray400}`,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: palette.white,
  }),
};

const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

interface FilterDrawerProps {
  children: React.ReactNode;
  placeholder: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ children, isOpen, setIsOpen, placeholder }) => {
  return (
    <Container>
      <Select isSearchable={false} styles={selectorOwnStyles} menuIsOpen={false} placeholder={placeholder} />
      <FilterButton onClick={() => setIsOpen(!isOpen)} />
      {isOpen && ReactDOM.createPortal(children, document.body)}
    </Container>
  );
};

export default FilterDrawer;
