import React, { useState } from 'react';
import useJobsDynamicQuery from './useEventsDynamicQuery';

export interface EventFiltersProps {
  keyword: string;
  registered: boolean;
}

interface EventFiltersContextProps {
  filters: EventFiltersProps;
  handleFilterChange: (value: Partial<EventFiltersProps>) => void;
  clearFilters: () => void;
  filterActive: boolean;
}

export const defaultFiltersValue = {
  keyword: '',
  registered: false,
};

const defaultContextValue = {
  filters: defaultFiltersValue,
  handleFilterChange: (value: Partial<EventFiltersProps>) => value,
  clearFilters: () => null,
  filterActive: false,
};

const EventFilters = React.createContext<EventFiltersContextProps>(defaultContextValue);

export const EventFiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<EventFiltersProps>(defaultFiltersValue);

  const handleFilterChange = (value: Partial<EventFiltersProps>) => setFilters({ ...filters, ...value });
  const filterActive = filters?.registered || filters?.keyword?.length > 0;
  const clearFilters = () => setFilters(defaultFiltersValue);

  const value = { filterActive, filters, handleFilterChange, clearFilters };

  return <EventFilters.Provider value={value}>{children}</EventFilters.Provider>;
};

export const useEventFiltersContext = () => React.useContext(EventFilters);
