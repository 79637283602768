import React, { useState } from 'react';

export interface JobFiltersProps {
  subIndustry: string[];
  role: string;
  keyword: string;
  hasHandraise: boolean;
  hasBookmark: boolean;
}

interface JobFiltersContextProps {
  filters: JobFiltersProps;
  filterActive: boolean;
  handleFilterChange: (value: Partial<JobFiltersProps>) => void;
  clearFilters: () => void;
}
export const defaultFiltersValue = {
  subIndustry: [],
  role: '',
  keyword: '',
  hasHandraise: false,
  hasBookmark: false,
};

const defaultContextValue = {
  filters: defaultFiltersValue,
  filterActive: false,
  handleFilterChange: (value: Partial<JobFiltersProps>) => value,
  clearFilters: () => null,
};

const JobFilters = React.createContext<JobFiltersContextProps>(defaultContextValue);

export const JobFiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<JobFiltersProps>(defaultFiltersValue);

  const handleFilterChange = (value: Partial<JobFiltersProps>) => setFilters({ ...filters, ...value });

  const clearFilters = () => setFilters(defaultFiltersValue);

  const filterActive =
    filters?.subIndustry?.length > 0 ||
    filters?.role?.length > 0 ||
    filters?.keyword?.length > 0 ||
    filters?.hasHandraise ||
    filters?.hasBookmark;

  const value = { filterActive, filters, handleFilterChange, clearFilters };

  return <JobFilters.Provider value={value}>{children}</JobFilters.Provider>;
};

export const useJobFiltersContext = () => React.useContext(JobFilters);
