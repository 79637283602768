import React from 'react';

import { useForm } from 'react-hook-form';
import { InputLegend } from 'views/Wizard/components';

import { CancelButton, SaveButton } from 'components';
import CityStatePicker, { CityStatePickerOption } from 'components/CityStatePicker';
import CompanyPicker from 'components/CompanyPicker';
import { MonthYearPicker } from 'components/MonthYearPicker';
import {
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledDeleteButton,
  StyledFormInputs,
  StyledInput,
  StyledInputLabel,
  StyledLeftH1,
} from 'components/library';

import {
  StyledFormButtons,
  StyledForm,
} from '../../../../components/common/StudentsProfileLayout/StudentsProfileLayout.styled';

import { GetMyWorkPartsFragment } from 'generated/graphql';

export interface WorkFormVariables {
  position: string | null;
  division: string | null;
  startMonth: { value: number; label: string };
  startYear: { value: number; label: string };
  endMonth: { value: number; label: string };
  endYear: { value: number; label: string };
  jobType: string;
  current: boolean;
  internship: boolean;
  cityStateOption: CityStatePickerOption[];
  companyOption: { value: string; label: string }[];
}

export interface WorkDetailInterface extends Omit<GetMyWorkPartsFragment, 'work_experience_id' | 'user_id'> {
  loading: boolean;
  onSubmit: (data: WorkFormVariables) => Promise<void>;
  onDelete: () => Promise<void>;
  onCancel: () => void;
  isNew: boolean;
}

const WorkDetail: React.FC<WorkDetailInterface> = ({
  onSubmit,
  onDelete,
  isNew,
  end_year,
  end_month_numeric,
  start_year,
  start_month_numeric,
  company,
  position,
  division,
  current,
  state,
  city,
  job_type = 'internship',
  onCancel,
}) => {
  const { register, control, watch, handleSubmit, errors } = useForm<WorkFormVariables>();

  const internship = job_type === 'internship';
  const currentJobPosition = !!watch('current', 'true');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledLeftH1>Relevant Work Experience/Internship</StyledLeftH1>
      <StyledFormInputs>
        <StyledInputLabel>Company</StyledInputLabel>
        <CompanyPicker defaultValue={company} name="companyOption" control={control} clearable required />
        {errors.companyOption && (
          <InputLegend error={!!errors.companyOption}>Complete this field to move on</InputLegend>
        )}

        <StyledInputLabel>Position</StyledInputLabel>
        <StyledInput
          type="text"
          name="position"
          placeholder="Accountant"
          ref={register({ required: true })}
          defaultValue={position || ''}
        />
        {errors.position && <InputLegend error={!!errors.position}>Complete this field to move on</InputLegend>}

        <StyledInputLabel>Division</StyledInputLabel>
        <StyledInput
          type="text"
          name="division"
          placeholder="Energy Team"
          ref={register({ required: false })}
          defaultValue={division || ''}
        />

        <StyledCheckboxContainer>
          <StyledCheckbox type="checkbox" ref={register} name="internship" defaultChecked={internship || false} />
          <StyledInputLabel>Internship experience</StyledInputLabel>
        </StyledCheckboxContainer>

        <StyledCheckboxContainer>
          <StyledCheckbox type="checkbox" ref={register} name="current" defaultChecked={current || false} />
          <StyledInputLabel>Current position</StyledInputLabel>
        </StyledCheckboxContainer>

        <StyledInputLabel>Start Date</StyledInputLabel>
        <MonthYearPicker
          required={true}
          control={control}
          monthFieldName="startMonth"
          yearFieldName="startYear"
          errors={errors}
          defaultMonth={start_month_numeric || null}
          defaultYear={start_year || null}
        ></MonthYearPicker>

        {!currentJobPosition && (
          <>
            <StyledInputLabel>End Date</StyledInputLabel>
            <MonthYearPicker
              required={!currentJobPosition}
              monthFieldName="endMonth"
              yearFieldName="endYear"
              errors={errors}
              control={control}
              defaultMonth={end_month_numeric || null}
              defaultYear={end_year || null}
            ></MonthYearPicker>
          </>
        )}

        <StyledInputLabel>City</StyledInputLabel>
        <CityStatePicker
          defaultCity={city}
          defaultState={state}
          name="cityStateOption"
          control={control}
          clearable
          required={false}
        />

        {!isNew && (
          <StyledDeleteButton onClick={onDelete}>Remove Relevant Work Experience/Internship</StyledDeleteButton>
        )}
      </StyledFormInputs>
      <StyledFormButtons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledFormButtons>
    </StyledForm>
  );
};

export default WorkDetail;
