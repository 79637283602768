import React, { useMemo } from 'react';
import { InputLegend } from 'views/Wizard/components';
import { FormContextValues } from 'react-hook-form';
import SelectorMultiControlled from '../SelectorMultiControlled';
import { OptionId } from '../Option';

import { useGetInterestRoleSelectionsQuery } from 'generated/graphql';

export interface RoleInterestPickerProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue?: OptionId[];
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  legend?: boolean;
  clearErrors?: () => void;
}

const RoleInterestPicker: React.FC<RoleInterestPickerProps> = ({
  name,
  clearable = false,
  required = false,
  defaultValue = [],
  placeholder = undefined,
  control,
  setValue,
  register,
  clearErrors,
  error = false,
  legend = false,
}) => {
  const { loading: loadingRoleInterests, data: dataRoleInterests } = useGetInterestRoleSelectionsQuery();

  const roleInterestOptions = useMemo(() => {
    if (!dataRoleInterests) {
      return [];
    }

    return (dataRoleInterests?.grad_interest_role_selection || []).map((fi) => ({
      label: fi.value,
      value: fi.value,
      id: fi.interest_role_selection_id,
    }));
  }, [dataRoleInterests]);

  return (
    <>
      <SelectorMultiControlled
        name={name}
        options={roleInterestOptions}
        control={control}
        defaultValue={defaultValue}
        isLoading={loadingRoleInterests}
        placeholder={placeholder}
        isClearable={clearable}
        required={required}
        setValue={setValue}
        register={register}
        clearErrors={clearErrors}
        maxSelection={3}
        legend={error || legend}
        error={error}
      />
      <InputLegend error={error}>{error ? 'Complete this field to move on' : 'Enter your top 3 options'}</InputLegend>
    </>
  );
};

export default RoleInterestPicker;
