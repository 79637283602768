import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/useDimensions';

const JobDetailDescription = styled.div`
  padding: 3rem;
  padding-bottom: 1.5rem;

  white-space: pre-wrap;

  max-height: 35rem;
  overflow-y: scroll;

  @media (max-width: ${MEDIA_SIZE.md}) {
    height: 100%;
    max-height: 100%;
    overflow-y: visible;
  }
`;

export default JobDetailDescription;
