import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserType from 'types/User';
import { SpinnerPage } from 'components';
import { useAuth0 } from 'react-auth0-spa';
import { useGetWizardCompleteLazyQuery } from 'generated/graphql';
import { WizardRoutes } from './Wizard';
import { ValidateStep } from './Wizard/ValidateStep';
import { ProfileUnderReview } from './Wizard/ProfileUnderReview';
import StudentView, { StudentViewPaths } from './Student';
import Login from './Login';

export interface StudentRoutesProps {
  user: UserType;
  match?: { path: string };
}

const Routes: React.FC<StudentRoutesProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();
  const [queryWizard, { loading, data }] = useGetWizardCompleteLazyQuery({ fetchPolicy: 'no-cache' });

  if (!props.match || loading) return <SpinnerPage />;

  if (!loading && !data && user) queryWizard({ variables: { userId: user.sub } });
  const goToWizard = !data?.grad_profile[0]?.wizard_completed;
  const emailNotVerified = !data || !data.grad_profile.length || !data.grad_profile[0].email_verified;
  const profileNotVerified = !data || !data.grad_profile.length || !data.grad_profile[0].profile_verified;

  return (
    <Switch>
      <Route exact path={StudentViewPaths.login} component={Login} />
      {!isAuthenticated || (isAuthenticated && goToWizard) ? (
        <Route component={WizardRoutes} />
      ) : emailNotVerified ? (
        <Route component={ValidateStep} />
      ) : profileNotVerified ? (
        <Route component={ProfileUnderReview} />
      ) : (
        <Route component={StudentView} />
      )}
      <Redirect to={StudentViewPaths.root} />
    </Switch>
  );
};

export default Routes;
