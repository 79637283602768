import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { StudentViewPaths } from '../../StudentViewRoutes';
import { StyledEditIcon, StyledEditUserImage, StyledProfilePhotoDetails, StyledUserImage } from './ProfilePhoto.styled';
import { Camera } from '../../../../theme/assets';
import { NavButtonLight } from '../../../../components';

interface ProfilePhotoProps {
  picture: string;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ picture }) => (
  <StyledProfilePhotoDetails>
    <RouterNavLink to={StudentViewPaths.profilePhoto}>
      <StyledEditUserImage>
        <StyledUserImage src={picture} />
        <StyledEditIcon src={Camera}></StyledEditIcon>
      </StyledEditUserImage>
    </RouterNavLink>
    <NavButtonLight text={'Manage Resume'} to={StudentViewPaths.profileCV} />
  </StyledProfilePhotoDetails>
);

export default ProfilePhoto;
