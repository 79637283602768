import {
  GetTestScoresByUserIdQuery,
  GradTestScoreDetailConstraint,
  GradTestScoreDetailInsertInput,
  GradTestScoreDetailUpdateColumn,
  GradTestScoreInsertInput,
  TestScorePartFragment,
  TestScoreDetailPartFragment,
} from 'generated/graphql';

import { isNullOrUndefined } from 'util';
import { TestOutOf, TestSATDetailsOutof, TestSATDetailsTypes, TestTypes } from 'data/tests';

export interface ParsedTests {
  [key: string]: TestScorePartFragment & {
    test_score_details?: { [key: string]: TestScoreDetailPartFragment };
  };
}

export const parseDetails = (testDetails: TestScoreDetailPartFragment[]) =>
  testDetails.reduce((obj, testDetailData) => ({ ...obj, [testDetailData.detail]: testDetailData }), {});

export const parseTests = (tests: GetTestScoresByUserIdQuery): ParsedTests =>
  tests.grad_test_score.reduce(
    (obj, testData) => ({
      ...obj,
      [testData.test]:
        testData.test === TestTypes.SAT
          ? { ...testData, test_score_details: parseDetails(testData.test_score_details) }
          : testData,
    }),
    {},
  );

const generateSAT = (
  satMathScore: number | undefined,
  testsData: ParsedTests,
  satReadingScore: number | undefined,
  satWritingScore: number | undefined,
  user_id: string,
) => {
  const SATMath: GradTestScoreDetailInsertInput = {
    score: satMathScore,
    detail: TestSATDetailsTypes.MATH,
    outof: TestSATDetailsOutof.MATH,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.math && {
        test_score_detail_id: testsData.SAT.test_score_details.math.test_score_detail_id,
      }),
  };
  const SATReading: GradTestScoreDetailInsertInput = {
    score: satReadingScore,
    detail: TestSATDetailsTypes.READING,
    outof: TestSATDetailsOutof.READING,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.reading && {
        test_score_detail_id: testsData.SAT.test_score_details.reading.test_score_detail_id,
      }),
  };
  const SATWriting: GradTestScoreDetailInsertInput = {
    score: satWritingScore,
    detail: TestSATDetailsTypes.WRITING,
    outof: TestSATDetailsOutof.WRITING,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.writing && {
        test_score_detail_id: testsData.SAT.test_score_details.writing.test_score_detail_id,
      }),
  };
  const SATDetails: GradTestScoreDetailInsertInput[] = [SATMath, SATReading, SATWriting].filter(({ score }) => score);

  const SATOutof: number = SATDetails.reduce((acc, { score, outof = 0 }) => {
    return score && outof ? acc + outof : acc;
  }, 0);

  const SAT: GradTestScoreInsertInput = {
    test: TestTypes.SAT,
    ...(testsData.SAT && { test_score_id: testsData.SAT.test_score_id }),
    outof: SATOutof,
    user_id,
    score: (satMathScore || 0) + (satReadingScore || 0) + (satWritingScore || 0),
    test_score_details: {
      data: SATDetails,
      on_conflict: {
        constraint: GradTestScoreDetailConstraint.TestScoreDetailTestScoreDetailIdKey,
        update_columns: [GradTestScoreDetailUpdateColumn.Score],
      },
    },
  };
  return SAT;
};

export const generateTests = ({
  testsData,
  satMathScore,
  satReadingScore,
  satWritingScore,
  user_id,
  gmatScore,
  greScore,
  lsatScore,
  mcatScore,
  actScore,
}: {
  testsData: ParsedTests;
  user_id: string;
  satMathScore: number | undefined;
  satReadingScore: number | undefined;
  satWritingScore: number | undefined;
  gmatScore: number | undefined;
  greScore: number | undefined;
  lsatScore: number | undefined;
  mcatScore: number | undefined;
  actScore: number | undefined;
}) => {
  const SAT: GradTestScoreInsertInput = generateSAT(satMathScore, testsData, satReadingScore, satWritingScore, user_id);
  const GMAT: GradTestScoreInsertInput = {
    ...(testsData.GMAT && { test_score_id: testsData.GMAT.test_score_id }),
    test: TestTypes.GMAT,
    outof: TestOutOf.GMAT,
    user_id,
    score: gmatScore,
  };
  const GRE: GradTestScoreInsertInput = {
    ...(testsData.GRE && { test_score_id: testsData.GRE.test_score_id }),
    test: TestTypes.GRE,
    outof: TestOutOf.GRE,
    user_id,
    score: greScore,
  };
  const LSAT: GradTestScoreInsertInput = {
    ...(testsData.LSAT && { test_score_id: testsData.LSAT.test_score_id }),
    test: TestTypes.LSAT,
    outof: TestOutOf.LSAT,
    user_id,
    score: lsatScore,
  };
  const MCAT: GradTestScoreInsertInput = {
    ...(testsData.MCAT && { test_score_id: testsData.MCAT.test_score_id }),
    test: TestTypes.MCAT,
    outof: TestOutOf.MCAT,
    user_id,
    score: mcatScore,
  };

  const ACT: GradTestScoreInsertInput = {
    ...(testsData.ACT && { test_score_id: testsData.ACT.test_score_id }),
    test: TestTypes.ACT,
    outof: TestOutOf.ACT,
    user_id,
    score: actScore,
  };

  const formTests = [SAT, GRE, LSAT, MCAT, GMAT, ACT];

  const toUpsert = formTests.filter(({ score }) => score);

  const SATReadingId =
    testsData.SAT &&
    testsData.SAT.test_score_details.reading &&
    testsData.SAT.test_score_details.reading.test_score_detail_id;

  const SATMathID =
    testsData.SAT &&
    testsData.SAT.test_score_details.math &&
    testsData.SAT.test_score_details.math.test_score_detail_id;

  const SATWritingID =
    testsData.SAT &&
    testsData.SAT.test_score_details.writing &&
    testsData.SAT.test_score_details.writing.test_score_detail_id;

  let toDeleteDetail = !satReadingScore && SATReadingId ? [SATReadingId] : [];
  if (!satMathScore && SATMathID) {
    toDeleteDetail = [...toDeleteDetail, SATMathID];
  }

  if (!satWritingScore && SATWritingID) {
    toDeleteDetail = [...toDeleteDetail, SATWritingID];
  }

  const toDelete = formTests.reduce<string[]>(
    (accum, test) =>
      !test.score && !isNullOrUndefined(test.test_score_id) ? (accum = [...accum, test.test_score_id]) : accum,
    [],
  );

  return { toUpsert, toDelete, toDeleteDetail };
};
