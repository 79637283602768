import React from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import WrappedToastContainer from './utils/StyledToastContainer';
import { ModelContextProvider } from 'views/Student/ModalContext/ModalContext';
import 'App.css';
import { useAuth0 } from 'react-auth0-spa';

import { SpinnerPage } from 'components';
import useInsertPageViewOnRouteChange from 'utils/pageViews';
import Routes from 'views/routes';

import { StudentViewPaths } from './views/Student/StudentViewRoutes';
import VerifyEmail from 'views/Student/VerifyEmail';

const App: React.FC = () => {
  const { loading } = useAuth0();
  const location = useLocation();
  useInsertPageViewOnRouteChange();

  if (loading) {
    return <SpinnerPage />;
  }
  return (
    <div className="app-container">
      <Switch>
        <ModelContextProvider>
          {location.pathname === '/app/verify-email' ? (
            <Route component={VerifyEmail} />
          ) : (
            <>
              <Route path="/app" component={Routes} />
              <Redirect to={StudentViewPaths.root}></Redirect>
            </>
          )}
        </ModelContextProvider>
      </Switch>
      <WrappedToastContainer />
    </div>
  );
};

export default App;
