import React from 'react';

import { ArrowIcon, Container, TopContainer, Text } from './ExpandComponent.styled';

interface ExpandComponentProps {
  open: boolean;
  handleToggle: () => void;
}

const ExpandComponent: React.FC<ExpandComponentProps> = ({ open, handleToggle }) => {
  return (
    <Container onClick={handleToggle}>
      <TopContainer>
        <Text>{open ? 'See less' : 'Show full profile'}</Text>
      </TopContainer>
      <ArrowIcon props={{ up: open }} />
    </Container>
  );
};

export default ExpandComponent;
