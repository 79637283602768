import React from 'react';
import Select from 'react-select';
import { mergeStyles } from 'react-select';
import { palette, selectorStyle } from 'theme';
import { Text } from 'theme/typography';
import Toggle from 'components/common/Toggle';

import { useEventFiltersContext } from '../EventList/EventList.context';
import useEventsDynamicQuery from '../EventList/useEventsDynamicQuery';

import { DrawerContainer, FiledContainer } from './EventListHeaderDrawer.styled';
import { Spacing } from 'components/Spacing';
import { TextInput } from 'components';
import { Button } from 'components/common/Button';
import { useAuth0 } from 'react-auth0-spa';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';

const overrideStyles = {
  container: (base: object) => ({
    ...base,
    height: '3.4rem',
    marginTop: '0.5rem',
    color: `${palette.gray900}`,
  }),
  control: (base: object) => ({
    ...base,
    minHeight: '3.4rem',
    height: '3.4rem',
    borderRadius: '8px 0px 0px 8px',
    color: `${palette.gray900}`,
    fontSize: '1.2rem',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: palette.white,
  }),
};

const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

interface DrawerProps {
  onApply: () => void;
  onCancel: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ onApply, onCancel }) => {
  const { filters, handleFilterChange, clearFilters, filterActive } = useEventFiltersContext();
  const { keyword, registered } = filters;
  const { user } = useAuth0();
  const { data } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  return (
    <DrawerContainer>
      <Text variant="l" color={palette.alpha500Red} bold>
        Filters
      </Text>
      <Spacing margin="1rem" />
      <Toggle
        active={registered}
        label="Registered"
        onChange={(value: boolean) => handleFilterChange({ registered: !value })}
      />
      <Spacing margin="1rem" />
      <FiledContainer>
        <Text variant="m" bold color={palette.black}>
          Keyword
        </Text>
        <Spacing margin="0.3rem" />
        <TextInput
          placeholder="Text here"
          value={keyword}
          onChange={(keyword) => handleFilterChange({ keyword })}
          customFontSize="1.3rem"
        />
      </FiledContainer>
      <Spacing margin="1rem" />
      <Button size="md" color="beta" isRounded onClick={onApply} isDisabled={!filterActive}>
        <Text variant="l" color="white" bold>
          Apply
        </Text>
      </Button>
      <Spacing margin="0.5rem" />
      <Button
        size="md"
        color="beta"
        isRounded
        onClick={() => {
          clearFilters();
          onApply();
        }}
        variant="outline"
      >
        <Text variant="l" color="beta" bold>
          Clear all filters
        </Text>
      </Button>
      <Spacing margin="0.5rem" />
      {/* TODO: make the apply button applies the filters and implement the cancel button after that */}
      {/* <Button size="lg" color="beta" isRounded variant="ghost" onClick={handleCancel}>
        <Text variant="l" color="beta" bold>
          Cancel
        </Text>
      </Button> */}
    </DrawerContainer>
  );
};

export default Drawer;
