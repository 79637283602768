import React from 'react';
import styled, { css } from 'styled-components';
import { Vector } from 'theme/assets';
import { palette } from 'theme';

interface Props {
  active?: boolean;
  disabled?: boolean;
  previous?: boolean;
  previousActive?: boolean;
  next?: boolean;
  text?: string;
  onClick?: () => void;
}

type StyledPageButtonProps = Omit<Props, 'onClick' | 'text'>;

const StyledPageButton = styled.button.attrs({
  type: 'button',
})<StyledPageButtonProps>`
  min-width: 2.8rem;
  height: 2.8rem;
  padding: 0 0.5rem;
  background-color: ${palette.gray50};
  cursor: pointer;
  border: none;
  font-family: Roboto;
  font-weight: bold;
  margin: 0 0.15rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  border-radius: 0.3rem;
  color: ${palette.black};
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: ${palette.black};

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      stroke: ${palette.gray50};
      color: ${palette.gray100};
    `}

  ${({ previousActive }) =>
    previousActive &&
    css`
      border-left: none;
    `}

  ${({ active }) =>
    active &&
    css`
      background-color: ${palette.alpha500Red};
      color: ${palette.white};
    `}

  ${({ previous }) =>
    previous &&
    css`
      align-items: center;
      min-width: 2.8rem;
      height: 2.8rem;
    `}

  ${({ next }) =>
    next &&
    css`
      align-items: center;
      min-width: 2.8rem;
      height: 2.8rem;
    `}
`;

const ChevronLeft = styled.img.attrs({ src: Vector })``;
const ChevronRight = styled.img.attrs({ src: Vector })`
  transform: rotate(180deg);
`;

const PaginationButton: React.FC<Props> = ({
  active = false,
  previous = false,
  previousActive = false,
  next = false,
  disabled = false,
  text,
  onClick,
}) => {
  return (
    <StyledPageButton
      active={active}
      disabled={disabled}
      next={next}
      previous={previous}
      previousActive={previousActive}
      onClick={onClick}
    >
      {previous && <ChevronLeft />}
      {next && <ChevronRight />}
      {text}
    </StyledPageButton>
  );
};

export default PaginationButton;
