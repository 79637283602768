import React from 'react';
import Linkify from 'react-linkify';
import { HandRed, HandWhite, Hand, HandRised, Bookmark, BookmarkSelected, Apple } from 'theme/assets';
import {
  CardContainer,
  CardHeader,
  Column,
  CompanyAvatar,
  CompanyBasicInfoContainer,
  CompanyContainer,
  CompanyExtraInfoContainer,
  Icon,
  IconsContainer,
  Row,
  TabCardContainer,
} from './JobDetail.styled';
import { Text } from 'theme/typography';
import { Badge } from 'components/Badge';
import { palette } from 'theme';

import TabsSection from 'components/common/TabsSection';
import JobDetailDescription from './JobDetailDescription';
import JobDetailCompany from './JobDetailCompany';
import { GetCompanyByJobPostingDetailQuery } from 'generated/graphql';

import { useWindowDimensions, inMobile } from 'utils/useDimensions';

interface JobDetailProp {
  jobName: string;
  jobType: string;
  deadline: string;
  location: string;
  risedHand: boolean;
  setRisedHand: () => void;
  bookmarked: boolean;
  setBookmarked: () => void;
  description: string;
  company?: GetCompanyByJobPostingDetailQuery;
}

const JobDetail: React.FC<JobDetailProp> = ({
  jobName: title,
  jobType,
  deadline,
  location,
  risedHand,
  setRisedHand,
  bookmarked,
  setBookmarked,
  description,
  company,
}) => {
  const { width } = useWindowDimensions();
  const companyData = company?.recruit_company[0];
  const companyViewObject = {
    name: companyData?.name || '',
    location: companyData?.address || '',
    subIndustries: companyData?.subindustries || [],
    avatar: companyData?.logo || Apple,
    description: companyData?.description || '',
    phone: companyData?.phone || '',
    website: companyData?.website || '',
    employees: companyData?.num_employees || '',
  };

  const Tabs = [
    {
      label: inMobile(width) ? 'Description' : 'Opportunity Description',
      content: (
        <TabCardContainer>
          <JobDetailDescription>
            <Text color={palette.gray700} variant="m">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {description || 'No description provided'}
              </Linkify>
            </Text>
          </JobDetailDescription>
        </TabCardContainer>
      ),
    },
    {
      label: inMobile(width) ? 'Company' : 'About the Company',
      content: (
        <TabCardContainer>
          <JobDetailCompany company={companyViewObject} />
        </TabCardContainer>
      ),
    },
  ];

  return (
    <CardContainer>
      <CardHeader>
        <CompanyContainer>
          <Column>
            <Row>
              <CompanyAvatar src={companyData?.logo || Apple} />
              <CompanyBasicInfoContainer>
                <Text variant="l" bold>
                  {title}
                </Text>
                <Text variant="m">{companyData?.name || ''}</Text>
                <Badge variant="solid" color={jobType === 'intershipt' ? 'alpha' : 'gamma'} size="medium">
                  {jobType}
                </Badge>
              </CompanyBasicInfoContainer>
              <IconsContainer>
                <Icon src={risedHand ? HandRised : Hand} onClick={() => setRisedHand()} />
                <Icon src={bookmarked ? BookmarkSelected : Bookmark} onClick={() => setBookmarked()} />
              </IconsContainer>
            </Row>
            <CompanyExtraInfoContainer>
              <Text variant="l" color={palette.gray500}>
                Deadline:
              </Text>
              <Text variant="l" color={palette.alpha500Red} bold>
                {deadline}
              </Text>
            </CompanyExtraInfoContainer>
            <CompanyExtraInfoContainer>
              <Text variant="l" color={palette.gray500}>
                Location:
              </Text>
              <Text variant="l" color={palette.alpha500Red} bold>
                {location}
              </Text>
            </CompanyExtraInfoContainer>
          </Column>
        </CompanyContainer>
      </CardHeader>
      <TabsSection tabs={Tabs} tabsStyle={inMobile(width) ? 'rounded' : 'enclosed'} />
    </CardContainer>
  );
};

export default JobDetail;
