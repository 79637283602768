import React from 'react';
import styled from 'styled-components';

import { SecondaryLink } from 'components/library';

const StyledNavButton = styled(SecondaryLink)`
  box-sizing: content-box;
  margin-left: 1.6rem;
  padding: 0 1rem;

  min-height: 3.2rem;
  min-width: 8.8rem;

  text-align: center;
  text-transform: capitalize;
  line-height: 3.2rem;
`;

export interface NavButtonLightProps {
  text: string;
  to: string;
}

const NavButtonLight: React.FC<NavButtonLightProps> = ({ text, to, ...props }) => {
  return (
    <StyledNavButton to={to} {...props}>
      {text}
    </StyledNavButton>
  );
};

export default NavButtonLight;
