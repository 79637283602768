import React from 'react';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiCreateV2 from '../SelectorMultiCreateV2';
import { OptionId } from '../Option';

import { useGetSelectorMajorQuery } from 'generated/graphql';

interface MajorSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  required: boolean;
  majorsValue: OptionId[];
  name: string;
  error?: boolean;
  clearErrors?: () => void;
}

export const MajorSelector: React.FC<MajorSelectorProps> = ({
  majorsValue,
  name,
  required,
  register,
  setValue,
  error = false,
  clearErrors,
}) => {
  const { loading, error: errorFetch, data } = useGetSelectorMajorQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const selectorOptions = ((!errorFetch && data && data.grad_major_selection) || []).map(({ major }) => ({
    value: major,
    label: major,
  }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={majorsValue}
      name={name}
      placeholder={'e.g: Computer Technician'}
      required={required}
      register={register}
      setValue={setValue}
      error={error}
      maxSelection={2}
      clearErrors={clearErrors}
    />
  );
};
