/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import { mergeStyles } from 'react-select';
import { selectorStyle } from 'theme';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { palette } from 'theme';

const SEARCH_TIMEOUT_LENGTH = 400;

export interface QueryOption {
  value: string;
  label: string;
}

const QuerySelector: React.FC<{
  searchResults: QueryOption[];
  defaultOptions: QueryOption | null;
  loading: boolean;
  debounceTime: number;
  callFetchQuery: any;
  name: string;
  control: any;
  required?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  error?: boolean;
}> = ({
  searchResults,
  defaultOptions,
  loading,
  debounceTime = SEARCH_TIMEOUT_LENGTH,
  callFetchQuery,
  name,
  control,
  required = true,
  placeholder = 'Search...',
  isClearable = false,
  error = false,
}) => {
  const overrideStyles = {
    control: (base: object) => ({
      ...base,
      height: '4.1rem',
      minHeight: '4.1rem',
      border: ` 1px solid ${error ? palette.alpha500Red : palette.gray200}`,
    }),
    container: (base: any) => ({
      ...base,
      marginBottom: error ? '0.8rem' : '1.6rem',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray900,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: error ? palette.alpha500Red : palette.gray400,
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);
  const defaultValue = defaultOptions?.value !== '' ? defaultOptions : null;
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [submitSearchTimer, setSubmitSearchTimer] = useState(0);
  const [localLoading, setLocalLoading] = useState(loading);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading]);

  useEffect(() => {
    return () => {
      clearTimeout(submitSearchTimer);
    };
  }, [submitSearchTimer]);

  const onInputChange = (displayValue: string, { action }: { action: string }) => {
    if (action !== 'input-change') {
      return;
    }

    setLocalLoading(true);
    setSubmitSearchTimer(
      window.setTimeout(() => {
        setLocalLoading(loading);
        callFetchQuery({ variables: { searchTerm: `%${displayValue}%` } });
      }, debounceTime),
    );
  };
  const onMenuClose = () => {
    setLocalLoading(false);
  };

  const onMenuOpen = useCallback(() => {
    if (isFirstSearch && (!submitSearchTimer || searchResults.length === 0)) {
      setIsFirstSearch(false);
      callFetchQuery({ variables: { searchTerm: '%%' } });
    }
  }, [isFirstSearch, submitSearchTimer, searchResults, callFetchQuery]);

  const onChange = (newSelectedOption: QueryOption, action: object) => {
    setLocalLoading(false);
    setSelectedOption(newSelectedOption);

    if (!newSelectedOption) {
      return [];
    }

    return [newSelectedOption, action];
  };
  return (
    <Controller
      as={
        <StyledSelect
          styles={selectorOwnStyles}
          isSearchable={true}
          isLoading={localLoading}
          options={searchResults}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          placeholder={placeholder}
          isClearable={isClearable}
        />
      }
      defaultValue={[selectedOption]}
      name={name}
      control={control}
      rules={{
        validate: ([value]) => !required || (value !== null && value !== undefined),
      }}
      onChange={([selectedOption, action]: [QueryOption, object]) => onChange(selectedOption, action)}
    />
  );
};

const StyledSelect = styled(ReactSelect)`
  margin-bottom: 1.4rem;
  width: 100%;

  & > div {
    box-shadow: none;
    padding: 0;

    border-radius: 8px;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.7rem;
  }
`;

export default QuerySelector;
