import React, { useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiControlled from '../SelectorMultiControlled';
import { OptionId } from '../Option';

import { useGetFirmInterestSelectionsQuery } from 'generated/graphql';

export interface FirmInterestsPickerProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue?: OptionId[];
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
}

const FirmInterestsPicker: React.FC<FirmInterestsPickerProps> = ({
  name,
  clearable = false,
  required = false,
  defaultValue = [],
  placeholder = undefined,
  control,
  setValue,
  register,
}) => {
  const { loading: loadingFirmInterests, data: dataFirmInterests } = useGetFirmInterestSelectionsQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const firmInterestOptions = useMemo(() => {
    if (!dataFirmInterests) {
      return [];
    }

    return dataFirmInterests.grad_interest_firm_selection.map((fi) => ({
      label: fi.value,
      value: fi.value,
      id: fi.interest_firm_selection_id,
    }));
  }, [dataFirmInterests]);

  return (
    <SelectorMultiControlled
      name={name}
      options={firmInterestOptions}
      control={control}
      defaultValue={defaultValue}
      isLoading={loadingFirmInterests}
      placeholder={placeholder}
      isClearable={clearable}
      required={required}
      setValue={setValue}
      register={register}
      maxSelection={3}
    />
  );
};

export default FirmInterestsPicker;
