import React, { Ref } from 'react';
import styled from 'styled-components';

import palette from 'theme/palette';

const RADIO_BUTTON_SIZE = '1.4rem';

const StyledRadioButton = styled.div`
  position: relative;
  z-index: 0;

  display: inline-block;

  & > input[type='radio'] {
    position: absolute;
    z-index: 1;

    width: ${RADIO_BUTTON_SIZE};
    height: ${RADIO_BUTTON_SIZE};

    opacity: 0;
  }

  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: ${RADIO_BUTTON_SIZE};
    height: ${RADIO_BUTTON_SIZE};

    border-radius: 50%;
    border-width: 2px;
    border-color: ${palette.black};
    background-color: ${palette.white};
    border-style: solid;

    vertical-align: top;
  }

  & > span > span {
    display: inline-block;

    width: calc(${RADIO_BUTTON_SIZE} - 1rem);
    height: calc(${RADIO_BUTTON_SIZE} - 1rem);

    border-radius: 50%;
    border-width: 0px;

    background-color: ${palette.white};
    opacity: 0;
  }

  & > input[type='radio']:checked ~ span > span {
    opacity: 1;
    background-color: ${palette.white};
  }
  & > input[type='radio']:checked ~ span {
    opacity: 1;
    border-color: ${palette.alpha500Red};
    background-color: ${palette.alpha500Red};
  }
`;

export interface RadioButtonProps {
  defaultChecked?: boolean;
  name: string;
  value: string;
  onClick?: () => void;
  register?: (arg: any) => Ref<HTMLInputElement>;
  required?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({ onClick, register, required = false, ...props }) => {
  return (
    <StyledRadioButton>
      <input
        type="radio"
        {...props}
        ref={register && register({ required: required ? true : false })}
        onClick={onClick}
      />
      <span>
        <span />
      </span>
    </StyledRadioButton>
  );
};

export default RadioButton;
