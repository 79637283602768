import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import ResumeUploader from './ResumeUploader';
import { Spinner } from 'components';
import { iOSNativeDownloadResume, requiresIOSNativeDownloadResume } from 'utils/iosUtils';
import { toBase64 } from 'utils/fileUtils';
import GoBackHeader from 'components/common/GoBackHeader';
import { StyledNoWrapContainer } from 'components/library';
import { useGetUploadDocByUserQuery } from 'generated/graphql';
import { ButtonsProps } from 'views/Wizard/ResumeStep/ResumeStepController';
import {
  useDeleteResumeProfileMutationHook,
  useInsertResumeProfileMutationHook,
  useUpdateResumeProfileMutationHook,
} from './ResumeUploader.hooks';

interface ResumeUploaderProps {
  isSubComponent?: boolean;
  WizardButtons: React.FC<ButtonsProps>;
  nextPage: string;
}

const ResumeUploaderController: React.FC<ResumeUploaderProps> = ({ isSubComponent, WizardButtons, nextPage }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();

  const {
    user: { sub: userId },
  } = useAuth0();

  const history = useHistory();

  const [newFile, setNewFile] = useState<File | null>();

  const { loading, data } = useGetUploadDocByUserQuery({
    variables: { userId },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteUploadDocsMutation] = useDeleteResumeProfileMutationHook(userId);
  const [insertUploadDocMutation] = useInsertResumeProfileMutationHook(userId);
  const [updateUploadDocMutation] = useUpdateResumeProfileMutationHook(userId);

  const gradUploadDoc = data && data.grad_upload_doc.length ? data.grad_upload_doc[0] : null;

  const handleOnClickDownload = () => {
    if (!gradUploadDoc) {
      return;
    }

    const { hex_content: content, filename } = gradUploadDoc;

    if (requiresIOSNativeDownloadResume()) {
      iOSNativeDownloadResume(content, filename);
    } else {
      const a = document.createElement('a');
      a.href = content;
      a.download = filename;
      a.click();
    }
  };

  const onSubmit = async () => {
    if (!newFile) {
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    const hexContent = await toBase64(newFile);

    const { grad_upload_doc: [{ upload_doc_id } = { upload_doc_id: null }] = [] } = data || {};

    if (upload_doc_id) {
      await updateUploadDocMutation({
        variables: {
          uploadDocId: upload_doc_id,
          fileName: newFile.name,
          hexContent,
        },
      });
    } else {
      await insertUploadDocMutation({
        variables: {
          fileName: newFile.name,
          hexContent,
          userId,
        },
      });
    }

    setNewFile(null);
    !isSubComponent ? history.goBack() : history.push(nextPage);
  };

  const handleOnClickDelete = () => {
    return deleteUploadDocsMutation({
      variables: { userId },
    });
  };

  const onSkip = () => history.push(nextPage);
  if (loading) return <Spinner />;

  return (
    <StyledNoWrapContainer>
      {!isSubComponent && <GoBackHeader />}
      <ResumeUploader
        isSubComponent={Boolean(isSubComponent)}
        filename={gradUploadDoc?.filename || ''}
        handleOnClickDownload={handleOnClickDownload}
        handleOnClickDelete={handleOnClickDelete}
        WizardButtons={WizardButtons}
        onSkip={onSkip}
        disabled={!gradUploadDoc}
        onSubmit={onSubmit}
        setNewFile={setNewFile}
        fileInputRef={fileInputRef}
        disabledNewFile={!newFile}
        nameNewFile={newFile?.name || null}
      />
    </StyledNoWrapContainer>
  );
};

export default ResumeUploaderController;
