import styled from 'styled-components';
import { palette } from 'theme';

interface TabOptionType {
  active: boolean;
}

export const TabOption = styled.ul<TabOptionType>`
  display: inline-block;
  vertical-align: middle;

  height: 3.5rem;
  margin-bottom: -0.1rem;
  padding: 0.6rem 1rem 0.3rem;
  ${({ active }) => active && `border-bottom: 1px solid ${palette.red}`};

  font-size: 1.4rem;
  font-weight: ${({ active }) => (active ? 'bolder' : 'normal')};

  line-height: 3.5rem;
  list-style: none;
`;

export const TabSelector = styled.div`
  flex-grow: 1;
  overflow: auto;

  width: inherit;
`;

export const TabsMenu = styled.ol`
  flex-shrink: 0;

  width: inherit;

  padding: 0 2rem;
  border-top: 1px solid ${palette.lightGray};
  border-bottom: 1px solid ${palette.lightGray};
`;
