import styled from 'styled-components';
import { palette } from 'theme';
import { EditButtonModal } from '../../library';

export const Img = styled.img`
  margin: 1.6rem 1rem auto 1rem;
  width: 7rem;
`;

export const UniversityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > * {
    margin-bottom: 0.2rem;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const GPA = styled.div<{ hasHonor?: boolean }>`
  display: flex;
  align-items: baseline;
  max-width: 10rem;
  margin-right: ${({ hasHonor }) => (hasHonor ? '0.8rem' : '0')};
`;

export const GPAText = styled.span`
  font-size: 1.2rem;
  font-family: 'Roboto';
  margin-left: 0.3rem;
  color: ${palette.alpha500Red};
`;

export const GpaDenominator = styled.div`
  font-size: 1.6rem;
  font-family: 'Roboto';
  color: ${palette.alpha500Red};
`;

export const GpaNumerator = styled.div`
  font-family: 'RobotoBold';
  font-size: 2.4rem;
  color: ${palette.alpha500Red};
`;

export const TopEditButton = styled(EditButtonModal)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  fill: ${palette.gray500};
  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const GpaAwardColumn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const MajorText = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${palette.black};
`;

export const DegreeUniversityText = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.gray500};
`;

export const DegreeDate = styled.span`
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${palette.gray500};
`;
