import { FormValidationContainer, StyledErrorIcon } from 'components';
import React from 'react';

import { ErrorExclamation } from 'theme/assets';

const ErrorToastMessage: React.FC<{ message: string }> = ({ message }) => (
  <FormValidationContainer>
    <StyledErrorIcon src={ErrorExclamation} alt="logo" />
    <p>{message}</p>
  </FormValidationContainer>
);

export default ErrorToastMessage;
