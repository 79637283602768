import React from 'react';
import {
  Container,
  CardHeader,
  CompanySection,
  Expiration,
  HeaderIconsContainer,
  Info,
  HeaderIcon,
  AvatarIcon,
} from './JobCard.styled';
import { Text } from 'theme/typography';
import { Badge } from 'components/Badge';
import { Hand, HandRised, Bookmark, BookmarkSelected, Apple } from 'theme/assets';
import { palette } from 'theme';

interface JobCardProp {
  title: string;
  jobType?: string;
  companyAvatar?: string;
  companyName: string;
  companyLocation: string;
  expirationDaysLeft: string;
  hasRisehand: boolean;
  hasBookmark: boolean;
  onClickBookmark: () => void;
  onClickRisehand: () => void;
  redirectToJobDetail: () => void;
}

const JobCard: React.FC<JobCardProp> = ({
  title,
  jobType,
  companyAvatar,
  companyName,
  companyLocation,
  expirationDaysLeft,
  hasRisehand,
  hasBookmark,
  onClickBookmark,
  onClickRisehand,
  redirectToJobDetail,
}) => {
  return (
    <Container onClick={() => redirectToJobDetail()}>
      <CardHeader>
        <Info>
          <Text variant="l" bold>
            {title}
          </Text>
        </Info>
        <HeaderIconsContainer>
          <HeaderIcon
            src={hasRisehand ? HandRised : Hand}
            onClick={(e) => {
              onClickRisehand();
              e.stopPropagation();
            }}
          />
          <HeaderIcon
            src={hasBookmark ? BookmarkSelected : Bookmark}
            onClick={(e) => {
              onClickBookmark();
              e.stopPropagation();
            }}
          />
        </HeaderIconsContainer>
      </CardHeader>
      <Badge variant="solid" size="medium" color={jobType === 'full time' ? 'gamma' : 'alpha'}>
        {jobType}
      </Badge>
      <CompanySection>
        <AvatarIcon src={companyAvatar || Apple} />
        <Info>
          <Text variant="m">{companyName}</Text>
          <Text variant="s" color={palette.gray400}>
            {companyLocation}
          </Text>
        </Info>
      </CompanySection>
      <Expiration>
        <Text variant="s" color={palette.gray400}>
          {expirationDaysLeft !== 'Invalid date' ? `${expirationDaysLeft} days left` : ''}
        </Text>
      </Expiration>
    </Container>
  );
};

export default JobCard;
