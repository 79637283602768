import React, { useState, useEffect } from 'react';
import SpinnerPage from 'components/SpinnerPage';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { WizardProps } from '../components/interface';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import { toBase64 } from 'utils/fileUtils';
import { useInsertResumeProfileMutationHook, useUpdateResumeProfileMutationHook } from './ResumeUploader.hooks';
import { useGetUploadDocByUserQuery } from 'generated/graphql';
import ResumeUploader from './ResumeUploader';
import { SUBMIT_GO_TO, WizardPage } from '../components';

export interface ButtonsProps {
  onNext?: () => void;
  onSkip?: () => void;
}

const ResumeStepController: React.FC<WizardProps> = ({ nextGoTo = SUBMIT_GO_TO, step, totalSteps, backGoTo }) => {
  const {
    user: { sub: userId },
  } = useAuth0();
  const { width } = useWindowDimensions();
  const history = useHistory();

  const { loading, data } = useGetUploadDocByUserQuery({
    variables: { userId },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [newFileName, setNewFileName] = useState<string>(data?.grad_upload_doc[0]?.filename || '');
  const [newFile, setNewFile] = useState<File | null>();
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [insertUploadDocMutation] = useInsertResumeProfileMutationHook(userId);
  const [updateUploadDocMutation] = useUpdateResumeProfileMutationHook(userId);

  useEffect(() => {
    setNewFileName(data?.grad_upload_doc[0]?.filename || '');
  }, [data]);

  if (loading) return <SpinnerPage />;

  const onSubmit = async () => {
    if (!newFile) {
      history.push(nextGoTo);
      return;
    }

    const hexContent = await toBase64(newFile);
    const { grad_upload_doc: [{ upload_doc_id } = { upload_doc_id: null }] = [] } = data || {};
    setUploadingFile(true);
    if (upload_doc_id) {
      await updateUploadDocMutation({
        variables: {
          uploadDocId: upload_doc_id,
          fileName: newFile.name,
          hexContent,
        },
      });
    } else {
      await insertUploadDocMutation({
        variables: {
          fileName: newFile.name,
          hexContent,
          userId,
        },
      });
    }
    setNewFile(null);
    history.push(nextGoTo);
  };

  return (
    <WizardPage
      onNext={() => onSubmit()}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons={!uploadingFile}
      disabledNext={!newFileName}
      onPrevious={() => history.push(backGoTo || '')}
    >
      <ResumeUploader setFile={setNewFile} file={newFileName} setNewFileName={setNewFileName} loading={uploadingFile} />
    </WizardPage>
  );
};

export default ResumeStepController;
