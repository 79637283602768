import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useForm } from 'react-hook-form';
import ModalOpenType from 'types/ModalOpen';
import { InputLegend } from '../../../Wizard/components';
import { WizardRequiredInputLabel } from '../../../Wizard/components/library';
import { Container } from './CurrentDegree.styled';
import {
  useInsertMyDegreeAcademicStepMutation,
  useInsertFillUniversityMutation,
  useInsertConcentrationsMutation,
  useDeleteConcentrationsMutation,
  useUpdateMyDegreeAcademicStepMutation,
} from 'generated/graphql';

import { ConcentrationTypes } from 'data/degree';
import { prepareConcentrationForUpsert } from 'components/MajorMinorSelector/concentrationUtils';
import Modal from 'components/common/EditProfileModal';
import {
  UniversitySelectorCreate,
  DegreePickerOption,
  MajorSelector,
  MonthYearPicker,
  OptionId,
  StyledCheckboxContainer,
} from 'components';

import DegreePicker from 'components/DegreePicker';
import CheckboxWithController from 'components/CheckboxWithController';
import GPAInput from 'components/GPAInput';
import { useWindowDimensions, mobileBreakpoint } from 'utils/useDimensions';

interface PictureStepProps {
  setModalOpen: (status: ModalOpenType) => void;
  majorOptions: OptionId[];
  gpa: number | null;
  gpa_outof: number;
  complete_month_numeric: number | null;
  complete_year: number;
  gpa_not_applicable: boolean;
  degree_level: string;
  degree: string;
  degreeId: string;
  fillInUniversity: string;
  universityName: string;
  universityId: string;
}

export interface DegreeFormVariables {
  gradEndMonth: { value: number; label: string };
  gradEndYear: { value: number; label: string };
  GPA: string;
  gpaOutOf: { value: number; label: string };
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  completed: boolean;
  majors: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  gpaNotApplicable: boolean;
}

const PictureStepController: React.FC<PictureStepProps> = ({
  setModalOpen,
  majorOptions,
  degreeId,
  gpa,
  gpa_outof,
  complete_month_numeric,
  complete_year,
  degree,
  degree_level,
  gpa_not_applicable,
  fillInUniversity,
  universityName,
  universityId,
}) => {
  const { user } = useAuth0();
  const { width } = useWindowDimensions();

  const [updateMyDegree] = useUpdateMyDegreeAcademicStepMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertFillUniversity] = useInsertFillUniversityMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertConcentrations] = useInsertConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteConcentrations] = useDeleteConcentrationsMutation({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const onSubmit = async (data: DegreeFormVariables, watchedGPANotApplicable: boolean) => {
    const {
      gradEndMonth,
      gradEndYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA = watchedGPANotApplicable ? null : data.GPA,
      gpaOutOf = watchedGPANotApplicable ? null : { value: data.gpaOutOf },
      majors: { optionsValue: optionsMajorsNew, deletedOptionsIds: deletedMajorsIds },
      degreeSelectionOption,
      gpaNotApplicable,
    } = data;
    const variables = {
      gradEndMonth: gradEndMonth?.value,
      gradEndYear: gradEndYear?.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degreeSelectionOption?.[0]?.degree || '',
      degreeLevel: degreeSelectionOption?.[0]?.degree_level || '',
      completed: false,
      gpaNotApplicable,
    };

    const getEraseOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !initial
        ? []
        : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));

    const getAddOptions = (selected: OptionId[] = [], initial: OptionId[] = []) =>
      !selected
        ? []
        : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (degreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId, userId: user.sub };
        await insertFillUniversity({ variables: fillInUniversity });
      }
      if (optionsMajorsNew) {
        const majorsAdd = getAddOptions(optionsMajorsNew, majorOptions);
        const majorsForUpsert = prepareConcentrationForUpsert(
          majorsAdd,
          degreeId,
          user.sub,
          degreeId,
          ConcentrationTypes.MAJOR,
        );
        await insertConcentrations({
          variables: { concentrations: majorsForUpsert },
        });
      }
    }
    if (deletedMajorsIds) {
      const majorsToDelete = getEraseOptions(optionsMajorsNew, majorOptions)
        .map(({ id }) => id)
        .filter((id) => id !== undefined) as string[];
      await deleteConcentrations({ variables: { ids: majorsToDelete } });
    }

    await updateMyDegree({ variables: { degreeId, ...variables }, refetchQueries: ['GetProfileDegrees'] });
    setModalOpen('');
  };

  const { register, setValue, handleSubmit, watch, errors, control, clearError } = useForm<DegreeFormVariables>();
  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');

  return (
    <Modal
      setModalOpen={setModalOpen}
      heightAuto={width > mobileBreakpoint}
      handleSubmit={handleSubmit((values) => onSubmit(values, watchedNotApplicable))}
      title={'Current Degree Information'}
    >
      <Container>
        <WizardRequiredInputLabel error={!!errors.universitySelectionIdOption}>
          What college or university are you currently attending?
        </WizardRequiredInputLabel>

        <UniversitySelectorCreate
          required
          fillInValue={fillInUniversity}
          value={{ id: universityId, label: universityName, value: universityName }}
          name={'universitySelectionIdOption'}
          control={control}
          error={!!errors.universitySelectionIdOption}
        />
        {errors.universitySelectionIdOption && <InputLegend error>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!errors.degreeSelectionOption}>
          What degree will you earn?
        </WizardRequiredInputLabel>
        <DegreePicker
          name={'degreeSelectionOption'}
          control={control}
          defaultDegree={degree}
          defaultLevel={degree_level}
          error={!!errors.degreeSelectionOption}
          clearable
          required
        />
        {errors.degreeSelectionOption && <InputLegend error>Complete this field to move on</InputLegend>}

        <WizardRequiredInputLabel error={!!(errors.gradEndMonth || errors.gradEndYear)}>
          When will you graduate?
        </WizardRequiredInputLabel>
        <MonthYearPicker
          required
          monthFieldName="gradEndMonth"
          yearFieldName="gradEndYear"
          errors={errors}
          control={control}
          defaultMonth={complete_month_numeric}
          defaultYear={complete_year}
        />

        <WizardRequiredInputLabel error={!!errors.majors}>What is your Major?</WizardRequiredInputLabel>
        <MajorSelector
          required
          majorsValue={majorOptions}
          name={'majors'}
          clearErrors={() => clearError('majors')}
          register={register}
          setValue={setValue}
          error={!!errors.majors}
        />

        <StyledCheckboxContainer>
          <CheckboxWithController name="gpaNotApplicable" control={control} defaultChecked={!!gpa_not_applicable} />
          {'GPA is not applicable'}
        </StyledCheckboxContainer>
        {!watchedNotApplicable && (
          <>
            <WizardRequiredInputLabel error={!!errors.GPA}>What is your GPA?</WizardRequiredInputLabel>
            <GPAInput
              required
              defaultOutOf={gpa_outof}
              defaultGPA={gpa}
              watchedOutOf={watchedOutOf}
              gpaFieldName="GPA"
              outOfFieldName="gpaOutOf"
              control={control}
              register={register}
              error={!!errors.GPA}
            />
          </>
        )}
        {!watchedNotApplicable && errors.GPA && (
          <InputLegend error>{errors.GPA.message || 'Complete this field to move on'}</InputLegend>
        )}
      </Container>
    </Modal>
  );
};

export default PictureStepController;
