import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WizardInputLabel as Label } from '../components';
import LanguageSelector from '../../../components/LanguageSelector';
import { TechnicalLanguageSelector, YesNoPicker } from 'components';
import { OptionId } from '../../../components';
import { Title } from 'views/Wizard/Wizard.styled';
import { Column, Row, Container, FloatingJeannie } from './ExtraStep.styled';
import { WizardRequiredInputLabel as RequiredLabel } from '../components/library';
import { TextArea } from 'components/TextArea';
import { Spacing } from 'components/Spacing';
import { JeannieAlmostThere } from 'theme/assets';
import { InputLegend } from 'views/Wizard/components';

export interface ExtraStepFormProps {
  studentAthlete: string;
  personalAchievements: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  volunteerActivities: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  languages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

interface ExtraStepProps {
  isStudentAthlete: boolean;
  achievementsOptions: string;
  computerLanguagesOptions: OptionId[];
  volunteerOptions: string;
  languagesOptions: OptionId[];
}

const ExtraStep: React.FC<ExtraStepProps> = ({
  achievementsOptions,
  computerLanguagesOptions,
  languagesOptions,
  volunteerOptions,
  isStudentAthlete,
}) => {
  const { register, setValue, control, errors } = useFormContext<ExtraStepFormProps>();

  useEffect(() => {
    const studentAthleteDefault = isStudentAthlete ? 'yes' : 'no';
    isStudentAthlete !== null && setValue('studentAthlete', studentAthleteDefault);
  }, [isStudentAthlete]);

  return (
    <Container>
      <Title>Extra You</Title>
      <Row>
        <Column>
          <FloatingJeannie src={JeannieAlmostThere} />
          <Label>In addition to English, what language(s) do you speak?</Label>
          <LanguageSelector
            name={'languages'}
            defaultValue={languagesOptions}
            control={control}
            setValue={setValue}
            register={register}
            error={!!errors.languages}
            required={false}
          />
          {errors.languages && <InputLegend error={!!errors.languages}>Complete this field to move on</InputLegend>}

          <YesNoPicker name="studentAthlete" required title="Are you a student athlete?" register={register} />
          {errors.studentAthlete && (
            <InputLegend error={!!errors.studentAthlete}>Complete this field to move on</InputLegend>
          )}

          <Spacing margin="1rem" />

          <Label>Are you proficient in any technical languages?</Label>
          <TechnicalLanguageSelector
            name="computerLanguages"
            defaultValue={computerLanguagesOptions}
            control={control}
            setValue={setValue}
            register={register}
            error={!!errors.computerLanguages}
            required={false}
          />
          {errors.computerLanguages && (
            <InputLegend error={!!errors.computerLanguages}>Complete this field to move on</InputLegend>
          )}
        </Column>
        <Column>
          <Label>
            Have you received any awards or scholarships? Do you have any personal achievements you would like to share?
          </Label>
          <TextArea
            name="personalAchievements"
            placeholder="John Harvard Scholar, Scholarship Athlete - Tennis"
            ref={register}
            defaultValue={achievementsOptions}
          />
          <Spacing margin="1rem" />

          <Label>
            Do you volunteer, belong to any student clubs or participate in extracurricular activities? If so, tell us!
          </Label>
          <TextArea
            name="volunteerActivities"
            placeholder="Big Brothers Big Sisters, Camillis House, Salvation"
            ref={register}
            defaultValue={volunteerOptions}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default ExtraStep;
