import React from 'react';
import { Container, PlaceholderImg, ClearFilterContainer } from './EventListPlaceholder.styled';
import { NoWorkExperience } from 'theme/assets';
import { HeadingText, Text } from 'theme/typography';
import { palette } from 'theme';
import { useEventFiltersContext, defaultFiltersValue } from '../EventList/EventList.context';

const JobListPlaceholder = () => {
  const { handleFilterChange } = useEventFiltersContext();

  return (
    <Container>
      <PlaceholderImg src={NoWorkExperience} />
      <HeadingText variant="h4" color={palette.gray300}>
        Sorry, no results found
      </HeadingText>
      <ClearFilterContainer onClick={() => handleFilterChange(defaultFiltersValue)}>
        <Text variant="l" color={palette.alpha400Red} bold>
          Clear all filters
        </Text>
      </ClearFilterContainer>
    </Container>
  );
};

export default JobListPlaceholder;
