import React from 'react';
import { FormContextValues } from 'react-hook-form';
import { OptionId } from '../Option';
import SelectorMultiCreateV2 from '../SelectorMultiCreateV2';
import { useGetEmployeerPreferenceSelectionQuery } from 'generated/graphql';

interface EmployerPreferenceSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  values: OptionId[];
  name: string;
}

const EmployerPreferenceSelector: React.FC<EmployerPreferenceSelectorProps> = ({
  values,
  name,
  register,
  setValue,
}) => {
  const { loading, data } = useGetEmployeerPreferenceSelectionQuery({
    errorPolicy: 'all',
    onError: console.warn, // eslint-disable-line no-console
  });
  const selectorOptions = data?.grad_employer_preference_selection.map(({ value }) => ({
    value,
    label: value,
  }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={values}
      name={name}
      placeholder={'Select'}
      register={register}
      setValue={setValue}
      maxSelection={3}
      label
    />
  );
};

export default EmployerPreferenceSelector;
