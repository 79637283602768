import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'theme/assets';
import { StyledBackLink, StyledImg } from './GoBackLink.styled';

const GoBackLink: React.FC = () => {
  const history = useHistory();
  return (
    <StyledBackLink onClick={history.goBack}>
      <StyledImg src={ArrowLeft} alt="goBack" />
      Back
    </StyledBackLink>
  );
};

export default GoBackLink;
