import React, { useEffect } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { Redirect, Route, useLocation } from 'react-router-dom';

import UserType from 'types/User';
import { StudentViewPaths } from '../views/Student/StudentViewRoutes';

interface PrivateRouteProps {
  component: React.ComponentType<{
    user: UserType;
  }>;
  path?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path, ...rest }) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  // TODO: remove this code after testing the log in and sign up flow 02/08/21
  // const location = useLocation();

  // useEffect(() => {
  //   const fn = async () => {
  //     if (!isAuthenticated) {
  //       await loginWithRedirect({
  //         appState: { targetUrl: location.pathname + location.search + location.hash },
  //       });
  //     }
  //   };
  //   fn();
  //   // don't do this every time location changes
  //   // TODO Find better solution to CRA warning for react-hooks/exhaustive-deps
  //   // eslint-disable-next-line
  // }, [isAuthenticated, loginWithRedirect]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = (props: any) =>
    isAuthenticated === true ? (
      <Component
        {...props}
        user={user}
        isAuthenticated={isAuthenticated}
        loginWithRedirect={loginWithRedirect}
        logout={logout}
      />
    ) : (
      <Redirect to={StudentViewPaths.login} />
    );

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
