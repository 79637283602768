import React from 'react';
import { WizardFormInputs, WizardInputLabel, WizardPage } from '../components';
import { WizardRequiredInputLabel } from '../components/library';
import { Divider, FormContainer, TitleContainer } from './ProfessionalYou.styled';
import { Title } from '../Wizard.styled';
import {
  OptionId,
  WantToKnowSelector,
  EmployerPreferenceSelector,
  JobRoleInterestPicker,
  JobTypeInterestPicker,
  YesNoPicker,
} from 'components';
import RelevantYearsSelector from '../../../components/RelevantYearsSelector';
import SelectorMultiCreateV2 from '../../../components/SelectorMultiCreateV2';
import { PROFESSIONAL_DESIGNATIONS_OPTIONS } from '../../../data/awards';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
export interface PersonalYouFormVariables {
  years_of_relevant_work: { value: string; label: string };
  professionalDesignations: { optionsValue: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  funFacts: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  employerPreferences: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  interestRoles: { current: OptionId[] };
  interestJobType: OptionId;
  careerInFinance: boolean;
}

interface ProfessionalYouProps {
  onSubmit: (values: PersonalYouFormVariables) => void;
  years_of_relevant_work?: string | null;
  funFactsOptions?: OptionId[];
  professionalDesignationsOptions?: OptionId[];
  employerPreferences?: OptionId[];
  interestRoles?: OptionId[];
  step: number;
  totalSteps: number;
  interestJobType: OptionId;
  backGoTo: string;
}

const ProfessionalYouStep: React.FC<ProfessionalYouProps> = ({
  onSubmit,
  years_of_relevant_work,
  funFactsOptions,
  professionalDesignationsOptions,
  step,
  totalSteps,
  interestRoles,
  employerPreferences,
  interestJobType,
  backGoTo,
}) => {
  const { register, control, handleSubmit, errors, setValue, clearError } = useForm<PersonalYouFormVariables>();
  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <WizardPage
      onNext={handleSubmit(onSubmit)}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons
      onPrevious={() => history.push(backGoTo)}
    >
      <WizardFormInputs>
        <TitleContainer>
          <Title>Professional You</Title>
        </TitleContainer>
        <FormContainer>
          <Divider>
            <YesNoPicker
              name="careerInFinance"
              required
              title="Are you absolutely, 100% sure you want a career in finance?"
              defaultValue={'yes'}
              register={register}
            />
            <WizardRequiredInputLabel error={!!errors.interestJobType}>
              Are you looking for a full-time role or an internship?
            </WizardRequiredInputLabel>
            <JobTypeInterestPicker
              name="interestJobType"
              defaultValue={interestJobType}
              control={control}
              required={true}
              placeholder="Select"
              error={!!errors.interestJobType}
            />

            <WizardRequiredInputLabel error={!!errors.interestRoles}>
              What type of role are you interested in?
            </WizardRequiredInputLabel>
            <JobRoleInterestPicker
              name="interestRoles"
              defaultValue={interestRoles}
              control={control}
              register={register}
              setValue={setValue}
              required={true}
              placeholder="Select"
              error={!!errors.interestRoles}
              clearErrors={() => clearError('interestRoles')}
              legend
            />

            <WizardInputLabel>Do you have any professional designations?</WizardInputLabel>
            <SelectorMultiCreateV2
              showMenu={true}
              dropDownIndicator={true}
              dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
              defaultOptionsValue={professionalDesignationsOptions || []}
              name="professionalDesignations"
              placeholder="Select"
              register={register}
              setValue={setValue}
              clearErrors={() => clearError('professionalDesignations')}
              error={!!errors.professionalDesignations}
              required={false}
            />
          </Divider>
          <Divider>
            <WizardRequiredInputLabel error={!!errors.years_of_relevant_work}>
              How many years of relevant work experience/internships do you have?
            </WizardRequiredInputLabel>
            <RelevantYearsSelector
              name={'years_of_relevant_work'}
              defaultValue={years_of_relevant_work || ''}
              control={control}
              required={true}
              error={!!errors.years_of_relevant_work}
            />

            <WizardInputLabel>What I want an employer to know about me</WizardInputLabel>
            <WantToKnowSelector
              name="funFacts"
              register={register}
              setValue={setValue}
              values={funFactsOptions || []}
            />
            <WizardInputLabel>What I look for in an employer?</WizardInputLabel>
            <EmployerPreferenceSelector
              name="employerPreferences"
              register={register}
              setValue={setValue}
              values={employerPreferences || []}
            />
          </Divider>
        </FormContainer>
      </WizardFormInputs>
    </WizardPage>
  );
};

export default ProfessionalYouStep;
