import React from 'react';
import Select from 'react-select';
import { mergeStyles } from 'react-select';
import { palette, selectorStyle } from 'theme';
import { Text } from 'theme/typography';
import Toggle from 'components/common/Toggle';

import { useJobFiltersContext } from '../JobList/JobList.context';
import useJobsDynamicQuery from '../JobList/useJobsDynamicQuery';

import { DrawerContainer, FiledContainer } from './JobListHeaderDrawer.styled';
import { Spacing } from 'components/Spacing';
import { TextInput } from 'components';
import { Button } from 'components/common/Button';
import { useAuth0 } from 'react-auth0-spa';
import { useGetProfileHeaderDataQuery } from 'generated/graphql';

const overrideStyles = {
  container: (base: object) => ({
    ...base,
    height: '3.4rem',
    marginTop: '0.5rem',
    color: `${palette.gray900}`,
  }),
  control: (base: object) => ({
    ...base,
    minHeight: '3.4rem',
    height: '3.4rem',
    borderRadius: '8px 0px 0px 8px',
    color: `${palette.gray900}`,
    fontSize: '1.2rem',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: palette.white,
  }),
};

const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

interface DrawerProps {
  onApply: () => void;
  onCancel: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ onApply, onCancel }) => {
  const { filters, handleFilterChange, clearFilters, filterActive } = useJobFiltersContext();
  const { subIndustry, role, keyword, hasHandraise, hasBookmark } = filters;
  const { user } = useAuth0();
  const { data } = useGetProfileHeaderDataQuery({ variables: { userId: user.sub } });

  const subIndustryOptions = data?.grad_interest_firm_selection.map((firm) => {
    return { label: firm.value, value: firm.value };
  });

  const jobTypeOptions = data?.grad_interest_job_type_selection.map((jobType) => {
    return { label: jobType.value, value: jobType.value };
  });

  return (
    <DrawerContainer>
      <Text variant="l" color={palette.alpha500Red} bold>
        Filters
      </Text>
      <Spacing margin="1rem" />
      <Toggle
        active={hasHandraise}
        label="Hand raised"
        onChange={(value: boolean) => handleFilterChange({ hasHandraise: !value })}
      />
      <Spacing margin="1rem" />
      <Toggle
        active={hasBookmark}
        label="Saved"
        onChange={(value: boolean) => handleFilterChange({ hasBookmark: !value })}
      />
      <Spacing margin="1rem" />
      {/* TODO: integrate this field with the company sub industry of the jobs */}
      {/* <FiledContainer>
        <Text variant="m" bold color={palette.black}>
          Sub Industry
        </Text>
        <Select
          options={subIndustryOptions}
          defaultValue={subIndustry.map((sub) => {
            return { label: sub, value: sub };
          })}
          isClearable={false}
          isMulti
          styles={selectorOwnStyles}
          isSearchable={false}
          placeholder="Select..."
          onChange={(selectedOption) =>
            selectedOption && handleFilterChange({ subIndustry: selectedOption.map((option) => option.value) })
          }
        />
      </FiledContainer> */}
      <FiledContainer>
        <Text variant="m" bold color={palette.black}>
          Role
        </Text>
        <Select
          options={jobTypeOptions}
          isClearable={false}
          styles={selectorOwnStyles}
          isSearchable={false}
          placeholder="Select..."
          defaultValue={(role && { label: role, value: role }) || null}
          onChange={(selectedOption) =>
            selectedOption && handleFilterChange({ role: (Object.values(selectedOption)[0] as unknown) as string })
          }
        />
      </FiledContainer>
      <FiledContainer>
        <Text variant="m" bold color={palette.black}>
          Keyword
        </Text>
        <Spacing margin="0.3rem" />
        <TextInput
          placeholder="Text here"
          value={keyword}
          onChange={(keyword) => handleFilterChange({ keyword })}
          customFontSize="1.3rem"
        />
      </FiledContainer>
      <Spacing margin="1rem" />
      <Button size="md" color="beta" isRounded onClick={onApply} isDisabled={!filterActive}>
        <Text variant="l" color="white" bold>
          Apply
        </Text>
      </Button>
      <Spacing margin="0.5rem" />
      <Button
        size="md"
        color="beta"
        isRounded
        onClick={() => {
          clearFilters();
          onApply();
        }}
        variant="outline"
      >
        <Text variant="l" color="beta" bold>
          Clear all filters
        </Text>
      </Button>
      <Spacing margin="0.5rem" />
      {/* TODO: make the apply button applies the filters and implement the cancel button after that */}
      {/* <Button size="lg" color="beta" isRounded variant="ghost" onClick={handleCancel}>
        <Text variant="l" color="beta" bold>
          Cancel
        </Text>
      </Button> */}
    </DrawerContainer>
  );
};

export default Drawer;
