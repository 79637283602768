import React from 'react';
import { WizardFormInputs, WizardPage } from '../components';
import { StyledInput } from '../../../components';
import { useWindowDimensions, mobileBreakpoint } from '../../../utils/useDimensions';
import { WizardRequiredInputLabel } from '../components/library';
import {
  StyledLabelWrite,
  StyledSatContainer,
  TitleContainer,
  TestCard,
  CheckboxContainer,
  TestContainer,
  TestLabel,
  TestLabelContainer,
  NotApplicable,
} from './TestStep.styled';
import { Title } from '../Wizard.styled';
import { TestOutOf, TestSATDetailsOutof } from '../../../data/tests';
import { generateErrorMessages } from '../../../components/FormValidationMessage';
import CheckboxWithController from '../../../components/CheckboxWithController';
import { useForm } from 'react-hook-form';
import { ParsedTests } from '../../../utils/testUtils';
import { useHistory } from 'react-router-dom';

export interface TestsScoresFormVariables {
  [name: string]: number;
  satMathScore: number;
  satWritingScore: number;
  satReadingScore: number;
  actScore: number;
}
interface TestStepProps {
  step: number;
  isLastStep: boolean;
  testsData: ParsedTests;
  cancelSkipGoTo: string;
  totalSteps: number;
  backGoTo: string;
  onSubmit: (values: TestsScoresFormVariables) => void;
}

const TestStep: React.FC<TestStepProps> = ({ onSubmit, step, totalSteps, backGoTo, testsData }) => {
  const { register, handleSubmit, errors, watch, control } = useForm<TestsScoresFormVariables>();
  const actValue = watch('actScore', '');
  const satMathScore = watch('satMathScore', '');
  const satWritingScore = watch('satWritingScore', '');
  const satReadingScore = watch('satReadingScore', '');
  const watchedSatNotApplicable = !!watch('satNotApplicable');
  const watchedActNotApplicable = !!watch('actNotApplicable');
  const satIncomplete = !satMathScore || !satWritingScore || ((!satMathScore || !satWritingScore) && satReadingScore);
  const satRequired = watchedSatNotApplicable ? false : !actValue || satIncomplete;
  const actRequired = !satRequired;

  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <WizardPage
      onNext={handleSubmit(onSubmit)}
      showBackground={width >= mobileBreakpoint}
      step={step}
      totalSteps={totalSteps}
      showButtons
      onPrevious={() => history.push(backGoTo)}
    >
      <WizardFormInputs>
        <TitleContainer>
          <Title>Test Information</Title>
        </TitleContainer>
        <TestCard>
          {!watchedSatNotApplicable && (
            <>
              <WizardRequiredInputLabel>SAT</WizardRequiredInputLabel>
              <StyledSatContainer>
                <TestContainer>
                  <TestLabel error={!!errors.satMathScore}>Math</TestLabel>
                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.math
                        ? testsData.SAT.test_score_details.math.score
                        : undefined
                    }
                    type="number"
                    name="satMathScore"
                    placeholder="Math"
                    ref={register({
                      required: satRequired ? 'Math is required if no ACT value' : false,
                      max: {
                        value: TestSATDetailsOutof.MATH,
                        message: `Maximum score is ${TestSATDetailsOutof.MATH}`,
                      },
                    })}
                    error={!!errors.satMathScore}
                    narrow
                  />
                  {generateErrorMessages(errors.satMathScore)}
                </TestContainer>

                <TestContainer>
                  <TestLabel error={!!errors.satReadingScore}>Reading</TestLabel>

                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.reading
                        ? testsData.SAT.test_score_details.reading.score
                        : undefined
                    }
                    type="number"
                    name="satReadingScore"
                    placeholder="Reading"
                    ref={register({
                      required: satRequired ? 'Reading is required if no ACT value' : false,
                      max: {
                        value: TestSATDetailsOutof.READING,
                        message: `Maximum score is ${TestSATDetailsOutof.READING}`,
                      },
                    })}
                    error={!!errors.satReadingScore}
                    narrow
                  />
                  {generateErrorMessages(errors.satReadingScore)}
                </TestContainer>

                <TestContainer>
                  <TestLabelContainer>
                    <TestLabel error={!!errors.satWritingScore}>Writing</TestLabel>
                    <StyledLabelWrite>{'(optional)'}</StyledLabelWrite>
                  </TestLabelContainer>
                  <StyledInput
                    defaultValue={
                      testsData.SAT && testsData.SAT.test_score_details.writing
                        ? testsData.SAT.test_score_details.writing.score
                        : undefined
                    }
                    type="number"
                    name="satWritingScore"
                    placeholder="Writing"
                    ref={register({
                      max: {
                        value: TestSATDetailsOutof.WRITING,
                        message: `Maximum score is ${TestSATDetailsOutof.WRITING}`,
                      },
                    })}
                    error={!!errors.satWritingScore}
                    narrow
                  />
                  {generateErrorMessages(errors.satWritingScore)}
                </TestContainer>
              </StyledSatContainer>
            </>
          )}
          <CheckboxContainer>
            <CheckboxWithController
              name="satNotApplicable"
              control={control}
              black
              defaultChecked={!!watchedSatNotApplicable}
            />
            <NotApplicable>{'SAT not applicable'}</NotApplicable>
          </CheckboxContainer>
        </TestCard>
        <TestCard>
          {!watchedActNotApplicable && (
            <>
              <WizardRequiredInputLabel error={!!errors.actScore}>ACT</WizardRequiredInputLabel>
              <StyledInput
                type="number"
                name="actScore"
                placeholder="Enter your ACT Score score"
                defaultValue={testsData.ACT ? testsData.ACT.score : undefined}
                ref={register({
                  required: actRequired ? 'ACT is required if no SAT value' : false,
                  max: { value: TestOutOf.ACT, message: `Maximum score is ${TestOutOf.ACT}` },
                })}
                error={!!errors.actScore}
                narrow
              />
              {generateErrorMessages(errors.actScore)}
            </>
          )}
          <CheckboxContainer>
            <CheckboxWithController
              name="actNotApplicable"
              control={control}
              black
              defaultChecked={!!watchedActNotApplicable}
            />
            <NotApplicable>{'ACT not applicable'}</NotApplicable>
          </CheckboxContainer>
        </TestCard>
        <TestCard>
          <StyledSatContainer split>
            <TestContainer>
              <TestLabel error={!!errors.greScore}>GRE</TestLabel>
              <StyledInput
                type="number"
                name="greScore"
                placeholder="Enter your GRE score"
                defaultValue={testsData.GRE ? testsData.GRE.score : undefined}
                ref={register({
                  max: { value: TestOutOf.GRE, message: `Maximum score is ${TestOutOf.GRE}` },
                })}
                error={!!errors.greScore}
                narrow
              />
              {generateErrorMessages(errors.greScore)}
            </TestContainer>
            <TestContainer>
              <TestLabel error={!!errors.gmatScore}>GMAT</TestLabel>
              <StyledInput
                type="number"
                name="gmatScore"
                placeholder="Enter your GMAT score"
                defaultValue={testsData.GMAT ? testsData.GMAT.score : undefined}
                ref={register({
                  max: { value: TestOutOf.GMAT, message: `Maximum score is ${TestOutOf.GMAT}` },
                })}
                error={!!errors.gmatScore}
                narrow
              />
              {generateErrorMessages(errors.gmatScore)}
            </TestContainer>
            <TestContainer>
              <TestLabel error={!!errors.lsatScore}>LSAT</TestLabel>
              <StyledInput
                type="number"
                name="lsatScore"
                placeholder="Enter your LSAT score"
                defaultValue={testsData.LSAT ? testsData.LSAT.score : undefined}
                ref={register({
                  max: { value: TestOutOf.LSAT, message: `Maximum score is ${TestOutOf.LSAT}` },
                })}
                error={!!errors.lsatScore}
                narrow
              />
              {generateErrorMessages(errors.lsatScore)}
            </TestContainer>
          </StyledSatContainer>
        </TestCard>
      </WizardFormInputs>
    </WizardPage>
  );
};

export default TestStep;
