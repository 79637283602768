import React, { ChangeEvent, UIEventHandler } from 'react';
import JobsListCard from '../JobsListCard';
import { CardsContainer } from './JobList.styled';
import JobListPlaceholder from '../JobListPlaceholder';
import InfiniteScroll from 'react-infinite-scroll-component';

import { GetJobPostingsListQuery } from 'generated/graphql';
import Spinner from 'views/Student/StudentWidget/Resume/ResumeSpinner';

interface JobListProps {
  jobs: GetJobPostingsListQuery | null;
  inMobile: boolean;
  fetchMore: () => void;
  hasFilters?: boolean;
}

const JobList: React.FC<JobListProps> = ({ jobs, inMobile, fetchMore, hasFilters }) => {
  const hasJobs = Boolean(jobs?.recruit_job_posting?.length);
  const hasMoreJobs =
    Number(jobs?.recruit_job_posting_aggregate.aggregate?.count) > Number(jobs?.recruit_job_posting?.length);

  return hasJobs ? (
    <CardsContainer id="cardsContainer">
      {inMobile ? (
        <InfiniteScroll
          dataLength={jobs?.recruit_job_posting?.length || 15}
          next={() => fetchMore()}
          hasMore={hasMoreJobs}
          loader={<Spinner />}
          scrollableTarget="cardsContainer"
        >
          {jobs?.recruit_job_posting?.map((job) => (
            <JobsListCard
              key={job.job_posting_id}
              bookmark_jobs={job.bookmark_jobs}
              account_id={job.account_id}
              job_posting_id={job.job_posting_id}
              job_type={job.job_type}
              position_title={job.position_title}
              publishing_date={job.publishing_date}
              region={job.region}
              raise_hands={job.raise_hands || null}
              deadline={job.deadline}
            />
          ))}
        </InfiniteScroll>
      ) : (
        jobs?.recruit_job_posting?.map((job) => (
          <JobsListCard
            key={job.job_posting_id}
            bookmark_jobs={job.bookmark_jobs}
            account_id={job.account_id}
            job_posting_id={job.job_posting_id}
            job_type={job.job_type}
            position_title={job.position_title}
            publishing_date={job.publishing_date}
            region={job.region}
            raise_hands={job.raise_hands || null}
            deadline={job.deadline}
          />
        ))
      )}
    </CardsContainer>
  ) : (
    <JobListPlaceholder hasFilters={hasFilters} />
  );
};

export default JobList;
