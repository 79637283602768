import React from 'react';
import { saveAs } from 'file-saver';
import { Spinner } from './ResumeSpinner';
import CollapsibleWidgetCard from '../CollapsibleWidgetCard';
import {
  Title,
  Container,
  UploadedResumeIcon,
  DefaultResumeIcon,
  Text,
  TopEditButton,
  TopAddButton,
  InfoContainer,
  WarningText,
  ResumeName,
  SpinnerContainer,
} from './Resume.styled';

export interface ResumeProps {
  fileName: string;
  content: string;
  loading: boolean;
  fullProfile?: boolean;
  handleEdit: () => void;
}

const Resume: React.FC<ResumeProps> = ({ fileName, content, loading, fullProfile = false, handleEdit }) => {
  const handleDownload = async () => {
    const res: Response = await fetch(content);
    const blob: Blob = await res.blob();
    saveAs(blob, fileName);
  };

  return (
    <CollapsibleWidgetCard fixed>
      {loading ? (
        <SpinnerContainer>
          <Title>Resume</Title>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {fileName ? (
            <Container hasFile>
              <UploadedResumeIcon />
              <InfoContainer hasFile>
                <Title>Resume {fullProfile && <TopEditButton onClick={handleEdit} />}</Title>
                <ResumeName onClick={handleDownload}>{fileName}</ResumeName>
              </InfoContainer>
            </Container>
          ) : (
            <Container>
              <DefaultResumeIcon />
              <InfoContainer>
                <Title>Resume {fullProfile && <TopAddButton onClick={handleEdit} />}</Title>
                <Text>No resume Uploaded</Text>
                <WarningText>Won´t be able to apply to jobs</WarningText>
              </InfoContainer>
            </Container>
          )}
        </>
      )}
    </CollapsibleWidgetCard>
  );
};

export default Resume;
