import React, { useState } from 'react';
import { Controller, FormContextValues } from 'react-hook-form';
import Checkbox from 'components/CheckBox';

interface CheckboxWithControllerProps extends Pick<FormContextValues, 'control'> {
  defaultChecked?: boolean;
  name: string;
  black?: boolean;
}

const CheckboxWithController: React.FC<CheckboxWithControllerProps> = ({
  name,
  defaultChecked = false,
  control,
  black = false,
}) => {
  const [toggle, setToggle] = useState(defaultChecked);

  const handleChange = (checked: boolean) => setToggle(checked);

  return (
    <Controller
      as={<Checkbox onChange={handleChange} checked={toggle} black={black} />}
      name={name}
      control={control}
      defaultValue={defaultChecked}
    />
  );
};

export default CheckboxWithController;
