import React, { useCallback, useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';

import QuerySelectorCreate from 'components/QuerySelectorCreate';
import { useGetCompanySelectionsLazyQuery } from 'generated/graphql';

const SEARCH_RESULTS_LIMIT = 6;

export interface CompanyPickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultValue?: string | null;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
}

const CompanyPicker: React.FC<CompanyPickerProps> = ({
  name,
  control,
  clearable = false,
  required = false,
  defaultValue = null,
  placeholder = undefined,
  error = false,
}) => {
  const [queryCompanies, { loading: loadingCompanies, data: dataCompanies }] = useGetCompanySelectionsLazyQuery({
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const handleOnSearch = useCallback(
    (searchTerm) => {
      queryCompanies({
        variables: {
          searchTerm: `%${searchTerm}%`,
          limit: SEARCH_RESULTS_LIMIT,
        },
      });
    },
    [queryCompanies],
  );

  const defaultCompanyOption = useMemo(() => {
    if (defaultValue) {
      return {
        label: defaultValue || '',
        value: defaultValue || '',
      };
    } else return null;
  }, [defaultValue]);

  const companyOptions = useMemo(
    () =>
      ((dataCompanies && dataCompanies.grad_company_selection) || []).map(({ company, company_selection_id }) => ({
        value: company,
        label: company,
        id: company_selection_id,
      })),
    [dataCompanies],
  );

  return (
    <QuerySelectorCreate
      defaultOptions={companyOptions}
      defaultOptionValue={defaultCompanyOption}
      name={name}
      placeholder={placeholder}
      control={control}
      loading={loadingCompanies}
      isClearable={clearable}
      required={required}
      onSearch={handleOnSearch}
      error={error}
    />
  );
};

export default CompanyPicker;
