import { colors } from 'react-select/src/theme';
import styled from 'styled-components';
import { palette } from 'theme';
import { SIZE, colorPicker } from './Button.constants';

type ButtonColorType = 'white' | 'alpha' | 'beta';
type ButtonSizeType = 'md' | 'lg';
export type ButtonStyleType = 'default' | 'outline' | 'ghost' | 'link';

export const Button = styled.button<{
  size: ButtonSizeType;
  color: ButtonColorType;
  variant?: ButtonStyleType;
  hasShadow?: boolean;
  isDisabled?: boolean;
  isRounded?: boolean;
  noPadding?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ size, noPadding }) => (noPadding ? '' : SIZE[size].padding)};

  background-color: ${({ color, variant }) => colorPicker(variant)[color].default.background};
  border: ${({ color, variant }) => `2px solid ${colorPicker(variant)[color].default.border}}`};
  border-radius: ${({ isRounded }) => (isRounded ? '100px' : '8px')};

  font-size: 1.4rem;
  color: ${({ color, variant }) => colorPicker(variant)[color].default.text};

  white-space: pre-line;
  word-break: break-all;
  cursor: pointer;
  filter: ${({ hasShadow }) => (hasShadow ? 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));' : 'none')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};

  :hover {
    border-color: ${({ color, variant }) => colorPicker(variant)[color].hover.border};
    color: ${({ color, variant }) => colorPicker(variant)[color].hover.text};
    background-color: ${({ color, variant }) => colorPicker(variant)[color].hover.background};
  }

  :active {
    border-color: ${({ color, variant }) => colorPicker(variant)[color].press.border};
    color: ${({ color, variant }) => colorPicker(variant)[color].press.text};
    background-color: ${({ color, variant }) => colorPicker(variant)[color].press.background};
  }

  //TODO
  ${({ isDisabled, color }) => isDisabled && `color: COLOR[${color}].disabled.text`}
`;
